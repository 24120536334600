export class SceneGraphAudioVisualStateEventComponent {
    json;

    constructor(json) {
        this.json = json;
    }

    initializeFromJson() {}

    get variations() {
        return this.json.variations;
    }
}
