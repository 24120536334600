//@ts-check
import { Action } from "./action";
import { ActivateAction } from "../sceneActions/ActivateAction";
import { BackAction } from "../sceneActions/BackAction";
import { NavigateAction } from "../sceneActions/NavigateAction";
import { NavigateHomeAction } from "../sceneActions/NavigateHomeAction";
//import { OpenAreaSizedModuleAction } from "../sceneActions/OpenAreaSizedModuleAction";
import { OpenFullscreenModuleAction } from "../sceneActions/OpenFullscreenModuleAction";
//import { OpenSceneSizedModuleAction } from '../sceneActions/OpenSceneSizedModuleAction';
import { ToggleAction } from "../sceneActions/ToggleAction";
import { PlayAudioAction } from "../sceneActions/playAudio";
import { ExitModule } from "../sceneActions/ExitModule";
import { CallWebFunction } from "../sceneActions/CallWebFunction";
import { ToggleApplicationSettingAction } from "../sceneActions/ToggleApplicationSettingAction";
import { SetApplicationSettingAction } from "../sceneActions/SetApplicationSettingAction";
import { ChangeSelectionMode } from "../sceneActions/ChangeSelectionMode";
import { ToggleInteractiveVisibility } from "../sceneActions/ToggleInteractiveVisibility";
import { AudioVisualScriptNextStateAction } from "../sceneActions/AudioVisualScriptNextStateAction";
import { WebApplication } from "../../webApplication";
import { RefreshPageAction } from "../sceneActions/RefreshPageAction";
import { ToggleRadio } from "../sceneActions/ToggleRadioAction";
import { ChangeRadioStationAction } from "../sceneActions/ChangeRadioStationAction";
import { NavigateBrowserAction } from "../sceneActions/NavigateBrowserAction";
import { NextAudioVisualScriptPlaybackQueueItem } from "../sceneActions/NextAudioVisualScriptPlaybackQueueItem";
import { OpenModuleRedirectAction } from "../sceneActions/OpenModuleRedirectAction";
/**
 *
 */
export class Actions {
    /**
     * @type {string}
     */
    static typename = "actions";

    /**
     * @type {Array.<Action>}
     */
    actions = [];
    /**
     *
     * @param {object} j
     * @param {WebApplication} application
     */
    addActionFromJson(j, application) {
        var a = newActionFromJson(j, application);
        if (a) {
            this.actions.push(a);
        }
    }
    /**
     *
     * @param {object} json
     * @param {WebApplication} application
     */
    parseJson(json, application) {
        for (let each in json) {
            if (each == Action.typename) {
                this.addActionFromJson(json[each], application);
            } else if (each == Actions.typename) {
                for (let each_action in json[each]) {
                    this.addActionFromJson(json[each][each_action], application);
                }
            }
        }
    }
    /**
     *
     * @param {import('../scene').SceneObjectInterface} param
     */
    runActions(param) {
        for (let each in this.actions) {
            this.actions[each].run(param);
        }
    }

    /**
     *
     * @param {string} verb
     * @param {string|undefined} name
     * @returns {object}
     */
    static newJsonFromVerb(verb, name = undefined) {
        return { verb: verb, name: name };
    }
}

/**
 *
 * @param {object|undefined} json
 * @param {WebApplication|undefined} application
 * @returns {Action|undefined}
 */
export function newActionFromJson(json, application) {
    if (json == undefined) {
        return undefined;
    }
    /** @type {string} */
    var verb = json.verb;

    /** @type {string} */
    var name = json.name;
    var obj;

    if (verb === undefined) {
        return undefined;
    }

    if (application) {
        if (json["if settings"]) {
            for (const settingName of json["if settings"]) {
                if (application.getSetting(settingName) == false) {
                    return newActionFromJson(json["else"], application);
                }
            }
        }
    }

    if (application == undefined) {
        console.log("action with no application");
    }

    if (CallWebFunction.verb_names.some((each) => each === verb))
        return new CallWebFunction(verb, name, json);

    switch (verb) {
        case "toggle":
            return new ToggleAction(verb, name, json);
        case "toggle_application_setting":
            return new ToggleApplicationSettingAction(verb, name, json, application);
        case "set_application_setting":
            return new SetApplicationSettingAction(verb, name, json, application);
        case "refresh_page":
            return new RefreshPageAction(verb, name, json);
        case "navigate_browser_page":
            return new NavigateBrowserAction(verb, name, json);
        case "navigate":
            return new NavigateAction(verb, name, json);
        case "back":
            return new BackAction(verb, name, json);
        case "home":
            return new NavigateHomeAction(verb, name, json);
        case "change-selection-mode":
            return new ChangeSelectionMode(verb, name, json);
        case "toggle-interactive-visibility":
            return new ToggleInteractiveVisibility(verb, name, json);
        case "activate":
            return new ActivateAction(verb, name, json);
        case "openFullscreenModule":
            return new OpenFullscreenModuleAction(verb, name, json);
        case "openModuleRedirect":
            return new OpenModuleRedirectAction(verb, name, json);
        case "toggle radio":
            return new ToggleRadio(verb, name, json);
        case "change radio station":
            return new ChangeRadioStationAction(verb, name, json);
        case "play audio":
            return new PlayAudioAction(verb, name, json);
        case "ExitModule":
            return new ExitModule(verb, name, json);
        case "audio_visual_script.next_state":
            return new AudioVisualScriptNextStateAction(verb, name, json);
        case "audio_visual_script.next_queue_item":
            return new NextAudioVisualScriptPlaybackQueueItem(verb, name, json);
        default:
            if (verb.startsWith("_"))
                return undefined;
            return new Action(verb, name);
    }
}
