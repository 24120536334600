// @ts-check

import { WebAudio } from "./webAudio";

/**
 * @callback stringFunction
 * @returns {string}
*/

/**
 * 
 */
export class Stem {
  
  /**
   * @type {object}
   */
  json;
  /**
 * @type {stringFunction}
 */
  parentGetResourcePath;
  /**
   * 
   * @param {object} json 
   * @param {stringFunction} parentGetResourcePath 
   */
  constructor(json, parentGetResourcePath) {
    this.json = json || {};

    if (this.json.isEnabled === undefined) {
      this.json.isEnabled = true;
    }
    if (this.json.gain === undefined) {
      this.json.gain = 1;
    }
    if (this.json.isLoop === undefined) {
      this.json.isLoop = true;
    }
    if (this.json.isAutoPlay === undefined) {
      this.json.isAutoPlay = true;
    }
    if (this.json.layer === undefined) {
      this.json.layer = WebAudio.ambiancelayerName;
    }
    if (this.json.region === undefined) {
      this.json.region = {};
    }
    if (this.json.region.gain === undefined) {
      this.json.region.gain = 0;
    }
    this.parentGetResourcePath = parentGetResourcePath;
  }
  /**
   * 
   * @param {Array.<Stem>} array 
   * @returns  {Stem|undefined}
   */
  findInArray(array) {
    return array.find((each) => each.isEquivilentToStem(this));
  }
  /**
 * 
 * @param {Array.<Stem>} array 
 */
  removeFromArray(array) {
    for (let index = 0; index < array.length; index++) {
      if (array[index].isEquivilentToStem(this)) {
        array.splice(index, 1);
      }
    }
  }
  /**
 * 
 * @param {Stem} stem 
 * @returns {boolean}
 */
  isEquivilentToStem(stem) {
    return /*this.name === stem.name ||*/ this.audioSrc === stem.audioSrc;
  }
  /**
   * 
   * @returns {boolean}
   */
  hasRegionGain() {
    return this.json.region?.gain;
  }
/**
 * 
 * @returns {Stem}
 */
  toRegionStem() {
    var result = new Stem({ ...this.json }, this.parentGetResourcePath);
    if (result.hasRegionGain()) {
      result.json.gain = result.json.region.gain;
    }
    return result;
  }
  /**
   * @returns {string}
   */
  get name() {
    return this.json.name;
  }
   /**
   * @returns {boolean}
   */
  get isEnabled() {
    return this.json.isEnabled;
  }
   /**
   * @returns {string}
   */
  get audioSrc() {
    return this.json.audioSrc;
  }
 /**
   * @returns {string}
   */
  getResourcePath() {
    return this.json.resourcePath || this.parentGetResourcePath();
  }
/**
 * 
 * @param {import('./ConnectedStem').ConnectedStem|undefined} connection 
 */
  load(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection?.addReference(this);
  }
/**
 * 
 * @param {import('./ConnectedStem').ConnectedStem|undefined} connection 
 */
  unload(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection?.removeReference(this);
  }
/**
 * 
 * @param {import('./ConnectedStem').ConnectedStem|undefined} connection 
 */
  play(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection?.playFrom(this);
  }
/**
 * 
 * @param {import('./ConnectedStem').ConnectedStem|undefined} connection 
 * @param {number} amount 
 */
  setGain(connection, amount) {
    if (!this.isEnabled) {
      return;
    }
    this.json.gain = amount;
    connection?.setGainFrom(this, amount);
  }
/**
 * 
 * @param {import('./ConnectedStem').ConnectedStem|undefined} connection 
 */
  stop(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection?.stopFrom(this);
  }
}
