//@ts-check

/**
 * 
 */
export class CachedAction {
    /**
 * 
 * @type {string}
 */
    static typename = "cachedAction";
      /**
 * 
 * @type {string}
 */
    name;
      /**
 * 
 * @type {object}
 */
    context;
      /**
 * 
 * @type {number}
 */
    now;
/**
 * 
 * @param {string} name 
 * @param {object} context 
 * @param {number} now 
 */
    constructor(name, context, now) {
      this.name = name;
      this.context = context;
      this.now = now;
    }
}