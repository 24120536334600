// @ts-check
import { VideoData } from './VideoData';
export const MIME_TYPE_MP4 = 'video/mp4';
const MIME_CODEC = 'video/mp4; codecs="avc1.42E01E"';
/** @type {string} tag for console.log() */
const TAG = "MediaSourcePlayer:"
/**
 * @enum {number}
 */
const PlayerState = {
    INITIALIZING: 0,
    IDLE: 1,
    LOADING: 2,
    PLAYING: 3,
    PAUSED: 4,
    ENDED: 5,
    ERROR: 6,
};

/**
 * MediaSource Player
 *
 * NOTE: the cleanUp() method is currently not implemented
 * in order to make player function 
 */
export class MediaSourcePlayer {
    /** @type {MediaSource} */
    mediaSource;

    /** @type {SourceBuffer} */
    #sourceBuffer;

    /** @type {PlayerState} */
    state;

    /** @type {Object} */
    #listeners = {};

    /** @type {Array<VideoData>} */ 
    #videoQueue = [];

    /** @type {HTMLVideoElement} */
    videoElement;

    /** @type {ArrayBuffer|undefined} */
    loopingVideo;

    /** @type {VideoData|undefined} */
    lastVideoDataAppendedToBuffer;

    /** @type {number} */
    #bufferStart = 0
    /** @type {number} */
    #lastSegmentStartTime = 0;
    /** @type {number} */
    #loopThreshold = 1; // Threshold in seconds before the end to reenqueue
    /** @type {number} */
    #endedThreshold = 0.03; // Threshold in seconds before notifying that the video has played once

    /**
    * @type {boolean}
    */
    ready = true 

    /**
    * @type {boolean}
    */
    isWaitingForGesture=false;

    /**
     * 
     * @param {HTMLVideoElement} videoElement 
     */
    constructor(videoElement) {
     
        if (videoElement === null) {
            throw new Error("VideoElement is Null")
        }
        this.state = PlayerState.INITIALIZING;
        this.videoElement = videoElement;
    }

    initialize(){
        this.mediaSource = new MediaSource();

        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
        if ("srcObject" in this.videoElement) {
            try {
                this.videoElement.srcObject = this.mediaSource;
            } catch (err) {
              if (err.name !== "TypeError") {
                throw err;
              }
              // Even if they do, they may only support MediaStream
              this.videoElement.src = URL.createObjectURL(this.mediaSource);
            }
        } else {
            this.videoElement.src = URL.createObjectURL(this.mediaSource);
        }
        // this.videoElement.srcObject = this.mediaSource;
        this.mediaSource.addEventListener('sourceopen', this.#onSourceOpen.bind(this));
        this.mediaSource.addEventListener('sourceended', () => this.#changeState(PlayerState.ENDED));
        this.videoElement.addEventListener('error', (err) => {
            console.error(TAG, err?.message, err.lineno, err.filename)
            console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message)
            return true
        })

        // The waiting event is fired when playback has stopped because of a temporary lack of data.
        this.videoElement.addEventListener("waiting", (event) => {
            // console.info(TAG+":NOT-READY")
            this.ready = false
        });
        // The loadeddata event is fired when the frame at the current playback position of the media has finished loading; often the first frame.
        this.videoElement.addEventListener("loadeddata", (event) => {
        });

        // The canplaythrough event is fired when the user agent can play the media, and estimates that enough data has been loaded to play the media up to its end without having to stop for further buffering of content.
        this.videoElement.addEventListener("canplaythrough", (event) => {
            this.ready = true
        });
        console.info("new:" + TAG + '(' + this.videoElement + ')')        
    }

    #waitForReady() {
        return /** @type {Promise<void>} */(new Promise((res) => {
            const id = setInterval(() => {
                if (this.ready) {
                    // console.info(TAG+":READY")
                    clearInterval(id)
                    res()
                }
            }, 100)
        }))
    }
    /**
     * Adds a video segment to the end of the player's buffered video
     * @param {ArrayBuffer} video 
     */
    enqueue(video) {
        var videoData = new VideoData();
        videoData.video = video;
        this.enqueueVideoData(videoData);
    }

    /**
     * Adds a video segment to the end of the player's buffered video
     * @param {VideoData} videoData 
     */
    enqueueVideoData(videoData) {
        this.#videoQueue.push(videoData);
        this.#processQueue();
    }

    /**
     * Adds a video segment to the player and plays that video when ready
     * @param {ArrayBuffer} video 
     * @param {string | undefined } name
     * 
     */
    async play(video, name) {
        var videoData = new VideoData();
        videoData.video = video;
        videoData.name = name;
        await this.playVideoData(videoData);
    }

    /**
     * Adds a video segment to the player and plays that video when ready
     * @param {VideoData} videoData 
     * 
     */
    async playVideoData(videoData) {
        try {
            console.info(TAG, "playing", name, videoData.video.byteLength)
            // console.info(TAG+":MIME type support:", this.videoElement.canPlayType(MIME_CODEC))
            // console.info(TAG+":ANALYZING MIME HEADERS", this.getMimeType(video))
            this.enqueueVideoData(videoData);
            //this.#processQueue();
            await this.#waitForReady()
            this.skipToLastAppendedSegment()
            await this.#waitForReady()
            // this.#clearStaleBuffer()
        } catch (err) {
            err.message = TAG + "play() " + err.message
            this.#changeState(PlayerState.ERROR, err)
        }
    }

    #clearStaleBuffer() {
        try {
            if (this.#sourceBuffer && !this.#sourceBuffer.updating && this.#bufferStart !== this.#lastSegmentStartTime) { 
                console.log(TAG, "removing previous buffer,", this.#bufferStart, "-", this.#lastSegmentStartTime)
                this.#sourceBuffer.remove(this.#bufferStart, this.#lastSegmentStartTime)
                this.#bufferStart = this.#lastSegmentStartTime
            } else {
                setTimeout(this.#clearStaleBuffer.bind(this), 1000)
            }

        } catch (err) {
            this.#changeState(PlayerState.ERROR, error)
        }

    }
    #processQueue() {
        try {
            if (this.#sourceBuffer && !this.#sourceBuffer.updating && this.#videoQueue.length) {
                const videoData = this.#videoQueue.shift();
                if (!videoData) {
                    throw new Error("retrieved undefined video from queue")
                }
                this.#appendToBuffer(videoData.video);
                if (!this.#videoQueue.length) {
                    this.lastVideoDataAppendedToBuffer = videoData;
                    if (videoData.isLoop) {
                        this.loopingVideo = videoData.video;
                    } else {
                        this.loopingVideo = undefined;
                    }
                }
            }
        } catch (err) {
            err.message = TAG + "processQueue() " + err.message
            this.#changeState(PlayerState.ERROR, error)
            console.error(TAG, error)
            console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message)
        }
    }

    skipToLastAppendedSegment() {
        try {
            if (this.#sourceBuffer?.buffered != undefined && this.#sourceBuffer.buffered.length > 0) {
                // Set currentTime to the start of the last appended segment
                this.videoElement.currentTime = this.#lastSegmentStartTime;
            } else {
                console.warn("No buffered data to skip to.");
            }
        } catch (err) {
            err.message = TAG + "skipToLastAppendedSegment() " + err.message
            this.#changeState(PlayerState.ERROR, err)
        }
    }

    /**
     * 
     * @param {ArrayBuffer} video 
     */
    #appendToBuffer(video) {
        if (!this.#sourceBuffer) {
            console.error(TAG, "no source buffer found");
            this.#changeState(PlayerState.ERROR)
            return
        }

        try {
            if (this.#sourceBuffer.buffered && this.#sourceBuffer.buffered.length > 0) {
                this.#sourceBuffer.timestampOffset = this.#sourceBuffer.buffered.end(this.#sourceBuffer.buffered.length - 1);
                this.#lastSegmentStartTime = this.#sourceBuffer.timestampOffset;
            }
            this.#sourceBuffer.appendBuffer(video);
        } catch (err) {

            err.message = TAG + "skipToLastAppendedSegment() " + err.message
            this.#changeState(PlayerState.ERROR, error)
        }
        
    }

    /** @param {ArrayBuffer} arrayBuffer */
    static getMimeType(arrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);

        if (uint8Array[4] === 0x66 && uint8Array[5] === 0x74 &&
            uint8Array[6] === 0x79 && uint8Array[7] === 0x70) {
            return MIME_TYPE_MP4;
        }
        return 'unknown';
    } 

    #checkForLoop() {
        if (this.videoElement.currentTime === 0) {
            console.trace(TAG, "current time set to 0")
        }
        try {
            if (!this.#sourceBuffer || !this.#sourceBuffer.buffered || this.#sourceBuffer.buffered.length === 0) return;

            const bufferedEnd = this.#sourceBuffer.buffered.end(this.#sourceBuffer.buffered.length - 1);
            const currentTime = this.videoElement.currentTime;

            //console.log(bufferedEnd - currentTime);

            // Check if we are within the threshold near the end of the buffer
            if (bufferedEnd - currentTime < this.#loopThreshold) {
                console.log(TAG, "Re-enqueueing last video segment for looping");


                // Re-enqueue the last segment if the queue is empty (i.e., the last video was played)
                if (this.#videoQueue.length === 0 && this.loopingVideo) {
                    
                    this.enqueue(this.loopingVideo); // Re-add the video to the queue to loop 
                    return;              
                }
            } 

            if (bufferedEnd - currentTime < this.#endedThreshold) {
                if (this.#videoQueue.length === 0 ) {
                    this.lastVideoDataAppendedToBuffer?.onPlayedOnce?.();
                }
            }
        } catch (err) {

            err.message = TAG + "checkForLoop() " + err.message
            this.#changeState(PlayerState.ERROR, err)
            // console.error(TAG, err)
            // console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message)
        }
    }

    #onSourceOpen() {
        try {

            this.#sourceBuffer = this.mediaSource.addSourceBuffer(MIME_CODEC);
            
            this.#changeState(PlayerState.IDLE);
            if (this.#listeners['ready']) {
                this.#listeners['ready']();
                console.log(TAG, "listeners called", this.#listeners)
            }

            this.#sourceBuffer.addEventListener('updatestart', () => this.#changeState(PlayerState.LOADING));
            this.#sourceBuffer.addEventListener('updateend', () => {
                this.#changeState(PlayerState.PLAYING);
                this.#processQueue(); 
            });

            this.videoElement.addEventListener('timeupdate', this.#checkForLoop.bind(this));
            this.videoElement.addEventListener('seeked', () => {
                if (this.videoElement.currentTime === 0) {
                  console.trace('currentTime is reset to 0');
                }
              });

            this.videoElement.addEventListener('loadeddata', 
                () => console.log(TAG, " Loaded")
            )
        } catch (err) {
            err.message = TAG + "onSourceOpen() " + err.message
            this.#changeState(PlayerState.ERROR, error)
            // console.error(TAG, error)
            // console.error(TAG, this.videoElement.error?.code, this.videoElement.error?.message)
        }
    }

    /**
     * add the callback to listeners during initialization, or else call the callback
     * @param {*} event 
     * @param {*} callback 
     */
    // TODO Complete Edge case handling
    addEventListener(event, callback) {
        if (this.state === PlayerState.INITIALIZING) {
            this.#listeners[event] = callback;
        } else {
            callback()
        }

    }

    /**
     * always add the callback to listeners
     * @param {string} event 
     * @param {*} callback 
     */
    addListener(event, callback) {       
        this.#listeners[event] = callback; 
    }

    cleanUp() {
        for (const listener in this.#listeners) {
            this.mediaSource.removeEventListener(listener, this.#listeners[listener]);
        }
        this.#listeners = {};
    }

    #changeState(newState, error) {
        if (this.state === newState) return;
        this.state = newState;
        switch (this.state) {
            case PlayerState.PLAYING:
                this.videoElement.play().catch(err =>{ 

                    if (err.name === "NotAllowedError") {
                        console.log("video delay:" + err.name);
                        this.isWaitingForGesture=true;
                    } 
                    this.#changeState(PlayerState.ERROR, err);
                    //console.warn("warning: on play()", err);
                });
                break;
            case PlayerState.LOADING:
                break;
            case PlayerState.ENDED:
                console.info(TAG, "MEDIA SOURCE ENDED")
                break;
            case PlayerState.IDLE:
                break;
            case PlayerState.ERROR:
                console.error(TAG, error, error?.message);
                console.trace(TAG);
                if(this.videoElement.error){
                    console.error(TAG, this.videoElement.error.code, this.videoElement.error.message);
                }
                break;
            default:
                console.info(TAG, `unknown state ${newState}`);
                break;

        }
    }

    /**
     * 
     */
    resumeOnGesture() {

        if (this.isWaitingForGesture) {

            console.log(TAG + "resume on gesture");

             this.isWaitingForGesture = false;

             if(this.state == PlayerState.ERROR){
                this.#changeState(PlayerState.PLAYING);
             }
        }
    }

    /**
     * 
     * @param {number} width 
     * @param {number} height 
     */
    setVideoSize(width,height){
        this.videoElement.width = width;
        this.videoElement.height = height;
    }
}

