// @ts-check

//https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro

/**
 *
 * @param {string} html
 * @returns {ChildNode | null}
 */
export function htmlToElement(html) {
    var template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

/**
 *
 * @param {string} html
 * @returns {NodeListOf<ChildNode>}
 */
export function htmlToElements(html) {
    var template = document.createElement("template");
    template.innerHTML = html;
    return template.content.childNodes;
}

/**
 *
 * @returns {HTMLElement}
 */
export function BorderlessTable() {
    var result = document.createElement("table");
    result = Object.assign(result, { cellspacing: 0, cellpadding: 0 });
    return result;
}
/**
 *
 * @param {HTMLElement} table
 * @param {Array.<HTMLElement>} elements
 */
export function addTableRow(table, elements) {
    var row = document.createElement("tr");

    elements.forEach((element) => {
        let col = Object.assign(document.createElement("td"), {});
        // col.style.padding = "2px";
        col.appendChild(element);
        row.appendChild(col);
    });
    table.appendChild(row);
}

/**
 *
 * @param {Array.<HTMLElement>} elements
 */
export function withBorderlessTableRow(elements) {
    var result = BorderlessTable();
    addTableRow(result, elements);
    return result;
}

/**
 *
 * @param {string} each_name
 */
export function hideElementByName(each_name) {
    const each = document.getElementById(each_name);
    if (each) {
        hideElement(each);
    }
}

/**
 *
 * @param {string} each_name
 */
export function showElementByName(each_name) {
    const each = document.getElementById(each_name);
    if (each) {
        showElement(each);
    }
}

/**
 *
 * @param {HTMLElement} each
 */
export function hideElement(each) {
    if (!each) {
        return;
    }
    each.style.display = "none";
}

/**
 *
 * @param {HTMLElement} each
 */
export function showElement(each) {
    if (!each) {
        return;
    }
    each.style.display = "block";
}
