//@ts-check
import { CustomerAccount } from "./customerAccount";
import { LocalServerFileCache } from "./LocalServerFileCache";
import { StackLayout } from "./view/stackLayout";
import { Treeview } from "./view/treeview";

/**
 *
 */
export const DataLocation = {
    None: 1,
    Unknown: 2,
    Client: 3,
    Cloud: 4,
};
/**
 *
 */
export class WebApplicationServer {
    /**
     * @type {import('./resources').Resources}
     */
    resources;
    /**
     * @type {string}
     */
    name;
    /**
     * @type {object}
     */
    json;
    /**
     * @type {import('./webBrowserClient').WebBrowserClient}
     */
    browser;
    /**
     * @type {import('./customerAccount').CustomerAccount}
     */
    account;
    /**
     * @type {import('./webApplication').WebApplication}
     */
    app;
    /**
     * @type {LocalServerFileCache}
     */
    server_file_cache;

    /**
     * @returns {import('./webApplication').WebApplication}
     */
    get application() {
        return this.app;
    }
    /**
     * @returns {string}
     */
    get url() {
        return window.location.href;
    }
    /**
     * @returns {string}
     */
    get pathname() {
        return window.location.pathname;
    }
    /**
     * @returns {string}
     */
    get search() {
        return window.location.search;
    }
    /**
     * @returns {string}
     */
    get hash() {
        return window.location.hash;
    }
    /**
     *
     * @param {import('./resources').Resources} resources
     * @param {import('./webApplication').WebApplication} app
     * @param {string} name
     */
    constructor(resources, app, name = "") {
        this.resources = resources;
        resources.server = this;
        this.name = name;
        this.app = app;
        this.server_file_cache = new LocalServerFileCache(this, app);
    }

    /**
     *
     * @param {string} account_name
     * @param {*} data
     * @returns {Promise}
     */
    async updateCustomerAccount(account_name, data) {
        return Promise.resolve();
    }

    /**
     *
     * @param {boolean} append_extension
     * @returns {string}
     */
    stroageItemName(append_extension = true) {
        return this.resources.combineJsonResourceNames(
            [this.name, "application.server.storage"],
            append_extension,
        );
    }
    /**
     *
     * @param {string|undefined} account_name
     * @returns {Promise.<CustomerAccount>}
     */
    async getCustomerAccount(account_name = undefined) {
        let result = CustomerAccount.fromGuest(this.resources, this.app, this);
        return Promise.resolve(result);
    }

    /**
     *
     * @param {CustomerAccount} account
     */
    setClientCustomerAccount(account) {
        this.account = account;
        //this.name = account.firstName
    }
    /**
     * @returns {Promise}
     */
    async initialize() {
        this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
        await this.server_file_cache.initialize();
    }
    /**
     *
     */
    saveState() {
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
        this.server_file_cache.saveState();
    }
    /**
     *
     */
    shutdown() {
        this.saveState();
        this.server_file_cache.shutdown();
    }
    /**
     *
     * @param {import('./webBrowserClient').WebBrowserClient} v
     */
    setBrowser(v) {
        this.browser = v;
    }
    /**
     *
     * @param {StackLayout} layout
     */
    collectEditableProperties(layout) {
        this.server_file_cache.collectEditableProperties(layout);
    }
    /**
     *
     * @returns {string}
     */
    getAuthorInterfaceName() {
        return "server";
    }
    /**
     *
     * @returns {HTMLElement}
     */
    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        return layout.element;
    }
    /**
     *
     * @param {Treeview} treeview
     */
    addAuthorInterfaceElementToTreeview(treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, false);
    }
}
