import { Action } from "../actions/action";

export class NavigateAction extends Action {
    value;
    value_context;
    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive) {
        console.log("action:Navigate");
        var obj = interactive.scene || interactive;
        obj.navigate(this.value, this.value_context);
    }
}
