//@ts-check
/**
 *
 */
export class UpdateContext {
    static OneSecondMS = 1000;
    /**
     *  @type {number}
     */
    interval;
    /**
     *
     * @type {number}
     */
    start_time;
    /**
     *
     * @type {number}
     */
    time;
    /**
     *
     * @type {number}
     */
    deltaTime;
    /**
     *
     * @type {boolean}
     */
    isDrawFrame;
}
