//https://github.com/ren-yuan/c2.js
let c2 = require("c2.js");

export function mouseEventToPoint(e) {
  return new c2.Point(e.x, e.y);
}

export class Geometry {
  relativeTo;
  letterboxedFrom;
  letterboxInto;
  shape;
  border_size = 2;
  scale = 1;
  geometry;
  coordinatesTypes = [Geometry.CoordinatesType.ABSOLUTE];

  static CoordinatesType = { 
    ABSOLUTE: 'absolute',
    RELATIVE: 'relative',
  };

  constructor() {
  }

  draw(icanvas, drawScope) { }
  get_absolute_rect_shape() { }
  containsPoint(point) { }
  setScale(scaler) {
    this.scale = scaler;
  }
}