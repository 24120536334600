import { FadeoutTimer } from './FadeoutTimer.js';


export class HTMLScreenOverlay {

  application;
  container_names;
  fadeout;
  enabled;
  timeout;
  enabled_setting;
  enabled_timeout_setting;
  constructor() {

  }
  update(){
    
  }
  // static initialize(self, application){
  //   self.application = application;
  //   self.elements = {};
  // }

  initialize(application) {
    this.application = application;


    this.enabled = this.application.getSetting(this.enabled_setting);
    this.timeout = this.application.getSetting(this.enabled_timeout_setting);

    this.application.addSettingSubscription(this.enabled_setting, this.onEnabledChanged.bind(this));
  }

  onEnabled(){
      this.show();
      this.fadeout?.start();

  }
  onEnabledChanged(value) {
    this.enabled=value;
    if(!value){
      this.fadeout?.stop();
      this.hide();
    }else{
      this.onEnabled();
    }

  }
  onActivity() {
    this.fadeout?.onActivity();

  }

  hide() {
    for (const each_name of this.container_names) {
      this.hideDiv(each_name);
    }

  }

  hideDiv(each_name) {
    const each = document.getElementById(each_name);
    if(!each){
      return;
    }
    each.style.display = 'none';
  }

  show() {
    for (const each_name of this.container_names) {
      this.showDiv(each_name);
    }
  }

  showDiv(each_name) {
    const each = document.getElementById(each_name);
    if(!each){
      return;
    }
    each.style.display = 'block';
  }

  static set_debug_table_x_offset(amount) {
    //for (const each_name of this.container_names) {
    const each = document.getElementById("debug-table");
    if(!each){
      return;
    }
    each.style.marginLeft = amount;
    //}
  }
  x_offset;
  set_x_offset(amount) {
    this.x_offset=amount;

    for (const each_name of this.container_names) {
      const each = document.getElementById(each_name);
      if(!each){
        continue;
      }
      each.style.marginLeft = amount;
    }
  }
  setupFadeout() {

    if (this.timeout != undefined) {
      this.fadeout = new FadeoutTimer(this, this.timeout * 60);
    }

    if (!this.enabled) {
      this.hide();
      return;
    }

    if (this.timeout != undefined) {
      this.fadeout.start();
    }
  }
}
