// @ts-check

import { InteractiveArea } from "./InteractiveArea.js";
import { InteractiveInput } from "./InteractiveInput.js";
import { Action } from "./actions/action.js";
import { Actions, newActionFromJson } from "./actions/actions.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { UserInterfaceElements } from "../view/userInterfaceElements.js";
import { InteractivePointOfInterest } from "./InteractivePointOfInterest.js";
import { CustomerAccount } from "../customerAccount.js";
import { Resources } from "../resources.js";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.js";
import { WebApplication } from "../webApplication.js";
import { InteractiveEvent } from "./InteractiveEvent.js";
import { DragDropEvent } from "./DragDropEvent.js";
import { ScenePath } from "./ScenePath.js";
import { SceneGraph } from "./scene_graph.js";
import { RectangleGeometry } from "../geometry/RectangleGeometry.js";
let c2 = require("c2.js");

/**
 *
 */
export class Interactive {
    /**
     *
     * @type {string}
     */
    static typename = "interactive";
    /**
     *
     * @type {import('./scene.js').SceneObjectInterface}
     */
    parent;
    /**
     *
     * @type {object}
     */
    json;
    /**
     *
     * @type {InteractiveArea|undefined}
     */
    area;
    /**
     *
     * @type {Array.<InteractiveInput>}
     */
    inputs = [];
    /**
     *
     * @type {Array.<Action>}
     */
    actions = [];
    /**
     *
     * @type {UserInterfaceElements}
     */
    ui;
    /**
     *
     * @type {Array.<InteractivePointOfInterest>}
     */
    points_of_interest;
    /**
     *
     * @type {boolean}
     */
    isSelected;
    /**
     *
     * @param {import('./scene.js').SceneObjectInterface} parent
     * @param {object} json
     */
    constructor(parent, json) {
        this.parent = parent;
        this.json = json;
    }
    /**
     *
     * @returns {boolean}
     */
    hasPointOfInterest() {
        return this.points_of_interest !== undefined;
    }
    /**
     *
     * @returns {Interactive}
     */
    clone() {
        return new Interactive(this.parent, { ...this.json });
    }
    /**
     *
     * @returns {boolean}
     */
    isSelectable() {
        return this.area != undefined && this.area.isSelectable() && !this.getHidden();
    }
    /**
     *
     * @param {boolean} value
     */
    setSelected(value) {
        if (this.isSelected == value) {
            return;
        }

        if (value) {
            for (const each of this.points_of_interest || []) {
                each.start();

                if (each.json["area.visible"] == false) {
                    this.area?.visual_element?.setHidden(true);
                }
            }
        } else {
            for (const each of this.points_of_interest || []) {
                each.stop();

                if (each.json["area.visible"] == false) {
                    this.area?.visual_element?.setHidden(false);
                }
            }
        }

        this.isSelected = value;
        this.icanvas.invalidate();
    }
    /**
     *
     */
    initializeFromJson() {
        if (this.json.layer === undefined) {
            this.json.layer = InteractiveLayers.DefaultLayerJson.name;
        }

        for (let each in this.json) {
            if (each == Action.typename) {
                this.addActionFromJson(this.json[each], this.scene.application);
            } else if (each == InteractiveInput.typename) {
                var i = new InteractiveInput(this, this.json[each]);
                this.inputs.push(i);
            } else if (each == InteractiveArea.typename) {
                this.area = new InteractiveArea(this, this.json[each]);
            } else if (each == Actions.typename) {
                for (let each_action in this.json[each]) {
                    this.addActionFromJson(this.json[each][each_action], this.scene.application);
                }
            } else if (each == "ui") {
                this.ui = new UserInterfaceElements(
                    () => {
                        return this.getScreenSpaceAreaRect();
                    },
                    this.json[each],
                    this.scene.application,
                );
            } else if (each == "isHidden") {
                this.setHidden(this.json[each]);
            }
        }

        if (this.json[InteractivePointOfInterest.typename]) {
            this.points_of_interest = [
                new InteractivePointOfInterest(
                    this,
                    this.json[InteractivePointOfInterest.typename],
                ),
            ];
        }
        if (this.json[InteractivePointOfInterest.array_typename]) {
            this.points_of_interest = [];
            for (const each of this.json[InteractivePointOfInterest.array_typename]) {
                this.points_of_interest.push(new InteractivePointOfInterest(this, each));
            }
        }

        if (this.area) {
            this.area.initializeFromJson();
        }
        for (const each of this.points_of_interest || []) {
            each.initializeFromJson();
        }
    }
    /**
     *
     * @returns {boolean}
     */
    getHidden() {
        return this.json["isHidden"] == true || this.isConditionalFalse();
    }
    isConditionalFalse() {
        if (
            this.json.if_setting &&
            this.parent.scene.application.getSetting(this.json.if_setting) == false
        ) {
            return true;
        }
        return false;
    }
    /**
     *
     * @param {boolean} value
     */
    setHidden(value) {
        this.json["isHidden"] = value;
    }
    /**
     *
     *  @returns {boolean}
     */
    get isTemporary() {
        return this.json.isTemporary;
    }
    /**
     *  @param {boolean} v
     */
    set isTemporary(v) {
        this.json.isTemporary = v;
    }
    /**
     *
     *  @returns {string}
     */
    get name() {
        return this.json.name;
    }
    /**
     *  @param {string} v
     */
    set name(v) {
        this.json.name = v;
    }
    /**
     * @returns {CustomerAccount}
     */
    get account() {
        return this.scene.sceneGraph.account;
    }
    /**
     * @returns {Resources}
     */
    get resources() {
        return this.scene.resources;
    }
    /**
     * @returns {InteractiveCanvas}
     */
    get icanvas() {
        return this.scene.icanvas;
    }
    /**
     * @returns {import('./scene.js').SceneObjectInterface}
     */
    get scene() {
        return this.parent.scene;
    }
    /**
     *
     * @param {object} file
     * @returns {string}
     */
    static getNameFromFilename(file) {
        return file.name;
    }
    /**
     *
     * @param {object} file
     * @returns {object}
     */
    static newJsonFromFile(file) {
        return {
            name: this.getNameFromFilename(file),
            "interactive.area": {
                "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
            },
        };
    }
    /**
     *
     * @returns {c2.Rect}
     */
    getScreenSpaceAreaRect() {
        if (this.area) {
            return this.area.getScreenSpaceAreaRect();
        }
        return new c2.Rect(0, 0, 0, 0);
    }
    /**
     *
     * @param {c2.Point} point
     */
    setAreaReletivePosition(point) {
        this.area?.setAreaReletivePosition(point);
    }
    /**
     *
     * @param {object} j
     * @param {WebApplication} [application]
     */
    addActionFromJson(j, application) {
        var a = newActionFromJson(j, application);
        if (a) {
            this.actions.push(a);
        }
    }
    /**
     *
     * @returns {string}
     */
    getResourcePath() {
        return this.json.resourcePath || this.parent.getResourcePath();
    }
    /**
     *
     * @returns {boolean}
     */
    isVisualContentReady() {
        if (this.area && !this.area.isVisualContentReady()) {
            return false;
        }
        return true;
    }
    /**
     *
     * @param {import('./GettingReadyCallbackCollector.js').GettingReadyCallbackCollector} gettingReadyCallbackCollector
     */
    startVisualContentGetReady(gettingReadyCallbackCollector) {
        this.area?.startVisualContentGetReady(gettingReadyCallbackCollector);

        if (this.json["points_of_interest.preload"] == true) {
            for (const each of this.points_of_interest || []) {
                each.startVisualContentGetReady(gettingReadyCallbackCollector);
            }
        }
    }
    /**
     *
     */
    start() {
        this.area?.start();

        this.ui?.addToDOM();
    }
    /**
     *
     */
    stop() {
        this.area?.stop();
        this.ui?.removeFromDOM();

        for (const each of this.points_of_interest || []) {
            each.stop();
        }
    }
    /**
     *
     * @param {import('../audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     */
    drawFrame(icanvas) {
        if (this.getHidden() == true) {
            return;
        }

        this.area?.drawFrame(icanvas);
        this.ui?.updateDOM();
    }
    /**
     * @param {InteractiveEvent} ievent
     * @returns {boolean}
     */
    isIEventOnInteractiveArea(ievent) {
        if (this.getHidden()) {
            return false;
        }
        return this.area?.isIEventOnInteractiveArea(ievent) || false;
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mousedown(ievent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.mousedown(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mouseup(ievent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.mouseup(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mousemove(ievent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.mousemove(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     * @param {InteractiveArea} area
     */
    onMouseUp(ievent, area) {
        if (!this.isSelectable()) {
            return;
        }
        ievent.isStopPropagation = true;

        this.runActions();
    }
    /**
     * @param {InteractiveEvent} ievent
     * @param {InteractiveInput} iinput
     */
    onInteractiveInput(ievent, iinput) {
        ievent.isStopPropagation = true;
        this.runActions();
    }
    /**
     * @returns {import('../audio_visual/visual/visualElement.js').VisualElement|undefined}
     */
    get visual_element() {
        return undefined;
    }
    /**
     *
     * @returns {boolean}
     */
    isFadedOut() {
        return this.scene.isFadedOut();
    }
    /**
     *
     * @returns {ScenePath}
     */
    toScenePath() {
        return this.scene.toScenePath();
    }
    /**
     * @returns {SceneGraph}
     */
    get sceneGraph() {
        return this.scene.sceneGraph;
    }
    /**
     * @returns {import('../LocalServerFileCache.js').LocalServerFileCache}
     */
    get server_file_cache() {
        return this.scene.server_file_cache;
    }
    /**
     * @returns {import('../webApplication.js').WebApplication}
     */
    get application() {
        return this.scene.application;
    }
    /**
     *
     * @type {import('../geometry/RectangleGeometry.js').RectangleGeometry}
     */
    get geometry() {
        var r = new RectangleGeometry();
        r.initialize(0, 0, 0, 0);
        return r;
    }
    /**
     * @returns {import('../audio_visual/visual/VisualElements.js').VisualElements|undefined}
     */
    get visualElements() {
        return this.scene.visualElements;
    }
    /**
     * @returns {import('../simulation.js').Simulation}
     */
    get simulation() {
        return this.scene.simulation;
    }
    /**
     * @returns {number}
     */
    get draw_order() {
        return this.scene.draw_order;
    }
    /**
     *
     */
    runActions() {
        for (let each in this.actions) {
            this.actions[each].run(this);
        }
    }
    /**
     *
     * @param {Action} action
     */
    runAction(action) {
        const actionStore = this.actions;
        this.actions = [action];
        for (let each in this.actions) {
            this.actions[each].run(this);
        }
        this.actions = actionStore;
    }
    /**
     *
     * @param {any} command
     */
    apply(command) {
        if (command instanceof Object) {
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    keydown(ievent) {
        for (let each in this.inputs) {
            this.inputs[each].keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    keyup(ievent) {
        if (this.getHidden()) {
            return;
        }
        for (let each in this.inputs) {
            this.inputs[each].keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    activate(ievent) {
        if (ievent.activate_value == "interactive.input") {
            for (let each in this.inputs) {
                this.inputs[each].activate(ievent);
                if (ievent.isStopPropagation) {
                    break;
                }
            }
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchTap(ievent) {
        if (!this.isSelectable()) {
            return;
        }
        this.area?.onTouchTap(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchPan(ievent) {
        if (!this.isSelectable()) {
            return;
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchSwipe(ievent) {
        if (!this.isSelectable()) {
            return;
        }
        for (let each in this.inputs) {
            this.inputs[each].onTouchSwipe(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchDistance(ievent) {
        if (!this.isSelectable()) {
            return;
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchRotate(ievent) {
        if (!this.isSelectable()) {
            return;
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchGesture(ievent) {
        if (!this.isSelectable()) {
            return;
        }
    }

    /**
     * @param {DragDropEvent} ievent
     */
    file_dropped(ievent) {
        if (!this.isSelectable()) {
            return;
        }
    }
    /**
     * @param {DragDropEvent} ievent
     */
    drag_file(ievent) {
        if (!this.isSelectable()) {
            return;
        }
    }
    /**
     *
     * @param {object} action
     */
    runActionJson(action) {
        var actions = new Actions();
        actions.parseJson(action, this.application);
        actions.runActions(this);
    }
}
