import { Action } from "../actions/action";

export class ToggleAction extends Action {
    value = false;
    values = { true: {}, false: {} };
    constructor(verb, name, json) {
        super(verb, name);
        this.values.true = json["=true"];
        this.values.false = json["=false"];
    }

    run(interactive) {
        console.log("action:Toggle");
        this.value = !this.value;
        interactive.apply(this.values[this.value]);
    }
}
