import { AudioAmbianceSet } from "../audio_visual/audio/audioAmbianceSet.js";
import { Interactive } from "./Interactive.js";
import { Scene } from "./scene.js";
import Graph from "graphology";
import { SceneObject } from "./scene_objects.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";
import { Resources } from "../resources.js";
import { SceneGraphAudioVisualStateSet } from "./SceneGraphAudioVisualStateSet.js";
import { SceneGraphAudioVisualScripts } from "./SceneGraphAudioVisualScripts.js";

export class SceneGraph {
    simulation;
    path;
    json;
    graph;
    sceneItemsToPush = [];
    parentSceneGraph;
    sceneGraphIndex;
    audioAmbiance;
    audio_visual_state_set;
    audio_visual_scripts;
    active_audio_visual_script;

    constructor(
        simulation,
        json,
        parentSceneGraph = undefined,
        path = undefined,
        sceneGraphIndex = undefined,
    ) {
        this.simulation = simulation;
        this.parentSceneGraph = parentSceneGraph;
        this.json = json;
        this.path = path;
        this.sceneGraphIndex = sceneGraphIndex;
        this.graph = new Graph();
        this.audioAmbiance = new AudioAmbianceSet(this.name, simulation.audio);

        if (this.parentSceneGraph) {
            this.pushSceneItems(this.parentSceneGraph.sceneItemsToPush);
        }
    }

    initializeFromJson() {
        if (!this.json.resourcePath) {
            this.json.resourcePath = this.name;
        }

        for (const key in this.json) {
            var value = this.json[key];
            this.initializeGraphFromJsonKey(key, value);
        }

        this.json.sceneCommon?.interactiveLayers?.forEach((element) => {
            this.sceneItemsToPush.push((scene) => new InteractiveLayerJson(element));
        });

        this.pushSceneItems(this.sceneItemsToPush);

        this.graph.forEachNode((node, attributes) => {
            if (attributes.scene.audioAmbiance) {
                this.audioAmbiance.addAmbiance(attributes.scene.audioAmbiance);
            }
        });
    }

    get resources() {
        return this.simulation.resources;
    }
    get icanvas() {
        return this.simulation.icanvas;
    }
    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }

    get account() {
        return this.simulation.account;
    }
    get application() {
        return this.simulation.application;
    }

    collectScenes(list) {
        this.graph.forEachNode((node, attributes) => {
            list.push(attributes.scene);
        });
    }

    pushSceneItems(from) {
        for (const eachItem in from) {
            this.graph.forEachNode((node, attributes) => {
                let eachScene = attributes.scene;
                let item = from[eachItem];
                item = item(eachScene);
                eachScene.push(item);
            });
        }
    }
    get name() {
        return this.json.name;
    }
    get version() {
        return this.json.version;
    }
    get defaultScene() {
        return this.json.defaultScene;
    }
    get defaultOrFirstScene() {
        var name = this.json.defaultScene;

        var found = this.findSceneByName(name);

        if (!found) {
            found = this.findFirstScene();
        }
        return found;
    }
    get defaultSceneGraph() {
        return this.json.defaultSceneGraph;
    }

    addScene(name, json, isTemporary = false) {
        var s = new Scene(this, name, json);
        this.graph.addNode(name, { scene: s, isTemporary: isTemporary });
    }
    removeScene(name) {
        if (this.graph.hasNode(name)) {
            this.graph.dropNode(name);
        }
    }

    initializeGraphFromJsonKey(key, value) {
        if (key == "scenes") {
            for (const sceneskey in value) {
                this.addScene(sceneskey, value[sceneskey]);
            }
        }
    }

    async async_fetch_dependencies() {
        var waitFor = [];

        var includes = this.json["includes"];
        if (includes) {
            for (const each of includes) {
                var path = this.resources.getDataFilePathByNameAndExtension(
                    each,
                    Resources.JsonExtension,
                );
                waitFor.push(this.resources.getOrFetchJsonPromise(path));
            }
        }

        await Promise.all(waitFor);
    }

    async async_fetch_defaultSceneGraph() {
        var waitFor = [];

        var default_sg = this.json["defaultSceneGraph"];
        if (default_sg) {
            var path = this.resources.getNavigationGraphPathFromName(default_sg);
            waitFor.push(this.resources.getOrFetchJsonPromise(path));
        }

        var prereqs = this.json["defaultSceneGraph.prerequisite.data"];
        if (prereqs) {
            for (const each of prereqs) {
                var path = this.resources.getDataFilePathByNameAndExtension(
                    each,
                    Resources.JsonExtension,
                );
                waitFor.push(this.resources.getOrFetchJsonPromise(path));
            }
        }

        await Promise.all(waitFor);
    }

    evaluate_json_file(filename) {
        var file_path = this.resources.getDataFilePathByNameAndExtension(
            filename,
            Resources.JsonExtension,
        );
        var json = this.resources.getFetchedJson(file_path);
        for (const key in json) {
            var value = json[key];
            this.initializeGraphFromJsonKey(key, value);
        }
    }
    toPath(scene_name = undefined) {
        return new ScenePath(this.name, scene_name);
    }
    toPathWithDefaultScene() {
        return this.toPath(this.defaultScene);
    }

    findSceneByPath(path, isTemporary = false) {
        return this.findSceneByName(path.sceneName, isTemporary);
    }

    findSceneByName(name, isTemporary = false) {
        let foundScene = null;
        this.graph.forEachNode((node, attributes) => {
            if (node === name && attributes.isTemporary === isTemporary) {
                foundScene = attributes.scene;
                return false; // Stop iteration
            }
        });
        return foundScene;
    }

    findFirstScene(isTemporary = false) {
        let foundScene = null;
        this.graph.forEachNode((node, attributes) => {
            if (attributes.isTemporary === isTemporary) {
                foundScene = attributes.scene;
                return false; // Stop iteration
            }
        });
        return foundScene;
    }
}
