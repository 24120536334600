import { VideoFile } from "./VideoFile";

export class VideoFiles {
    constructor(filenames = []) {
        this.videoFiles = filenames.map((filename) => new VideoFile(filename));
    }

    // Method to add a new video file
    addVideoFile(filename) {
        this.videoFiles.push(new VideoFile(filename));
    }

    // Method to get all video files
    getVideos() {
        return this.videoFiles;
    }

    // Method to sort video files by a given property (default: bitrate)
    sortBy(property = "bitrate", ascending = true) {
        this.videoFiles.sort((a, b) => {
            const comparison =
                typeof a[property] === "string"
                    ? a[property].localeCompare(b[property])
                    : a[property] - b[property];

            return ascending ? comparison : -comparison;
        });
    }

    getVideoPathByQuality(qualityPercentage = 100) {
        if (qualityPercentage < 0 || qualityPercentage > 100) {
            throw new Error("Quality percentage must be between 0 and 100");
        }

        // Sort by bitrate to ensure correct index calculation
        this.sortBy("bitrate");

        // Calculate index based on percentage, handling edge cases
        const index = Math.round((qualityPercentage / 100) * (this.videoFiles.length - 1));

        // Return the filename at the calculated index
        return this.videoFiles[index].getFullFilename();
    }

    filterByResolution(resolution) {
        const filteredVideos = this.videoFiles.filter(
            (video) => video.resolution.toLowerCase() === resolution.toLowerCase(), // Case-insensitive
        );
        return new VideoFiles(filteredVideos.map((v) => v.filename));
    }

    filterByCodec(codec) {
        const filteredVideos = this.videoFiles.filter(
            (video) => video.videoCodec.toLowerCase() === codec.toLowerCase(),
        );
        return new VideoFiles(filteredVideos.map((v) => v.filename));
    }

    searchByName(query) {
        const queryLower = query.toLowerCase();
        const filteredVideos = this.videoFiles.filter((video) =>
            video.filename.toLowerCase().includes(queryLower),
        );
        return new VideoFiles(filteredVideos.map((v) => v.filename));
    }

    getTotalSize() {
        return this.videoFiles.reduce((sum, video) => sum + video.size, 0);
    }

    getAverageBitrate() {
        if (this.videoFiles.length === 0) {
            return 0;
        }
        const totalBitrate = this.videoFiles.reduce((sum, video) => sum + video.bitrate, 0);
        return totalBitrate / this.videoFiles.length;
    }

    getResolutionCounts() {
        return this.videoFiles.reduce((counts, video) => {
            const resolution = video.resolution.toLowerCase();
            counts[resolution] = (counts[resolution] || 0) + 1;
            return counts;
        }, {});
    }

    // ... (downloadAll and clear methods would require more complex logic)

    toJSON() {
        return JSON.stringify(this.videoFiles, null, 2); // Use 2 spaces for indentation
    }

    toCSV() {
        const header = "Filename,Bitrate,Framerate,Resolution,Size,VideoCodec\n";
        const rows = this.videoFiles.map((video) => Object.values(video).join(","));
        return header + rows.join("\n");
    }
}
