//@ts-check

import { ScenePath } from "./sceneGraph/ScenePath";
import { SceneChangeRequest } from "./sceneGraph/SceneChangeRequest";

/**
 *
 */
export class Player {
    /**
     *
     * @type {import('./customerAccount').CustomerAccount}
     */
    account;
    /**
     *
     * @type {object}
     */
    json;
    /**
     *
     * @type {import('./simulation').Simulation}
     */
    simulation;
    /**
     *
     * @type {object}
     */
    active_location;
    /**
     *
     * @param {import('./customerAccount').CustomerAccount} account
     * @param {import('./simulation').Simulation} simulation
     */
    constructor(account, simulation) {
        this.account = account;
        this.json = account.json_player;
        this.simulation = simulation;

        if (this.json.tokenCount === undefined) {
            this.json.tokenCount = 0;
        }
        if (this.json.homeLocation === undefined) {
            //var sg = this.sceneGraphSet.defaultNavigationSceneGraph;
            this.json.homeLocation = new ScenePath("rustic-log-cabin", "cabin-splash-screen");
        }
        if (this.json.location === undefined) {
            this.json.location = this.json.homeLocation;
        }
        if (this.json.navigationHistory === undefined) {
            this.json.navigationHistory = [this.json.location];
        }
    }
    /**
     * @returns {import('./sceneGraph/sceneGraphSet').SceneGraphSet}
     */
    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }

    // get simulation() {
    //   return this.sceneGraphSet.simulation;
    // }
    /**
     * @returns {import('./sceneGraph/scene_graph_node').SceneGraphNode}
     */
    get sceneGraphNode() {
        return this.simulation.sceneGraphNode;
    }
    /**
     * @returns {object}
     */
    get navigationHistory() {
        return this.json.navigationHistory;
    }
    /**
     * @param {object} v
     */
    set navigationHistory(v) {
        this.json.navigationHistory = v;
    }
    /**
     *
     * @param {SceneChangeRequest} request
     */
    on_scene_change_request_success(request) {
        this.json.location = request.location;
        this.active_location = request.location;

        // if (this.navigationHistory.length == 0 || this.navigationHistory[this.navigationHistory.length - 1] !== path) {
        if (request.isPushNavigationHistory) {
            this.navigationHistory.push(request.location);

            if (this.navigationHistory.length > 100) {
                this.navigationHistory = this.navigationHistory.splice(0, 100 / 2);
            }
        }
        // }
    }
    /**
     *
     * @param {object} path
     * @param {boolean} isPushNavigation
     * @param {object|undefined} fallback_path_on_error
     */
    startChangeLocation(path, isPushNavigation = true, fallback_path_on_error = undefined) {
        var request = new SceneChangeRequest(path, isPushNavigation);
        var fallback_request_on_error;
        if (fallback_path_on_error) {
            fallback_request_on_error = new SceneChangeRequest(
                fallback_path_on_error,
                isPushNavigation,
            );
        }
        // request.replace_location = this.active_location;
        this.simulation.startSceneChange(request, fallback_request_on_error);
        // if (this.json.location === path) {
        //   return;
        // }

        // this.sceneGraphSet.tryPopulateDefaultPaths(path);
        // var success = this.sceneGraphNode.TryReplaceScene(this.sceneGraphSet, this.json.location, path);

        // if (success) {

        //   this.simulation.selectionMode.startState();
        //   this.json.location = path;

        //   if (this.navigationHistory.length == 0 || this.navigationHistory[this.navigationHistory.length - 1] !== path) {
        //     if (isPushNavigation) {
        //       this.navigationHistory.push(path);

        //       if (this.navigationHistory.length > 100) {
        //         this.navigationHistory = this.navigationHistory.splice(0, 100 / 2);
        //       }
        //     }
        //   }

        //   this.sceneGraphNode.invalidate();
        // }
    }

    // initializeSceneGraphNode() {
    //   if (!this.sceneGraphNode.tryAddAndStartScenePath(this.sceneGraphSet, this.json.location, true)) {
    //     console.warn(`scene path not found in scene graph set ${JSON.stringify(this.json.location)}`);
    //     if (this.sceneGraphNode.tryAddAndStartScenePath(this.sceneGraphSet, this.json.homeLocation, true)) {
    //       this.json.location = this.json.homeLocation;
    //       this.navigationHistory.push(this.json.location);
    //     }
    //   }

    //   this.simulation.selectionMode.startState();
    // }
    /**
     *
     * @returns {boolean}
     */
    canNavigateBack() {
        return this.navigationHistory.length > 1;
    }
    /**
     *
     */
    navigateBack() {
        if (this.canNavigateBack()) {
            this.navigationHistory.pop();
            let path = this.navigationHistory[this.navigationHistory.length - 1];
            this.startChangeLocation(path, false);
        }
    }
    /**
     *
     */
    navigateHome() {
        this.startChangeLocation(this.json.homeLocation);
    }
}
