// @ts-check

import { StackLayout } from "../../view/stackLayout";
import IWebAudio from "./interfaces/IAudioPlayer.js";
import { NullConnectedStems } from "./NullConnectedStems";

import { PlaylistManager } from "./playlistAPI/playlistManager.js";
import { getRadioSettings } from "./playlistAPI/Radio.js";
/**
 * @implements {IWebAudio}
 */
export class NullWebAudio {
    /**
     *
     * @type {NullConnectedStems}
     */
    connectedStems;
    /**
     * stores a reference to the playlist manager object
     * @type {PlaylistManager}
     */
    playlistManager;
    /**
     *
     */
    constructor() {
        this.connectedStems = new NullConnectedStems();
        //this.playlistManager = new PlaylistManager("playlists", getRadioSettings)
    }
    /** @type {null} */
    account = null;
    /** @type {null} */
    resources = null;
    /** @type {Map<String>} */
    layers = new Map();
    audioContext;
    storageItemName() {
        return "No StorageItem";
    }
    addAuthorInterfaceElementToTreeview(treeview) {}

    connectNodeToLayer(layer, node) {}

    initialize() {
        console.log("No Audio");
    }

    tryStartAudio() {
        return true;
    }

    uninitialize() {}

    start() {}

    deactivate() {}

    reactivate() {}

    stroageItemName() {}

    saveState() {}

    shutdown() {}

    playSoundEffects(stems) {}

    startAudioOnGesture() {}

    collectEditableProperties(layout) {}

    getAuthorInterfaceName() {
        return "audio";
    }
    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        return layout.element;
    }
}
