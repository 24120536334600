import { AudioPlaylist } from "../AudioPlaylist";
import { PlaylistData } from "../PlaylistData";

export class IPlaylistManager {
    /**
     *
     * @type {number}
     */
    size;

    /**
     * adds a playlist to the playlist manager
     * @param {string} name
     * @param {PlaylistData} playlistData
     */
    add(channel, playlistData) {}

    /**
     *
     * @param {string} name
     * @returns {AudioPlaylist|undefined} returns a playlist with the provided name or undefined if no such playlist name exists
     */
    getPlaylist(name) {}

    /**
     * lists the names for all playlists
     * @function
     * @returns {Array<string>} list of strings
     */
    keys() {}

    /**
     * removes a playlist from the manager
     * @function
     * @param {string} name
     * @returns {AudioPlaylist|undefined} a reference to the removed playlist
     */
    remove(name) {}

    /**
     * removes all playlists from the manager
     * @function
     */
    clearAll() {}

    /**
     * applies Radio specific settings to
     * @param {string} channel
     * @param {object} radioSettings
     */
    applyRadio(channel, radioSettings) {}
}
