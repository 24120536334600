import { WebApplicationState } from "../../../WebApplicationState";
import { KeyCodes } from "../../../input/KeyCodes";
import page from "page";

/**
 * Shows the login page by setting the viewport to a login page.
 * @param {WebApplicationState} state the state of the application.
 */
export function showLoadingPage(state) {
    state.router.addPageToViewport(`
        <div class="login-page">
            <h2>Loading...</h2>
            <div class="loading-indicator">
        </div>
    `);

    /**
     * Returns the user to the previous page.
     */
    const returnHandler = () => {
        console.log("exit loading page");
        state.router.resetPageToCanvas();
        state.externalModules.closeModules();
        removeEventListeners();
        page.redirect("/");
    };


    /**
     * Handles keys pressed on the remote.
     * @param {Event} event the keydown event.
     */
    const keydownHandler = (event) => {
        event.preventDefault();
        if (
            event.keyCode === KeyCodes.Home ||
            event.keyCode === KeyCodes.samsung_tv_back ||
            event.keyCode === KeyCodes.sony_tv_back ||
            KeyCodes.Backspaces.includes(event.keyCode)) {
            // Return back to the previous page
            returnHandler();
        }
    };

    // Add event listeners
    addEventListener("keydown", keydownHandler);

    // Function to remove event listeners
    function removeEventListeners() {
        removeEventListener("keydown", keydownHandler);
    }
}