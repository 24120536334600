//@ts-check

/**
 * 
 */
export class Action {
  /**
   * @type {string}
   */
  static typename = "action";
  /**
 * @type {string}
 */
  verb;
  /**
 * @type {string|undefined}
 */
  name;
  /**
   * 
   * @param {string} verb 
   * @param {string|undefined} name 
   */
  constructor(verb, name) {
    this.verb = verb;
    this.name = name;
  }
  /**
   * 
   * @param {import('../scene').SceneObjectInterface} param 
   * @returns {boolean}
   */
  canRun(param) {
    return true;
  }
  /**
  * 
  * @param {import('../scene').SceneObjectInterface} param 
  */
  run(param) { }
}
/**
 * 
 */
export class ActionWithValue extends Action {
  /**
  * @type {object|undefined}
  */
  value;
  /**
  * @type {object|undefined}
  */
  value_context;
  /**
   * 
   * @param {string} verb 
   * @param {string|undefined} name 
   * @param {object} json 
   */
  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }
}

