//@ts-check

import { AssetData } from "./AssetData";
/**
 * 
 */
export class AssetDatabase {
    /**
   * 
   * @type {string}
   */
  static storeName = "assets";
    /**
   * 
   * @type {string}
   */
  static dbName = "localServerFileCache";
  /**
   * 
   * @type {object}
   */
  assets = {};
  // json;
  /**
   * 
   * @type {AssetDataInterface}
   */
  dataInterface;

  /**
   * 
   * @param {object} json 
   */
  loadFromJson(json) {
    this.assets = json;
    // if (this.json.db_version === undefined) {
    //   this.json.db_version = 1;
    // }
  }
  /**
   * 
   * @returns {object}
   */
  saveToJson() {
    return this.assets;
  }
  /**
   * 
   * @param {AssetDataInterface} dataInterface 
   */
  setDataInterface(dataInterface) {
    this.dataInterface = dataInterface;
  }
/**
 * 
 * @param {Array.<object>} server_asset_list 
 * @returns {Promise}
 */
  async downloadNewAssets(server_asset_list) {
    await this.dataInterface?.downloadNewAssets(server_asset_list, this);
  }
/**
 * 
 * @param {import('../../LocalServerFileCache').AssetObjectInterface} assetObject 
 * @param {string} url 
 * @param {boolean} is_cache_enabled 
 */
  loadFromCacheOrUrl(assetObject, url,is_cache_enabled=true) {
    if (this.dataInterface && is_cache_enabled) {
      this.dataInterface.loadFromCacheOrUrl(this, assetObject, url);
    } else {
      assetObject.src = url;
    }
  }
}

export class AssetDataInterface {
/**
 * 
 * @param {Array.<object>} server_asset_list 
 * @param {AssetDatabase} asset_database 
 * @returns {Promise}
 */
  async downloadNewAssets(server_asset_list, asset_database) {
    return Promise.resolve();
  }
  /**
 * 
 * @param {AssetDatabase} asset_database 
 * @param {import('../../LocalServerFileCache').AssetObjectInterface} assetObject 
 * @param {string} url 
 */
  loadFromCacheOrUrl(asset_database, assetObject, url) {}
}
/**
 * 
 */
export class IndexedDBAssetDataInterface extends AssetDataInterface {
    /**
* 
* @type {IDBDatabase}
*/
  db;
/**
 * 
 * @param {IDBDatabase} db 
 */
  constructor(db) {
    super();
    this.db = db;
  }
/**
 * 
 * @param {string} url 
 * @param {object} info 
 * @param {AssetDatabase} asset_database 
 * @returns 
 */
  downloadFile(url, info, asset_database) {
    let self = this;

    var p = new Promise((resolve) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";

      xhr.onload = function () {
        let data = { path: url, info: info };

        let transaction = self.db.transaction([AssetDatabase.storeName], "readwrite");
        let store = transaction.objectStore(AssetDatabase.storeName);

        let storeData = { path: url, info: info, data: this.response };
        var request = store.put(storeData);
        request.onerror = function (e) {
          //let name = e.target.error.name;
          resolve(data);
        };
        request.onsuccess = function (e) {
          asset_database.assets[url] = data;
          console.log(`end download ${url}`);
          resolve(data);
        };
      };

      console.log(`start download ${url}`);
      xhr.send();
    });
    return p;
  }
/**
 * 
 * @param {Array.<object>} server_asset_list 
 * @param {AssetDatabase} asset_database 
 * @returns {Promise}
 */
  async downloadNewAssets(server_asset_list, asset_database) {
    let promises = [];

    for (const each_entry of server_asset_list) {

      if(each_entry.path==undefined){
        continue;
      }

      if (each_entry.path.startsWith("__")) {
        continue;
      }
      let found = asset_database.assets[each_entry.path];

      if (found && found.info.md5_hash == each_entry.md5_hash) {
        continue;
      }

      let p = this.downloadFile(each_entry.path, each_entry, asset_database);
      if (p) {
        promises.push(p);
      }
    }

    await Promise.all(promises);
  }
  /**
 * 
 * @param {AssetDatabase} asset_database 
 * @param {import('../../LocalServerFileCache').AssetObjectInterface} assetObject 
 * @param {string} url 
 */
  loadFromCacheOrUrl(asset_database, assetObject, url) {
    let found = asset_database.assets[url];

    if (found) {
      let transaction = this.db.transaction([AssetDatabase.storeName], "readwrite");
      transaction.objectStore(AssetDatabase.storeName).get(url).onsuccess = function (event) {

        if(!event.target.result){
          assetObject.src = url;
          return;
        }
        
        var imgFile = event.target.result.data;

        console.log("load from cache: " + event.target.result.path);

        var URL = window.URL || window.webkitURL;

        var imgURL = URL.createObjectURL(imgFile);

        assetObject.onload = () => URL.revokeObjectURL(imgURL);
        assetObject.src = imgURL;
      };
    } else {
      assetObject.src = url;
    }
  }
}
