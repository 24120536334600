import { KeyCodes } from "../../../input/KeyCodes";
import { WebApplicationState } from "../../../WebApplicationState";
import page from "page";
import { showPhoneLoginPage } from "./phoneAuth/login";
import { InteractiveMobileCanvas } from "../../../audio_visual/interactive_mobile_canvas";

/**
 * Shows the login page by setting the viewport to a login page.
 * @param {WebApplicationState} state the state of the application.
 */
export function showTVLoginPage(state) {
    let redirectURL;
    if (window.location.hash.includes("game_redirect")) {
        redirectURL = window.location.hash.split("_")[2];
    } else if (window.location.hash.includes("redirect")) {
        redirectURL = window.location.hash.split("_")[1];
    }

    state.router.addPageToViewport(`
        <div class="login-page">
            <h1>Sign in to Lakeside Cabin</h1>
            <div id="login-buttons">
                <button id="signin-button">Sign in</button>
                <button id="guest-button">Continue as guest</button>
                ${!redirectURL ? '<button id="return">Return</button>' : ""}
            </div>
        </div>
    `);

    let activeButton = 0;
    const buttons = document.querySelectorAll("button");
    buttons[activeButton].focus();

    // Handles the sign in button
    const signInHandler = () => {
        removeEventListeners();
        // Check whether on tv or mobile, to either show QR code or just show login page.
        if (InteractiveMobileCanvas.is_platform) {
            console.log("show phone login page");
            showPhoneLoginPage("", state);
        } else {
            console.log("show TV login page");
            state.auth.createLoginSession(redirectURL);
        }
    };

    /**
     * Handles the guest button.
     */
    const guestHandler = () => {
        if (redirectURL) {
            window.location.href = redirectURL;
        } else {
            state.auth.createGuestSession();
        }
    };

    /**
     * Returns the user to the previous page.
    */
   const returnHandler = () => {
       console.log("exit login page");
       state.router.resetPageToCanvas();
        state.externalModules.closeModules();
        removeEventListeners();
        page.redirect("/");
    };

    /**
     * Handles keys pressed on the remote.
     * @param {Event} event the keydown event.
     */
    const keydownHandler = (event) => {
        event.preventDefault();
        if (KeyCodes.Ups.includes(event.keyCode)) {
            if (activeButton > 0) {
                activeButton--;
            }
        } else if (KeyCodes.Downs.includes(event.keyCode)) {
            if (activeButton < 2) {
                activeButton++;
            }
        } else if (
            event.keyCode === KeyCodes.Home ||
            event.keyCode === KeyCodes.samsung_tv_back ||
            event.keyCode === KeyCodes.sony_tv_back ||
            KeyCodes.Backspaces.includes(event.keyCode)
        ) {
            // Return back to the previous page
            document.getElementById("return")?.click();
        } else if (event.keyCode === KeyCodes.Enter) {
            buttons[activeButton]?.click();
        }
        // Set the active button
        buttons[activeButton]?.focus();
    };

    // Add event listeners
    document.getElementById("signin-button")?.addEventListener("click", signInHandler);
    document.getElementById("guest-button")?.addEventListener("click", guestHandler);
    document.getElementById("return")?.addEventListener("click", returnHandler);
    addEventListener("keydown", keydownHandler);

    // Function to remove event listeners
    function removeEventListeners() {
        document.getElementById("signin-button")?.removeEventListener("click", signInHandler);
        document.getElementById("guest-button")?.removeEventListener("click", guestHandler);
        document.getElementById("return")?.removeEventListener("click", returnHandler);
        removeEventListener("keydown", keydownHandler);
    }
}
