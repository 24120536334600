//@ts-check

//import { WebApplicationState } from '../WebApplicationState';


/**
 * @callback initializeFunction 
*/
/**
 * @callback authenticateFunction 
 * @returns {Promise}
*/
/**
 * @callback saveStateFunction 
*/
/**
 * @callback shutdownFunction 
*/

/**
 * @typedef AuthInterface
 * @property {initializeFunction} initialize 
 * @property {authenticateFunction} authenticate 
 * @property {saveStateFunction} saveState 
 * @property {shutdownFunction} shutdown 
*/

/**
 * 
 */
export class NoInternalAuth {
    /**
     * 
     * @param {import('../WebApplicationState').WebApplicationState} state 
     */
    constructor(state) {
    }
    /**
     * 
     */
    initialize() {
        console.log("No Internal Auth");
    }
    /**
     * @returns {Promise}
     */
    async authenticate() {

    }
    /**
     * 
     */
    saveState() { }
    /**
     * 
     */
    shutdown() { this.saveState(); }
}