import { Action } from "../actions/action";

export class NavigateHomeAction extends Action {
    value;
    constructor(verb, name, json = undefined) {
        super(verb, name);
    }

    run(interactive) {
        console.log("action:NavigateHome");
        interactive.scene.navigateHome();
    }
}
