// @ts-check

import IAudioPlayer from "./interfaces/IAudioPlayer.js";
import { PlaylistManager } from "./playlistAPI/playlistManager.js";
import { WebAudioComponent } from "./WebAudioComponent.js";

/**
 *
 */
export class AudioAmbianceGroup {
    /** @type {PlaylistManager} */
    playlistManger;
    /** @type {Array.<import('./audioAmbiance').AudioAmbiance>} */
    collection = [];
    /** @type {IAudioPlayer} */
    audio;
    /**
     *
     * @param {import('./WebAudioComponent.js').WebAudioInterface} audio
     */
    constructor(audio) {
        // this.playlistManger = new PlaylistManager("playlists", getRadio())
        this.audio = audio.player;
    }
    /**
     *
     */
    onStartAudioOnGesture() {}
    /**
     *
     * @param {import('./audioAmbiance').AudioAmbiance} ambiance
     */
    add(ambiance) {
        if (!ambiance) {
            return;
        }

        if (this.collection.filter((each) => each === ambiance).length > 0) {
            return;
        }

        var to_soundscape = ambiance.get_soundscape();

        this.loadSoundscape(to_soundscape);
        this.playSoundscape(to_soundscape);
        this.collection.push(ambiance);
    }
    /**
     *
     * @param {import('./audioAmbiance').AudioAmbiance} ambiance_from
     * @param {import('./audioAmbiance').AudioAmbiance} ambiance_to
     */
    transition(ambiance_from, ambiance_to) {
        const playlists_to = new Map();
        ambiance_to?.playlists.forEach((element) =>
            playlists_to.set(element.name, element.playlist),
        );
        this.audio?.playlistManager?.transition(playlists_to);

        this.removeAmbianceFromCollection(ambiance_from);

        var from_soundscape = ambiance_from.get_soundscape();
        var to_soundscape = ambiance_to.get_soundscape();

        from_soundscape.forEach((each) => {
            var found = each.findInArray(to_soundscape);
            if (found) {
            } else {
                this.audio.connectedStems.stopStem(each);
            }
        });
        ambiance_from.onListenLocationExit(this);

        to_soundscape.forEach((each) => {
            var found = each.findInArray(from_soundscape);
            if (found) {
                this.audio.connectedStems.updateStem(each);
            } else {
                this.audio.connectedStems.loadStem(each);
                this.audio.connectedStems.playStem(each);
            }
        });

        this.collection.push(ambiance_to);
    }
    /**
     *
     * @param {import('./audioAmbiance').AudioAmbiance} ambiance
     */
    removeAmbianceFromCollection(ambiance) {
        const index = this.collection.indexOf(ambiance);
        if (index > -1) {
            this.collection.splice(index, 1);
        }
    }
    /**
     *
     * @param {import('./audioAmbiance').AudioAmbiance} ambiance
     */
    remove(ambiance) {
        if (!ambiance) {
            return;
        }
        var from_soundscape = ambiance.get_soundscape();
        this.stopSoundscape(from_soundscape);
        this.removeAmbianceFromCollection(ambiance);
        ambiance.onListenLocationExit(this);
    }
    /**
     *
     * @param {Array.<import('./stem').Stem>} soundscape
     */
    playSoundscape(soundscape) {
        soundscape.forEach((stem) => {
            this.audio.connectedStems.playStem(stem);
        });
    }
    /**
     *
     * @param {Array.<import('./stem').Stem>} soundscape
     */
    stopSoundscape(soundscape) {
        soundscape.forEach((stem) => {
            this.audio.connectedStems.stopStem(stem);
        });
    }
    /**
     *
     * @param {Array.<import('./stem').Stem>} soundscape
     */
    loadSoundscape(soundscape) {
        soundscape.forEach((stem) => {
            this.audio.connectedStems.loadStem(stem);
        });
    }
}
