import { Interactive } from "./Interactive";
import { SceneObject } from "./SceneObject";

export class SceneObjectProperty {}

export class SceneObjects {
    static typename = "objects";
    static baseResourcePath = "sceneObjects";
    parent;
    json;
    items = [];

    constructor(parent, json) {
        this.parent = parent;
        this.json = json;
    }

    initializeFromJson() {
        for (let each in this.json) {
            var i = new SceneObject(this, this.json[each]);
            this.items.push(i);
            i.initializeFromJson();
        }
    }

    get scene() {
        return this.parent.scene;
    }

    isVisualContentReady() {
        return true;
    }
    startVisualContentGetReady(gettingReadyCallbackCollector) {}

    start() {
        this.items.forEach((element) => {
            element.start();
        });
    }

    stop() {
        this.items.forEach((element) => {
            element.stop();
        });
    }

    drawFrame(icanvas) {
        this.items.forEach((element) => {
            element.drawFrame(icanvas);
        });
    }
}
