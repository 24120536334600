// @ts-check

import { CachedAction } from "../../cachedAction";
import IAudioPlayer from "./interfaces/IAudioPlayer";

/**
 * @callback actionFunction
 */
/**
 * @callback AudioBufferSourceNode_actionFunction
 * @param {AudioBufferSourceNode|undefined} node
 */
/**
 *
 */
export class ConnectedStem {
    /** @type {object} */
    static default_play_cxt = { startAtSeconds: 0, isLoop: false, gain: undefined };
    /** @type {object} */
    static default_stop_cxt = { isPause: false };

    /** @type {Array.<import('./stem').Stem>} */
    stemReferences = [];
    /** @type {import('./resources/audioResource').AudioResource|undefined} */
    resource;
    /** @type {string} */
    audioSrc;
    /** @type {AudioBufferSourceNode|undefined} */
    sourceNode;
    /** @type {Array.<CachedAction>} */
    blocked_actions = [];
    /** @type {GainNode|undefined} */
    gainNode;
    /** @type {IAudioPlayer} */
    audio;
    /** @type {number} */
    durration;
    /** @type {number} */
    gain;
    /** @type {boolean} */
    isLoop;
    /** @type {boolean} */
    isConnected;
    /** @type {number} */
    playCount;
    /** @type {boolean} */
    isPlaying;
    /** @type {string|undefined} */
    name;
    /** @type {actionFunction} */
    onDone;

    /** @type {import('./stem').Stem} */
    get firstStem() {
        return this.stemReferences[0];
    }
    /** @type {object} */
    get firstStemData() {
        return this.firstStem.json;
    }
    /** @type {import('../../resources').Resources} */
    get resources() {
        return this.audio.resources;
    }
    /**
     *
     * @param {IAudioPlayer} audio
     * @param {string} audioSrc
     */
    constructor(audio, audioSrc) {
        this.audio = audio;
        this.audioSrc = audioSrc;
        this.playCount = 0;
    }
    /**
     * @param {import('./stem').Stem} stem
     */
    addReference(stem) {
        this.stemReferences.push(stem);
        if (!this.resource) {
            this.loadFrom(stem);
        }
    }
    /**
     * @param {import('./stem').Stem} stem
     */
    removeReference(stem) {
        const index = this.stemReferences.indexOf(stem);
        if (index > -1) {
            this.stemReferences.splice(index, 1);
        }
    }
    /**
     *
     * @returns {boolean}
     */
    isUnreferenced() {
        if (this.stemReferences.length == 0) {
            return true;
        }
        return false;
    }
    /**
     *
     * @param {AudioBufferSourceNode_actionFunction} callback
     */
    useSourceNode(callback) {
        if (!this.resource) {
            return;
        }
        let loading = this.resource.isLoading();

        if (loading) {
            let self = this;

            var and_then = (res) => {
                try {
                    callback(self.sourceNode);
                } catch (e) {
                    console.log("audio exception");
                }
            };

            this.resource.addLoadingThen(and_then);
        } else {
            callback(this.sourceNode);
        }
    }
    /**
     * Starts loading of audio from stem
     * @param {import('./stem').Stem} stem
     */
    async loadFrom(stem) {
        if (this.resource !== undefined) {
            throw new Error("invalid operation - connected stem can load once");
        }

        this.isLoop = stem.json.isLoop;
        this.gain = stem.json.gain;
        this.resource = this.resources.requestAudioResource(
            this.audioSrc,
            stem.getResourcePath(),
            stem.json.resourcePath2,
        );

        var isLoading = this.resource.isLoading();
        if (isLoading) {
            let self = this;
            var and_then = async (loading_cxt) => {
                await self.createNodeFromResource();
            };
            this.resource.addLoadingThen(and_then);
        } else {
            await this.createNodeFromResource();
        }
    }
    /**
     * @param {import('./stem').Stem} stem
     */
    unloadFrom(stem) {}
    /**
     * @param {import('./stem').Stem} stem
     */
    playFrom(stem) {
        this.playCount = this.playCount + 1;

        if (this.playCount > 1) {
            return;
        }

        var cxt = this.newPlayContextFromStem(stem);
        this.play(cxt);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    stopFrom(stem) {
        this.playCount = this.playCount - 1;

        if (this.playCount > 0) {
            return;
        }
        var cxt = this.newStopContextFromStem(stem);
        this.stop(cxt);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     * @param {number} amount
     */
    setGainFrom(stem, amount) {
        this.setGain(amount);
    }
    /**
     * starts audio on user input because of chrome browser limitation
     * https://developer.chrome.com/blog/autoplay
     * @param {number} now
     */
    startAudioOnGesture(now) {
        if (this.resource === undefined || this.resource.isLoading()) {
            return;
        }

        if (this.blocked_actions.length == 0) {
            return;
        }

        var latest = this.blocked_actions[this.blocked_actions.length - 1];
        if (latest.name == "stop") {
            return;
        }

        if (latest.name == "play") {
            this.blocked_actions = [];

            let delayed_context = { ...latest.context };

            delayed_context.startAtSeconds += now - latest.now;

            if (delayed_context.isLoop) {
                var duration = this.durration;

                if (delayed_context.startAtSeconds > duration) {
                    delayed_context.startAtSeconds = delayed_context.startAtSeconds % duration;
                }
            }

            this.play(delayed_context);
        }
    }
    /**
     * @param {import('./stem').Stem} stem
     * @returns {object}
     */
    newPlayContextFromStem(stem) {
        let result = { ...ConnectedStem.default_play_cxt };
        result.isLoop = stem.json.isLoop;
        return result;
    }
    /**
     *
     * @param {object} play_cxt
     */
    play(play_cxt = ConnectedStem.default_play_cxt) {
        play_cxt = Object.assign({}, ConnectedStem.default_play_cxt, play_cxt);
        var self = this;

        this.useSourceNode(() => {
            if (!self.gainNode) {
                console.error(`gain node undefined at play() for ${self}`);
                return;
            }

            if (!self.isConnected) {
                self.audio.connectNodeToLayer(self.firstStemData.layer, self.gainNode);
                self.isConnected = true;
            }
            var message = "";
            try {
                if (play_cxt.gain !== undefined) {
                    this.setGain(play_cxt.gain);
                }

                message = `audio: play(gain:${this.gain}) - ${self.name || self.audioSrc}`;
                console.log(message);

                self.sourceNode?.start();
            } catch (e) {
                console.log("blocked:" + message);
            }
        });
    }

    /**
* @param {import('./stem').Stem} stem
 @returns {object}
*/
    newStopContextFromStem(stem) {
        let result = { ...ConnectedStem.default_stop_cxt };

        return result;
    }
    /**
     * @param {object} stop_cxt
     */
    stop(stop_cxt = ConnectedStem.default_stop_cxt) {
        stop_cxt = Object.assign({}, ConnectedStem.default_stop_cxt, stop_cxt);
        var self = this;
        this.useSourceNode(() => {
            if (!self.gainNode) {
                console.error(`gain node undefined at stop() for ${self}`);
                return;
            }

            // self.sourceNode.disconnect();
            self.gainNode.disconnect();
            self.isConnected = false;

            var name = stop_cxt.isPause ? "pause" : "stop";
            var message = `audio: ${name} - ${self.name || self.audioSrc}`;
            console.log(message);

            self.isPlaying = false;
        });
    }
    /**
     *
     */
    pause() {
        this.stop({ isPause: true });
    }
    /**
     * connects Aud
     */
    async createNodeFromResource() {
        if (!this.resource) {
            return;
        }

        this.sourceNode = this.audio.audioContext.createBufferSource();
        if (!this.sourceNode) {
            console.trace("Node Creation Failed");
            return;
        }
        if (this.isLoop) {
            this.sourceNode.loop = true;
        }
        const buffer = await this.audio.audioContext.decodeAudioData(
            await this.resource.resource_element,
        );
        this.durration = buffer.duration;
        this.sourceNode.buffer = buffer;
        this.gainNode = this.audio.audioContext.createGain();

        this.setGain(this.gain);

        if (!this.gainNode) {
            console.trace("Node Creation Failed");
            return;
        }
        this.sourceNode.connect(this.gainNode);
        this.gainNode.connect(this.audio.audioContext.destination);
    }
    /**
     * Sets the gain value for the output gain of the connected stem
     * @param {number} amount -
     * @param {boolean} isLog - logs relevant info to console if true
     * @returns
     */
    setGain(amount, isLog = false) {
        this.gain = amount;

        if (!this.gainNode) {
            console.warn(`connected stem setGain call with no gain node`);
            return;
        }

        this.gainNode.gain.setValueAtTime(amount, this.audio.audioContext.currentTime);
        if (isLog) {
            var message = `audio: update(gain:${this.gain}) - ${this.name || this.audioSrc}`;
            console.log(message);
        }
    }
    /**
     * Removes both sourceNode and gainNode from audioContext
     */
    dispose() {
        if (this.sourceNode) {
            this.sourceNode.disconnect();
            this.sourceNode = undefined;

            if (this.gainNode) {
                this.gainNode.disconnect();
                this.gainNode = undefined;
            }
        }

        if (this.resource) {
            this.resources.disposeResource(this.resource);
            this.resource = undefined;
        }
    }
}
