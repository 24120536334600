//@ts-check
import { Treeview } from "../view/treeview";
import { OpenModule } from "../externalModules/OpenModule";
import { ModuleIntegration } from "../externalModules/ModuleIntegration";
import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper";

import { BaseModules, ModulesCommon } from "./ModulesCommon";
import { InteractiveCanvas } from '../audio_visual/interactive_canvas';
import { AuthorInterface } from '../sceneAuthorInterface/authorInterface';
import { Resources } from '../resources';
import { CustomerAccount } from '../customerAccount';
import { WebApplicationServer } from '../webApplicationServer';
import { WebAudio } from '../audio_visual/audio/webAudio';
import { WebApplicationState } from '../WebApplicationState';
import { WebApplication } from '../webApplication';
import { ExternalModule } from '../externalModules/ExternalModule';

/**
* @type {object}
*/
export var dialogOptions = {};
/**
* @type {string|undefined}
*/
export var id;
/**
 * 
 */
export class ExternalModules extends BaseModules {
	/**
	* @type {string}
	*/
	static fullscreenIframeDivId = ModulesCommon.fullscreenIframeDivId;
	/**
	* @type {InteractiveCanvas}
	*/
	icanvas;
	/**
	* @type {AuthorInterface|undefined}
	*/
	authoringInterface;
	/**
	* @type {Resources}
	*/
	resources;
	/**
	* @type {CustomerAccount}
	*/
	account;
	/**
	* @type {WebApplicationServer}
	*/
	server;
	/**
	* @type {Array.<ExternalModule>}
	*/
	static modules = [];
	/**
	* @type {object}
	*/
	json;
	/**
	* @type {WebAudio}
	*/
	audio;
	/**
   * @type {Array.<OpenModule>}
   */
	open_modules = [];
	/**
	* @type {WebApplicationState}
	*/
	state;
	/**
	 * 
	 * @param {WebApplicationState} state 
	 * @param {Resources} resources 
	 * @param {InteractiveCanvas} icanvas 
	 * @param {CustomerAccount} account 
	 * @param {WebApplicationServer} server 
	 * @param {WebAudio} audio 
	 * @param {object} json 
	 */
	constructor(state, resources, icanvas, account, server, audio, json = {}) {
		super();
		this.resources = resources;
		this.icanvas = icanvas;
		this.icanvas.externalModules = this;
		this.account = account;
		this.server = server;
		this.json = json;
		this.audio = audio;
		this.ready = false;
		this.state = state;
		this.auth = null;
	}
	/**
	 * @returns {WebApplication}
	 */
	get application() {
		return this.account.application;
	}
	/**
	 * 
	 * @returns {string}
	 */
	stroageItemName() {
		return this.resources.combineJsonResourceName(this.application.name, this.account.name, "externalModules");
	}
	/**
	 * 
	 */
	initialize() {
		if (!this.json.modules) {
			this.json.modules = ExternalModules.modules;
		}
		var self = this;
		window.addEventListener("message", (event) => {
			if (event.data.userInfo) { this.auth = event.data.userInfo }
			if (this.auth?.user_id) { event.data.userInfo = this.auth; }
			self.postMessageHandler(event);
		}, false);
	}

	/**
	 * 
	 * @param {MessageEvent<any>} event 
	 */
	postMessageHandler(event) {
		this.open_modules.forEach((element) => {
			// if (element.module.html == event.origin && event.source === element.iframe.contentWindow) {
			element.windowMessage(event);
			// }
		});
	}
	/**
	 * 
	 */
	saveState() {
		// this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
	}
	/**
	 * 
	 */
	shutdown() {
		this.saveState();
	}

	/**
	 * 
	 * @returns {string}
	 */
	getAuthorInterfaceName() {
		return "External Modules";
	}
	/**
	 * 
	 * @returns {HTMLElement}
	 */
	createAuthorInterfaceElement() {
		let layout = new Treeview();
		layout.buildElementFromJson(this.json, 1);
		return layout.element;
	}

	/**
	 * 
	 * @param {string} uuid 
	 * @param {string} name 
	 * @returns {string}
	 */
	static newDialogiFrame(uuid, name = "module") {
		var fullscreen_iframe_div = document.getElementById(ExternalModules.fullscreenIframeDivId);

		var div_id = `module-instance-${uuid}`;
		// title="${name}"
		var div = htmlHelper.htmlToElement(`<div id=${div_id} ></div>`);

		if (fullscreen_iframe_div && fullscreen_iframe_div.parentElement && div) {
			fullscreen_iframe_div.parentElement.insertBefore(div, fullscreen_iframe_div.nextSibling);
		}

		return div_id;
	}
	/**
	 * 
	 * @param {string} name 
	 * @returns {ExternalModule|undefined}
	 */
	findModule(name) {
		for (let index = 0; index < this.json.modules.length; index++) {
			const element = this.json.modules[index];
			if (element.name == name) {
				return element;
			}
		}
	}
	/**
	 * 
	 * @param {string} name 
	 * @returns {OpenModule|undefined}
	 */
	findOpenModule(name) {
		for (let index = 0; index < this.open_modules.length; index++) {
			const element = this.open_modules[index];
			if (element.name == name) {
				return element;
			}
		}
	}
	/**
	 * 
	 * @param {string} name 
	 * @param {boolean} isFullscreen 
	 * @returns 
	 */
	openModule(name, isFullscreen = true/*, screenRect = undefined*/) {
		var module = this.findModule(name);
		if (this.open_modules[0]) { this.closeModule(this.open_modules[0]) }
		this.ready = false;

		//isFullscreen = true;

		if (!module) { return undefined; }
		// if (this.icanvas.isMobile) { isFullscreen = true; }

		var uuid = uuidv4();


		if (isFullscreen) {
			id = ExternalModules.fullscreenIframeDivId;

			this.icanvas.deactivate();
			this.authoringInterface?.deactivate();
			this.audio.deactivate();

		} else {
			id = ExternalModules.newDialogiFrame(uuid, name);
		}

		var iframeDiv = /** @type {HTMLElement} */ (document.getElementById(id));

		iframeDiv.style.display = 'none'
		iframeDiv.style.zIndex = '100';
		iframeDiv.innerHTML = '';

		var iframe = /** @type {HTMLIFrameElement} */ document.createElement("iframe");

		let userId;

		if (this.auth?.user_id) { userId = this.auth?.user_id.split("auth0|")[1] }

		var openModule = new OpenModule(module, id, iframe, uuid, userId, this.state);
		var self = this;

		if (isFullscreen) { iframeDiv.style.display = "block"; }
		// else {
		// 	// if (screenRect) {
		// 	dialogOptions.width = this.icanvas.elements[1].width
		// 	dialogOptions.height = this.icanvas.elements[1].height
		// 	// dialogOptions.close = function () {
		// 	// 	openModule.tryClose();
		// 	// };
		// 	dialogOptions.resize = function (event, ui) {
		// 		self.icanvas.disableInput();
		// 	};
		// 	dialogOptions.resizeStop = function (event, ui) {
		// 		self.icanvas.enableInput();
		// 	};

		// 	dialogOptions.resize = false;
		// 	// dialogOptions.autoOpen = false
		// 	// dialogOptions.dialogClass = "transparent-dialog";
		// 	dialogOptions.position = {
		// 		// my: `left+${Math.floor(screenRect.p.x)} top+${Math.floor(screenRect.p.y)}`,
		// 		my: "left top",
		// 		at: "left top",
		// 		of: window,
		// 		collision: "none",
		// 	};

		// 	dialogOptions.closeOnEscape = true;

		// 	dialogOptions.open = function () {
		// 		document.getElementById(`${id}`).dialog("widget").find(".ui-dialog-titlebar").hide();
		// 	}

		// 	// dialogOptions.open = function (event, ui) {
		// 	//   $(this).parent().children().children(".ui-dialog-titlebar-close").hide();
		// 	// };
		// 	// }

		// 	// $.ui.dialog.prototype._makeDraggable = function () {
		// 	// 	this.uiDialog.draggable({
		// 	// 		containment: true,
		// 	// 		handle: ".ui-dialog-titlebar",
		// 	// 	});
		// 	// };

		// 	var found_dialog = document.getElementById(`${id}`);
		// 	found_dialog.dialog(dialogOptions).siblings('.ui-dialog-titlebar').hide();

		// 	document.querySelectorAll(".ui-widget").forEach(each => {
		// 		each.style.border = "0px solid black";
		// 		each.style.padding = "0";
		// 		each.style.zIndex = -1;
		// 	});
		// 	document.querySelectorAll(".ui-widget-content").forEach(each => {
		// 		each.style.border = "0px solid black";
		// 		each.style.padding = "0";
		// 		each.style.zIndex = -1;
		// 	});
		// 	document.querySelectorAll(".ui-dialog").forEach(each => {
		// 		each.style.border = "0px solid black";
		// 		each.style.zIndex = -1;
		// 		each.style.padding = "0";
		// 		each.style.overflow = "hidden";
		// 	});
		// 	document.querySelectorAll(".ui-dialog-content").forEach(each => {
		// 		each.style.border = "0px solid black";
		// 		each.style.zIndex = -1;
		// 		each.style.padding = "0";
		// 		each.style.overflow = "hidden";
		// 	});

		// 	// $(document).find(".ui-widget").css({ border: "0px solid black", padding: "0", zIndex: -1 })
		// 	// $(document).find(".ui-widget-content").css({ border: "0px solid black", padding: "0", zIndex: -1 })
		// 	// $(document).find(".ui-dialog").css({ border: "0px solid black",  padding: "0", overflow: "hidden", zIndex: -1 })
		// 	// $(document).find(".ui-dialog-content").css({ border: "0px solid black", padding: "0", overflow: "hidden", zIndex: -1 })

		// 	dialogOptions.height = this.icanvas.elements[1].height;
		// 	dialogOptions.width = this.icanvas.elements[1].width
		// 	document.getElementById(`${id}`).dialog(dialogOptions);

		// 	// $(`#${id}`).dialog('close')					
		// 	// dialogOptions.height = dialogOptions.height + (dialogOptions.height * $(`#${id}`).siblings('.ui-dialog-titlebar').height() / this.icanvas.elements[1].height);

		// 	// $(`#${id}`).dialog(dialogOptions);
		// }

		iframeDiv.innerHTML = "";
		openModule.externalExtensions = this;

		if (isFullscreen) {
			iframe.style.height = "100vh";
			iframe.style.width = "100vw";
		} else {
			iframe.style.height = "100%";
			iframe.style.width = "100%";
		}

		iframe.onload = () => {
			iframe.contentWindow?.focus()
			openModule.start();
		};

		openModule.iframe.src = module.html;
		iframeDiv.appendChild(iframe);

		// iframe.allowTransparency=true;
		// iframe.style.backgroundColor="#FFFFFF00";
		// iframe.contentWindow.document.open();
		// iframe.contentWindow.document.write(module.html);
		// iframe.contentWindow.document.close();

		this.open_modules[0] = openModule;

		//.push(openModule);

		return openModule;
	}
/**
 * 
 */
	closeModules() {
		var i = this.open_modules.length;
		while (i--) {
			this.closeModule(this.open_modules[i]);
		}
	}
		/**
	 * 
	 * @param {string} name 
	 */
	closeModuleByName(name) {
		var found = this.findOpenModule(name);
		if (found) {
			this.closeModule(found);
		}
	}
	/**
	 * 
	 * @param {string} name 
	 */
	closeModuleByDivId(name) {
		var found = this.findOpenModule(name);
		if (found) {
			this.closeModule(found);
		}
	}
	/**
	 * 
	 * @param {string} id 
	 * @returns {OpenModule|undefined}
	 */
	findOpenModuleByDivId(id) {
		for (let index = 0; index < this.open_modules.length; index++) {
			const element = this.open_modules[index];
			if (element.iframeDivId == id) {
				return element;
			}
		}
	}
	/**
	 * 
	 * @param {OpenModule} module 
	 */
	closeModule(module) {
		if (module.isClosed) {
			return;
		}
		module.isClosed = true;

		var iframeDiv =/** @type {HTMLElement} */ ( document.getElementById(module.iframeDivId));
		iframeDiv.style.display = "none";
		iframeDiv.innerHTML = "";

		if (module.iframeDivId == ExternalModules.fullscreenIframeDivId) {
			this.icanvas.reactivate();
			this.authoringInterface?.reactivate();
			this.audio.reactivate();
			this.icanvas.try_invalidated_draw();
		} else {

		//	document.getElementById(`${module.iframeDivId}`).dialog("close");
			//document.querySelector(`#${module.iframeDivId}`).parentElement.remove();

		}
		this.open_modules = this.open_modules.filter((each) => !(each === module));
	}
}