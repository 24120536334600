// @ts-check

// @ts-check
import { VisualElements } from "./VisualElements";
import { Actions } from "../../sceneGraph/actions/actions";
import { WebApplication } from "../../webApplication";
import { OnDrawingParams } from "./OnDrawingParams";
import { GettingReadyCallbackCollector } from "../../sceneGraph/GettingReadyCallbackCollector";
import { InteractiveCanvas } from "../interactive_canvas";
import { ResourceCanvasElement } from "./resourceCanvasElements/ResourceCanvasElement";
import { WebApplicationState } from "../../WebApplicationState";
import { UpdateContext } from "../../update";
let c2 = require("c2.js");

/**
 *
 * @callback onDisplayedFunction
 * @param {import('../../resources').ResourceInterface} resource
 * @param {boolean} success
 */

/**
 *
 */
export class VisualElement {
    /** @type {import('../../sceneGraph/scene').SceneObjectInterface} */
    obj;

    /** @type {object} */
    json;

    /** @type {Array.<import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface>} */
    resource_canvas_elements = [];

    /** @type {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} */
    active_element;

    /** @type {onDisplayedFunction} */
    onDisplayed;

    /** @type {boolean} */
    isHidden;

    /** @type {boolean} */
    isStopped = true;

    /** @type {import('./OnDrawingParams').onDrawingFunction} */
    onDrawing;

    /** @type {GettingReadyCallbackCollector|undefined} */
    callback_collector;

    /** @type {Promise|undefined} */
    callback_collector_promise;

    /** @type {string} */
    resource_path;
    /**
     *
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj
     * @param {object} json
     * @param {string} resource_path
     */
    constructor(obj, json, resource_path) {
        this.obj = obj;
        this.json = json;
        this.resource_path = resource_path;
        this.addFromJson();
    }
    /**
     *
     * @returns {import('../../LocalServerFileCache').FileInfoType|undefined}
     */
    get_first_file_info() {
        if (this.active_element && this.active_element.url_file_info) {
            return this.active_element.url_file_info;
        }

        for (const each of this.resource_canvas_elements) {
            if (each.url_file_info) {
                return each.url_file_info;
            }
        }
    }
    /**
     *
     */
    on_display_timeout() {
        if (this.active_element) {
            this.onResourceCanvasElementError(this.active_element);
        }
    }
    /**
     *
     * @param {import('./resources/resourceInterface').ResourceInterface} resource
     * @param {boolean} success
     */
    resource_displaying(resource, success) {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }
        if (this.application.getSetting(WebApplication.isVideoPlayingTimeoutSettingName)) {
            if (this.visualElements.isAdded(this) == true) {
                this.visualElements.startDisplayTimeout(this);
            }
        }
    }
    /**
     *
     * @param {import('./resources/resourceInterface').ResourceInterface} resource
     * @param {boolean} success
     */
    resource_displayed(resource, success) {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }
        if (this.application.getSetting(WebApplication.isVideoPlayingTimeoutSettingName)) {
            if (this.visualElements.isAdded(this) == true) {
                this.visualElements.cancelDisplayTimeout(this);
            }
        }

        this.onDisplayed?.(resource, success);
    }
    /**
     * @returns {import('../../resources').Resources}
     */
    get resources() {
        return this.obj.resources;
    }
    /** @return {InteractiveCanvas} */
    get icanvas() {
        return this.obj.icanvas;
    }
    /** @return {WebApplicationState} */
    get webApplicationState() {
        return this.icanvas.state;
    }
    /**
     * @returns {import('../../geometry/RectangleGeometry').RectangleGeometry}
     */
    get geometry() {
        return this.obj.geometry;
    }
    /**
     * @returns {import('../../sceneGraph/scene').SceneObjectInterface}
     */
    get scene() {
        return this.obj.scene;
    }
    /**
     * @returns {import('../../webApplication').WebApplication}
     */
    get application() {
        return this.scene.application;
    }
    /**
     * @returns {VisualElements | undefined}
     */
    get visualElements() {
        return this.obj.visualElements;
    }
    /**
     * @returns {import('../../LocalServerFileCache').LocalServerFileCache}
     */
    get server_file_cache() {
        return this.obj.scene.server_file_cache;
    }
    /**
     * @returns {import('./resources/resourceInterface').ResourceInterface|undefined}
     */
    get visual_resource() {
        return this.active_element?.visual_resource;
    }
    /**
     *
     * @param {boolean} value
     */
    setHidden(value) {
        this.isHidden = value;
        this.active_element?.setHidden(value);
    }
    /**
     *
     * @param {number} value
     */
    setDrawOrder(value) {
        this.drawOrder = value;
        this.active_element?.setDrawOrder(value);
    }
    /**
     *
     * @param {import('../interactive_canvas').InteractiveCanvas} icanvas
     * @returns
     */
    drawFrame(icanvas) {
        if (this.onDrawing) {
            var params = new OnDrawingParams();
            this.onDrawing(params);
            if (params.isCancel) {
                return;
            }
        }

        var background_color = this.json["background.color"];

        if (background_color) {
            var shape = this.geometry.get_absolute_rect_shape();
            icanvas.draw_rect_with_border(
                shape,
                [background_color[0], background_color[1], background_color[2]],
                background_color[3],
            );
        }

        var text = this.json["text"];

        if (text) {
            var shape = this.geometry.get_absolute_rect_shape();
            var pos = new c2.Point(shape.p.x + 4, shape.p.y + 24);

            if (text.includes("{{selectionMode.help}}")) {
                text = text.replace(
                    "{{selectionMode.help}}",
                    this.scene.simulation.selectionMode.helpText(),
                );
            }

            icanvas.draw_text_with_newlines(text, pos, 20, this.json["text.color"]);
        }
    }

    /**
     *
     * @param {import('./resources/resourceInterface').ResourceTypeInterface} type
     * @param {string} json_property
     */
    createAndAddVisualResourceFromJson(type, json_property) {
        var resource = type.createResourceFromJson(
            this.json,
            json_property,
            this.resource_path,
            this.json.resourcePath2,
            this.application,
        );
        if (!resource) {
            console.error("resource Failed", this.json, this.resource_path);
            return;
        }

        resource.onRunAction = (action) => {
            var actions = new Actions();
            actions.parseJson(action, this.application);
            console.log(this.obj);
            actions.runActions(this.obj);
            console.log(this.obj);
        };

        var element = resource.createResourceCanvasElement(this);

        this.resource_canvas_elements.push(element);
    }
    /**
     *
     */
    addFromJson() {
        if (this.json == undefined) {
            return;
        }
        // iterate through every property on the json object assocated with this Visual Element
        for (const each_property in this.json) {
            // iterate through the the visualResourceTypes [audio, video, image]
            for (const each of VisualElements.visualResourceTypes) {
                if (each?.canCreateResourceFromJson(this.json, each_property, this.application)) {
                    // console.log("can create resource from ", each)
                    // console.log("can create resource from ", each_property)

                    this.createAndAddVisualResourceFromJson(each, each_property);
                }
            }
            if (each_property == "drawOrder") {
                this.setDrawOrder(this.json[each_property]);
            }
        }
    }
    /**
     *
     * @param {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
     */
    onResourceCanvasElementError(resource_canvas_element) {
        console.warn("visual element on error: " + resource_canvas_element.toSourceURLNameExt());
    }
    /**
     *
     * @returns {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined}
     */
    getFirstReadyResourceCanvasElement() {
        for (const each of this.resource_canvas_elements) {
            if (each.isReady && !each.isError) {
                return each;
            }
        }
        return undefined;
    }

    /**
     *
     * @param {GettingReadyCallbackCollector} gettingReadyCallbackCollector
     */
    startVisualContentGetReady(gettingReadyCallbackCollector) {
        for (let index = 0; index < this.resource_canvas_elements.length; index++) {
            const each_element = this.resource_canvas_elements[index];

            each_element.start_loading(gettingReadyCallbackCollector);
            break;
        }
    }
    /**
     *
     */
    start_active_element() {
        if (this.active_element) {
            this.active_element.onDrawing = this.active_element_on_drawing.bind(this);
            this.active_element.setHidden?.(this.isHidden);
            if (this.drawOrder != undefined) {
                this.active_element.setDrawOrder(this.drawOrder);
            }
            this.active_element.start();
        }
    }
    start() {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }

        this.visualElements.add(this);
        this.isStopped = false;

        if (this.active_element) {
            this.start_active_element();
            return;
        }

        var first_ready = this.getFirstReadyResourceCanvasElement();
        if (first_ready) {
            this.active_element = first_ready;
            this.start_active_element();

            return;
        }

        if (!this.callback_collector) {
            this.callback_collector = new GettingReadyCallbackCollector();

            this.startVisualContentGetReady(this.callback_collector);
            this.callback_collector_promise = this.callback_collector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promise = undefined;
                    this.callback_collector = undefined;
                    if (this.visualElements?.isAdded(this) == true) {
                        var first_ready = this.getFirstReadyResourceCanvasElement();
                        if (first_ready) {
                            this.active_element = first_ready;
                            this.start_active_element();
                            this.icanvas.try_invalidated_draw();
                        }
                    }
                });
        }
    }
    /**
     *
     * @param {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface | undefined} next_resource_canvas_element
     */
    stop(next_resource_canvas_element) {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }

        this.visualElements.remove(this);

        this.isStopped = true;
        this.active_element?.stop(next_resource_canvas_element);
    }
    /**
     *
     * @returns {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface}
     */
    firstResourceCanvasElement() {
        return this.resource_canvas_elements[0];
    }
    /**
     *
     * @param {OnDrawingParams} params
     */
    active_element_on_drawing(params) {
        this.onDrawing?.(params);
    }

    // collectCanvasElements(result) {
    //     this.active_element?.collectCanvasElements(result);
    // }
    /**
     *
     * @returns {boolean|undefined}
     */
    isLoading() {
        if (this.active_element) {
            return this.active_element.isLoading();
        }

        return false;
    }
    /**
     *
     * @returns {boolean}
     */
    isReady() {
        if (this.icanvas.application.getSetting(WebApplication.IsMSVideoEnabledSettingName)) {
            return false;
        }
        return this.active_element != undefined;
    }
    /**
     *
     * @returns {boolean}
     */
    isReadyOrEmpty() {
        return this.isReady() || this.isEmpty();
    }
    /**
     *
     * @returns {boolean}
     */
    isEmpty() {
        return this.resource_canvas_elements.length == 0;
    }
    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context) {
        this.active_element?.update?.(update_context);
    }
}
