// @ts-check

import { ConnectedStem } from "./ConnectedStem";
import IAudioPlayer from "./interfaces/IAudioPlayer";

/**
 *
 */
export class ConnectedStems {
    /**
     * @type {Array.<ConnectedStem>}
     */
    collection = [];
    /**
     * @type {Array.<ConnectedStem>}
     */
    effects = [];
    //collection_unreferenced = [];
    /**
     * @type {IAudioPlayer}
     */
    audio;
    /**
     *
     * @param {IAudioPlayer} audio
     */
    constructor(audio) {
        this.audio = audio;
    }
    /**
     *
     */
    startAudioOnGesture() {
        var now = Math.floor(Date.now() / 1000);
        this.collection.forEach((element) => {
            element.startAudioOnGesture(now);
        });
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    addReference(stem) {
        var found = this.findOrAddConnectionForStem(stem);
        found?.addReference(stem);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    removeReference(stem) {
        var found = this.findConnectionForStem(stem);
        found?.removeReference(stem);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     * @returns {ConnectedStem|undefined}
     */
    findConnectionForStem(stem) {
        for (let index = 0; index < this.collection.length; index++) {
            const connectedStem = this.collection[index];
            if (connectedStem.audioSrc === stem.audioSrc) {
                return connectedStem;
            }
        }

        return undefined;
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     * @returns {ConnectedStem|undefined}
     */
    findConnectionForEffectStem(stem) {
        for (let index = 0; index < this.effects.length; index++) {
            const connectedStem = this.effects[index];
            if (connectedStem.audioSrc === stem.audioSrc) {
                return connectedStem;
            }
        }

        return undefined;
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     * @returns {ConnectedStem|undefined}
     */
    findConnectionForAnyStem(stem) {
        var found = this.findConnectionForStem(stem);
        if (found) {
            return found;
        }
        return this.findConnectionForEffectStem(stem);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     * @returns {ConnectedStem|undefined}
     */
    findOrAddConnectionForStem(stem) {
        var found = this.findConnectionForStem(stem);
        if (found) {
            return found;
        }

        var toAdd = new ConnectedStem(this.audio, stem.audioSrc);
        this.collection.push(toAdd);
        return toAdd;
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    loadStem(stem) {
        var found = this.findOrAddConnectionForStem(stem);
        stem.load(found);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    unloadStem(stem) {
        var found = this.findOrAddConnectionForStem(stem);
        stem.unload(found);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    playStem(stem) {
        var found = this.findOrAddConnectionForStem(stem);
        stem.play(found);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    updateStem(stem) {
        var found = this.findConnectionForStem(stem);
        if (found) {
            if (found.gain != stem.json.gain) {
                found.setGain(stem.json.gain, true);
            }
        }
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     * @param {number} amount
     */
    setGainStem(stem, amount) {
        var found = this.findOrAddConnectionForStem(stem);

        stem.setGain(found, amount);
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    stopStem(stem) {
        var found = this.findConnectionForStem(stem);
        if (found) {
            stem.stop(found);
            return;
        }

        var found = this.findConnectionForEffectStem(stem);
        if (found) {
            stem.stop(found);
            found.onDone();
        }
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    addConnectionForEffectStem(stem) {
        if (!stem.isEnabled) {
            return;
        }

        var toAdd = new ConnectedStem(this.audio, stem.audioSrc);
        this.effects.push(toAdd);
        return toAdd;
    }
    /**
     *
     * @param {import('./stem').Stem} stem
     */
    playSoundEffect(stem) {
        var found = this.addConnectionForEffectStem(stem);
        if (!found) {
            return;
        }

        var effect = /** @type {ConnectedStem} */ (found);
        effect.addReference(stem);
        effect.playFrom(stem);

        var self = this;
        effect.onDone = () => {
            const index = self.effects.indexOf(effect);
            if (index > -1) {
                self.effects.splice(index, 1);
            }
        };
    }
}
