//@ts-check
import { CustomerAccount } from '../customerAccount';
import { ModuleActivationRewards } from "../externalModules/ModuleActivationRewards";
/**
 * 
 */
export class CustomerAccountTokenRewards extends ModuleActivationRewards {
  /**
   * @type {string}
   */
  source_name;
  /**
   * @type {CustomerAccount}
   */
  account;
  /**
   * 
   * @param {CustomerAccount} account 
   * @param {string} source_name 
   */
  constructor(account, source_name) {
    super();
    this.account = account;
    this.source_name = source_name;
  }
  /**
   * 
   * @param {number} tokens_per_second 
   */
  onStart(tokens_per_second) { }
  /**
   * 
   * @param {number} tokens_amount 
   */
  onDepositTokens(tokens_amount) { }
  /**
   * 
   */
  onEnd() { }
}
