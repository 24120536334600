import { ActionWithValue } from "../actions/action";
import { TextTemplateReplace } from "../../textTemplateReplacement";
export class CallWebFunction extends ActionWithValue {
    static verb_names = ["call_post_function", "call_get_function"];

    constructor(verb, name, json) {
        super(verb, name, json);
    }

    run(param) {
        var args = TextTemplateReplace.withReplacedPropertyValues(this.value_context, param);
        var args_string = JSON.stringify(args);

        var log = `action:${this.verb} ${this.value} ${args_string}`;
        console.log(log);

        if (this.verb == CallWebFunction.verb_names[0]) {
            // $.post(this.value, args_string, function (data, status) {
            //   console.log(`success:${status}, ${log}`);

            // });
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4) {
                    console.log(`${this.status}:${log}: ${this.responseText}`);
                }
            };
            xhttp.open("POST", this.value, true);
            xhttp.send(args_string);
        }
    }
}
