// @ts-check
import { startClient } from "./library_main.js";
import { WebApplication } from "./webApplication.js";
import { LakesideCabinServer } from "./LakesideCabinServer.js";
import { WebApplicationState } from "./WebApplicationState.js";
import { ExternalModules } from './modules/ExternalModules.js';

import { ChessExternalModule } from './client_modules/chess.js';
import { SolitaireExternalModule } from './client_modules/solitaire.js';
import { CardCraftExternalModule } from './client_modules/card_craft.js';

/**
 * @type {string}
 */
let versionNumber = "0.7.7";

/**
 * 
 */
class LakesideCabinWebApplication extends WebApplication {
  /**
   * 
   * @param {WebApplicationState} state 
   */
  constructor(state) {
    super(state.resources, "Lakeside Cabin", state.clientVersionNumber);
  }
/**
 * 
 * @param {WebApplicationState} state 
 */
  initializeExternalModules(state) {
    ExternalModules.modules = [
      new ChessExternalModule(),
      new SolitaireExternalModule(), 
      new CardCraftExternalModule()
	]
  }
}
/**
 * 
 */
class LakesideCabinWebApplicationServer extends LakesideCabinServer {
  /**
   * 
   * @param {WebApplicationState} state 
   */
  constructor(state) {
    super(state.resources, state.application);
  }
}

(async () => {
  let state = new WebApplicationState();
  state.clientVersionNumber = versionNumber;

  let newWebApplication = (withState) => {
    return new LakesideCabinWebApplication(withState);
  };

  let newServer = (withState) => {
    return new LakesideCabinWebApplicationServer(withState);
  };

  await startClient(state, newWebApplication, newServer);
})();