import { Stem } from "../../audio_visual/audio/stem";
import { WebAudio } from "../../audio_visual/audio/webAudio";
import { Action } from "../actions/action";

export class PlayAudioAction extends Action {
    value;
    value_context;

    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    newSoundEffectStem(obj, json) {
        if (json.isLoop === undefined) {
            json.isLoop = false;
        }

        if (json.layer === undefined) {
            json.layer = WebAudio.effectslayerName;
        }

        if (json.isListenerAreaLocal === undefined) {
            json.isListenerAreaLocal = false;
        }

        return new Stem(json, obj.getResourcePathFunction());
    }

    run(interactive) {
        var stems = [];
        var obj = interactive.scene;

        if (Array.isArray(this.value)) {
            this.value.forEach((element) => {
                stems.push(this.newSoundEffectStem(obj, element));
            });
        } else {
            stems.push(this.newSoundEffectStem(obj, this.value));
        }

        obj.simulation.audio.player.playSoundEffects(stems);
        obj.onPlaySoundEffects(stems);
    }
}
