//@ts-check
import { AuthorInterface } from '../sceneAuthorInterface/authorInterface';
import { ExternalModules } from './ExternalModules';
import { BaseModules, ModulesCommon } from "./ModulesCommon";
/**
 * 
 */
export class InternalModules extends BaseModules {
  /**
  * @type {ExternalModules|undefined}
  */
  external_modules;
  /**
  * @type {AuthorInterface|undefined}
  */
  authoringInterface;
  /**
  * @type {object|undefined}
  */
  json;
  /**
   * 
   * @param {ExternalModules} external_modules 
   * @param {object} json 
   */
  constructor(external_modules, json = {}) {
    super();
    this.external_modules = external_modules;
    this.json = json;
  }
  /**
   * 
   */
  initialize() { }
  /**
   * 
   */
  saveState() {
    // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  /**
   * 
   */
  shutdown() {
    this.saveState();
  }
}
