//@ts-check

/**
 *
 */
export class InteractiveMouseEvent {
    /**
     *
     * @type {MouseEvent}
     */
    e;
    /**
     *
     * @type {number}
     */
    x;
    /**
     *
     * @type {number}
     */
    y;
    /**
     *
     * @param {MouseEvent} e
     */
    constructor(e) {
        this.e = e;
        this.x = e.offsetX;
        this.y = e.offsetY;
    }
}
