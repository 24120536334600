import { WebApplication } from "../../webApplication";
import { ActionWithValue } from "../actions/action";

/**
 *
 */
export class SetApplicationSettingAction extends ActionWithValue {
    /**
     * @type {WebApplication}
     */
    application;

    /**
     *
     * @param {string} verb
     * @param {string} name
     * @param {object} json
     * @param {WebApplication} application
     */
    constructor(verb, name, json, application) {
        super(verb, name, json);
        this.application = application;
    }
    /**
     *
     */
    run() {
        console.log(`action: SetApplicationSettingAction ${this.value} ${this.value_context}`);
        this.application.setSetting(this.value, this.value_context);
    }
}
