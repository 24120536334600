//@ts-check

/**
 * @callback onValueChanged
 * @param {*} value
 */

/**
 *
 */
export class ValueChangedSubscriptions {
    /**
     *
     * @static
     * @param {object} obj
     * @param {string} name
     * @param {onValueChanged} item
     */
    static addToObjectArrayPropertyByName(obj, name, item) {
        var value = obj[name];

        if (value == undefined) {
            value = obj[name] = new ValueChangedSubscriptions(name);
        } else {
        }

        value.elements.push(item);
    }
    /**
     *
     * @static
     * @param {object} obj
     * @param {string} name
     * @param {onValueChanged} item
     */
    static removeFromObjectArrayPropertyByName(obj, name, item) {
        var value = obj[name];
        value?.removeElement(item);
    }
    /**
     *
     * @static
     * @param {object} obj
     * @param {string} name
     * @param {*} value
     */
    static updateValueByName(obj, name, value) {
        var subscription = obj[name];
        subscription?.updateValue(value);
    }
    /**
     * @type {string}
     */
    name;
    /**
     * @type {Array.<onValueChanged>}
     */
    elements;

    /**
     *
     * @param {string} name
     */
    constructor(name) {
        this.name = name;
        this.elements = [];
    }

    /**
     *
     * @param {onValueChanged} element
     */
    removeElement(element) {
        let index = this.elements.indexOf(element);
        if (index >= 0) {
            this.elements.splice(index, 1);
        }
    }
    /**
     *
     * @returns {boolean}
     */
    get isEmpty() {
        return this.elements.length == 0;
    }

    /**
     *
     * @param {*} value
     */
    updateValue(value) {
        for (const each of this.elements) {
            each(value);
        }
    }
}
