// @ts-check

import { ResourceCanvasElement } from "../audio_visual/visual/resourceCanvasElements/ResourceCanvasElement";

/**
 *
 */
export class FileResource {
    /**
     *
     * @type {string}
     */
    url;
    /**
     *
     * @type {import('../audio_visual/visual/resources/resourceInterface').onRunAction_ResourceInterfaceFunction|undefined}
     */
    onRunAction;
    /**
     * @type {import('../LocalServerFileCache').FileInfoType|undefined}
     */
    url_file_info;
    /**
     * @type {import('../LocalServerFileCache').LocalServerFileCache}
     */
    server_file_cache;

    /**
     *
     * @type {string}
     */
    type;

    /**
     *
     */
    constructor() {}
    /**
     * @returns {boolean}
     */
    checkForErrors() {
        return false;
    }
    /**
     * @returns {import('../audio_visual/visual/resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface}
     */
    // createResourceCanvasElement(vis) {
    //     throw "invalid operation";
    //     //return new ResourceCanvasElement(vis, this);
    // }
    /**
     *
     * @returns {Array.<number>}
     */
    pixel_size() {
        return [0, 0];
    }
    /**
     *
     * @param {string} path
     * @returns {string}
     */
    static getPathWithoutExtension(path) {
        const lastDotIndex = path.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return path; // No extension found
        }
        return path.substring(0, lastDotIndex);
    }
    /**
     *
     * @param {string} filePath
     * @returns {string}
     */
    static getFullpathFilenameWithoutExtension(filePath) {
        const filename = filePath.split(/[\/\\]/).pop(); // Get filename (with extension)
        if (!filename) {
            return filePath;
        }
        const lastDotIndex = filename.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return filename; // No extension found
        }
        return filename.substring(0, lastDotIndex);
    }
}
