//@ts-check
/**
 * 
 */
export class UpdateContext {
   /**
   * 
   * @type {number}
   */
  start_time;
   /**
   * 
   * @type {number}
   */
  time;
   /**
   * 
   * @type {number}
   */
  deltaTime;
   /**
   * 
   * @type {boolean}
   */
  isDrawFrame;
}
