//@ts-check
import { ExternalModule } from "./../externalModules/ExternalModule";
import { GameCenterModuleIntegration } from "./../externalModules/GameCenterModuleIntegration";

/**
 * This module is responsible for handling the login page.
 */
export class LoadingExternalModule extends ExternalModule {
    constructor() {
        super();
        this.name = "loading";
        this.html = window.location.origin + "/loading";
        this.addIntegrations = (openModule) =>
            (openModule.integrations[0] = new GameCenterModuleIntegration(openModule));
    }
}
