//@ts-check

import { ModuleIntegration } from "./ModuleIntegration";
import { OpenModule } from "./OpenModule";

/**
 * @callback addIntegrationsFunction
 * @param {OpenModule} openModule
 */

/**
 *
 */
export class ExternalModule {
    /**
     * @type {string}
     */
    name;
    /**
     * @type {string}
     */
    html;
    /**
     * @type {addIntegrationsFunction}
     */
    addIntegrations;
}
