// @ts-check

import { FileResourceRequest } from "../../../resources/FileResourceRequest";
import { AudioPlaylist } from "./AudioPlaylist";

const TAG = "PlaylistStem:";

/**
 *
 */
export class PlaylistStem {
    /** @type {boolean} */
    isActive = false;
    /** @type {HTMLAudioElement} */
    element;
    /** @type {FileResourceRequest} */
    resourceRequest;
    /** @type {number} */
    stemGain = 1.0;
    /** @type {AudioPlaylist} */
    playlist;
    /** @type {Function} */
    onDone;
    /**
     *
     * @param {Function} parentGetResourcePath
     * @param {AudioPlaylist} playlist
     * @param {Function} playNext
     */
    constructor(audioSrc, gain, parentGetResourcePath, playlist, playNext) {
        this.audioSrc = audioSrc;
        this.playlist = playlist;
        this.stemGain = gain;
        this.resourceRequest = new FileResourceRequest(parentGetResourcePath(), audioSrc, ".mp3");
        this.element = document.createElement("audio");
        this.element.src = this.resourceRequest.toUrlPath();
        this.element.id = this.audioSrc;
        this.playlistContainer = this.playlist.container;
        this.onDone = playNext;
    }

    /**
     *
     */
    syncStemWithPlaylistSettings() {
        this.#applyPlaylistGainToStem();
        this.#applyPlaylistMuteToStem();
    }

    /**
     *
     */
    #applyPlaylistGainToStem() {
        this.element.volume = this.stemGain * this.playlist.gain;
        console.log(
            `CHANGE GAIN: ${this.element.volume} = stemGain {${this.stemGain}} * playlistGain {${this.playlist.gain}}`,
        );
    }

    /**
     *
     */
    #applyPlaylistMuteToStem() {
        this.element.muted = this.playlist.muted;
    }

    /**
     * @description loads audio element into the playlist container
     */
    load() {
        this.syncStemWithPlaylistSettings();
        if (document.getElementById(this.audioSrc)) {
            return;
        }
        this.element.addEventListener("ended", () => {
            console.log;
            this.remove();
            this.onDone();
        });
        this.playlist.container?.appendChild(this.element);
    }
    /**
     *
     */
    play() {
        this.syncStemWithPlaylistSettings();
        if (document.contains(this.element)) {
            this.element.play().catch((err) => {
                console.warn(TAG + "Play call error", err);
            });
        }
    }

    /**
     *
     * @returns {boolean} true if
     */
    remove() {
        try {
            this.playlist.container?.removeChild(this.element);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    /**
     *
     */
    pause() {
        this.element.pause();
    }
}
