export class AssetInfo {
    numberVersion;
    semanticVersion;
    size;
    modified;
    hash;
    /*json;
  constructor(json){
    this.json=json;
  }

  get numberVersion(){return this.json.numberVersion;}
  get semanticVersion(){return this.json.semanticVersion;}
  get size(){return this.json.size;}
  get modified(){return this.json.modified;}
  get hash(){return this.json.hash;}*/
}
