import { SceneGraphAudioVisualScriptItem } from './SceneGraphAudioVisualScriptItem';

export class SceneGraphAudioVisualScript {
    json;
    items;
    av_scripts;
    item_index;
    item_index_start_time;

    constructor(json, av_scripts) {
        this.json = json;
        this.av_scripts=av_scripts;
    }

    get scenes() {
        return this.json.scenes;
    }
    get isDefault() {
        return this.json["isDefault"];
    }
    get script() {
        return this.json["script"];
    }
    getActiveItem(){
        return this.items[this.item_index];
    }
    includesScene(name){
        if(this.scenes==undefined){
            return true;
        }

        return this.scenes.indexOf(name) !== -1;
    }
    initializeFromJson() {

        if (this.script) {
            this.items = [];
            for (let each in this.script) {
                var new_item = new SceneGraphAudioVisualScriptItem(this.script[each]);
                new_item.initializeFromJson();
                this.items.push(new_item);
            }
        }
    }

    startFromBeginning(now) {
        this.item_index = 0;
        this.item_index_start_time = now;
    }
}
