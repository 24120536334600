import { ActionWithValue } from "../actions/action";

export class ToggleApplicationSettingAction extends ActionWithValue {
    constructor(verb, name, json, application) {
        super(verb, name, json);
        this.application = application;
    }

    run() {
        console.log(`action: ${"ToggleApplicationSettingAction"} ${this.value}`);
        this.application.toggleBooleanSetting(this.value);
    }
}
