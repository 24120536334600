//@ts-check
/**
 *
 */
export class Canvas {
    /**
     *
     * @type {HTMLCanvasElement}
     */
    element;
}
/**
 *
 */
export class CanvasStack {
    /**
     *
     * @type {Array.<Canvas>}
     */
    canvas_stack = [];
    /**
     *
     * @type {import('../interactive_canvas').InteractiveCanvas}
     */
    icanvas;
    /**
     *
     * @param {import('../interactive_canvas').InteractiveCanvas} icanvas
     */
    constructor(icanvas) {
        this.icanvas = icanvas;
    }
    /**
     *
     * @param {Canvas} canvas
     */
    push(canvas) {
        this.canvas_stack.push(canvas);
        this.icanvas.viewport.appendChild(canvas.element);
    }
    /**
     *
     * @param {Canvas|undefined} canvas
     */
    pop(canvas = undefined) {
        var popped;

        if (canvas != undefined) {
            let index = this.canvas_stack.indexOf(canvas);

            if (index < 0) {
                console.warn(`canvas pop not found`);
                return;
            }
            popped = this.canvas_stack[index];
            this.canvas_stack.splice(index, 1);
        } else {
            popped = this.canvas_stack.pop();
        }
        if (popped) {
            this.icanvas.viewport.removeChild(popped.element);
        }
    }
}
