//@ts-check

import { Resources } from '../../../resources';
import { SceneGraphAudioVisualScripts } from '../../../sceneGraph/SceneGraphAudioVisualScripts';
import { SceneGraphAudioVisualStateSet } from '../../../sceneGraph/SceneGraphAudioVisualStateSet';
import { AudioVisualScriptResourceCanvasElement } from '../resourceCanvasElements/AudioVisualScriptResourceCanvasElement';
/**
 * 
 */
export class AudioVisualScriptResource {
    /**
     * 
     * @type {string}
     */
    json_name;
    /**
     * 
     * @type {object}
     */
    json;
    /**
     * 
     * @type {string|undefined}
     */
    video_base_name;
    /**
     * 
     * @type {string|undefined}
     */
    audio_base_name;
    /**
     * 
     * @type {string|undefined}
     */
    default_state;
    /**
    * 
    * @type {SceneGraphAudioVisualStateSet}
    */
    state_set;
    /**
    * 
    * @type {SceneGraphAudioVisualScripts}
    */
    scripts;

    /**
     * 
     * @type {boolean}
     */
    isLoaded;
    /**
     * 
     * @type {boolean}
     */
    isError;

    /**
    * @type {import('../../../LocalServerFileCache').FileInfoType|undefined}
    */
    url_file_info;

    /**
     * 
     * @param {object} json 
     * @param {string} property 
     * @returns {boolean}
     */
    static canCreateResourceFromJson(json, property) {
        return property == "audio_visual_script" && json[property] !== undefined;
    }
    /**
     * 
     * @param {object} json 
     * @returns {boolean}
     */
    static canCreateResourceFromJsonObject(json) {
        return json["audio_visual_script"] !== undefined;
    }

    /**
     *
     * @param {object} json
     * @param {string} property
     * @param {string} path
     * @param {string} path2
     * @param {import('../../../webApplication').WebApplication} webapp
     * @returns {import('./resourceInterface').ResourceInterface} 
     */
    static createResourceFromJson(json, property, path, path2, webapp) {

        var result = new AudioVisualScriptResource();

        result.json_name = json[property].name + "-audio_visual_script";
        var file_path = webapp.resources.getDataFilePathByNameAndExtension(result.json_name, Resources.JsonExtension)

        result.video_base_name = json[property].video_base_name;
        result.audio_base_name = json[property].audio_base_name;

        result.json = webapp.resources.getFetchedJson(file_path);

        result.default_state = json[property].default_state;

        result.state_set = new SceneGraphAudioVisualStateSet(result.json["states"], result);
        result.state_set.initializeFromJson();

        result.scripts = new SceneGraphAudioVisualScripts(result.json["scripts"], result);
        result.scripts.initializeFromJson();

        var r = /** @type{import('../resources/AudioVisualScriptResource').AudioVisualScriptResource} */ (result);

        return r;
    }
    /**
     * 
     * @param {import('../visualElement').VisualElement} vis 
     * @returns {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface}
     */
    createResourceCanvasElement(vis) {
        return new AudioVisualScriptResourceCanvasElement(vis, this);
    }

    /**
 * 
 * @returns {Array.<number>}
 */
    pixel_size() {
        return [0, 0];
    }
    /**
     * 
     * @returns {false}
     */
    isLoading() {
        return false;
    }
    /**
     * 
     */
    cancelLoading() {

    }
    /**
     * 
     * @returns {boolean}
     */
    checkForErrors() {
        return false;
    }
    /**
     * 
      * @param {import('../../../LocalServerFileCache').LocalServerFileCache} server_file_cache
      * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
     */
    start_loading(server_file_cache, resource_canvas_element) {
    }
    /**
     * 
      * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
    */
    start(resource_canvas_element) {

    }
    /**
     * 
     */
    stop() {

    }
    /**
     * 
      * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
      * @returns {import('../canvasElements/CanvasElement').CanvasElement}
      */
    createCanvasElement(resource_canvas_element) {

        throw "invalid operation";
    }
    /**
     * 
     */
    notifyError() {
        if (!this.isError) {
            this.isError = true;
        }
    }
    /**
     * 
     * @returns {Promise|undefined}
     */
    getLoadingPromise(){
        return undefined
    }
}
