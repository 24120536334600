import { Action } from "../actions/action";

export class RefreshPageAction extends Action {
    constructor(verb, name, json) {
        super(verb, name);
    }

    run(interactive) {
        console.log("action:RefreshPageAction");
        location.reload();
    }
}
