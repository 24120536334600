import { toggleRadio } from "../../audio_visual/audio/playlistAPI/Radio";
import { Action } from "../actions/action";

export class ToggleRadio extends Action {
  value;
  value_context;

  constructor(verb, name, json) {
    super(verb, name);
    this.value = json["value"];
    this.value_context = json["value_context"];
  }

  run(interactive) {
    var obj = interactive.scene
    toggleRadio(this.value)
    // TODO: for review, correct access pattern for audio
    const audio = interactive.scene.scene_graph.simulation.audio 
    audio.playlistManager?.applyRadio(this.value)
  }
}
