//@ts-check

/**
 *
 */
export class WebApplicationState {
    /**
     * @type {import('./auth/InternalAuth').AuthInterface}
     */
    auth;
    /**
     * @type {import('./routing/StaticRouter').StaticRouter}
     */
    router;
    /**
     * @type {string}
     */
    clientVersionNumber;
    /**
     * @type {string}
     */
    libraryVersionNumber;
    /**
     * @type {import('./resources').Resources}
     */
    resources;
    /**
     * @type {import('./webApplication').WebApplication}
     */
    application;
    /**
     * @type {import('./webBrowserClient').WebBrowserClient}
     */
    browser;
    /**
     * @type {import('./audio_visual/interactive_canvas').InteractiveCanvas}
     */
    canvas;
    /**
     * @type {import('./customerAccount').CustomerAccount}
     */
    account;
    /**
     * @type {import('./simulation').Simulation}
     */
    simulation;
    /**
     * @type {import('./webApplicationServer').WebApplicationServer}
     */
    server;
    /**
     * @type {import('./sceneAuthorInterface/authorInterface').AuthorInterface}
     */
    author;
    /**
     * @type {import('./audio_visual/audio/webAudio').WebAudioInterface}
     */
    audio;
    /**
     * @type {import('./modules/ExternalModules').ExternalModules}
     */
    externalModules;
    /**
     * @type {import('./modules/internalModules').InternalModules}
     */
    internalModules;
    /**
     * @type {import('./modules/sceneModules').SceneModules}
     */
    sceneModules;

    /**
     * @type {import('./audio_visual/visual/MediaSourcePlayerComponent').MeidaSourcePlayerComponent}
     */
    msePlayer;
    /**
     *
     */
    saveState() {
        this.auth?.saveState();
        this.simulation?.saveState();
        this.server?.saveState();
        this.browser?.saveState();
        this.account?.saveState();
        this.application?.saveState();
        this.author?.saveState();
        this.audio?.saveState();
        this.externalModules?.saveState();
        this.internalModules?.saveState();
        this.sceneModules?.saveState();
        this.msePlayer?.saveState();
    }

    // shutdownState() {
    //   this.auth?.shutdown()
    //   this.simulation?.shutdown();
    //   this.simulation = undefined;
    //   this.server?.shutdown();
    //   this.server = undefined;
    //   this.browser?.shutdown();
    //   this.browser = undefined;
    //   this.account?.shutdown();
    //   this.account = undefined;
    //   this.application?.shutdown();
    //   this.application = undefined;
    //   this.author?.shutdown();
    //   this.author = undefined;
    //   this.audio?.shutdown();
    //   this.audio = undefined;
    //   this.externalModules?.shutdown();
    //   this.externalModules = undefined;
    //   this.internalModules?.shutdown();
    //   this.internalModules = undefined;
    //   this.sceneModules?.shutdown();
    //   this.sceneModules = undefined;
    // }
}
