import { SceneGraphAudioVisualState } from './SceneGraphAudioVisualState';

export class SceneGraphAudioVisualStateSet {
  json;
  av_script_resource;
  constructor(json, av_script_resource) {
    this.json = json;
    this.av_script_resource = av_script_resource;
  }

  initializeFromJson() {
  }

  getNextState(state) {
    var found = false;
    for (let each in this.json) {
      if (each == state.name) {
        found = true;
        continue;
      }
      if (found) {
        return this.getNewStateObjectByName(each);
      }
    }

    if (found) {
      for (let each in this.json) {
        return this.getNewStateObjectByName(each);
      }
    }
    return undefined;
  }

  getDefaultState() {
    for (let each in this.json) {
      var item = this.json[each];
      if (item.isDefault) {
        var obj = new SceneGraphAudioVisualState(item, this, each);
        obj.initializeFromJson();
        return obj;
      }
    }
  }

  getNewStateObjectByName(propertyName) {
    if (this.json[propertyName]) {
      var data = this.json[propertyName];
      var obj = new SceneGraphAudioVisualState(data, this, propertyName);
      obj.initializeFromJson();
      return obj;
    } else {
      // Handle cases where the property might not exist
      console.warn(`Property '${propertyName}' not found.`);
      return undefined; // Or throw an error if appropriate
    }
  }
}

