//@ts-check
import { ExternalModule } from "./../externalModules/ExternalModule";
import { GameCenterModuleIntegration } from "./../externalModules/GameCenterModuleIntegration";
/**
 *
 */
export class TriviaExternalModule extends ExternalModule {
    /**
     *
     */
    constructor() {
        super();
        this.name = "trivia";
        this.html = "https://nonprod-trivia.nmp.nonprod-sinclairstoryline.com/";
        this.addIntegrations = (openModule) =>
            (openModule.integrations[0] = new GameCenterModuleIntegration(openModule)); //.push(new GameCenterModuleIntegration(openModule));
    }
}
