// @ts-check

import { FileResourceRequest } from '../../../resources/FileResourceRequest';

/**
 * 
 */
export class AudioResource {
  /**
* 
* @type {HTMLAudioElement}
*/
  resource_element;
  /**
* 
* @type {FileResourceRequest}
*/
  resource_request;
  /**
* 
* @type {Promise|undefined}
*/
  loading_promise;
  /**
* 
* @type {Array.<Promise|undefined>}
*/
  loading_promises = [];
  /**
   * 
   * @returns {boolean}
   */
  checkForErrors() {
    return false;
  }
  /**
   * 
   * @returns {string}
   */
  toSourceURLName() {
    return this.resource_request.name;
  }
  /**
   * 
   * @param {FileResourceRequest} resource_request 
   */
  constructor(resource_request) {
    this.resource_request = resource_request;


    this.resource_element = new Audio();
    this.resource_request.application?.memory_diagnostics.start_download("audio", this.resource_request, this.resource_element);
    this.resource_element.autoplay = false;
    this.resource_element.loop = false;
    //this.resource_element.name = this.resource_request.name;
    let self = this;

    this.loading_promise = new Promise((resolve, reject) => {
      self.resource_element.addEventListener("canplay", function () {
        self.loading_promise = undefined;
        self.loading_promises[0] = undefined;
        resolve(self);
      });

      self.resource_element.addEventListener("error", function () {
        self.loading_promise = undefined;
        self.loading_promises[0] = undefined;
        reject(self);
      });

    });

    this.loading_promise = this.loading_promise.catch((e) => {
      console.log("audio blocked");
    });

    this.loading_promises.push(this.loading_promise);

    resource_request.server_file_cache.loadFromCacheOrUrl(this.resource_element, this.resource_request.toUrlPath());
    this.resource_element.load();
    console.log(`audio:resource:open AudioResource ${this.resource_request.toUrlPath()}`);

  }
  /**
   * 
   * @param {import('../../../sceneGraph/scene').SceneObjectInterface} [scene] 
   */
  initialize(scene) {

  }
  /**
   * 
   */
  dispose() {
    var isLoading = this.isLoading();
    if (isLoading) {
      var self = this;
      var and_then = (res) => {
        self.dispose();
      };
      this.addLoadingThen(and_then);
    } else {

      var url = new URL(this.resource_element.src);
      this.resource_element.src = "";

      console.log(`audio:resource:close AudioResource ${url.pathname}`);
    }
  }
  /**
   * 
   * @param {*} and_then 
   */
  addLoadingThen(and_then) {
    var latest = this.getLoadingPromise();

    if (!latest) {
      latest = new Promise((resolve, reject) => {
        resolve(true);
      });
    }

    var index;
    index = this.loading_promises.length;
    var next = latest.then(and_then);
    next = next.catch((e) => {
      console.error(e);
    });
    next = next.finally(() => {
      this.loading_promises[index] = undefined;
    });

    this.loading_promises.push(next);
  }
  /**
   * 
   * @returns {boolean}
   */
  isLoading() {
    return this.getLoadingPromise() !== undefined;
  }
  /**
   * 
   * @returns {Promise|undefined}
   */
  getLoadingPromise() {
    return this.loading_promises.length == 0 ? undefined : this.loading_promises[this.loading_promises.length - 1];
  }
}
