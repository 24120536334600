//@ts-check
import { TokenRewardsFromDataContext } from "../tokenEconomy/mod";
import { ModuleActivationRewards } from "./ModuleActivationRewards";
import { ModuleIntegration } from "./ModuleIntegration";
import { v4 as uuidv4 } from "uuid";
import { OpenModule } from "./OpenModule";
/**
 *
 */
export class GameCenterModuleIntegration extends ModuleIntegration {
    /**
     * @type {boolean}
     */
    receivedExit;

    /**
     *
     * @param {OpenModule} openModule
     */
    constructor(openModule) {
        super(openModule);
    }
    /**
     *
     * @param {MessageEvent<any>} event
     */
    async windowMessage(event) {
        let body = event.data;
        if (body) {
            const { action, receiptToken } = body; // Return message handler for the current event.
            if (action) {
                console.log(
                    `[CABIN][EXTERNAL MODULE] Message received from ${event.origin}:  [action: ${event.data?.action || false}]`,
                );
            }
            if (body.action == "exit") {
                this.sendMessageToModule({
                    action: "return",
                    receiptToken: `${action}-${receiptToken}`,
                });
                if (this.openModule) {
                    this.openModule.state.canvas.reactivate();
                    this.openModule.exit(body);
                    this.rewards?.onEnd();
                    this.openModule.state.canvas.drawFrame();
                    this.openModule = undefined;
                }
                this.rewards = undefined;
            } else if (body.action == "results") {
                var t = new TokenRewardsFromDataContext(this.data_context).tokens_per_win;
                this.rewards?.onDepositTokens(t);

                this.openModule?.exit(body);
            } else if (body.action == "ready") {
                this.sendMessageToModule({
                    action: "return",
                    receiptToken: `${action}-${receiptToken}`,
                });

                var tps = new TokenRewardsFromDataContext(this.data_context).tokens_per_second;
                this.rewards?.onStart(tps);

                var iframeDiv = document.getElementById("fullscreen_iframe");
                var self = this;

                setTimeout(function () {
                    document.querySelectorAll(".ui-widget").forEach((each) => {
                        var eachAsHtmlElement = /** @type {HTMLElement} */ (each);
                        eachAsHtmlElement.style.border = "none";
                        eachAsHtmlElement.style.zIndex = "1";
                    });
                    document.querySelectorAll(".ui-widget-content").forEach((each) => {
                        var eachAsHtmlElement = /** @type {HTMLElement} */ (each);
                        eachAsHtmlElement.style.border = "none";
                        eachAsHtmlElement.style.zIndex = "1";
                    });
                    document.querySelectorAll(".ui-dialog").forEach((each) => {
                        var eachAsHtmlElement = /** @type {HTMLElement} */ (each);
                        eachAsHtmlElement.style.border = "none";
                        eachAsHtmlElement.style.zIndex = "1";
                        eachAsHtmlElement.style.padding = "0";
                        eachAsHtmlElement.style.overflow = "hidden";
                    });
                    document.querySelectorAll(".ui-dialog-content").forEach((each) => {
                        var eachAsHtmlElement = /** @type {HTMLElement} */ (each);
                        eachAsHtmlElement.style.border = "none";
                        eachAsHtmlElement.style.zIndex = "1";
                        eachAsHtmlElement.style.padding = "0";
                        eachAsHtmlElement.style.overflow = "hidden";
                    });
                }, 1000);

                this.openModule?.ready();
            } else if (body.action == "return") {
                this.openModule?.onStarted(body);
            }
        }
    }

    /**
     *
     * @returns {object}
     */
    static getStartMessage() {
        return { action: "start" };
    }
    /**
     *
     */
    async start() {
        let start = GameCenterModuleIntegration.getStartMessage();
        this.sendMessageToModule(start);
        return;
    }
}
