// @ts-check

import { AudioVisualPlaybackQueueItem } from '../audio_visual/visual/AudioVisualPlaybackQueue';
import { SceneGraphAudioVisualStateBaseComponent } from './SceneGraphAudioVisualStateBaseComponent';
import { SceneGraphAudioVisualStateEventComponent } from './SceneGraphAudioVisualStateEventComponent';
/**
 * 
 */
export class SceneGraphAudioVisualState {
    /**
     * 
     * @type {object}
     */
    json;
    /**
    * 
    * @type {SceneGraphAudioVisualStateBaseComponent}
    */
    base_component;
    /**
    * 
    * @type {object}
    */
    event_components;
    /**
    * 
    * @type {import('./SceneGraphAudioVisualStateSet').SceneGraphAudioVisualStateSet}
    */
    av_states;
    /**
    * 
    * @type {string}
    */
    name;
    /**
    * 
    * @type {Map.<string,SceneGraphAudioVisualState>}
    */
    transitionFromMap;
    /**
     * 
     * @param {object} json 
     * @param {import('./SceneGraphAudioVisualStateSet').SceneGraphAudioVisualStateSet} av_states 
     * @param {string} name 
     */
    constructor(json, av_states, name) {
        this.json = json;
        this.av_states = av_states;
        this.name = name;
    }
    /**
     * 
     */
    initializeFromJson() {
        if (this.json.components) {
            this.base_component = new SceneGraphAudioVisualStateBaseComponent(this.json.components.base);
            this.base_component.initializeFromJson();

            if (this.json.components.events) {
                this.event_components = {};
                for (let each in this.json.components.events) {
                    this.event_components[each] = new SceneGraphAudioVisualStateEventComponent(this.json.components.events[each]);
                    this.event_components[each].initializeFromJson();
                }
            }
        }
        if (this.transition_from) {
            this.transitionFromMap = new Map();
            for (const each in this.transition_from) {
                var each_json = this.transition_from[each];
                var state = new SceneGraphAudioVisualState(each_json, this.av_states, each);
                this.transitionFromMap.set(each,state);
            }
        }
    }
    /**
     * 
     * @param {import('../audio_visual/visual/AudioVisualPlaybackQueue').AudioVisualPlaybackQueue} playbackQueue 
     * @param {SceneGraphAudioVisualState|undefined} [previousState]
     */
    loadPlayback(playbackQueue, previousState) {
    
        if (previousState) {
            var transition = this.transitionFromMap?.get(previousState.name);
            if (transition) {
                var playbackItem = transition.createPlaybackItem();
                playbackItem.is_looping = false;
                playbackQueue.pushItem(playbackItem);
            }
        }

        playbackQueue.pushItem(this.createPlaybackItem());
        playbackQueue.beginPlayingNextInQueue();
    }
    /**
     * 
     * @returns {AudioVisualPlaybackQueueItem}
     */
    createPlaybackItem() {
        var item = new AudioVisualPlaybackQueueItem();

        var stems = [];

        if (this.json['video.suffix']) {
            var video_stem = {
                video: this.av_states.av_script_resource.video_base_name + this.json['video.suffix']
            };

            // -4k upgrade

            stems.push(video_stem);
        } else if (this.json['video']) {
            var video_stem = {
                video: this.json['video']
            };

            // -4k upgrade

            stems.push(video_stem);
        }

        if (this.json['ambiance.suffix']) {
            var audio_stem = {
                gain: this.json['ambiance.gain'],
                audioSrc: this.av_states.av_script_resource.audio_base_name + this.json['ambiance.suffix']
            };
            stems.push(audio_stem);
        }

        if (this.json['audioAmbiance']) {
            var audioAmbiance_stem = {
                audioAmbiance: this.json['audioAmbiance']
            };
            stems.push(audioAmbiance_stem);
        }
        item.stems = stems;
        return item;
    }

    /**
     * @returns {boolean|undefined}
     */
    get isDefault() {
        return this.json.isDefault;
    }
    /**
     * @returns {string|undefined}
     */
    get video() {
        return this.json["video"];
    }
    /**
    * @returns {string|undefined}
    */
    get video_suffix() {
        return this.json["video.suffix"];
    }
    /**
    * @returns {string|undefined}
    */
    get image_suffix() {
        return this.json["image.suffix"];
    }
    /**
    * @returns {string|undefined}
    */
    get ambiance_suffix() {
        return this.json["ambiance.suffix"];
    }
    /**
    * @returns {number|undefined}
    */
    get ambiance_gain() {
        return this.json["ambiance.gain"];
    }
    /**
    * @returns {object|undefined}
    */
    get audioAmbiance() {
        return this.json["audioAmbiance"];
    }
    /**
    * @returns {object|undefined}
    */
    get transition_from() {
        return this.json["transition.from"];
    }
}
