export class DownloadDiagnostics {
    constructor(type, resource_request, startTime, memoryDiagnostics) {
        this.type = type;
        this.name = resource_request.name;
        this.resource_request = resource_request;
        this.memoryDiagnostics = memoryDiagnostics; // Reference to MemoryDiagnostics
        this.bytes = 0;
        this.totalBytes = 0;
        this.startTime = startTime;
        this.finished = false;
        this.started = false;
        this.isError = false;
        this.handleLoadStart = this.handleLoadStart.bind(this);
        this.handleProgress = this.handleProgress.bind(this);
        this.handleLoadedData = this.handleLoadedData.bind(this);
        this.handleLoadedDataWithSize = this.handleLoadedDataWithSize.bind(this);
        this.item_set_count = 0;
        this.item_count = 0;
    }

    get kilobytes() {
        return this.bytes / 1024;
    }

    get megabytes() {
        return this.bytes / (1024 * 1024);
    }
    setupEventHandlers(mediaElement) {
        this.mediaElement = mediaElement; // Store a reference to the media element

        //this.totalBytes = parseInt(this.mediaElement.getAttribute('data-total-bytes')) || 0;
        this.handleLoadStart();
        var self = this;
        // const xhr = new XMLHttpRequest();
        var url = this.resource_request.toUrlPath();
        // xhr.open('HEAD', url, true); // True for asynchronous
        // xhr.onload = function () {
        //   if (this.status === 200) {
        //     const contentLength = this.getResponseHeader('Content-Length');
        //     if (contentLength) {
        //       self.totalBytes = parseInt(contentLength);
        //       //resolve(parseInt(contentLength));
        //     } else {
        //       self.totalBytes = 0;
        //       // reject(new Error('Content-Length header not found'));
        //     }
        var file_info = this.memoryDiagnostics.application.server.server_file_cache.findFile(url);
        if (file_info) {
            self.totalBytes = file_info.size;
        } else {
            self.totalBytes = 0;
        }

        if (self.mediaElement instanceof HTMLImageElement) {
            if (self.mediaElement.complete) {
                //self.bytes = this.totalBytes; // Assume fully loaded if complete
                // self.memoryDiagnostics.total_bytes_downloaded+=this.bytes;
                self.handleLoadedDataWithSize(); // Trigger finish if already loaded
            } else {
                self.mediaElement.addEventListener("load", self.handleLoadedDataWithSize);
            }
        } else if (
            self.mediaElement instanceof HTMLVideoElement ||
            self.mediaElement instanceof HTMLAudioElement
        ) {
            // Handle Video and Audio elements
            if (self.mediaElement.readyState > 0) {
                self.update_stream_bytes();
            } else {
                self.mediaElement.addEventListener("loadstart", self.handleLoadStart);
            }
            self.mediaElement.addEventListener("progress", self.handleProgress);
            self.mediaElement.addEventListener("loadeddata", self.handleLoadedData);
        }
        //   }
        // };

        // xhr.send();
    }
    update_stream_bytes() {
        if (
            this.mediaElement instanceof HTMLVideoElement ||
            this.mediaElement instanceof HTMLAudioElement
        ) {
            if (this.mediaElement.buffered.length > 0) {
                const bufferedTime = this.mediaElement.buffered.end(0);
                const progress = bufferedTime / this.mediaElement.duration;

                var previous = this.bytes;
                this.bytes = Math.round(progress * this.totalBytes); // Calculate bytes

                this.memoryDiagnostics.total_bytes_downloaded += this.bytes - previous;
            }
        }
    }

    handleLoadStart() {
        this.started = true;
        this.memoryDiagnostics.on_download_changed();
    }

    removeEventHandlers() {
        if (this.mediaElement) {
            if (this.mediaElement instanceof HTMLImageElement) {
                this.mediaElement.removeEventListener("load", this.handleLoadedDataWithSize);
            } else if (
                this.mediaElement instanceof HTMLVideoElement ||
                this.mediaElement instanceof HTMLAudioElement
            ) {
                this.mediaElement.removeEventListener("loadstart", this.handleLoadStart);
                this.mediaElement.removeEventListener("progress", this.handleProgress);
                this.mediaElement.removeEventListener("loadeddata", this.handleLoadedData);
            }

            this.mediaElement = undefined; // Clear the reference
        }
    }

    handleProgress() {
        this.update_stream_bytes();
        this.memoryDiagnostics.on_download_changed();
    }

    handleDataError() {
        this.isError = true;

        this.memoryDiagnostics.on_download_changed();
    }

    handleFetchedData(bytes_downloaded) {
        this.finished = true;

        this.bytes = bytes_downloaded;

        this.memoryDiagnostics.total_bytes_downloaded += bytes_downloaded;

        this.memoryDiagnostics.on_download_changed();
    }
    handleLoadedData() {
        this.finished = true;

        this.update_stream_bytes();

        this.memoryDiagnostics.on_download_changed();
    }
    handleLoadedDataWithSize() {
        this.finished = true;

        this.bytes = this.totalBytes;
        this.memoryDiagnostics.total_bytes_downloaded += this.bytes;

        this.memoryDiagnostics.on_download_changed();
    }
}
