// @ts-check

/**
 * 
 */
export class NullConnectedStems {

  addReference(stem) {
  }
  
  removeReference(stem) {
  }

  findConnectionForStem(stem) {
  }

  findConnectionForEffectStem(stem) {
  }

  findOrAddConnectionForStem(stem) {
  }

  loadStem(stem) {
  }

  unloadStem(stem) {
  }

  playStem(stem) {
  }

  updateStem(stem) { }

  setGainStem(stem, amount) {
  }

  stopStem(stem) {
  }

  addConnectionForEffectStem(stem) {
  }

  playSoundEffect(stem) {
  }
}
