//@ts-check

import { showLoadingPage } from "../routing/routes/loading/loading";
import { WebApplication } from "../webApplication";
import { WebApplicationState } from "../WebApplicationState";
import QRCode from "qrcode";

/**
 * @typedef {Object} AuthInterface
 * @property {() => void} initialize
 * @property {() => void} saveState
 * @property {() => void} shutdown
 * @property {(Function) => void} isAuthenticated
 * @property {(Function) => void} createLoginSession
 * @property {() => void} createGuestSession
 * @property {(connectionID: String) => void} showQRCode
 */

/**
 *
 */
export class InternalAuth {
    /**
     * @param {WebApplicationState} state
     */
    constructor(state) {
        this.state = state;
        this.authenticated = false; // Check if user is authenticated on page load
        this.isGuest = false; // Assume user is a guest unless told otherwise.
        this.ws = null;
    }

    /**
     * Initialize the auth system
     */
    initialize() {}

    /**
     * Creates a guest session for the user.
     */
    createGuestSession() {
        showLoadingPage(this.state);
        console.log("create guest session");
        fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ action: "guest" }),
            credentials: "include",
        }).then((response) => {
            if (response.ok) {
                // Allow the user to continue to the requested module as a guest.
                console.log("guest session created");
                this.authenticated = true;
                this.isGuest = true;
                const requestedPageString = localStorage.getItem("requestedPage");
                const requestedPage = requestedPageString ? JSON.parse(requestedPageString) : undefined;
                this.state.externalModules.openModule(requestedPage.name, requestedPage.isFullscreen, requestedPage.isRedirect);
            }
        });
    }

    /**
     * Shows a welcome message to the user.
     * @param {Object} userInfo the user information to display in the welcome message.
     */
    showWelcomeMessage(userInfo) {
        const welcomeMessage = document.createElement("div");
        welcomeMessage.id = "welcome-message";
        welcomeMessage.innerHTML = `<h1>Welcome back, ${userInfo.username}!</h1>`;

        document.body?.appendChild(welcomeMessage);
        setTimeout(() => {
            welcomeMessage.classList.add("show");
        }, 500);

        setTimeout(() => {
            welcomeMessage.classList.remove("show");
            setTimeout(() => {
                welcomeMessage.remove();
            }, 500);
        }, 5000);
        return;
    }

    /**
     * Checks if a user is authenticated by sending the JWT to the backend.
     * This function is called immediately upon loading the page.
     * If manually overridden will return true, bypassing authentication.
     * @param {Function} callback - Function to call with the authentication result.
     */
    isAuthenticated(callback) {
        console.log("checking if user is authenticated");
        this.authenticated =
            !this.state.application.getSetting(WebApplication.IsAuthEnabledSettingName) ||
            this.authenticated ||
            this.isGuest;

        // Bypassing authentication, open the module.
        if (this.authenticated) {
            console.log("user is authenticated");
            callback(true);
            return;
        }

        fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        })
            .then(async (response) => {
                if (response.ok) {
                    const data = await response.json();
                    if (data.error) {
                        this.authenticated = false;
                        callback(false);
                    } else if (data.ok) {
                        this.showWelcomeMessage(data.ok);
                        this.authenticated = true;
                        callback(true);
                    }
                } else {
                    this.authenticated = false;
                    callback(false);
                }
            })
            .catch((error) => {
                console.error("Error during authentication check:", error);
                this.authenticated = false;
                callback(false);
            });
    }

    /**
     * Creates a login session for the user.
     * @param {String | null} redirectURL the URL to redirect to after login.
     */
    createLoginSession(redirectURL = null) {
        showLoadingPage(this.state);
        const ws = new WebSocket("wss://6uuph5d57g.execute-api.us-west-2.amazonaws.com/nonprod/");

        ws.onopen = () => {
            console.log("ws connection opened");
            ws.send(JSON.stringify({ action: "login" }));
            const interval = setInterval(() => {
                if (ws.readyState === ws.CLOSED || ws.readyState === ws.CLOSING) {
                    clearInterval(interval);
                    return;
                }
                ws.send(JSON.stringify({ action: "ping" }));
            }, 30000);
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event?.data);
            console.log("received message from ws");
            if (!data) return;
            if (data.connectionID) {
                window.location.href = "/login#pending/" + data.connectionID;
            } else if (data.status === "authenticated") {
                this.getJWTFromID(data.user_id, redirectURL);
                ws.close();
            }
        };

        ws.onclose = () => {
            console.log("ws connection closed");
        };
        this.ws = ws;
    }

    /**
     * Retrieves a JWT from the server for the given user ID.
     * Also returns the user information.
     * @param {String} id the userID to get the JWT for.
     * @param {String | null} redirectURL the URL to redirect to after login.
     */
    getJWTFromID(id, redirectURL = null) {
        console.log("checking users jwt");
        fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                action: "authenticate",
                user_id: id,
            }),
        }).then(async (response) => {
            if (response.ok) {
                const requestedPageString = localStorage.getItem("requestedPage");
                if (!requestedPageString) {
                    console.error("requestedPage string was null");
                    return;
                }
                const requestedPage = requestedPageString ? JSON.parse(requestedPageString) : undefined;
                this.authenticated = true;
                if (redirectURL) {
                    // Redirect the user to the requested page.
                    window.location.href = redirectURL;
                } else {
                    this.state.router.resetPageToCanvas();
                    this.state.externalModules.openModule(requestedPage?.name, requestedPage?.isFullscreen, requestedPage?.isRedirect);
                }
            } else {
                console.error("Failed to authenticate user");
            }
        });
    }

    /**
     * Creates a QR code for the user to scan.
     * @param {String} connectionID the connection ID to use for the QR code.
     */
    showQRCode(connectionID) {
        console.log("creating qr code");
        const qrCodeURL = `${window.location.origin}/login#${connectionID}`;
        QRCode.toCanvas(
            document.getElementById("qr-code"),
            qrCodeURL,
            { color: { dark: "#230c01", light: "#d9b996" }, width: 400, margin: 2 },
            function (error) {
                if (error) {
                    console.error(error);
                }
            },
        );
    }

    // TODO - what is this for?
    saveState() {
        console.log("Internal Auth Save State");
    }

    shutdown() {
        this.saveState();
    }
}
