//@ts-check

import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
/**
 *
 */
export class SceneChangeRequest {
    /**
     *
     * @type {boolean}
     */
    isPushNavigationHistory;
    /**
     *
     * @type {object}
     */
    location;
    //replace_location;
    /**
     *
     * @type {any}
     */
    error;
    /**
     *
     * @type {Promise|undefined}
     */
    loading_promise;
    /**
     *
     * @type {number}
     */
    startedAt;
    /**
     *
     * @type {boolean}
     */
    isCancled;
    /**
     *
     * @type {boolean}
     */
    isPrimary;
    /**
     *
     * @type {boolean}
     */
    isCompleted;
    /**
     *
     * @type {GettingReadyCallbackCollector}
     */
    callback_collector;
    /**
     *
     * @type {Promise|undefined}
     */
    callback_collector_promise;

    /**
     *
     * @param {object} location
     * @param {object} isPushNavigationHistory
     */
    constructor(location, isPushNavigationHistory = true) {
        this.location = location;
        this.isPushNavigationHistory = isPushNavigationHistory;
    }
}
