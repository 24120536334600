// @ts-check
import { RectangleGeometry } from '../../../geometry/RectangleGeometry';
import { GettingReadyCallback, GettingReadyCallbackCollector } from '../../../sceneGraph/GettingReadyCallbackCollector';
import { MediaSourceResourceResolver } from '../resources/MediaSourceResourceResolver';
import { VisualElement } from '../visualElement';
import { MediaSourcePlayer } from './MediaSourcePlayer';
import { VideoData } from './VideoData';

const TAG = "MediaSourcePlayerWrapper"
export class MediaSourcePlayerWrapper {
    /** @type {HTMLVideoElement | null} */
    buffer_video_element;
    buffer_video_index;
    /** @type {MediaSourceResourceResolver} */
    resourceResolver;
    vis;
    isReady = false;
    isError = false;
    isCanceled = false;
    /** @type {MediaSourcePlayer} */
    player

    /**
     * 
     * @param {VisualElement} vis 
     * @param {MediaSourceResourceResolver} resourceResolver
     * @throws {Error}
     */
    constructor(vis, resourceResolver) {
        this.vis = vis;
        this.resourceResolver = resourceResolver;
        console.info("new:" + TAG + '(' + this.resourceResolver.toSourceURLName() + ')');
    }

    get url_file_info() {
        return this.resourceResolver?.url_file_info;
    }
    /**
     * 
     * @param {GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     * @throws {error}
     */
    start_loading(gettingReadyCallbackCollector) {
        this.buffer_video_index = this.vis.webApplicationState.msePlayer.buffer_video_index;

        this.buffer_video_element = this.vis.webApplicationState.msePlayer.buffer_video_element;

        if (!this.buffer_video_element) {
            throw new Error(`missing video buffer: ${this.resourceResolver.toSourceURLName()} could not find VideoElement`)
        }


        this.player = this.vis.webApplicationState.msePlayer.player;

        const callback = new GettingReadyCallback('readyPlayer')

        const playbackReady = () => {
            return new Promise(async (resolve, reject) => {

                try {
                    const dataReady = this.resourceResolver.fetchResource(this.resourceResolver.toSourceURL())

                    const video = await dataReady
                    var videoData = this.resourceResolver.newVideoDataForVideo(video);
                    if (videoData.event_playedOnce) {
                        videoData.onPlayedOnce = () => {
                            this.vis.obj.runActionJson(videoData.event_playedOnce);
                        };
                    }
                    
                    await this.player.playVideoData(videoData);
                    // await setTimeout(() => resolve(""), 500)
                    this.isReady = true
                    resolve("")
                } catch (error) {
                    console.error(TAG, "failure durring GetPlaybackReady()", error)

                }
            });
        }
        const promise = playbackReady()
        callback.addPromise(promise)
        gettingReadyCallbackCollector.add(callback)
    }
    start() {
        this.isCanceled = false;
        var loaded = this.isLoadingSuccess;
        if (!loaded) {
            return false;
        }
        return true;
    }
    on_buffer_play(index, buffer) {
        this.vis.resource_displaying(this.resourceResolver, true);
    }
    on_buffer_playing(index, buffer) {
        this.vis.resource_displayed(this.resourceResolver, true);
    }
    on_buffer_error(index, buffer) {
        this.isError = true;
        this.resourceResolver.notifyError();
        this.vis.onResourceCanvasElementError(this);
    }


    stop(next_resource_canvas_element) {

        if (this.resourceResolver.isLoading()) {
            this.resourceResolver.cancelLoading();
            this.isCanceled = true;
        } else {
            this.resourceResolver.stop();
        }

        // this.clearFromBuffer(next_resource_canvas_element);
    }

    clearFromBuffer(next_resource_canvas_element) {
        if (this.buffer_video_index == undefined) {
            return;
        }

        if (this.vis.icanvas.is_registered_video_buffer_index_as(this, this.buffer_video_index)) {

            this.resourceResolver.unapply_event_values_to_resource_element();

            this.vis.icanvas.clear_buffer_by_index(this.buffer_video_index);

            this.vis.icanvas.unregister_video_buffer_index(this, this.buffer_video_index);

        } else {
            console.warn(`video not registered on clear from buffer ${this.resourceResolver.toSourceURLName()}`);
        }

        this.buffer_video_element = null;
        this.buffer_video_index = undefined;
    }

    collectCanvasElements(result) {

    }
    setHidden(value) {

    }
    setDrawOrder(value) {

    }
    removeCanvasElement() {

    }

    get isLoadingSuccess() {

        if (this.resourceResolver == undefined) {
            return false;
        }
        if (this.resourceResolver.isLoaded != true) {
            return false;
        }
        this.resourceResolver.checkForErrors();
        if (this.resourceResolver.isError) {
            return false;
        }
        return true;
    }
    toSourceURLName() {
        return this.resourceResolver.toSourceURLName();
    }
    toSourceURLNameExt() {
        return this.resourceResolver.resource_request.toURLNameAndExtension();
    }
    toSourceURL() {
        return this.resourceResolver.toSourceURL();
    }
    get url() {
        return this.toSourceURL();
    }
    isLoading() {
        return this.resourceResolver?.isLoading();
    }
}
