//@ts-check

import { ExternalModules } from "../modules/ExternalModules";
import { WebApplicationState } from "../WebApplicationState";
import { ExternalModule } from "./ExternalModule";
import { ModuleActivationRewards } from "./ModuleActivationRewards";
import { ModuleIntegration } from "./ModuleIntegration";

/**
 *
 */
export class OpenModule {
    /**
     * @type {ExternalModule}
     */
    module;
    /**
     * @type {string}
     */
    iframeDivId;
    /**
     * @type {HTMLIFrameElement}
     */
    iframe;
    /**
     * @type {ExternalModules}
     */
    externalExtensions;
    /**
     * @type {Array.<ModuleIntegration>}
     */
    integrations = [];
    /**
     * @type {any}
     */
    data_context;
    /**
     * @type {string}
     */
    uuid;
    /**
     * @type {boolean}
     */
    isClosed;
    /**
     * @type {string}
     */
    userId;
    /**
     * @type {WebApplicationState}
     */
    state;
    /**
     * @returns {string}
     */
    get name() {
        return this.module.name;
    }
    /**
     *
     * @param {ExternalModule} module
     * @param {string} iframeDivId
     * @param {HTMLIFrameElement} iframe
     * @param {string} uuid
     * @param {string} userId
     * @param {WebApplicationState} state
     */
    constructor(module, iframeDivId, iframe, uuid, userId, state) {
        this.module = module;
        this.iframeDivId = iframeDivId;
        this.iframe = iframe;
        this.uuid = uuid;
        this.userId = userId;
        module.addIntegrations(this);
        this.state = state;
    }
    /**
     *
     */
    start() {
        this.integrations.forEach(async (element) => {
            await element.start(this.userId);
        });
    }
    /**
     *
     */
    tryClose() {
        this.externalExtensions.closeModule(this);
    }
    /**
     *
     * @param {object} message
     */
    exit(message = undefined) {
        this.tryClose();
    }
    /**
     *
     * @param {MessageEvent<any>} message
     */
    windowMessage(message) {
        this.integrations.forEach((element) => {
            element.windowMessage(message);
        });
    }
    /**
     *
     * @param {object} message
     */
    ready(message = undefined) {
        return;
    }
    /**
     *
     * @param {object} message
     */
    onStarted(message = undefined) {}
    /**
     *
     * @param {ModuleActivationRewards} rewards
     */
    addActivationRewards(rewards) {
        this.integrations.forEach((element) => {
            element.addActivationRewards(rewards);
        });
    }
}
