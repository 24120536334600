// @ts-check
/**
 *
 */
export class InteractiveEvent {
    /**
     *
     * @type {import('../audio_visual/interactive_canvas').InteractiveCanvas }
     */
    icanvas;
    /**
     *
     * @type {Event }
     */
    e;
    /**
     * @returns {KeyboardEvent}
     */
    get e_keyboardevent() {
        return /** @type {KeyboardEvent} */ (this.e);
    }
    /**
     * @returns {MouseEvent}
     */
    get e_mouseevent() {
        return /** @type {MouseEvent} */ (this.e);
    }
    /**
     *
     * @type {boolean}
     */
    isStopPropagation;
    /**
     *
     * @type {any}
     */
    activate_value;
    /**
     *
     * @type {any}
     */
    activate_value_context;

    /**
     *
     * @param {import('../audio_visual/interactive_canvas').InteractiveCanvas | undefined} icanvas
     * @param {Event | undefined} e
     */
    constructor(icanvas = undefined, e = undefined) {
        if (icanvas) {
            this.icanvas = icanvas;
        }
        if (e) {
            this.e = e;
        }
    }
    /**
     *
     */
    preventDefaultAndStopPropagation() {
        this.isStopPropagation = true;
        this.e?.preventDefault?.();
        this.e?.stopPropagation?.();
    }
}
