// @ts-check

import { RectangleGeometry } from '../../../geometry/RectangleGeometry';
import { AudioVisualPlaybackQueue } from './../AudioVisualPlaybackQueue';

/**
 * 
 */
export class AudioVisualScriptResourceCanvasElement {
    /**
     * @type {import('../visualElement').VisualElement}
     */
    vis;

    /**
     * @type {import('../resources/resourceInterface').ResourceInterface}
     */
    visual_resource;

    /**
     * @type {import('../OnDrawingParams').onDrawingFunction}
     */
    onDrawing;

    /**
     * @type {boolean}
     */
    isReady = false;

    /**
     * @type {boolean}
     */
    isError;

    /**
     * @type {import('../AudioVisualPlaybackQueue').AudioVisualPlaybackQueue}
     */
    playback;
    /**
     * @type {import('../../../sceneGraph/SceneGraphAudioVisualState').SceneGraphAudioVisualState|undefined}
     */
    active_state;
    //active_script;

    /**
     * @returns {import('../resources/AudioVisualScriptResource').AudioVisualScriptResource}
     */
    get av_script() {
        var r = /** @type{import('../resources/AudioVisualScriptResource').AudioVisualScriptResource} */ (this.visual_resource);
        return r;
    }

    /**
     * 
     * @param {import('../visualElement').VisualElement} vis 
     * @param {import('../resources/AudioVisualScriptResource').AudioVisualScriptResource} visual_resource 
     */
    constructor(vis, visual_resource) {

        this.vis = vis;
        this.visual_resource = visual_resource;
        this.playback = new AudioVisualPlaybackQueue(this.vis.obj);

        if (visual_resource.default_state) {
            this.active_state = visual_resource.state_set.getNewStateObjectByName(visual_resource.default_state);
        } else {
            this.active_state = visual_resource.state_set.getDefaultState();
        }

        if (this.active_state) {
            this.active_state.loadPlayback(this.playback);
        }
    }

    /**
     * 
     * @param {import('../../../sceneGraph/scene').SceneObjectInterface} obj 
     */
    static nextState(obj) {

        var av_script = obj.visual_element?.active_element;
        if (!(av_script instanceof AudioVisualScriptResourceCanvasElement)) {
            return;
        }
        var next = av_script.av_script.state_set.getNextState(av_script.active_state);

        if (!next) {
            return;
        }

        var previous = av_script.active_state;
        av_script.active_state = next;
        av_script.active_state.loadPlayback(av_script.playback,previous);
    }

    /**
     * 
     * @param {import('../../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     */
    start_loading(gettingReadyCallbackCollector) {
        this.playback.start_loading(gettingReadyCallbackCollector);
        this.isReady = true;
    }

    /**
     * 
     */
    start() {
        this.playback.start();
    }

    /**
     * 
     * @param {import('./ResourceCanvasElement').ResourceCanvasElementInterface|undefined} next_resource_canvas_element 
     */
    stop(next_resource_canvas_element) {
        this.playback.stop(next_resource_canvas_element);
    }
    /**
     * 
     * @param {boolean} value 
     */
    setHidden(value) {
    }
    /**
     * 
     * @param {number} value 
     */
    setDrawOrder(value) {

    }
    /**
     * 
     * @returns {boolean|undefined}
     */
    isLoading() {
        return false;
    }
    /**
     * 
     * @returns {string|undefined}
     */
    toSourceURLNameExt() {
        return undefined;
    }
    /**
     * @return {RectangleGeometry}
     */
    get geometry() {
        if (this.vis.obj.visual_geometry) {
            return this.vis.obj.visual_geometry;
        }
        if (this.vis.obj.geometry) {
            return this.vis.obj.geometry;
        }
        if (this.visual_resource) {
            var size = this.visual_resource.pixel_size();
            var result = new RectangleGeometry();
            result.initialize(0, 0, size[0], size[1]);
            return result;
        }
        var result = new RectangleGeometry();
        result.initialize(0, 0, 0, 0);
        return result;
    }
}
