// @ts-check

/**
 * 
 */
export class GettingReadyCallback {
  /**
   * @type {string}
   */
  name;

  /**
   * @type {Array.<Promise>}
   */
  promises = [];

  /**
   * @type {boolean}
   */
  isCanceled = false;

  /**
   * @type {boolean}
   */
  isReady = false;

  /**
   * @type {*}
   */
  error = null;

  /**
   * 
   * @param {string} name 
   */
  constructor(name="") {
    this.name = name;
  }

  /**
   * 
   * @param {Promise} promise 
   */
  addPromise(promise) {
    this.promises.push(promise);
  }

  /**
   * 
   */
  cancel() {
    this.isCanceled = true;
  }
}

/**
 * 
 */
export class GettingReadyCallbackCollector {
  /**
   * @type {Array.<GettingReadyCallback>}
   */
  calllbacks = [];

  /**
   * @type {Array}
   */
  errors;

  /**
   * @type {boolean}
   */
  is_auto_invalidate = false;

  /**
   * @type {boolean}
   */
  isCanceled;

  /**
   * 
   */
  cancel() {
    this.isCanceled = true;
  }

  /** 
   * @param {GettingReadyCallback} callback 
   */
  add(callback) {
    this.calllbacks.push(callback);
  }

  /**
   * 
   * @returns {Promise} 
   */
  newWaitPromiseForAllCallbacks() {
    const allPromises = this.calllbacks.flatMap(
      (callback) => callback.promises
    );

    return Promise.allSettled(allPromises).then((results) => {
      // note: indivividual callback property setting disabled, does not appear to work
      //this.calllbacks.forEach((callback) => {
      //   const callbackResults = results.filter(
      //     (result) =>{
      //       return callback.promises.includes(result.value);
      //     } 
      //   );

      //   // Set error field for individual callbacks
      //   const callbackErrors = callbackResults.filter(
      //     (result) => result.status === "rejected"
      //   );
      //   callback.error = callbackErrors.length > 0 ? callbackErrors[0].reason : null;

      //   callback.isReady =
      //     callbackResults.length > 0 &&
      //     callbackResults.every((result) => result.status === "fulfilled");
      // });

      this.errors = results
        .filter((result) => result.status === "rejected")
        .map((result) => result.reason);
    });
  }
}
