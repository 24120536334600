import { Action } from "../actions/action";

export class ActivateAction extends Action {
    value;
    value_context;
    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }
    canRun(interactive) {
        return true;
    }
    run(interactive) {
        console.log("action:Activate");
        interactive.scene.icanvas.activate(this.value, this.value_context);
    }
}
