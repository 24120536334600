//@ts-check
import { DrawScope } from "./audio_visual/DrawScope.js";
import { Player } from "./Player.js";
import { SceneGraphSet } from "./sceneGraph/sceneGraphSet.js";
import { SceneGraph } from "./sceneGraph/scene_graph.js";
import { Treeview } from "./view/treeview.js";
import { SceneGraphNode } from "./sceneGraph/scene_graph_node";
import { NavigationMode, SceneInteractionMode } from "./input/selectionMode.js";
import { InteractiveEvent } from "./sceneGraph/InteractiveEvent.js";
import { ScenePath } from "./sceneGraph/ScenePath.js";
import { SceneChangeRequest } from "./sceneGraph/SceneChangeRequest.js";
import { StackLayout } from "./view/stackLayout.js";
import { RectangleGeometry } from "./geometry/RectangleGeometry.js";
import { UpdateContext } from "./update.js";

/**
 *
 */
export class Simulation {
    /**
     *
     * @type {import('./audio_visual/interactive_canvas.js').InteractiveCanvas}
     */
    icanvas;
    /**
     *
     * @type {import('./resources.js').Resources}
     */
    resources;
    /**
     *
     * @type {import('./sceneGraph/sceneGraphSet.js').SceneGraphSet}
     */
    sceneGraphSet;
    /**
     *
     * @type {object}
     */
    json;
    /**
     *
     * @type {import('./customerAccount.js').CustomerAccount}
     */
    account;
    /**
     *
     * @type {import('./webApplicationServer.js').WebApplicationServer}
     */
    server;
    /**
     *
     * @type {import('./audio_visual/audio/webAudio.js').WebAudioInterface}
     */
    audio;
    /**
     *
     * @type {import('./sceneGraph/scene_graph_node.js').SceneGraphNode}
     */
    sceneGraphNode;
    /**
     *
     * @type {import('./Player.js').Player}
     */
    player;
    /**
     *
     * @type {import('./input/selectionMode.js').SelectionMode}
     */
    selectionMode;
    /**
     *
     * @type {import('./WebApplicationState.js').WebApplicationState}
     */
    state;
    /**
     *
     * @type {import('./geometry/RectangleGeometry.js').RectangleGeometry}
     */
    geometry;

    /**
     *
     * @param {import('./resources.js').Resources} resources
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('./customerAccount.js').CustomerAccount} account
     * @param {import('./webApplicationServer.js').WebApplicationServer} server
     * @param {import('./audio_visual/audio/webAudio.js').WebAudioInterface} audio
     * @param {import('./WebApplicationState.js').WebApplicationState} state
     */
    constructor(resources, icanvas, account, server, audio, state) {
        this.state = state;
        this.resources = resources;
        this.icanvas = icanvas;
        this.account = account;
        this.server = server;
        this.sceneGraphSet = new SceneGraphSet(this);

        this.audio = audio;
        this.sceneGraphNode = new SceneGraphNode(this);
        this.selectionMode = new SceneInteractionMode(); //icanvas.platformCanvas.createDefaultSelectionMode();

        this.geometry = RectangleGeometry.createGeometryFromJson_Relative({});
        this.geometry.letterboxInto = this.icanvas;
    }
    /**
     *
     * @returns {boolean}
     */
    is_ux_faded_out() {
        return this.getUXScene()?.isFadedOut() == true;
    }
    /**
     *
     */
    increment_item_set() {
        this.application.memory_diagnostics.increment_item_set();
        this.state.author.console_overlay.increment_item_set();
    }
    /**
     * @returns {import('./sceneGraph/scene_graph_node.js').SceneGraphNode}
     */
    get playerSceneGraphNode() {
        return this.player.sceneGraphNode;
    }
    /**
     * @returns {import('./webApplication.js').WebApplication}
     */
    get application() {
        return this.account.application;
    }
    /**
     * @returns {number}
     */
    get drawScope() {
        let result = this.account.json_simulation.drawScope;

        if (result == undefined) {
            result = DrawScope.Normal;
        }
        return result;
    }
    /**
     * @param {number} v
     */
    set drawScope(v) {
        this.account.json_simulation.drawScope = v;
        this.updateCanvasDrawScope();
    }
    /**
     * @returns {Promise}
     */
    async initialize() {
        this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());

        await this.sceneGraphSet.initialize();

        this.updateCanvasDrawScope();

        this.player = new Player(this.account, this);

        this.selectionMode.initialize(this);

        await this.sceneGraphNode.initiaize();
        this.sceneGraphNode.event_scene_change_request_complete = (request) => {
            this.scene_change_request_complete(request);
        };

        //this.icanvas.startBatchUpdate();
        //this.sceneGraphSet.initializeGraphNodeScene(this.sceneGraphNode);

        this.player.startChangeLocation(
            this.player.json.location,
            true,
            ScenePath.onlySceneGraphNameFromJsonPath(this.player.json.location),
        );

        //this.player?.initializeSceneGraphNode();

        //this.icanvas.endBatchUpdate();
        // this.sceneGraphNode.initiaizeEnd();

        //this.initializeSceneGraphNode();
    }
    /**
     *
     * @param {SceneChangeRequest} request
     * @param {SceneChangeRequest|undefined} fallback_request_on_error
     */
    startSceneChange(request, fallback_request_on_error = undefined) {
        this.application.server.server_file_cache.clearFileBinaryCache();

        this.sceneGraphNode.startSceneChange(request, fallback_request_on_error);
    }

    /**
     *
     * @param {SceneChangeRequest} request
     */
    scene_change_request_complete(request) {
        if (request.error) {
            if (ScenePath.areLocationsTheSame(request.location, this.player.json.location)) {
                if (
                    !ScenePath.areLocationsTheSame(request.location, this.player.json.homeLocation)
                ) {
                    this.player.startChangeLocation(this.player.json.homeLocation);
                }
            }
        } else {
            this.player.on_scene_change_request_success(request);

            //this.sceneGraphNode.selectNone();
            this.selectionMode.startState();
            //this.selectionMode.transitionState(new NavigationMode(this));
        }
    }
    /**
     *
     * @param {number} iteration_amount
     */
    selectNextSceneInteractive(iteration_amount) {
        this.sceneGraphNode.selectNextSceneInteractive(iteration_amount);
    }
    /**
     *
     * @returns
     */
    getSelectedSceneInteractive() {
        return this.sceneGraphNode.getSelectedSceneInteractive();
    }

    // initializeSceneGraphNode() {

    // }
    /**
     *
     */
    updateCanvasDrawScope() {
        this.icanvas.drawScope = this.drawScope;
    }
    /**
     *
     */
    start() {}
    /**
     *
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     */
    drawFrame(icanvas) {
        this.playerSceneGraphNode.drawFrame(icanvas);
    }
    /**
     *
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('./MouseEvent.js').InteractiveMouseEvent} e
     */
    mousedown(icanvas, e) {
        this.playerSceneGraphNode.mousedown(icanvas, e);
    }
    /**
     *
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('./MouseEvent.js').InteractiveMouseEvent} e
     */
    mouseup(icanvas, e) {
        this.playerSceneGraphNode.mouseup(icanvas, e);
    }
    /**
     *
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('./MouseEvent.js').InteractiveMouseEvent} e
     */
    mousemove(icanvas, e) {
        this.playerSceneGraphNode.mousemove(icanvas, e);
    }
    /**
     *
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('./sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent
     */
    keydown(icanvas, ievent) {
        if (ievent.e_keyboardevent.key == "1") {
            this.toggleDrawScope();
            this.icanvas.invaidate();
        }

        this.selectionMode.keydown(icanvas, ievent);
        if (ievent.isStopPropagation) {
            return;
        }
        this.playerSceneGraphNode.keydown(icanvas, ievent);
    }
    /**
     *
     * @param {import('./audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('./sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent
     */
    keyup(icanvas, ievent) {
        this.selectionMode.keyup(icanvas, ievent);
        if (ievent.isStopPropagation) {
            return;
        }
        this.playerSceneGraphNode.keyup(icanvas, ievent);
    }
    /**
     *
     * @returns {string}
     */
    stroageItemName() {
        return this.resources.combineJsonResourceName(
            this.application.name,
            this.account.name,
            "simulation.storage",
        );
    }
    /**
     *
     */
    saveState() {
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }
    /**
     *
     */
    shutdown() {
        this.saveState();
    }
    /**
     *
     */
    toggleDrawScope() {
        if (this.drawScope == DrawScope.Normal) {
            this.drawScope = DrawScope.Debug;
        } else {
            this.drawScope = DrawScope.Normal;
        }
    }
    /**
     *
     * @param {*} e
     */
    onTouchTap(e) {
        this.playerSceneGraphNode.onTouchTap(e);
    }
    /**
     *
     * @param {*} e
     */
    onTouchPan(e) {
        this.playerSceneGraphNode.onTouchPan(e);
    }
    /**
     *
     * @param {*} e
     */
    onTouchSwipe(e) {
        this.playerSceneGraphNode.onTouchSwipe(e);
    }
    /**
     *
     * @param {*} e
     */
    onTouchDistance(e) {
        this.playerSceneGraphNode.onTouchDistance(e);
    }
    /**
     *
     * @param {*} e
     */
    onTouchRotate(e) {
        this.playerSceneGraphNode.onTouchRotate(e);
    }
    /**
     *
     * @param {*} e
     */
    onTouchGesture(e) {
        this.playerSceneGraphNode.onTouchGesture(e);
    }
    /**
     *
     * @param {StackLayout} layout
     */
    collectEditableProperties(layout) {}
    /**
     *
     * @returns {string}
     */
    getAuthorInterfaceName() {
        return "Simulation";
    }
    /**
     *
     * @returns {HTMLElement|undefined}
     */
    createAuthorInterfaceElement() {
        return undefined;
    }
    /**
     * @param {DragEvent} e
     * @param {Array.<object>|undefined} files
     */
    file_dropped(e, files) {
        //this.sceneGraph.file_dropped(e, files);
    }
    /**
     * @param {DragEvent} e
     * @param {Array.<object>|undefined} files
     */
    drag_file(e, files) {
        // this.sceneGraph.drag_file(e, files);
    }
    /**
     * @param {InteractiveEvent} event
     */
    activate(event) {
        this.sceneGraphNode.activate_event(event);
    }
    /**
     *
     * @returns {import('./sceneGraph/scene.js').Scene|undefined}
     */
    getActiveScene() {
        return this.sceneGraphNode.getActiveScene();
    }
    /**
     *
     * @returns {import('./sceneGraph/scene.js').Scene|undefined}
     */
    getUXScene() {
        return this.sceneGraphNode.getUXScene();
    }
    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context) {
        this.sceneGraphNode.update(update_context);
    }
}
