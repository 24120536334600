import { Interactive } from "./Interactive.js";
let c2 = require("c2.js");

export class Interactives {
    parent;
    json;
    isStarted = false;
    interactives = [];

    constructor(parent, json) {
        this.parent = parent;
        this.json = json;
    }

    initializeFromJson() {
        var json = this.json;
        if (json) {
            for (let each in json) {
                var i = this.newInteractive(json[each]);

                this.interactives.push(i);
            }
        }
    }

    get scene() {
        return this.parent.scene;
    }

    changeSelected(toSelect) {
        var selected = this.getSelected();

        if (selected === toSelect) {
            return;
        }

        selected?.setSelected(false);
        toSelect?.setSelected(true);
    }

    selectNone() {
        var selected = this.getSelected();
        selected?.setSelected(false);
    }

    selectDefaultOrFirst() {
        var selectable = this.interactives.filter((item) => item.isSelectable());
        if (selectable.length == 0) {
            return;
        }

        for (let each of selectable) {
            if (each.json.isDefaultSelection) {
                this.changeSelected(each);
                return;
            }
        }
        this.changeSelected(selectable[0]);
    }

    getSelected() {
        for (let each of this.interactives) {
            if (each.isSelected) {
                return each;
            }
        }
        return undefined;
    }

    addInteractive(name, json, isTemporary = false) {
        var i = this.newInteractive(json, isTemporary);
        i.name = name;
        this.interactives.push(i);

        if (this.isStarted) {
            i.start();
        }
        return i;
    }
    removeInteractive(name, isTemporary = false) {
        var temp = this.interactives.filter((each) => !(each.name == name));
        this.interactives = temp;
    }
    removeTemporary() {
        var temp = this.interactives.filter((each) => !(each.isTemporary == true));
        this.interactives = temp;
    }

    findInteractiveByName(name, isTemporary) {
        for (let i in this.interactives) {
            var each = this.interactives[i];
            if (each.area.json.name == name && each.isTemporary == isTemporary) {
                return each;
            }
        }
    }

    getResourcePath() {
        return this.json.resourcePath || this.parent.getResourcePath();
    }

    newInteractive(json, isTemporary = false) {
        var i = new Interactive(this, json);
        i.isTemporary = isTemporary;
        i.initializeFromJson();

        return i;
    }

    collectCanvasElements(result) {
        this.interactives.forEach((element) => {
            element.collectCanvasElements(result);
        });
    }

    add(interactive) {
        this.interactives.push(interactive);
    }

    isVisualContentReady() {
        for (const each of this.interactives) {
            if (!each.isVisualContentReady()) {
                return false;
            }
        }
        return true;
    }
    startVisualContentGetReady(gettingReadyCallbackCollector) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.startVisualContentGetReady(gettingReadyCallbackCollector);
        }
    }
    start() {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.start();
        }
        this.isStarted = true;
    }

    stop() {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.stop();
        }
    }

    drawFrame(icanvas) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.drawFrame(icanvas);
        }
    }
    isIEventOnInteractiveArea(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            if (i.isIEventOnInteractiveArea(ievent)) {
                return true;
            }
        }
        return false;
    }
    mousedown(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.mousedown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    mouseup(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];

            i.mouseup(ievent);

            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    mousemove(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.mousemove(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }

        var selectable = this.interactives.filter((item) => item.isSelectable());
        var foundSelection = false;

        for (const each of selectable) {
            var isHover = each.area.isIEventOnInteractiveArea(ievent);

            if (isHover) {
                this.changeSelected(each);
                foundSelection = true;
                break;
                // console.log("i-h-t");
            } else {
                //  console.log("i-h-f");
            }
        }
        if (!foundSelection) {
            this.selectNone();
        }
    }
    keydown(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    keyup(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchTap(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchTap(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchPan(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchPan(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchSwipe(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchSwipe(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchDistance(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchDistance(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchRotate(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchRotate(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchGesture(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.onTouchGesture(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    activate(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.activate(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    file_dropped(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.file_dropped(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
        this.try_add_file_as_scene(ievent);

        ievent.isStopPropagation = true;
    }
    drag_file(ievent) {
        for (let each in this.interactives) {
            let i = this.interactives[each];
            i.drag_file(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }

        this.preview_add_file_as_scene(ievent);
        ievent.isStopPropagation = true;
    }

    static preview_name = "preview";
    preview_add_file_as_scene(ievent) {
        // var file=ievent.files[0].file;

        // var name = Interactive.getNameFromFilename(file);

        // if (this.scene.findInteractiveByName(name)) {
        //   console.warn("duplicate interactive name");
        //   return;
        // }

        var e = ievent.e;

        var found = this.findInteractiveByName(Interactives.preview_name, true);

        if (!found) {
            var json = {
                name: Interactives.preview_name,
                "interactive.area": {
                    "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
                },
            };
            found = this.addInteractive(Interactives.preview_name, json, true);
        }
        var relative = this.parent.convertEventWithPointToRelativePoint(e);
        found.setAreaReletivePosition(relative);
    }

    try_add_file_as_scene(ievent) {
        var found = this.removeInteractive(Interactives.preview_name);
        ievent.icanvas.invalidate();
    }
}
