import { AssetInfo } from "./AssetInfo";

export class AssetData {
    path;
    info;
    data;

    //json;
    // constructor(json){
    //   this.path=json.path;

    //   this.info=new AssetInfo(this.json.info);
    // }

    //get path(){return this.json.path;}
}
