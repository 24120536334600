//@ts-check
import { WebApplicationServer } from "./webApplicationServer";
import { CustomerAccount } from "./customerAccount";
/**
 *
 */
export class LakesideCabinServer extends WebApplicationServer {
    /**
     *
     * @type {string}
     */
    static typeName = "lakeside_cabin";
    /**
     *
     * @type {object}
     */
    json;
    /**
     *
     * @param {import('./resources').Resources} resources
     * @param {import('./webApplication').WebApplication} app
     * @param {string} name
     */
    constructor(resources, app, name = "") {
        super(resources, app, name);
    }
    /**
     *
     * @returns {string|undefined}
     */
    getCustomerNameFromUrl() {
        if (this.pathname == "/") {
            return "guest";
        }
        var match = this.pathname.match("/");
        if (!match) {
            return "guest";
        }

        let slash_count = match.length;

        if (slash_count == 1) {
            const regex = /^\/([\w\W]+)$/;
            const matches = this.pathname.match(regex);

            if (matches?.length == 2) {
                return matches[1];
            }
        }
    }
    /**
     *
     * @returns {string}
     */
    stroageItemName() {
        return this.resources.combineJsonResourceName(
            this.app.name,
            LakesideCabinServer.typeName,
            super.stroageItemName(false),
        );
    }
    /**
     *
     * @returns {Promise}
     */
    async initialize() {
        await super.initialize();
        this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
    }
    /**
     *
     */
    saveState() {
        super.saveState();
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }
    /**
     *
     */
    shutdown() {
        super.shutdown();
        this.saveState();
    }
    /**
     *
     * @param {string} account_name
     * @param {*} data
     * @returns {Promise}
     */
    async updateCustomerAccount(account_name, data) {
        //if (!this.app.getSetting("isUseREST")) {
        return Promise.resolve();
        // }

        // let promise = new Promise((resolve) => {
        //   $.post("/accounts/" + account_name, data, function (data, status) {
        //     resolve(status);
        //   }).fail(function (xhr, status, error) {
        //     resolve(status);
        //   });
        // });

        // return promise;
    }
    /**
     *
     * @param {string|undefined} account_name
     * @returns {Promise.<CustomerAccount>}
     */
    async getCustomerAccount(account_name = undefined) {
        if (account_name == undefined) {
            account_name = this.getCustomerNameFromUrl();
        }

        let self = this;
        let super_resources = this.resources;
        let super_app = this.app;

        // if (!this.app.getSetting("isUseREST")) {
        let result = CustomerAccount.fromGuest(super_resources, super_app, self);
        return Promise.resolve(result);
        // }

        // let promise = new Promise((resolve) => {
        //   $.get("/accounts/" + account_name, function (data, status) {
        //     let result = CustomerAccount.fromServerData(super_resources, super_app, self, data);

        //     resolve(result);
        //   }).fail(function (xhr, status, error) {
        //     let result = CustomerAccount.fromGuest(super_resources, super_app, self);

        //     resolve(result);
        //   });
        // });
        // return promise;
    }
}
