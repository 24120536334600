// @ts-check
import { getRadio, resolveRadioStems, resolveRadioMuted, resolveRadioStationName } from "./Radio"

export class PlaylistData {
    /** @type {string} */
    name 
    /** @type {Array} */
    stems
    /** @type {number} */
    gain
    /** @type {boolean} */
    isLooping
    /** @type {boolean} */
    on 
    /**
     * 
     * @param {Object} config 
     * @param {String} name 
     * @param {String} playlistId 
     * @param {number} gain 
     * @param {Boolean} isLooping 
     */
    constructor(config, name, type, playlistId, gain, isLooping) {
        // TODO Validate Add error handling
        if (type === 'radio') {
            const radio = getRadio(name)
            this.stems = resolveRadioStems(radio, config)
            this.name = resolveRadioStationName(radio, config) 
            this.on = resolveRadioMuted(name)
        } else {
            const matchingType = config.playlists[type]
            const playlist = matchingType.filter((playlist) => playlist.name === playlistId)
            this.name = playlist[0].name;
            this.stems = playlist[0].stems
            this.on = true
        }
        this.gain = gain
        this.isLooping = isLooping
    }

}

