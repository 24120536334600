// @ts-check
import { VideoFileDoubleBufferedResource } from "./resources/VideoFileDoubleBufferedResource.js";
import { VideoFileResource } from "./resources/VideoFileResource.js";
import { VideoFileSingleBufferedResource } from "./resources/VideoFileSingleBufferedResource.js";
import { WebApplication } from "../../webApplication.js";
import { ImageResource } from "./resources/ImageResource.js";
import { AudioVisualScriptResource } from "./resources/AudioVisualScriptResource.js";
import { VisualElement } from "./visualElement.js";
import { MediaSourceResourceResolver } from "./resources/MediaSourceResourceResolver.js";
import { SceneGraphNode } from "../../sceneGraph/scene_graph_node.js";
import { UpdateContext } from "../../update.js";

export class VisualElements {
    /** @type {number} */
    static DisplayTimeoutMS = 4000;
    static ImageVisualResourceType;
    static VideoVisualResourceType;
    static AudioVisualScriptVisualResourceType;
    /** @type {Array<any>} */
    static visualResourceTypes;

    /**
     * @param {WebApplication} application
     */
    static initializeResourceTypes(application) {
        VisualElements.visualResourceTypes = [];

        VisualElements.ImageVisualResourceType = ImageResource;
        VisualElements.visualResourceTypes.push(VisualElements.ImageVisualResourceType);

        if (application.getSetting(WebApplication.IsVideoEnabledSettingName)) {
            if (application.getSetting(WebApplication.IsMSVideoEnabledSettingName)) {
                console.log(`new MSE video visual element`);
                VisualElements.VideoVisualResourceType = MediaSourceResourceResolver;
                // VisualElements.VideoVisualResourceType = VideoFileResource;
            } else if (application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
                // console.log(`new db video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileDoubleBufferedResource;
            } else if (application.getSetting(WebApplication.IsSBVideoEnabledSettingName)) {
                //  console.log(`new sb video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileSingleBufferedResource;
            } else {
                //  console.log(`new ps video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileResource;
            }
            VisualElements.visualResourceTypes.push(VisualElements.VideoVisualResourceType);
            console.log("can create", VisualElements.visualResourceTypes);
        }

        if (
            application.getSetting(WebApplication.isAudioVisualScriptsEnabled_SettingName) &&
            application.getSetting(WebApplication.IsMSVideoEnabledSettingName)
        ) {
            VisualElements.AudioVisualScriptVisualResourceType = AudioVisualScriptResource;
            VisualElements.visualResourceTypes.push(
                VisualElements.AudioVisualScriptVisualResourceType,
            );
        }
    }

    visual_element_display_timeouts = {};
    /** @type {Array<VisualElement>} */
    visual_elements = [];
    /** @type {SceneGraphNode} */
    obj;

    /** @param {SceneGraphNode} obj */
    constructor(obj) {
        this.obj = obj;
    }
    get server_file_cache() {
        return this.obj.server_file_cache;
    }
    /**
     *
     * @param {VisualElement} e
     * @returns {boolean}
     */
    isAdded(e) {
        const index = this.visual_elements.indexOf(e);
        return index > -1;
    }
    /**
     *
     * @param {VisualElement} e
     * @returns
     */
    add(e) {
        this.visual_elements.push(e);
        return e;
    }
    /**
     *
     * @param {VisualElement} e
     */
    remove(e) {
        this.cancelDisplayTimeout(e);
        const index = this.visual_elements.indexOf(e);
        if (index > -1) {
            this.visual_elements.splice(index, 1);
        }
    }
    /**
     *
     * @param {VisualElement} e
     */
    onDisplayTimeout(e) {
        this.cancelDisplayTimeout(e);
        console.info(`visual:timeout `);
        e.on_display_timeout();
    }
    /**
     *
     * @param {VisualElement} e
     */
    startDisplayTimeout(e) {
        this.cancelDisplayTimeout(e);
        //   console.assert(this.visual_element_display_timeouts[e] == undefined);

        var timer = setTimeout(() => {
            this.onDisplayTimeout(e);
        }, VisualElements.DisplayTimeoutMS);
        // console.info(`visual:start:timeout `);

        this.visual_element_display_timeouts[e] = timer;
    }
    /**
     *
     * @param {VisualElement} e
     */
    cancelDisplayTimeout(e) {
        var timer = this.visual_element_display_timeouts[e];
        //console.assert(timer != undefined);
        if (timer) {
            // console.info(`visual:stop:timeout `);
            clearTimeout(timer);
            delete this.visual_element_display_timeouts[e];
        }
    }
    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context) {
        for (const each of this.visual_elements) {
            each.update(update_context);
        }
    }
}
