import { ActionWithValue } from '../actions/action';


export class NavigateBrowserAction extends ActionWithValue {

  constructor(verb, name, json) {
    super(verb, name, json);
  }

  run(interactive) {
    console.log("action:NavigateBrowserAction");
    window.location.href = this.value;
  }
}
