import { WebApplication } from "../webApplication.js";
import { HTMLScreenOverlay } from "./HTMLScreenOverlay.js";
import { DebugOverlay } from "./DebugOverlay.js";

export class MemoryDebugOverlay extends HTMLScreenOverlay {
    static downloads_container_name = "downloads-container";

    constructor() {
        super();
    }

    initialize(application) {
        this.enabled_setting = WebApplication.isShowMemoryDiagnosticsOverlaySettingName;
        this.enabled_timeout_setting = WebApplication.isDebugOverlayTimeoutSettingName;

        super.initialize(application);
        //this.application = application;
        //this.elements = {};
        //this.elements = {};
        this.container_names = [DebugOverlay.downloads_container_name];

        this.setupElements();
        this.setupFadeout();

        this.onEnabledChanged(this.enabled);
    }

    onEnabledChanged(value) {
        super.onEnabledChanged(value);

        //this.memory_enabled=value;
        if (!value) {
            this.application.memory_diagnostics?.cleanupElements(this.application);
            //  this.hideDiv(DebugOverlay.downloads_container_name);
        } else {
            this.application.memory_diagnostics?.setupElements(this.application);
            this.application.memory_diagnostics?.update();
            // this.showDiv(DebugOverlay.downloads_container_name);
        }
    }

    setupElements() {
        if (this.enabled) {
            this.application.memory_diagnostics?.setupElements(this.application);
        }
    }

    update() {
        // if (this.memory_enabled) {
        this.application.memory_diagnostics?.update();
        // }
    }
}
