// @ts-check
import { BaseModules, ModulesCommon } from "./ModulesCommon";
import { AuthorInterface } from '../sceneAuthorInterface/authorInterface';
/**
 * 
 */
export class SceneModules extends BaseModules {
  /**
  * @type {AuthorInterface|undefined}
  */
  authoringInterface;
  /**
  * @type {object}
  */
  json;
  /**
   * 
   * @param {object} json 
   */
  constructor(json = {}) {
    super();
    this.json = json;
  }
  /**
   * 
   */
  initialize() { }
  /**
   * 
   */
  saveState() {
    // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  /**
   * 
   */
  shutdown() {
    this.saveState();
  }
}
