/**
 * @interface
 */
export class IAudioPlaylist {
    /**
     * The name of the playlist.
     * @type {string}
     */
    name;

    /**
     * Starts the playlist.
     * @method
     */
    start() {}

    /**
     * ends the playlist.
     * @method
     */
    stop() {}

    /**
     * Mutes the playlist.
     * @method
     */
    mute() {}

    /**
     * Unmutes the playlist.
     * @method
     */
    unmute() {}

    /**
     * Pauses the current track in the playlist.
     * @method
     */
    pause() {}

    /**
     * Resumes the current track in the playlist.
     * @method
     */
    resume() {}

    /**
     * Sets the gain (volume) for the playlist.
     * @method
     * @param {number} gain The new gain value (0.0 to 1.0).
     */
    setGain(gain) {}

    /**
     * Plays the next track in the playlist.
     * @method
     */
    playNextTrack() {}
}
