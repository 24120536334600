// @ts-check

/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { NumberEditableProperty } from "../../view/propertyEditor";
import { StackLayout } from "../../view/stackLayout";
import { Treeview } from "../../view/treeview.js";
import { ConnectedStems } from "./ConnectedStems";
import IAudioPlayer from "./interfaces/IAudioPlayer.js";
import { NullWebAudio } from "./NullWebAudio.js";
import { PlaylistManager } from "./playlistAPI/playlistManager.js";
import { getRadioSettings } from "./playlistAPI/Radio.js";
import { WebAudio } from "./webAudio.js";

/**
 *
 */
export class NullWebAudioComponent {
    /**
     *
     * @type {import('../../customerAccount.js').CustomerAccount}
     */
    account;
    /**
     * @type {boolean}
     */
    isWaitingForGesture;

    /**
     * @type {import("./WebAudioComponent.js").actionFunction}
     */
    onStartAudioOnGesture;

    /** @type {IAudioPlayer} */
    player;

    /**
     *
     */
    constructor() {
        this.player = new NullWebAudio();
    }
    /**
     *
     */
    initialize() {
        this.player.tryStartAudio();
    }

    /**
     *
     */
    saveState() {
        //this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }
    /**
     *
     */
    start() {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     */
    drawFrame(icanvas) {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
     */
    mousedown(icanvas, e) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
     */
    mouseup(icanvas, e) {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
     */
    mousemove(icanvas, e) {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent
     */
    keydown(icanvas, ievent) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {*} e
     */
    onTouchTap(e) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {*} e
     */
    onTouchPan(e) {}
    /**
     *
     * @param {*} e
     */
    onTouchSwipe(e) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {*} e
     */
    onTouchDistance(e) {}
    /**
     *
     * @param {*} e
     */
    onTouchRotate(e) {}
    /**
     *
     * @param {*} e
     */
    onTouchGesture(e) {}
}
