// @ts-check

/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { NumberEditableProperty } from "../../view/propertyEditor";
import { StackLayout } from "../../view/stackLayout";
import { Treeview } from "../../view/treeview.js";
import { ConnectedStems } from "./ConnectedStems";
import IAudioPlayer from "./interfaces/IAudioPlayer.js";
import { PlaylistManager } from "./playlistAPI/playlistManager.js";
import { getRadioSettings } from "./playlistAPI/Radio.js";
import { WebAudio } from "./webAudio.js";
/**
 * @callback actionFunction
 */
/**
 * @callback initialize
 */
/**
 * @callback saveState
 */
/**
 * @callback connectNodeToLayer_function
 * @param {string} layer
 * @param {GainNode} node
 */
/**
 * @typedef WebAudioInterface
 * @property {import('../../resources.js').Resources} resources
 * @property {initialize} initialize
 * @property {saveState} saveState
 * @property {IAudioPlayer} player
 * @property {AudioContext} audioContext
 * @property {import('./ConnectedStems.js').ConnectedStems} connectedStems
 * @property {import('../interactive_canvas').start_InteractiveCanvasComponentInterfaceFunction} start
 * @property {import('../interactive_canvas').onActivity_InteractiveCanvasComponentInterfaceFunction} [onActivity]
 * @property {import('../interactive_canvas').activate_InteractiveCanvasComponentInterfaceFunction|undefined} [activate]
 * @property {import('../interactive_canvas').drawFrame_InteractiveCanvasComponentInterfaceFunction} drawFrame
 * @property {import('../interactive_canvas').mousedown_InteractiveCanvasComponentInterfaceFunction} mousedown
 * @property {import('../interactive_canvas').keydown_InteractiveCanvasComponentInterfaceFunction} keydown
 * @property {import('../interactive_canvas').keyup_InteractiveCanvasComponentInterfaceFunction} [keyup]
 * @property {import('../interactive_canvas').mouseup_InteractiveCanvasComponentInterfaceFunction} mouseup
 * @property {import('../interactive_canvas').mousemove_InteractiveCanvasComponentInterfaceFunction} mousemove
 * @property {import('../interactive_canvas').file_dropped_InteractiveCanvasComponentInterfaceFunction|undefined} [file_dropped]
 * @property {import('../interactive_canvas').drag_file_InteractiveCanvasComponentInterfaceFunction|undefined} [drag_file]
 * @property {import('../../sceneAuthorInterface/authorInterface').AuthorInterfaceComponentInterface_addAuthorInterfaceElementToTreeview} [addAuthorInterfaceElementToTreeview]
 * @property {import('../../sceneAuthorInterface/authorInterface').AuthorInterfaceComponentInterface_createAuthorInterfaceElement} [createAuthorInterfaceElement]
 * @property {import('../../sceneAuthorInterface/authorInterface').AuthorInterfaceComponentInterface_getAuthorInterfaceName} getAuthorInterfaceName
 * @property {PlaylistManager} playlistManager
 */

/**
 *
 */
export class WebAudioComponent {
    /**
     *
     * @type {import('../../customerAccount.js').CustomerAccount}
     */
    account;
    /**
     * @type {boolean}
     */
    isWaitingForGesture;

    /*
     * @type {number}
     */
    //archiveVolume;
    /**
     * @type {actionFunction}
     */
    onStartAudioOnGesture;

    /** @type {WebAudio} */
    player;

    /**
     *
     * @param {import('../../resources.js').Resources} resources
     * @param {import('../../customerAccount.js').CustomerAccount} account
     */
    constructor(resources, account) {
        this.player = new WebAudio(resources, account);
    }
    /**
     *
     */
    initialize() {
        this.player.tryStartAudio();
    }

    /**
     *
     */
    saveState() {
        //this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }
    /**
     *
     */
    start() {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     */
    drawFrame(icanvas) {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
     */
    mousedown(icanvas, e) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
     */
    mouseup(icanvas, e) {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
     */
    mousemove(icanvas, e) {}
    /**
     *
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {import('../../sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent
     */
    keydown(icanvas, ievent) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {*} e
     */
    onTouchTap(e) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {*} e
     */
    onTouchPan(e) {}
    /**
     *
     * @param {*} e
     */
    onTouchSwipe(e) {
        this.player.startAudioOnGesture();
    }
    /**
     *
     * @param {*} e
     */
    onTouchDistance(e) {}
    /**
     *
     * @param {*} e
     */
    onTouchRotate(e) {}
    /**
     *
     * @param {*} e
     */
    onTouchGesture(e) {}
}
