//@ts-check
/**
 *
 */
export class AudioListenerScope {
    /**
     *
     * @type {string|undefined}
     */
    regionName;
    /**
     *
     * @type {string|undefined}
     */
    areaName;
    /**
     *
     * @type {any}
     */
    region;
    /**
     *
     * @type {any}
     */
    area;
    /**
     *
     * @type {number}
     */
    gain = 1;
    /**
     *
     * @type {boolean}
     */
    isPlayingRegion = false;
    /**
     *
     * @type {boolean}
     */
    isPlayingArea = false;
    /**
     *
     * @param {import('../../sceneGraph/ScenePath').ScenePathInterface} path
     * @param {any} area
     * @param {any} region
     * @returns
     */
    static fromScenePath(path, area = undefined, region = undefined) {
        var r = new AudioListenerScope(path.sceneGraphName, path.sceneName);
        r.area = area;
        r.region = region;
        return r;
    }
    /**
     *
     * @param {string|undefined} regionName
     * @param {string|undefined} areaName
     * @param {any} area
     * @param {any} region
     */
    constructor(regionName, areaName, area = undefined, region = undefined) {
        this.regionName = regionName;
        this.areaName = areaName;
        this.area = area;
        this.region = region;
    }
}
