import { Action } from "../actions/action";

export class ToggleInteractiveVisibility extends Action {
    value;
    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
    }

    run(interactive) {
        console.log("action:ToggleInteractiveVisibility");
        var scene = interactive.scene;

        var found = scene.findInteractiveByName(this.value, false);

        if (found) {
            found.setHidden(!found.getHidden());
            scene.icanvas.invalidate();
        }
    }
}
