import { WebApplication } from "../webApplication.js";
import { HTMLScreenOverlay } from "./HTMLScreenOverlay.js";
let c2 = require("c2.js");

export class DebugOverlay extends HTMLScreenOverlay {
    static diagnostics_container_name = "diagnostics-container";
    static downloads_container_name = "downloads-container";

    constructor() {
        super();
    }

    initialize(application) {
        this.enabled_setting = WebApplication.isShowDebugOverlaySettingName;
        this.enabled_timeout_setting = WebApplication.isDebugOverlayTimeoutSettingName;

        super.initialize(application);
        //this.application = application;
        //this.elements = {};
        this.elements = {};

        this.container_names = [DebugOverlay.diagnostics_container_name];

        this.setupElements();
        this.setupFadeout();

        this.onEnabledChanged(this.enabled);
    }

    addAndSetupElement(
        container_name,
        element_name,
        element_text_content = undefined,
        isInline = false,
    ) {
        var element = document.createElement("p");

        var container = document.getElementById(container_name);
        element.classList.add("diagnostics-item");
        element.style.margin = "1px";
        element.style.setProperty("white-space", "pre-line");
        if (isInline) {
            element.style.display = "inline";
        }
        container.appendChild(element);
        this.elements[element_name] = element;
        this.elements[element_name].textContent = element_text_content;
    }

    setupElements() {
        const diagnostics_container = document.getElementById(
            DebugOverlay.diagnostics_container_name,
        ); // Get the container element

        if (diagnostics_container == undefined) {
            return;
        }

        var element = document.createElement("p");
        element.classList.add("diagnostics-item");
        element.style.margin = "1px";
        element.style.display = "inline";
        diagnostics_container.appendChild(element);
        this.elements["version"] = element;
        this.elements["version"].textContent = this.application.versionNumber;

        element = document.createElement("p");
        element.style.margin = "1px";
        element.style.marginLeft = "12px";
        element.style.display = "inline";
        element.classList.add("diagnostics-item");
        diagnostics_container.appendChild(element);
        this.elements["profile"] = element;

        if (this.application.getSetting(WebApplication.isShowDetailedDebugOverlaySettingName)) {
            element = document.createElement("p");
            element.style.margin = "1px";
            element.style.marginLeft = "12px";
            element.style.display = "inline";
            element.classList.add("diagnostics-item");
            diagnostics_container.appendChild(element);
            this.elements["brand"] = element;

            this.addAndSetupElement(DebugOverlay.diagnostics_container_name, "useragent");
            var useragent_string = navigator.userAgent.replace(/\)/g, ")\n");
            this.setDiagnosticText(
                DebugOverlay.diagnostics_container_name,
                "useragent",
                useragent_string,
            );

            element = document.createElement("p");
            element.style.margin = "1px";
            element.style.marginLeft = "12px";
            //element.style.display = 'inline';
            element.classList.add("diagnostics-item");
            diagnostics_container.appendChild(element);
            this.elements["keycode_down"] = element;

            element = document.createElement("p");
            element.style.margin = "1px";
            element.style.marginLeft = "12px";
            element.style.display = "inline";
            element.classList.add("diagnostics-item");
            diagnostics_container.appendChild(element);
            this.elements["keycode_up"] = element;
        }

        element = document.createElement("p");
        element.style.margin = "1px";
        element.style.marginLeft = "12px";
        //element.style.display = 'inline';
        element.classList.add("diagnostics-item");
        diagnostics_container.appendChild(element);
        this.elements["user_agent_name"] = element;

        // var limitedPointerEvents = !('ontouchstart' in window) &&
        //   !('onmousemove' in window);

        // if ("MediaSource" in window) {
        //   var hasHvecSupport = MediaSource.isTypeSupported('video/mp4; codecs="hvc1.1.6.L120.90"');
        //   // Example codec string, adjust for specific profiles
        //   var hasAc4Support = MediaSource.isTypeSupported('audio/mp4; codecs="ac-4.8"');
        //   // ...check for other ATSC 3.0 related APIs or features

        // }
        //userAgent=${navigator.userAgent}, vendor= ${ navigator.vendor}, opera=${window.opera}, limited pointer=${limitedPointerEvents},

        //element.textContent = ` hasHvecSupport=${hasHvecSupport},hasAc4Support=${hasAc4Support}`;

        // function isLikelyTVBrowser() {
        // Check for specific APIs or features often present on TVs
        // if (navigator.mediaDevices) {

        //   const hasTVFeatures = navigator.mediaDevices.enumerateDevices().then(devices => {

        //     var istv = devices.some(device => device.kind === 'tv') // TV input device

        //     element.textContent += `, tv features=${istv}, `;

        //   });
        // }
    }

    set_key_code(code, iskeydown) {
        var s = (iskeydown ? "keycode down " : "keycode up ") + code;
        var found = this.elements[iskeydown ? "keycode_down" : "keycode_up"];
        if (found) {
            found.textContent = s;
        }
    }

    set_brand(p) {
        var s = "brand=" + p;
        var found = this.elements["brand"];
        if (found) {
            found.textContent = s;
        }
    }
    set_profile(p) {
        var s = "profile=" + p;
        var found = this.elements["profile"];
        if (found) {
            found.textContent = s;
        }
    }
    update() {
        // if (this.memory_enabled) {
        //  this.application.memory_diagnostics?.update();
        // }
    }
    setDiagnosticText(container_name, element_name, textContent) {
        var container = document.getElementById(container_name);
        var element = this.elements[element_name];
        element.textContent = textContent;
    }
}
