import { ImageResource } from '../audio_visual/visual/resources/ImageResource';
import { VisualElement } from '../audio_visual/visual/visualElement';

export class UXIcons {
  static json_has_icon(json) {
    return json['icon'] != undefined;
  }
  
  static CopyIconToImageJson(json) {
    var result = ImageResource.CopyImageJson(json,"icon","icon-")
    return result;
  }

  static json_to_visual_element(obj, json) {

    var result = new VisualElement(obj, UXIcons.CopyIconToImageJson(json), "ux");

    return result;
  }
}
