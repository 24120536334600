//@ts-check
/**
 *
 */
export class ModulesCommon {
    /**
     * @type {string}
     */
    static fullscreenIframeDivId = "fullscreen_iframe";
}
/**
 *
 */
export class BaseModules {}
