import {
    changeStation,
    getRadio,
    resolveRadioStems,
    toggleRadio,
} from "../../audio_visual/audio/playlistAPI/Radio";
import { Stem } from "../../audio_visual/audio/stem";
import { WebAudio } from "../../audio_visual/audio/webAudio";
import { Action } from "../actions/action";

export class ChangeRadioStationAction extends Action {
    value;
    value_context;

    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive) {
        // TODO: for review, correct access pattern for config data
        const playlistConfig = interactive.scene.resources.fetched_json.playlist;
        const radioPlaylistConfig = playlistConfig.playlists.radio;
        // TODO: for review, correct access pattern for audio
        const audio = interactive.scene.scene_graph.simulation.audio;
        const radio = getRadio(this.value);
        const stations = Object.keys(radioPlaylistConfig);
        const index = stations.indexOf(radio.station);
        if (index === undefined)
            throw new Error(
                `Config Error: input {${this.value}} does not exist in set of ${stations}`,
            );
        let nextStation;
        if (index + 1 < stations.length) {
            nextStation = stations[index + 1];
        } else {
            nextStation = stations[0];
        }
        changeStation(this.value, nextStation);
        audio.playlistManager?.applyRadio(
            this.value,
            resolveRadioStems(getRadio(this.value), playlistConfig),
        );
    }
}
