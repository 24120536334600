// @ts-check
import { AudioPlaylist } from "./AudioPlaylist";
import { IPlaylistManager } from "./Interfaces/IPlaylistManager";
import { PlaylistData } from "./PlaylistData";

/**
 * @implements {IPlaylistManager}
 */
export class PlaylistManager {
    /** @type {Record<String, AudioPlaylist>} */
    #playlists = {};
    /** @type {HTMLElement} */
    playlistContainer;
    /** @type {string} */
    playlistContainerId;

    #radioSettings;
    get radioSettings() {
        return this.#radioSettings();
    }
    get size() {
        return Object.keys(this.#playlists).length;
    }
    /**
     *
     * @param {String} playlistGroupName
     * @param {Function} getRadioSettings getter function for radio settings
     * @param {String} parentId
     */
    constructor(playlistGroupName, getRadioSettings, parentId = "main") {
        this.#radioSettings = getRadioSettings;
        this.playlistContainerId = playlistGroupName;
        this.playlistContainer = document.createElement("div");
        this.playlistContainer.id = playlistGroupName;

        const parent = document.getElementById(parentId);
        if (parent === null) {
            // TODO better error handling
            throw new Error(`element with id ${parentId} does not exist within the document`);
        }
        parent.appendChild(this.playlistContainer);
    }
    clearAll() {
        throw new Error("Method not implemented.");
    }
    /**
     * @param {string} channel
     * @param {PlaylistData} playlist
     * @throws {Error}
     */
    add(channel, playlist) {
        if (!playlist) {
            throw new Error(`Invlaid playlist data provided: ${playlist}`);
        }
        if (!this.#playlists[channel]) {
            this.#playlists[channel] = new AudioPlaylist(
                this.playlistContainerId,
                channel,
                playlist.name,
                playlist.stems,
                playlist.gain,
                playlist.isLooping,
            );
        }
        this.applyRadio(channel);
        return this.#playlists[channel];
    }

    /**
     *
     * @param {string} channel
     * @returns {AudioPlaylist}
     */
    getPlaylist(channel) {
        return this.#playlists[channel];
    }

    /**
     *
     * @param {string} channel
     * @returns {boolean}
     */
    containsKey(channel) {
        return this.#playlists[channel] !== undefined;
    }

    /**
     *
     * @param {AudioPlaylist} targetPlaylist
     * @returns {boolean}
     */
    contains(targetPlaylist) {
        let result = false;
        Object.values(this.#playlists).forEach((playlist) => {
            if (playlist.name === targetPlaylist.name) {
                result = true;
            }
        });
        return result;
    }
    /**
     *
     * @returns {Array<string>}
     */
    keys() {
        return Object.keys(this.#playlists) ?? [];
    }
    /**
     * removes a playlist with unique `channel` identifier from the mananger
     * @param {string} channel
     */
    remove(channel) {
        if (!this.containsKey(channel)) {
            return undefined;
        }
        const playlist = this.getPlaylist(channel);
        playlist.stop();
        let success = delete this.#playlists[channel];
        if (!success) {
            throw new Error("ALERT Playlist removal from manager FAILED");
        }
        return playlist;
    }

    /**
     * scene change lifecycle, updates existing manged playlists with the state represented by `playlists_to`
     * @param {Map<string, PlaylistData>} playlists_to
     */
    transition(playlists_to) {
        const allKeys = new Set();
        const playlist_from = this.keys();
        playlist_from.forEach((key) => {
            allKeys.add(key);
        });
        playlists_to.forEach((_, key) => {
            allKeys.add(key);
        });

        const remove = [];
        const update = [];
        const add = [];

        allKeys.forEach((channel) => {
            if (playlists_to.has(channel) && this.containsKey(channel)) {
                update.push(channel);
            } else if (playlists_to.has(channel) && !this.containsKey(channel)) {
                add.push(channel);
            } else if (!playlists_to.has(channel) && this.containsKey(channel)) {
                remove.push(channel);
            }
        });

        remove.forEach((channel) => {
            let from = this.getPlaylist(channel);
            this.applyRadio(from.name);
            from.pause();
        });

        add.forEach((channel) => {
            let to = playlists_to.get(channel);
            if (to == undefined) {
                throw new Error("this is a bug please fix");
            }
            const playlist = this.add(channel, to);
            playlist.start();
        });
        update.forEach((channel) => {
            let from = this.getPlaylist(channel);
            let to = playlists_to.get(channel);
            if (to == undefined) {
                throw new Error("this is a bug please fix");
            }
            if (from.name !== to.name) {
                console.log("NAMES DIDNT MATCH", from.name, to.name);
                console.log("we've gotta set this");
            }
            this.applyRadio(from.name);
            from.setGain(to.gain ?? 0.0);
            from.resume();
            console.log("resume");
        });
    }

    /**
     * Applies Radio Specific Settings to any playlist that has a "radio station" channel identifier
     * @param {string} channel
     * @param {Array} stems - optional
     */
    applyRadio(channel, stems = []) {
        const radioSettings = this.radioSettings;

        const playlist = this.getPlaylist(channel);
        if (radioSettings[channel] === undefined) {
            return;
        }
        if (radioSettings[channel].station && playlist.name !== radioSettings[channel].station) {
            console.info("REMOVING unused playlist");
            this.#changeRadioStation(channel, radioSettings[channel].station, stems);
        }
        if (radioSettings[channel].on) {
            playlist.unmute();
        } else {
            playlist.mute();
        }
    }

    /**
     * changes current playlist `station` attatched to the unique `channel` identifier
     * @param {String} channel - unique identifier of the playlist channel
     * @param {String} station - unique identifier for a particular radio station
     * @param {Array} stems - Array of raw stems
     */
    #changeRadioStation(channel, station, stems) {
        const previousStation = this.getPlaylist(channel);
        const isLooping = true;
        const gain = previousStation.gain;

        const nextStation = new AudioPlaylist(
            this.playlistContainerId,
            channel,
            station,
            stems,
            gain,
            isLooping,
        );

        previousStation.stop();
        this.#playlists[channel] = nextStation;

        nextStation.start();
        if (this.radioSettings[channel].on) {
            nextStation.unmute();
        } else {
            nextStation.mute();
        }
    }
}
