// @ts-check

/**
 * 
 */
export class AudioAmbianceSet {
  /**
* 
* @type {Array.<import('./audioAmbiance').AudioAmbiance>}
*/
  collection = [];
  /**
* 
* @type {import('./webAudio').WebAudioInterface}
*/
  audio;
  /**
* 
* @type {string}
*/
  name;
  /**
   * 
   * @param {string} name 
   * @param {import('./webAudio').WebAudioInterface} audio 
   */
  constructor(name, audio) {
    this.name = name;
    this.audio = audio;
  }
  /**
   * 
   * @param {import('./audioAmbiance').AudioAmbiance} ambiance 
   */
  addAmbiance(ambiance) {
    this.collection.push(ambiance);
    ambiance.ambianceSet = this;
  }
    /**
   * 
   * @param {import('./audioAmbiance').AudioAmbiance} ambiance 
   */
  removeAmbiance(ambiance) {
    var i = this.collection.indexOf(ambiance);
    if (i != -1) {
      this.collection = this.collection.splice(i, 1);
    }
    ambiance.ambianceSet = undefined;
  }

  // start() {}

  // stop() {}

}
