// @ts-check

import { FileResource } from "./resources/FileResource";
import { AssetDatabase, IndexedDBAssetDataInterface } from "./server/shared/AssetDatabase";
import { StackLayout } from "./view/stackLayout";
import { Treeview } from "./view/treeview";
import { WebApplication } from "./webApplication";

/**
 * @callback ImageLoadCallback
 * @param {HTMLImageElement} resource_element

*/
/**
 * @callback ImageErrorCallback
 * @param {HTMLImageElement|undefined} resource_element
 * @param {any} error
 */
/**
 * @typedef AssetObjectInterface
 * @property {string} src

*/
/**
 * @typedef FileInfoType
 * @property {string|undefined} [now]
 * @property {string} path
 * @property {number} size
 * @property {string} md5_hash
 * @property {number|undefined} [width]
 * @property {number|undefined} [height]
 *
 */

/**
 *
 */
export class HTMLElementCacheItem {
    /**
     * html element cached
     * @type {HTMLElement}
     */
    htmlElement;
    /**
     *
     * @param {HTMLElement} htmlElement
     */
    constructor(htmlElement) {
        this.htmlElement = htmlElement;
    }
}
/**
 *
 */
export class BinaryCacheItem {
    /**
     * binary element cached
     * @type {ArrayBuffer}
     */
    data;
    /**
     *
     * @param {ArrayBuffer} data
     */
    constructor(data) {
        this.data = data;
    }
}
/**
 *
 */
export class LocalServerFileCache {
    /**
     *
     * @type {import('./webApplicationServer').WebApplicationServer}
     */
    server;
    /**
     *
     * @type {Array.<object>}
     */
    server_asset_list;
    /**
     *
     * @type {object}
     */
    server_asset_lookup;
    /**
     *
     * @type {Promise}
     */
    db_promise;
    //db_upgrade_promise;
    /**
     *
     * @type {IDBDatabase}
     */
    db;
    /**
     *
     * @type {AssetDatabase}
     */
    asset_database;
    /**
     *
     * @type {import('./webApplication').WebApplication}
     */
    application;

    /**
     *
     * @type {Map.<string, HTMLElementCacheItem>}
     */
    file_element_cache;

    /**
     *
     * @type {Map.<string, BinaryCacheItem>}
     */
    file_binary_cache;

    /**
     *
     * @param {import('./webApplicationServer').WebApplicationServer} server
     * @param {import('./webApplication').WebApplication} application
     */
    constructor(server, application) {
        this.server = server;
        this.application = application;
        this.asset_database = new AssetDatabase();
        this.file_element_cache = new Map();
        this.file_binary_cache = new Map();
    }
    /**
     *
     * @param {import('./resources/FileResourceRequest').FileResourceRequest} request
     * @returns
     */
    get_additional_manifest_entries_for_file_request(request) {
        var result = [];
        var look_for = FileResource.getPathWithoutExtension(request.fullpath);
        look_for += ".";

        for (let index = 0; index < this.server_asset_list.length; index++) {
            const each = this.server_asset_list[index];
            var each_path = each.path;

            if (!each_path || each_path === request.fullpath) {
                continue;
            }

            // each_path = FileResource.getPathWithoutExtension(each_path);

            if (each_path.startsWith(look_for)) {
                result.push(each_path);
            }
        }
        return result;
    }
    /**
     *
     * @param {string} path
     * @returns {FileInfoType}
     */
    findFile(path) {
        var found = this.server_asset_lookup[path];
        return found;
    }
    /**
     *
     * @param {StackLayout} layout
     */
    collectEditableProperties(layout) {
        if (this.server_asset_list) {
            let server_asset_list_view = new Treeview();
            server_asset_list_view.buildElementFromJson(this.server_asset_list, 1);
            layout.add(server_asset_list_view.element);
        }
    }

    // createNewDatabase(database, resolve) {
    //   database.createObjectStore(AssetDatabase.storeName, { keyPath: "path" /*, autoIncrement: true*/ });
    //   resolve();
    // }
    /**
     *
     * @returns {Promise}
     */
    async initialize() {
        //self = this;

        var fromLocalStorage = this.server.resources.getLocalStorageJsonItem(
            this.stroageItemName(),
        );
        this.asset_database.loadFromJson(fromLocalStorage);

        this.server_asset_list = this.server.resources.getFetchedJson(
            this.server.resources.getFileManifestJsonPath(),
        );

        this.server_asset_lookup = this.server_asset_list.reduce((acc, item) => {
            if (item.path) {
                acc[item.path] = item;
            }
            return acc;
        }, {});

        if (!this.application.getSetting("isPreCacheAssets")) {
            return;
        }

        this.db_promise = new Promise((resolve, reject) => {
            const request = window.indexedDB.open(AssetDatabase.dbName, 5);

            request.onerror = (event) => {
                console.error("error: indexedDB");
                reject(request.error); // Rej
            };

            request.onsuccess = (event) => {
                console.log("using: indexedDB");
                resolve(request.result);
                // self.db = self.db_promise.result;
            };

            request.onupgradeneeded = (event) => {
                //this.db_upgrade_promise = new Promise((resolve) => {
                //let db = event.target.result;
                // if (db.objectStoreNames.contains(AssetDatabase.storeName)) {
                //   db.deleteObjectStore(AssetDatabase.storeName);
                //   self.createNewDatabase(db, resolve);
                // } else {
                //   self.createNewDatabase(db, resolve);
                // }
                //  });
            };
        });

        try {
            this.db = await this.db_promise;
        } catch (error) {
            console.error("Error opening database:", error);
            return;
        }

        if (this.db) {
            this.asset_database.setDataInterface(new IndexedDBAssetDataInterface(this.db));

            //await this.asset_database.downloadNewAssets(this.server_asset_list);
        }
    }
    /**
     *
     * @returns {string}
     */
    stroageItemName() {
        return this.server.resources.combineJsonResourceName(
            this.server.application.name,
            "server_file.storage",
        );
    }
    /**
     *
     */
    saveState() {
        //this.asset_database.json.db_version = this.db.version;
        var asset_db = this.asset_database.saveToJson();
        //asset_db={};
        this.server.resources.setLocalStorageItemAsJson(this.stroageItemName(), asset_db);
    }
    /**
     *
     */
    shutdown() {
        this.saveState();
    }

    // startFetchPromise(url) {
    // }

    /**
     *
     * @param {AssetObjectInterface} assetObject
     * @param {string} url
     */
    loadFromCacheOrUrl(assetObject, url) {
        var is_enabled = this.application.getSetting("isPreCacheAssets");
        this.asset_database.loadFromCacheOrUrl(assetObject, url, is_enabled);
    }

    /**
     *
     * @param {string} url
     */
    RemoveHTMLElementFromCache(url) {
        this.file_element_cache.delete(url);
    }
    /**
     *
     * @param {string} url
     * @param {HTMLElement} htmlElemnt
     */
    AddHTMLElementToCache(url, htmlElemnt) {
        this.file_element_cache.set(url, new HTMLElementCacheItem(htmlElemnt));
    }

    /**
     *
     * @param {string} url
     * @returns {HTMLElement|undefined}
     */
    TryGetHTMLElementFromCache(url) {
        if (this.application?.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            console.log(`htmlElement:cache:request ${url}`);
        }

        var found = this.file_element_cache.get(url);

        if (found) {
            return found.htmlElement;
        }

        return undefined;
    }

    /**
     *
     */
    clearFileBinaryCache() {
        this.file_binary_cache.clear();
    }

    /**
     *
     * @param {string} url
     */
    removeBinaryFromCache(url) {
        this.file_binary_cache.delete(url);
    }
    /**
     *
     * @param {string} url
     * @param {ArrayBuffer} data
     */
    addBinaryToCache(url, data) {
        this.file_binary_cache.set(url, new BinaryCacheItem(data));
    }

    /**
     *
     * @param {string} url
     * @returns {ArrayBuffer|undefined}
     */
    tryGetBinaryFromCache(url) {
        if (this.application?.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            console.log(`binary:cache:request ${url}`);
        }

        var found = this.file_binary_cache.get(url);

        if (found) {
            return found.data;
        }

        return undefined;
    }
}
