//@ts-check
import { WebApplication } from '../webApplication';
import * as htmlHelper from "../htmlHelper.js";
import { InteractiveCanvas, VideoBufferStatus } from './interactive_canvas.js';

/**
 * @callback on_buffer_ended_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction 
 * @param {number} each_index 
 * @param {HTMLVideoElement} each_item 
*/

/**
 * @callback on_buffer_play_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction 
 * @param {number} each_index 
 * @param {HTMLVideoElement} each_item 
*/

/**
 * @callback on_buffer_playing_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction 
 * @param {number} each_index 
 * @param {HTMLVideoElement} each_item 
*/
/**
 * @callback on_buffer_error_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction 
 * @param {number} each_index 
 * @param {HTMLVideoElement} each_item 
*/
/**
 * @callback toSourceURLName_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction 
 * @returns {string}  
*/
/**
 * @callback onCanvasResized_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction 

*/
/**
 * @typedef InteractiveCanvasVideoBuffersRegistrationInterface
 * @property {on_buffer_ended_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction} on_buffer_ended 
 * @property {on_buffer_play_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction} on_buffer_play 
 * @property {on_buffer_playing_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction} on_buffer_playing 
 * @property {on_buffer_error_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction} on_buffer_error 
 * @property {toSourceURLName_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction} toSourceURLName 
 * @property {onCanvasResized_InteractiveCanvasVideoBuffersRegistrationInterfaceFunction|undefined} onCanvasResized 

*/

/**
 * 
 */
export class InteractiveCanvasVideoBuffers {

	/** @type {InteractiveCanvas} */
    icanvas;
	/** @type {Array<HTMLVideoElement | null>} */
	video_buffers;//= [undefined];//, undefined
	/**
	 * @type {Array.<InteractiveCanvasVideoBuffersRegistrationInterface|undefined>}
	 */
	video_buffer_registrations; //= [undefined];//, undefined

	/**
	 * @type {Array.<VideoBufferStatus|undefined>}
	 */
	video_buffer_status;//=[undefined];
	/**
	 * @type {number|undefined}
	 */
	visible_video_buffer_index;
	/**
	 * 
	 */
	initializeVideoBuffers() {

		if (this.icanvas.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {

			this.video_buffers = [null, null];
			this.video_buffer_registrations = [undefined, undefined];
			this.video_buffer_status = [undefined, undefined];
			const videoA = document.getElementById('video-a');
			const videoB = document.getElementById('video-b');
			if (!videoA || !videoB || !(videoA instanceof HTMLVideoElement) || !(videoB instanceof HTMLVideoElement)) {
				throw new Error("Invalid Video Buffer Element")
			}
			this.video_buffers[0] = videoA;
			this.video_buffers[1] = videoB;

		} else if (
			this.icanvas.application.getSetting(WebApplication.IsSBVideoEnabledSettingName) || 
			this.icanvas.application.getSetting(WebApplication.IsMSVideoEnabledSettingName)
		) {

			this.video_buffers = [null];
			this.video_buffer_registrations = [undefined];
			this.video_buffer_status = [undefined];
			const videoElement = document.getElementById('video-');
			if (!videoElement || !(videoElement instanceof HTMLVideoElement)) {
				throw new Error("Invalid Video Buffer Element")
			}

			this.video_buffers[0] = videoElement
			htmlHelper.showElement(this.video_buffers[0]);
		}

		for (let index = 0; index < this.video_buffers?.length || 0; index++) {
			var each_item = this.video_buffers[index];

			if (!each_item) {
				continue;
			}

			each_item.autoplay = false;
			each_item.setAttribute('playsinline', 'playsinline');
			each_item.controls = false;

			var onError = this.get_error_callback(index, each_item);
			each_item.addEventListener("error", (err) => {
				const stringbuilder = []
				for (const property in err) {
					stringbuilder.push(property+ ":" + err[property])
				}
				console.error("BUBBLING=", stringbuilder.join())
				onError()
			});
			each_item.addEventListener("ended", this.get_ended_callback(index, each_item));
			each_item.addEventListener("canplay", this.get_canplay_callback(index, each_item));
			each_item.addEventListener("loadeddata", this.get_loadeddata_callback(index, each_item));
			each_item.addEventListener("playing", this.get_playing_callback(index, each_item));

		}
	}
	/**
	 * 
	 * @param {number} each_index 
	 * @param {HTMLVideoElement} each_item 
	 * @returns {any}
	 */
	get_error_callback(each_index, each_item) {
		console.error()
		var self = this;
		var result = (event) => {
			const each_item_registration = self.video_buffer_registrations[each_index];
			console.error(`error:video:buffer ${each_item.id}`);
			self.on_buffer_error(each_index, each_item, each_item_registration);
		};
		return result;
	}
	/**
	 * 
	 * @param {number} each_index 
	 * @param {HTMLVideoElement} each_item 
	 * @returns {any} 
	 */
	get_ended_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			console.info(`video:ended ${each_item.id}`);
			const each_item_registration = self.video_buffer_registrations[each_index];

			each_item_registration?.on_buffer_ended(each_index, each_item);
		};
		return result;
	}
	/**
	 * 
	 * @param {number} each_index 
	 * @param {HTMLVideoElement} each_item 
	 * @returns {any} 
	 */
	get_canplay_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {

			const each_status = self.video_buffer_status[each_index];
			if (each_status == undefined) {
				return;
			}
			each_status.canplay_count += 1;
			if (each_status.canplay_count > 1) {
				return;
			}

			const each_item_registration = self.video_buffer_registrations[each_index];
			console.info(`video:canplay ${each_item.id} ${each_item_registration?.toSourceURLName()}`);


			if (
				this.icanvas.application.getSetting(WebApplication.IsSBVideoEnabledSettingName) ||
				this.icanvas.application.getSetting(WebApplication.IsVideoEnabledSettingName)
			) { 
				return result
			}
			//next.currentTime = 0;
			if (each_item.paused) {
				const each_item_registration = self.video_buffer_registrations[each_index];
				console.info(`video:play ${each_item.id} ${each_item_registration?.toSourceURLName()}`);
				each_item.play().catch(err => console.warn("warning: on play()", err));
				each_item_registration?.on_buffer_play(each_index, each_item);
			}

		};
		return result;
	}
	/**
	 * 
	 * @param {number} each_index 
	 * @param {HTMLVideoElement} each_item 
	 * @returns 
	 */
	get_loadeddata_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			const each_item_registration = self.video_buffer_registrations[each_index];
			console.info(`video:loadeddata ${each_item.id} ${each_item_registration?.toSourceURLName()}`);

		};
		return result;
	}
	/**
	 * 
	 * @param {number} each_index 
	 * @param {HTMLVideoElement} each_item 
	 * @returns 
	 */
	get_playing_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			const each_status = self.video_buffer_status[each_index];
			if (each_status == undefined) {
				return;
			}
			each_status.playing_count += 1;
			if (each_status.playing_count > 1) {
				return;
			}
			const each_item_registration = self.video_buffer_registrations[each_index];
			console.info(`video:playing ${each_item.id} ${each_item_registration?.toSourceURLName()}`);
			each_item_registration?.on_buffer_playing(each_index, each_item);

		};
		return result;
	}
	/**
	 * 
	 * @param {number} index 
	 * @param {HTMLVideoElement} buffer 
	 * @param {InteractiveCanvasVideoBuffersRegistrationInterface|undefined} registered 
	 */
	on_buffer_error(index, buffer, registered) {
		if (registered) {
			registered.on_buffer_error(index, buffer);
			//this.clear_buffer_by_index(index);
		}
		else {
			this.clear_buffer_by_index(index);
		}
	}
	/**
	 * 
	 * @param {InteractiveCanvasVideoBuffersRegistrationInterface} subject 
	 * @param {number} index 
	 * @returns 
	 */
	unregister_video_buffer_index(subject, index) {
		var registered = this.video_buffer_registrations[index];

		if (registered !== subject) {
			console.warn(`unregistered video buffer with different registration: ${subject.toSourceURLName()}`)
			return false;
		}

		this.video_buffer_status[index] = undefined;
		this.video_buffer_registrations[index] = undefined;
	}
	/**
	 * 
	* @param {InteractiveCanvasVideoBuffersRegistrationInterface} subject 
	* @param {number} index 
	 * @returns 
	 */
	is_registered_video_buffer_index_as(subject, index) {
		var registered = this.video_buffer_registrations[index];

		if (registered !== subject) {
			return false;
		}
		return true;
	}
	/**
	 * 
	 * @param {InteractiveCanvasVideoBuffersRegistrationInterface} subject 
	 * @returns {number|undefined}
	 */
	register_next_video_buffer(subject) {
		var r;

		if (this.visible_video_buffer_index == undefined) {
			r = 0;
		} else {
			r = this.get_next_video_buffer_index(this.visible_video_buffer_index);

		}

		if (this.video_buffer_registrations[r] != undefined) {
			console.error(`registered video buffer with different registration: ${subject.toSourceURLName()}`)
			return undefined;
		}

		this.video_buffer_status[r] = new VideoBufferStatus();
		this.video_buffer_registrations[r] = subject;

		return r;
	}

	/**
	 * 
	 * @param {number} index 
	 */
	clear_buffer_by_index(index) {
		var previous = this.video_buffers[index];

		// if MSE player the player itself needs to control the state of the HTMLVideoElement
		// do not not element state outside of that class
		if (this.icanvas.application.getSetting(WebApplication.IsMSVideoEnabledSettingName)) {
			return
		}
		if (previous) {
			previous.pause();
			previous.currentTime = 0;

			previous.removeAttribute('src');

			previous.removeAttribute('poster');

			//	if (this.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
			htmlHelper.hideElement(previous);
			//	}
		}
	}
	/**
	 * 
	 * @param {number} index 
	 */
	swap_buffer_to_index(index) {

		var next = this.video_buffers[index];
		if (next) {
			var next_id = next.id;
			var name = this.video_buffer_registrations[index]?.toSourceURLName() ?? "";
			console.info(`video:play id=${next_id}, name=${name},  video_src=${next.src}`);

			// next.currentTime = 0;
			// next.play();

			if (this.icanvas.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
				if (this.visible_video_buffer_index != undefined) {
					this.clear_buffer_by_index(this.visible_video_buffer_index);
				}
			}
			htmlHelper.showElement(next);
		}

		this.visible_video_buffer_index = index;
	}
	/**
	 * 
	 * @param {number} index 
	 * @returns {number}
	 */
	get_next_video_buffer_index(index) {
		var next = index + 1;
		next = next % this.video_buffers.length;
		return next;
	}
	/**
	 * 
	 * @param {number} index 
	 * @returns {HTMLVideoElement| null}
	 */
	get_video_buffer(index) {
		return this.video_buffers[index];
	}
	/**
	 * 
	 */
	onCanvasResizedForVideoBuffers() {
		if (this.visible_video_buffer_index != undefined) {
			var registered = this.video_buffer_registrations[this.visible_video_buffer_index];
			if (registered != undefined) {
				registered.onCanvasResized?.();
			}
		}
	}
}
