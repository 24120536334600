//@ts-check
import { WebApplication } from "../../../webApplication.js";
import { HTML5CanvasElement } from "./HTML5CanvasElement.js";
import { VideoFileToCanvasElement } from "./VideoFileToCanvasElement.js";
import { VideoFileToVideoElement } from "./VideoFileToVideoElement.js";
/**
 *
 */
export class VideoFileToElement extends HTML5CanvasElement {
    /**
     *  @type {import('./VideoDisplayToElement.js').VideoDisplayToElement|undefined}
     */
    video_display;

    /**
     * @returns {import('../resources/VideoFileResource.js').VideoFileResource}
     */
    get resource_video() {
        var r = /** @type {import('../resources/VideoFileResource.js').VideoFileResource} */ (
            this.resource
        );
        return r;
    }
    /**
     * @returns {HTMLVideoElement}
     */
    get element_video() {
        var r = /** @type {HTMLVideoElement} */ (this.element);
        return r;
    }
    /**
     * @type {object}
     */
    state = { isPlaying: false };

    /**
     * @type {Promise}
     */
    playing;

    /**
     *
     * @param {import('../resources/ImageResource.js').ImageResource} resource
     * @param {HTMLVideoElement} element
     * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} resource_canvas_element
     * @param {boolean} is_invalidating_draw
     */
    constructor(resource, element, resource_canvas_element, is_invalidating_draw = true) {
        super(resource, element, resource_canvas_element, is_invalidating_draw);
    }
    /**
     *
     * @param {import('../../../webApplication.js').WebApplication} webapp
     * @returns {boolean}
     */
    static isEnabled(webapp) {
        return webapp.getSetting(WebApplication.IsVideoEnabledSettingName);
    }

    /**
     *
     * @param {import('../../../webApplication.js').WebApplication} webapp
     * @returns {boolean}
     */
    static isDrawToCanvasEnabled(webapp) {
        return webapp.getSetting(WebApplication.IsAllCanvasVideosSettingName);
    }

    /**
     *
     * @param {Event} event
     */
    onStartedError(event) {
        // console.warn("video:element:start:error")

        this.addError(`video:element:start:error ${event}` + "\n");
    }

    /**
     *
     */
    addingToInteractiveCanvas() {
        if (!this.icanvas) {
            return;
        }

        var isEnabled = VideoFileToElement.isEnabled(this.icanvas.State.application);

        if (!isEnabled) {
            return this.addError("video not enabled");
        }

        this.element_video.onerror = this.onStartedError.bind(this);

        if (this.isFirstTimeCanvasAdd) {
            if (this.resource_video.isAutoPlay) {
                this.playVideo();
            }
        } else if (this.element_video.paused && this.state.isPlaying) {
            this.playVideo();
        }

        var isCanvasDisplayEnabled = VideoFileToElement.isDrawToCanvasEnabled(
            this.icanvas.State.application,
        );

        if (isCanvasDisplayEnabled) {
            this.video_display = new VideoFileToCanvasElement(this);
        } else {
            this.video_display = new VideoFileToVideoElement(this);
        }
        this.video_display.addedToInteractiveCanvas();
    }
    /**
     *
     */
    removeingFromInteractiveCanvas() {
        this.video_display?.removedFromInteractiveCanvas();

        this.element_video.onerror = null;

        if (!this.element_video.paused) {
            this.element_video.pause();
        }

        this.video_display = undefined;
    }
    /**
     *
     */
    playVideo() {
        console.error("VIDEOFILEtoElement:playVideo called()");
        if (!this.element_video.paused) {
            return;
        }

        this.state.isPlaying = true;

        console.info("video:element:play");
        console.error("PLAY() should not be called");
        this.playing = this.element_video
            .play()
            .catch((err) => console.warn("warning: on play()", err));

        this.playing.catch((e) => {
            var pause_error = "The play() request was interrupted by a call to pause()";
            if (e.message.includes(pause_error)) {
                return;
            }
            this.addError("error:video:element:play" + this.toSourceURL() + "\n" + e);
        });
    }
    /**
     *
     */
    draw() {
        super.draw();
        this.video_display?.draw();
    }
    /**
     *
     */
    onCanvasResized() {
        super.onCanvasResized();
        this.video_display?.onCanvasResized?.();
    }
    /**
     *
     */
    validate() {
        super.validate();
        this.video_display?.validate?.();
    }
}
