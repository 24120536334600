import { WebApplication } from "../webApplication.js";
import { ConsoleMessageElement } from "./ConsoleMessageElement.js";
import { HTMLScreenOverlay } from "./HTMLScreenOverlay.js";

export class ConsoleOverlay extends HTMLScreenOverlay {
    static consoleLog_container_name = "consoleLog-container";
    static MessageCountLimit = 10;
    item_set_count = 0;
    item_count = 0;
    isInitializedListeners = false;
    constructor() {
        super();
    }

    initialize(application) {
        this.enabled_setting = WebApplication.isShowConsoleLogOverlaySettingName;
        this.enabled_timeout_setting = WebApplication.isConsoleLogOverlayTimeoutSettingName;

        super.initialize(application);
        //this.application = application;
        //this.elements = {};
        this.elements = [];

        this.container_names = [ConsoleOverlay.consoleLog_container_name];
        this.setupElements();
        super.setupFadeout();

        this.onEnabledChanged(this.enabled);
    }

    onEnabled() {
        super.onEnabled();
        this.initializeListeners();
    }

    initializeListeners() {
        if (!this.isInitializedListeners) {
            this.listenToGlobalUncaughtExceptions();
            this.listenToGlobalConsoleLogMessages();
            this.isInitializedListeners = true;
        }
    }

    listenToGlobalUncaughtExceptions() {
        window.addEventListener("error", (message, source, lineno, colno, error) => {
            this.handleUncaughtException(message, source, lineno, colno, error);
        });

        // Listen for unhandled promise rejections
        window.addEventListener("unhandledrejection", (event) => {
            this.handleUncaughtRejection(event);
            //console.error("Unhandled promise rejection:", event.reason);

            // Similar error handling as in handleUncaughtException
        });
    }

    listenToGlobalConsoleLogMessages() {
        // 'log',
        ["error", "warn", "info", "debug"].forEach((method) => {
            const originalMethod = console[method];
            console[method] = (...args) => {
                // Call the original console method
                originalMethod.apply(console, args);

                // Pass the message to our custom handler
                this.handleConsoleMessage({
                    level: method,
                    message: args.join(" "), // Combine arguments into a single message string
                    // Add additional context like timestamp, stack trace (if available), etc.
                });
            };
        });
    }

    increment_item_set() {
        this.item_set_count += 1;
        this.item_count = 0;
    }

    addElementForMessage() {
        var container = document.getElementById(this.container_names[0]);

        if (container == undefined) {
            return undefined;
        }

        var element = document.createElement("p");
        element.style.margin = "1px";
        element.classList.add("consoleLog-item");
        //container.appendChild(element);

        var element_item = new ConsoleMessageElement();
        element_item.html_element = element;
        element_item.item_set_count = this.item_set_count;
        element_item.item_count = this.item_count;
        this.item_count += 1;

        container.insertBefore(element, container.firstChild);
        this.elements.push(element_item);

        if (this.elements.length > ConsoleOverlay.MessageCountLimit) {
            var first = this.elements.shift();
            container.removeChild(first.html_element);
        }

        return element_item;
    }

    getLatestMessage() {
        if (this.elements == undefined || this.elements.length == 0) {
            return undefined;
        }

        return this.elements[this.elements.length - 1];
    }

    handleConsoleMessage(message) {
        var latest = this.getLatestMessage();
        if (latest?.isSameAsConsoleLog(message)) {
            latest.incrementDuplicateCount();
            return;
        }

        var element_item = this.addElementForMessage();

        if (element_item == undefined) {
            return;
        }

        element_item.console_message_data = message;
        element_item.updateElement();
    }

    handleUncaughtException(message, source, lineno, colno, error) {
        const errorDetails = {
            message: message,
            source: source,
            lineno: lineno,
            colno: colno,
            error: error,
        };
        var element_item = this.addElementForMessage();

        if (element_item == undefined) {
            return;
        }

        element_item.uncaught_exception_data = errorDetails;
        element_item.updateElement();
    }

    handleUncaughtRejection(event) {
        var element_item = this.addElementForMessage();

        if (element_item == undefined) {
            return;
        }

        element_item.uncaught_rejection_data = event;
        element_item.updateElement();
    }

    setupElements() {}

    update() {}
}
