//@ts-check

import { ImageCanvasElement } from "../canvasElements/imageCanvasElement";
import { ResourceCanvasElement } from '../resourceCanvasElements/ResourceCanvasElement';
import { WebApplication } from '../../../webApplication';
import { FileResourceRequest } from '../../../resources/FileResourceRequest';

import { FileResource } from '../../../resources/FileResource';

let c2 = require("c2.js");

/**
 * 
 */
export class ImageResource extends FileResource {
  /**
  *  @type {string}
  */
  static errrorImage;
  /**
*  @type {string}
*/
  static webp_extension = ".webp";
  /**
*  @type {string}
*/
  static png_extension = ".png";
  /**
  *  @type {string}
  */
  static svg_extension = ".svg";
  /**
  *  @type {string}
  */
  static webp_category = "visuals/images/webp/";
  /**
  *  @type {string}
  */
  static png_category = "visuals/images/png/";
  /**
  *  @type {string}
  */
  static svg_category = "visuals/images/svg/";
  /**
  *  @type {string}
  */
  static json_name = "image";
  /**
   * 
   * @returns {boolean}
   */
  checkForErrors() {
    return false;
  }
  /**
   * 
   * @param {import('../visualElement').VisualElement} vis 
   * @returns {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface}
   */
  createResourceCanvasElement(vis) {
    return new ResourceCanvasElement(vis, this);
  }
  /**
   * 
   */
  notifyError() {
    if (!this.isError) {
      this.isError = true;
    }
  }
  /**
   * 
    * @param {object} json
    * @param {string} property
   * @returns {boolean}
   */
  static canCreateResourceFromJson(json, property) {
    return property == "image" && json[property] !== undefined;
  }
  /**
 * 
  * @param {object} json
 * @returns {boolean}
 */
  static canCreateResourceFromJsonObject(json) {
    return json["image"] !== undefined;
  }
  /**
 *
 * @param {string} name
 * @param {string} path
 * @param {string} path2
 * @param {import('../../../webApplication').WebApplication} webapp
 * @returns {ImageResource|undefined} 
 */
  static requestResource(name, path, path2, webapp) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, webapp);

    var resource;

    var webp_enabled = webapp.getSetting(WebApplication.IsWebpEnabledSettingName);
    var webp_image_path = request.fullpathWithExtension(ImageResource.webp_extension, ImageResource.webp_category);
    var svg_image_path = request.fullpathWithExtension(ImageResource.svg_extension, ImageResource.svg_category);
    var webp_image_asset = webapp.server.server_file_cache.findFile(webp_image_path);
    var svg_image_asset = webapp.server.server_file_cache.findFile(svg_image_path);

    // if (svg_image_asset) {
    //   request.setExtension(ImageResource.svg_extension);
    //   request.setCategoryPath(ImageResource.svg_category);
    //   resource = new SvgImageResource();
    // }
    // else 
    if (webp_enabled && webp_image_asset) {
      request.setExtension(ImageResource.webp_extension);
      request.setCategoryPath(ImageResource.webp_category);

      if (webapp.getSetting(WebApplication.IsWebpWebAssemblyEnabledSettingName)) {
        resource = new WebPWebAssemblyImageResource();
      } else {
        resource = new WebPImageResource();
      }
    }
    else {
      request.setExtension(ImageResource.png_extension);
      request.setCategoryPath(ImageResource.png_category);
      resource = new PngImageResource();
    }

    if (resource) {

      resource.resource_request = request;
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "image";
    }

    return resource;
  }
  /**
   * 
   * @param {object} json 
   * @returns {object}
   */
  static CopyImageJsonToJsonProperties(json) {
    var result = {
    };

    var key = "image";
    var key_dot = key + ".";

    for (let each in json) {
      if (each == key) {
        result["name"] = json[each];
      } else if (each.startsWith(key_dot)) {
        var new_key = each.slice(key_dot.length);
        result[new_key] = json[each];
      }
    }
    return result;
  }
  /**
   * 
   * @param {object} json 
   * @param {string|undefined} replace_key_name 
   * @param {string|undefined} replaced_name_prefix 
   * @returns {object}
   */
  static CopyImageJson(json, replace_key_name = undefined, replaced_name_prefix = undefined) {

    var result = {
    };

    var replace_key_name_dot = replace_key_name + ".";
    var key = "image";
    var key_dot = key + ".";

    for (let each in json) {

      if (replace_key_name) {
        if (each == replace_key_name) {
          result[key] = replaced_name_prefix ? replaced_name_prefix + json[each] : json[each];

        } else if (each.startsWith(replace_key_name_dot)) {
          var new_key = key_dot + each.slice(replace_key_name_dot.length);
          result[new_key] = json[each];
        }
      } else {
        if (each == key || each.startsWith(key_dot)) {
          result[each] = json[each];
        }
      }
    }

    return result;
  }
  /**
   *
   * @param {object} json
   * @param {string} property
   * @param {string} path
   * @param {string} path2
   * @param {import('../../../webApplication').WebApplication} webapp
   * @returns {import('./resourceInterface').ResourceInterface|undefined} 
   */
  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property)) {
      let result = this.requestResource(json.image, path, path2, webapp);
      if (result) {
        result.centerRotateDegrees = json["image.rotate"];
        result.scale = json["image.scale"];
        result.corner_radius = json["image.corner_radius"];
        result.json_properties = ImageResource.CopyImageJsonToJsonProperties(json);
        return result;
      }
    }
  }
  /**
 * 
 * @type {HTMLImageElement|undefined}
 */
  resource_element;
  /**
  * 
  * @type {FileResourceRequest}
  */
  resource_request;
  /**
  * 
  * @type {boolean}
  */
  is_loading = false;
  /**
  * 
  * @type {boolean}
  */
  isError;
  /**
  * 
  * @type {boolean}
  */
  isLoaded;
  /**
  * 
  * @type {object}
  */
  json_properties;
  /**
    * 
    * @type {number}
    */
  centerRotateDegrees;
  /**
  * 
  * @type {number}
  */
  scale;
  /**
  * 
  * @type {number}
  */
  corner_radius;
  /**
  * 
  * @type {import('./resourceInterface').onVisualLoaded_ResourceInterfaceFunction}
  */
  onVisualLoaded;

  /**
   * 
   */
  constructor() {
    super();
  }
  /**
   * 
   * @returns {string}
   */
  toSourceURLName() {
    return this.resource_request.name;
  }
  /**
   * 
  * @param {import('../../../LocalServerFileCache').LocalServerFileCache} server_file_cache
 * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
 */
  start_loading(server_file_cache, resource_canvas_element) {

    this.is_loading = true;
    var found = /** @type {HTMLImageElement|undefined} */ (this.server_file_cache.TryGetHTMLElementFromCache(this.url));

    if (found) {
      this.onImageLoaded(found);
    } else {

      var image = new Image();

      image.addEventListener("load", () => {
        this.onImageLoaded(image);
        server_file_cache.AddHTMLElementToCache(this.url, image);
      });

      image.addEventListener("error", (event) => {
        this.onImageLoadError(image);
        console.warn("image resource error" + " : " + this.url);
      });

      image.src = this.url;
    }
  }

  /**
   * 
   * @param {HTMLImageElement} htmlImageElement 
   */
  onImageLoaded(htmlImageElement) {
    this.resource_element = htmlImageElement;
    this.is_loading = false;
    this.isLoaded = true;
    this.isError = false;
    this.onVisualLoaded?.(this);
  }

  /**
  * 
  * @param {HTMLImageElement} htmlImageElement 
  */
  onImageLoadError(htmlImageElement) {
    this.resource_element = undefined;
    this.is_loading = false;
    this.isLoaded = true;
    this.isError = true;
    this.onVisualLoaded?.(this);
  }

  /**
   * 
   */
  initialize() {

  }
  /**
 *
 * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
 */
  start(resource_canvas_element) {

  }
  /**
   * 
   */
  stop() {

  }
  /**
 *
 * @returns {boolean} 
 */
  isLoading() {
    return this.is_loading;
  }
  /**
   *
   * @returns {Promise|undefined} 
   */
  // getLoadingPromise() {
  //   return this.loading_promise;
  // }
  /**
 *
 * @returns {Array.<number>} 
 */
  pixel_size() {
    return [this.imageWidth(), this.imageHeight()];
  }
  /**
 * 
 * @returns {number}
 */
  imageWidth() {
    var result = 0;

    if (this.isError) {
      result = 1920;
    }
    else if (this.resource_element && this.resource_element.width != undefined) {
      result = this.resource_element.width;
    } else if (this.url_file_info && this.url_file_info.width != undefined) {
      result = this.url_file_info.width;
    }

    // var scaler = this.scale == undefined ? 1 : this.scale;
    // result *= scaler;

    return result;
  }
  /**
   * 
   * @returns {number}
   */
  imageHeight() {
    var result = 0;

    if (this.isError) {
      result = 1080;
    }
    else if (this.resource_element && this.resource_element.height != undefined) {
      result = this.resource_element.height;
    } else if (this.url_file_info && this.url_file_info.height != undefined) {
      result = this.url_file_info.height;
    }

    // var scaler = this.scale == undefined ? 1 : this.scale;
    // result *= scaler;

    return result;
  }

  /**
   *
   * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} resource_canvas_element
   * @returns {import('../canvasElements/CanvasElement').CanvasElement|undefined}
   */
  createCanvasElement(resource_canvas_element) {
    if (!this.resource_element) {
      return undefined;
    }
    return new ImageCanvasElement(this, this.resource_element, resource_canvas_element);
  }
}
/**
 * 
 */
export class ImageElementImageResource extends ImageResource {

  constructor() {
    super();
  }
}
/**
 * 
 */
export class PngImageResource extends ImageElementImageResource {

  constructor() {
    super();
  }
}
/**
 * 
 */
export class WebPImageResource extends ImageElementImageResource {

  constructor() {
    super();
  }
}
/**
 * 
 */
export class WebPWebAssemblyImageResource extends ImageResource {

  constructor() {
    super();
  }
}
/**
 * 
 */
export class SvgImageResource extends ImageResource {

  constructor() {
    super();
  }
}
