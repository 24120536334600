//@ts-check
/**
 *
 */
export class ModuleActivationRewards {
    /**
     *
     * @param {number} tokens_per_second
     */
    onStart(tokens_per_second) {}
    /**
     *
     * @param {number} tokens_amount
     */
    onDepositTokens(tokens_amount) {}
    /**
     *
     */
    onEnd() {}
}
