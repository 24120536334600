//@ts-check
/**
 * 
 */
export class VideoDisplayToElement {
  /**
*  @type {import('./VideoFileToElement').VideoFileToElement}
*/
  video_file_to_element;

  /**
  *  @type {HTMLMediaElement}
  */
  get element() {
    return /** @type {HTMLMediaElement} */ (this.video_file_to_element.element);
  }

   /**
  *  @returns {import('../../interactive_canvas').InteractiveCanvas|undefined}
  */
  get icanvas() {
    return this.video_file_to_element.icanvas;
  }
    /**
  *  @returns {import('../resources/resourceInterface').ResourceInterface|undefined}
  */
  get resource() {
    return this.video_file_to_element.resource;
  }
 /**
  * 
  * @param {import('./VideoFileToElement.js').VideoFileToElement} video_file_to_element 
  */
  constructor(video_file_to_element) {
    this.video_file_to_element = video_file_to_element;
  }
  /**
   * 
   */
  addedToInteractiveCanvas() { }
  /**
   * 
   */
  removedFromInteractiveCanvas() {
  }
  /**
   * 
   */
  draw() { }
/**
 * 
 */
  onCanvasResized() { }
/**
 * 
 */
  validate() { }
}
