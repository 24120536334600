// @ts-check
let c2 = require("c2.js");
let geometry_js = require("../geometry.js");
import { DrawScope } from "../audio_visual/DrawScope.js";
import { OnDrawingParams } from "../audio_visual/visual/OnDrawingParams.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { RectangleGeometry } from "../geometry/RectangleGeometry.js";
import { InteractiveEvent } from "./InteractiveEvent.js";
import { SceneGraph } from "./scene_graph.js";
import { ScenePath } from "./ScenePath.js";
/**
 *
 */
export class InteractiveArea {
    /**
     *
     * @type {string}
     */
    static typename = "interactive.area";
    /**
     *
     * @type {import('./Interactive.js').Interactive}
     */
    interactive;
    /**
     *
     * @type {RectangleGeometry}
     */
    geometry;
    /**
     *
     * @type {object}
     */
    json;
    /**
     *
     * @type {VisualElement}
     */
    visual_element;
    /**
     * @returns {import('../resources.js').Resources}
     */
    get resources() {
        return this.interactive.resources;
    }
    /**
     * @returns {import('../audio_visual/interactive_canvas.js').InteractiveCanvas}
     */
    get icanvas() {
        return this.interactive.icanvas;
    }
    /**
     *
     * @returns {import('./scene.js').SceneObjectInterface}
     */
    get scene() {
        return this.interactive.scene;
    }
    /**
     * @returns {import('../audio_visual/visual/VisualElements.js').VisualElements|undefined}
     */
    get visualElements() {
        return this.scene.visualElements;
    }
    /**
     * @returns {import('../webApplication.js').WebApplication}
     */
    get application() {
        return this.scene.application;
    }

    /**
     *
     * @param {import('./Interactive.js').Interactive} interactive
     * @param {object} json
     */
    constructor(interactive, json) {
        this.interactive = interactive;
        this.json = json;
    }
    /**
     *
     */
    initializeFromJson() {}
    /**
     *
     * @returns {boolean}
     */
    isVisualContentReady() {
        if (!this.visual_element?.isReadyOrEmpty()) {
            return false;
        }
        return true;
    }

    /**
     *
     * @param {OnDrawingParams} params
     */
    onVisualDrawing(params) {
        if (this.scene.isFadedOut()) {
            params.isCancel = true;
        }
    }
    /**
     *
     * @param {import('../audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     */
    drawFrame(icanvas) {
        this.visual_element.drawFrame(icanvas);
        //console.log("area draw");

        // if (this.scene.simulation.is_ux_faded_out()) {
        //   return;
        // }

        this.geometry.border_size = 2;
        var outline_scope = DrawScope.Debug;

        if (this.scene.simulation.selectionMode.isSceneInteractionMode()) {
            if (this.interactive.isSelected && !this.interactive.hasPointOfInterest()) {
                var ux_scene = this.scene.simulation.getUXScene();
                if (ux_scene) {
                    var ux_scene_interactive_layer = ux_scene.sceneInteractiveLayer;

                    if (!ux_scene_interactive_layer.isFadedOut) {
                        this.geometry.border_size = 5;
                    }
                }
            }
        }

        this.geometry.draw(icanvas, outline_scope);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mousedown(ievent) {}
    /**
     * @param {InteractiveEvent} ievent
     * @returns {boolean}
     */
    isIEventOnInteractiveArea(ievent) {
        let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
        return this.geometry.containsPoint(mouse_point);
    }
    /**
     *
     * @returns {c2.Rect}
     */
    getScreenSpaceAreaRect() {
        return this.geometry.get_absolute_rect_shape();
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mouseup(ievent) {
        if (this.isIEventOnInteractiveArea(ievent)) {
            this.interactive.onMouseUp(ievent, this);
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchTap(ievent) {
        if (this.isIEventOnInteractiveArea(ievent)) {
            this.interactive.onMouseUp(ievent, this);
        }
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mousemove(ievent) {}
    /**
     *
     * @returns {boolean}
     */
    isSelectable() {
        return (
            this.geometry != undefined &&
            this.geometry.scale != 0 &&
            this.json.isSelectable != false
        );
    }
    /**
     *
     * @returns {boolean}
     */
    isFadedOut() {
        return this.scene.isFadedOut();
    }
    /**
     *
     * @returns {ScenePath}
     */
    toScenePath() {
        return this.scene.toScenePath();
    }
    /**
     * @returns {SceneGraph}
     */
    get sceneGraph() {
        return this.scene.sceneGraph;
    }
    /**
     * @returns {import('../LocalServerFileCache.js').LocalServerFileCache}
     */
    get server_file_cache() {
        return this.scene.server_file_cache;
    }
    /**
     * @returns {import('../simulation.js').Simulation}
     */
    get simulation() {
        return this.scene.simulation;
    }
    /**
     * @returns {number}
     */
    get draw_order() {
        return this.scene.draw_order;
    }
    /**
     *
     * @param {import('./GettingReadyCallbackCollector.js').GettingReadyCallbackCollector} gettingReadyCallbackCollector
     */
    startVisualContentGetReady(gettingReadyCallbackCollector) {
        if (!this.visual_element) {
            this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
        }

        this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
            this.json,
            this,
            this.scene,
            this.scene,
        );

        var test_rect = this.geometry.get_absolute_shape();

        this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
    }
    /**
     *
     */
    start() {
        this.visual_element.start();
        this.visual_element.onDrawing = (params) => this.onVisualDrawing(params);
    }
    /**
     *
     */
    stop() {
        this.visual_element.stop(undefined);
    }
    /**
     *
     * @returns {string}
     */
    getResourcePath() {
        return this.json.resourcePath || this.interactive.getResourcePath();
    }

    /**
     *
     * @param {c2.Point} point
     */
    setAreaReletivePosition(point) {
        if (this.geometry) {
            this.geometry.setPosition(point);
            this.icanvas.invaidate();
        }
    }

    // toRect(scaleToCanvas = false) {

    //   return this.getScreenSpaceAreaRect();
    // }
}
