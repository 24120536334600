import { KeyCodes } from "../../../../input/KeyCodes";
import { WebApplicationState } from "../../../../WebApplicationState";

/**
 * Shows the pending page, shown when the user is waiting for the phone to authenticate.
 * This is where the QR code is shown.
 * @param {String} connectionID the connection ID to show the QR code for.
 * @param {WebApplicationState} state the state of the application.
 */
export function showPendingPage(connectionID, state) {
    state.router.addPageToViewport(`
        <div class="login-page">
            <h1>Sign in to Lakeside Cabin</h1>
            <h3>Scan the QR code below to get started.</h3>
            <canvas id="qr-code"></canvas>
            <button id="cancel-button">Cancel</button> 
        </div>
    `);

    const cancelButton = document.getElementById("cancel-button");
    cancelButton?.focus();

    /**
     * Handles when the cancel button is clicked.
     * Returns to the login page.
     */
    const cancelHandler = () => {
        console.log("Cancel login session");
        state.auth.ws?.close(); // Close the WS connection.
        window.location.hash = "";
        removeEventListeners();
    };

    /**
     * Removes the event listeners for the pending page.
     */
    function removeEventListeners() {
        cancelButton?.removeEventListener("click", cancelHandler);
        removeEventListener("keydown", cancelHandler);
    }

    cancelButton?.addEventListener("click", cancelHandler);
    state.auth.showQRCode(connectionID);
}
