// @ts-check

/**
 * @callback replace_text_templateFunction 
 * @param {string} template
*/

/**
 * @typedef TextTemplateReplaceContextInterface
 * @property {replace_text_templateFunction} replace_text_template 
*/

/**
 * 
 */
export class TextTemplateReplace {
/**
 * 
 * @param {object} obj 
 * @param {TextTemplateReplaceContextInterface} context 
 * @returns {object}
 */
    static withReplacedPropertyValues(obj, context) {

        var result = { ...obj };

        for (let each in result) {
            var value = result[each];

            if (typeof value != "string") {
                continue;
            }

            if (value.startsWith("{{") && value.endsWith("}}")) {

                var template = value.substring(2, value.length - 2);
                var replaced = context.replace_text_template(template);
                result[each] = replaced;
            }
        }

        return result;
    }
}