//@ts-check

import { ModuleActivationRewards } from "./ModuleActivationRewards";
import { OpenModule } from "./OpenModule";

/**
 *
 */
export class ModuleIntegration {
    /**
     * @type {OpenModule|undefined}
     */
    openModule;

    /**
     * @type {ModuleActivationRewards|undefined}
     */
    rewards;

    /**
     *
     * @param {OpenModule} openModule
     */
    constructor(openModule) {
        this.openModule = openModule;
    }
    /**
     * @returns {any|undefined}
     */
    get data_context() {
        return this.openModule?.data_context;
    }
    /**
     *
     * @param {any} message
     */
    sendMessageToModule(message) {
        this.openModule?.iframe.contentWindow?.postMessage(message, "*");
    }
    /**
     *
     * @param {string} userId
     */
    async start(userId) {}
    /**
     *
     * @param {MessageEvent<any>} event
     */
    async windowMessage(event) {}

    /**
     *
     * @param {ModuleActivationRewards} rewards
     */
    addActivationRewards(rewards) {
        this.rewards = rewards;
    }
}
