import { RectangleGeometry } from "../geometry/RectangleGeometry.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector.js";
import { UXIcons } from "./UXIcons.js";

export class InteractivePointOfInterest {
    static typename = "point_of_interest";
    static array_typename = "points_of_interest";
    interactive;
    geometry;
    json;

    visual_element;
    isVisualError = false;

    get resources() {
        return this.interactive.resources;
    }
    get icanvas() {
        return this.interactive.icanvas;
    }

    get scene() {
        return this.interactive.scene;
    }
    get area() {
        return this.interactive.area;
    }
    get visualElements() {
        return this.scene.visualElements;
    }
    get application() {
        return this.scene.application;
    }

    constructor(interactive, json) {
        this.interactive = interactive;
        this.json = json;
    }

    setHidden(value) {
        this.visual_element.setHidden(value);
    }

    initializeFromJson() {}

    isVisualContentReady() {
        if (!this.visual_element?.isReadyOrEmpty()) {
            return false;
        }
        return true;
    }

    startVisualContentGetReady(gettingReadyCallbackCollector) {
        if (!this.visual_element) {
            if (UXIcons.json_has_icon(this.json)) {
                this.visual_element = UXIcons.json_to_visual_element(this, this.json);

                var geometry_json = {};

                var point = [0.0, 0.0];
                var relativeTo = this.area;

                if (this.json["area.position"]) {
                    point = this.json["area.position"];
                } else if (this.json["scene.position"]) {
                    point = this.json["scene.position"];
                    relativeTo = this.scene;
                }
                geometry_json["geometry.point"] = point;
                this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
                    geometry_json,
                    this,
                    relativeTo,
                    this.scene,
                );
            } else {
                this.visual_element = new VisualElement(this, this.json, this.getResourcePath());

                var geometry_json = {};

                var point = [0.0, 0.0];
                var relativeTo = this.scene;

                if (this.json["area.position"]) {
                    point = this.json["area.position"];
                    relativeTo = this.area;
                }

                geometry_json["geometry.rectangle"] = [point[0], point[1], 1, 1];
                this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
                    geometry_json,
                    this,
                    relativeTo,
                );
            }

            this.visual_element.setDrawOrder(1.2);
        }

        this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
    }

    start() {
        if (!this.visual_element) {
            this.callback_collector = new GettingReadyCallbackCollector();
            this.startVisualContentGetReady(this.callback_collector);
            this.callback_collector_promise = this.callback_collector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promise = undefined;

                    if (!this.callback_collector.isCanceled) {
                        this.start();
                        this.icanvas.try_invalidated_draw();
                    }

                    this.callback_collector = undefined;
                });
            return;
        }

        this.visual_element.onError = (resource) => this.onVisualError(resource);

        this.visual_element.start();

        this.visual_element.onDrawing = (params) => this.onVisualDrawing(params);
    }

    stop() {
        if (this.callback_collector) {
            this.callback_collector?.cancel();
        }
        if (this.visual_element && this.visual_element.isStopped == false) {
            this.visual_element.stop();
        }
    }

    onVisualDrawing(params) {
        if (this.scene.simulation.is_ux_faded_out()) {
            params.isCancel = true;
        }
    }

    onVisualError(resource) {
        this.isVisualError = true;
    }

    getResourcePath() {
        return this.json.resourcePath || this.interactive.getResourcePath();
    }

    collectCanvasElements(result) {
        this.visual_element.collectCanvasElements(result);
    }
}
