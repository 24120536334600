// @ts-check

import { Geometry } from "../geometry";

let c2 = require("c2.js");

/**
 * @typedef VisualObjectInterface
 * @property {import('../audio_visual/visual/visualElement.js').VisualElement|undefined} visual_element
 */
/**
 * @typedef HasGeometryInterface
 * @property {Geometry} geometry
 */

/**
 *
 */
export class RectangleGeometry extends Geometry {
    /**
     *
     */
    constructor() {
        super();
    }
    /**
     *
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @param {string} pointCoordinatesType
     * @param {string} sizeCoordinatesType
     */
    initialize(
        x = 0,
        y = 0,
        w = 0,
        h = 0,
        pointCoordinatesType = Geometry.CoordinatesType.ABSOLUTE,
        sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE,
    ) {
        //https://c2js.org/docs/classes/geometry.rect.html#constructor
        this.shape = new c2.Rect(x, y, w, h);
        this.geometry = this;
        this.coordinatesTypes = [pointCoordinatesType, sizeCoordinatesType];
    }

    /**
     *
     * @param {object} json
     * @param {VisualObjectInterface|undefined} visual_obj
     * @param {HasGeometryInterface|undefined} relativeTo
     * @param {HasGeometryInterface|undefined} letterboxedFrom
     * @param {string} pointCoordinatesType
     * @param {string} sizeCoordinatesType
     * @returns {RectangleGeometry}
     */
    static createGeometryFromJson_Relative(
        json,
        visual_obj = undefined,
        relativeTo = undefined,
        letterboxedFrom = undefined,
        pointCoordinatesType = Geometry.CoordinatesType.RELATIVE,
        sizeCoordinatesType = Geometry.CoordinatesType.RELATIVE,
    ) {
        var result = new RectangleGeometry();
        result.relativeTo = relativeTo;

        var as_rect = json["geometry.rectangle"];
        if (as_rect) {
            result.initialize(
                as_rect[0],
                as_rect[1],
                as_rect[2],
                as_rect[3],
                pointCoordinatesType,
                sizeCoordinatesType,
            );
        } else {
            var as_point = json["geometry.point"];
            if (as_point) {
                result.letterboxedFrom = letterboxedFrom;

                var w = 0;
                var h = 0;

                var file_info = visual_obj?.visual_element?.get_first_file_info();
                if (file_info && file_info.width !== undefined && file_info.height !== undefined) {
                    w = file_info.width;
                    h = file_info.height;
                    sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;

                    // if (relativeTo) {
                    //   var relativeToRect = relativeTo.geometry.get_absolute_rect_shape();
                    //   w /= relativeToRect.w;
                    //   h /= relativeToRect.h;
                    // } else {
                    //   sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;
                    // }
                }

                result.initialize(
                    as_point[0],
                    as_point[1],
                    w,
                    h,
                    pointCoordinatesType,
                    sizeCoordinatesType,
                );
            } else {
                var w = 0;
                var h = 0;

                var file_info = visual_obj?.visual_element?.get_first_file_info();
                if (file_info && file_info.width !== undefined && file_info.height !== undefined) {
                    w = file_info.width;
                    h = file_info.height;
                    sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;
                } else {
                    var defaultW = 3840;
                    var defaultH = 2160;
                    w = defaultW;
                    h = defaultH;
                    sizeCoordinatesType = Geometry.CoordinatesType.ABSOLUTE;
                }

                result.initialize(0, 0, w, h, pointCoordinatesType, sizeCoordinatesType);
            }
        }
        return result;
    }
    /**
     *
     * @param {c2.Point} point
     */
    setPosition(point) {
        this.shape.p = point.copy();
    }
    /**
     *
     * @param {import('../audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     * @param {number} drawScope
     */
    draw(icanvas, drawScope) {
        let absolute_shape = this.get_absolute_shape();
        icanvas.draw_point(absolute_shape.p, 3, drawScope);
        icanvas.draw_rect(absolute_shape, drawScope, this.border_size);
    }
    /**
     *
     * @returns {c2.Rect}
     */
    get_absolute_shape() {
        var result = this.shape.copy();

        if (this.coordinatesTypes[0] == Geometry.CoordinatesType.RELATIVE) {
            if (this.relativeTo) {
                var relativeToRect = this.relativeTo.geometry.get_absolute_rect_shape();
                result.p.x = relativeToRect.p.x;
                result.p.y = relativeToRect.p.y;

                result.p.x += this.shape.p.x * relativeToRect.w;
                result.p.y += this.shape.p.y * relativeToRect.h;
            }
        }

        if (this.coordinatesTypes[1] == Geometry.CoordinatesType.RELATIVE) {
            if (this.relativeTo) {
                var relativeToRect = this.relativeTo.geometry.get_absolute_rect_shape();
                result.w *= relativeToRect.w;
                result.h *= relativeToRect.h;
            }
        } else {
            if (this.letterboxedFrom) {
                var ratio = this.letterboxedFrom.geometry.get_letterboxIntoRatio();
                result.w *= ratio;
                result.h *= ratio;
            }
        }

        if (this.letterboxInto) {
            var into_ratio = this.get_letterboxIntoRatio();
            result.w *= into_ratio;
            result.h *= into_ratio;

            // return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
        }

        if (this.scale != 1) {
            result.w *= this.scale;
            result.h *= this.scale;
        }

        return result;
    }
    /**
     *
     * @returns {number}
     */
    get_letterboxIntoRatio() {
        if (this.letterboxInto) {
            var letterboxIntoRect = this.letterboxInto.geometry.get_absolute_rect_shape();
            var hRatio = letterboxIntoRect.w / this.getAbsoluteWidth();
            var vRatio = letterboxIntoRect.h / this.getAbsoluteHeight();
            var ratio = Math.min(hRatio, vRatio);
            return ratio;
        }
        return 1;
    }
    /**
     *
     * @returns {c2.Rect}
     */
    get_absolute_rect_shape() {
        return this.get_absolute_shape();
    }
    /**
     *
     * @param {c2.Point} point
     * @returns {boolean}
     */
    containsPoint(point) {
        let absolute_shape = this.get_absolute_shape();
        return absolute_shape.intersects(point) == true;
    }
    /**
     *
     * @returns {number}
     */
    getAbsoluteWidth() {
        return (
            this.shape.w *
            this.scale *
            (this.coordinatesTypes[1] === Geometry.CoordinatesType.RELATIVE && this.relativeTo
                ? this.relativeTo.geometry.get_absolute_rect_shape().w
                : 1)
        );
    }
    /**
     *
     * @returns {number}
     */
    getAbsoluteHeight() {
        return (
            this.shape.h *
            this.scale *
            (this.coordinatesTypes[1] === Geometry.CoordinatesType.RELATIVE && this.relativeTo
                ? this.relativeTo.geometry.get_absolute_rect_shape().h
                : 1)
        );
    }
    /**
     *
     * @param {number} dx
     * @param {number} dy
     */
    translate(dx, dy) {
        this.shape.p.x += dx;
        this.shape.p.y += dy;
    }
}
