// @ts-check
import { CanvasElement } from "./visual/canvasElements/CanvasElement.js";
import { InteractiveMobileCanvas } from "./interactive_mobile_canvas.js";
import { UpdateContext } from "../update.js";
import { InteractiveMouseEvent } from "../MouseEvent.js";
import { DrawScope } from "./DrawScope.js";
import { CanvasStack } from "./visual/canvasStack.js";
import { dialogOptions, ExternalModules } from "../modules/ExternalModules.js";
import {
    InteractiveATSC3TVCanvas,
    InteractiveBrowserTVCanvas,
    InteractiveTVCanvas,
} from "./interactive_tv_canvas.js";
import { InteractivePCCanvas } from "./InteractivePCCanvas.js";
import { AutoCanvasElementInvalidation } from "./AutoCanvasElementInvalidation.js";
import { InteractiveInput } from "../sceneGraph/InteractiveInput.js";
import { NavigateHomeAction } from "../sceneGraph/sceneActions/NavigateHomeAction.js";
import { BackAction } from "../sceneGraph/sceneActions/BackAction.js";
import { InteractiveEvent } from "../sceneGraph/InteractiveEvent.js";
import { InteractiveCanvasVideoBuffers } from "./InteractiveCanvasVideoBuffers.js";
import { RectangleGeometry } from "../geometry/RectangleGeometry.js";
import { WebApplicationState } from "../WebApplicationState.js";
import { InteractivePlatformCanvas } from "./InteractivePlatformCanvas.js";
import { DragDropEvent } from "../sceneGraph/DragDropEvent.js";
import { WebApplication } from "../webApplication.js";

let c2 = require("c2.js");
/**
 * @callback start_InteractiveCanvasComponentInterfaceFunction
 */
/**
 * @callback onActivity_InteractiveCanvasComponentInterfaceFunction
 */
/**
 * @callback onCanvasResized_InteractiveCanvasComponentInterfaceFunction
 */
/**
 * @callback update_InteractiveCanvasComponentInterfaceFunction
 * @param {UpdateContext} update_context
 */
/**
 * @callback drawFrame_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveCanvas} icanvas
 */
/**
 * @callback activate_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveEvent} event
 */
/**
 * @callback mousedown_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveCanvas} icanvas
 * @param {InteractiveMouseEvent} ievent
 */
/**
 * @callback mouseup_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveCanvas} icanvas
 * @param {InteractiveMouseEvent} ievent
 */
/**
 * @callback mousemove_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveCanvas} icanvas
 * @param {InteractiveMouseEvent} ievent
 */
/**
 * @callback keydown_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveCanvas} icanvas
 * @param {InteractiveEvent} ievent
 */
/**
 * @callback keyup_InteractiveCanvasComponentInterfaceFunction
 * @param {InteractiveCanvas} icanvas
 * @param {InteractiveEvent} ievent
 */
/**
 * @callback file_dropped_InteractiveCanvasComponentInterfaceFunction
 * @param {DragEvent} e
 * @param {Array.<object>|undefined} files
 */
/**
 * @callback drag_file_InteractiveCanvasComponentInterfaceFunction
 * @param {DragEvent} e
 * @param {Array.<object>|undefined} files
 */
/**
 * @typedef InteractiveCanvasComponentInterface
 * @property {start_InteractiveCanvasComponentInterfaceFunction} start
 * @property {onActivity_InteractiveCanvasComponentInterfaceFunction} [onActivity]
 * @property {onCanvasResized_InteractiveCanvasComponentInterfaceFunction} [onCanvasResized]
 * @property {activate_InteractiveCanvasComponentInterfaceFunction|undefined} [activate]
 * @property {drawFrame_InteractiveCanvasComponentInterfaceFunction} drawFrame
 * @property {mousedown_InteractiveCanvasComponentInterfaceFunction} mousedown
 * @property {keydown_InteractiveCanvasComponentInterfaceFunction} keydown
 * @property {keyup_InteractiveCanvasComponentInterfaceFunction} [keyup]
 * @property {mouseup_InteractiveCanvasComponentInterfaceFunction} mouseup
 * @property {mousemove_InteractiveCanvasComponentInterfaceFunction} mousemove
 * @property {file_dropped_InteractiveCanvasComponentInterfaceFunction|undefined} [file_dropped]
 * @property {drag_file_InteractiveCanvasComponentInterfaceFunction|undefined} [drag_file]
 * @property {update_InteractiveCanvasComponentInterfaceFunction|undefined} [update]
 */

/**
 *
 */
export class VideoBufferStatus {
    /**
     *
     * @type {number}
     */
    canplay_count = 0;
    /**
     *
     * @type {number}
     */
    playing_count = 0;
}
/**
 *
 */
export class InteractiveCanvas {
    /**
     *
     * @type {string}
     */
    static viewportId = "viewport";
    /**
     *
     * @type {HTMLDivElement}
     */
    viewport;
    /**
     *
     * @type {HTMLCanvasElement}
     */
    canvas;
    /**
     *
     * @type {CanvasRenderingContext2D}
     */
    ctx;
    /** @type {InteractivePlatformCanvas} */
    platformCanvas;
    /**
     *
     * @type {Array.<InteractiveCanvasComponentInterface>}
     */
    components = [];
    /**
     *
     * @type {object}
     */
    window_listeners = {};

    /**
     *
     * @type {Map.<Number,NodeJS.Timer>}
     */
    timers = new Map();

    /**
     *
     * @type {number}
     */
    drawScope = DrawScope.Normal;
    /**
     *
     * @type {Array.<CanvasElement>}
     */
    elements = [];
    /** @type {CanvasElement} */
    screenElement;
    /** @type {CanvasStack} */
    canvasStack;
    /**
     * @type {ExternalModules}
     */
    externalModules;
    /** @type {boolean} */
    isInputDisabled;
    /** @type {number} */
    isInputEnabledMilliseconds;
    /** @type {boolean} */
    isBatchUpdating;
    /** @type {Array.<Promise>} */
    batchUpdatePromises;
    /** @type {number} */
    frameCount = 0;
    /** @type {WebApplicationState} */
    state;
    /**
     * @returns {WebApplicationState}
     */
    get State() {
        return this.state;
    }
    /**
     * @returns {import('../webApplication.js').WebApplication}
     */
    get application() {
        return this.state.application;
    }
    /** @type {object} */
    element_invalidate_frequencies = {};
    /** @type {boolean} */
    isDrawDebugText = true;
    /** @type {string} */
    debugMessage = "";
    /** @type {RectangleGeometry} */
    geometry;
    /** @type {InteractiveCanvasVideoBuffers|undefined} */
    video_buffers;
    /**
     * @type {Array.<object>}
     */
    draw_log;

    /**
     *
     * @param {WebApplicationState} state
     */
    constructor(state) {
        this.intervals = [];
        this.canvasStack = new CanvasStack(this);
        this.state = state;
        this.mode = "MOVE";
        this.navigateHomeAction = new NavigateHomeAction("home", "Navigate Home");
        this.backAction = new BackAction("back", "Go Back");
        this.keydownCount = { Enter: 0, Backspace: 0 };
        this.keyTimer = { Enter: null, Backspace: null };
    }
    /**
     *
     * @param {CanvasElement} element
     */
    removeAutoInvalidateFrequencyPerSecond(element) {
        for (const eachFrequency in this.element_invalidate_frequencies) {
            var each = this.element_invalidate_frequencies[eachFrequency];
            each.removeElement(element);
            if (each.isEmpty) {
                each.stop();
                delete this.element_invalidate_frequencies[eachFrequency];
            }
        }
    }
    /**
     *
     * @param {CanvasElement} element
     * @param {number|undefined} value
     * @returns
     */
    addAutoInvalidateFrequencyPerSecond(element, value) {
        if (value == 0 || value == undefined) {
            return;
        }

        var obj = this.element_invalidate_frequencies[value];

        if (obj == undefined) {
            obj = this.element_invalidate_frequencies[value] = new AutoCanvasElementInvalidation(
                value,
                this,
            );

            obj.elements.push(element);
            obj.start();
        } else {
            obj.elements.push(element);
        }
    }
    /**
     *
     */
    onActivity() {
        for (let each in this.components) {
            this.components[each].onActivity?.();
        }
    }
    /**
     *
     */
    disableInput() {
        this.isInputDisabled = true;
    }
    /**
     *
     */
    enableInput() {
        this.isInputDisabled = false;
        this.isInputEnabledMilliseconds = Date.now();
    }
    /**
     *
     */
    deactivate() {
        this.viewport.style.display = "none";
    }
    /**
     *
     */
    reactivate() {
        this.viewport.style.display = "block";
        this.invalidate();
    }
    /**
     *
     * @param {InteractivePlatformCanvas} c
     */
    setPlatformCanvas(c) {
        this.platformCanvas = c;
    }
    /**
     *
     */
    initialize() {
        window.addEventListener("resize", () => {
            // e.preventDefault();
            // e.stopPropagation();
            this.resize();
        });
        // Added to prevent error with duplicate event listeners.
        if (this.window_listeners.mousedown) {
            window.removeEventListener("mousedown", this.window_listeners.mousedown);
        }
        if (this.window_listeners.mouseup) {
            window.removeEventListener("mouseup", this.window_listeners.mouseup);
        }
        if (this.window_listeners.mousemove) {
            window.removeEventListener("mousemove", this.window_listeners.mousemove);
        }

        if (
            this.application.getSetting(WebApplication.IsSBVideoEnabledSettingName) ||
            this.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)
        ) {
            this.video_buffers = new InteractiveCanvasVideoBuffers();
        }

        this.window_listeners.mousedown = (e) => this.mousedown(e);
        this.window_listeners.mouseup = (e) => this.mouseup(e);
        this.window_listeners.mousemove = (e) => this.mousemove(e);

        var pc_platform = new InteractivePCCanvas();
        var platforms = [
            new InteractiveATSC3TVCanvas(),
            new InteractiveBrowserTVCanvas(),
            new InteractiveMobileCanvas(),
            pc_platform,
        ];

        for (const each of platforms) {
            each.initialize(this);
        }

        for (const each of platforms) {
            if (each.isPlatform()) {
                this.setPlatformCanvas(each);
                break;
            }
        }

        if (this.platformCanvas == undefined) {
            this.setPlatformCanvas(pc_platform);
        }

        if (this.window_listeners.keydown) {
            window.removeEventListener("keydown", this.window_listeners.keydown);
        }
        if (this.window_listeners.keyup) {
            window.removeEventListener("keyup", this.window_listeners.keyup);
        }

        this.window_listeners.keydown = (e) => this.keydown(e);
        this.window_listeners.keyup = (e) => this.keyup(e);

        for (const [key, value] of Object.entries(this.window_listeners)) {
            window.addEventListener(key, value);
        }

        this.viewport = /** @type {HTMLDivElement} */ (
            document.getElementById(InteractiveCanvas.viewportId)
        );

        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/drop_event
        //
        this.viewport.ondrop = (event) => {
            this.drop_file(event);
        };

        this.viewport.ondragover = (event) => {
            this.drag_file(event);
        };

        this.canvas = /** @type {HTMLCanvasElement} */ (document.getElementById("viewport_canvas"));

        var context = this.canvas.getContext("2d");
        if (context) {
            this.ctx = context;
        } else {
            console.error("missing canvas 2d context");
        }
        this.geometry = new RectangleGeometry();
        this.updateCanvasSize(false);

        this.setupUpdate();
        this.screenElement = /** @type {CanvasElement} */ (this.addElement(new CanvasElement()));

        this.platformCanvas.initialize_input();
        this.platformCanvas.apply_to_default_settings();
        this.platformCanvas.configure_visual_elements();

        this.initializeVideoBuffers();
    }
    /**
     *
     */
    uninitialize() {
        this.platformCanvas.uninitialize();
        window.removeEventListener("resize", this.resize);
        for (const [key, value] of Object.entries(this.window_listeners)) {
            window.removeEventListener(key, value);
        }
        this.intervals.forEach((each) => clearInterval(each));
        this.removeElement(this.screenElement);
    }
    /**
     *
     * @param {CanvasElement|undefined} canvasElement
     * @returns {CanvasElement|undefined}
     */
    addElement(canvasElement) {
        if (canvasElement) {
            this.elements.push(canvasElement);
            canvasElement.addedToInteractiveCanvas(this);
        }
        return canvasElement;
    }
    /**
     *
     * @param {CanvasElement|undefined} canvasElement
     */
    removeElement(canvasElement) {
        if (!canvasElement) {
            return;
        }

        let index = this.elements.indexOf(canvasElement);
        if (index >= 0) {
            this.elements[index].removedFromInteractiveCanvas();
            this.elements.splice(index, 1);
        }
    }
    /**
     *
     */
    setupUpdate() {
        this.addTimer(UpdateContext.OneSecondMS);
    }
    /**
     *
     * @param {boolean} notifyElements
     */
    updateCanvasSize(notifyElements = true) {
        this.canvas.width = this.viewport.clientWidth;
        this.canvas.height = this.viewport.clientHeight;

        this.geometry.initialize(0, 0, this.viewport.clientWidth, this.viewport.clientHeight);

        if (notifyElements) {
            this.elements.forEach((v) => v.onCanvasResized());
        }

        if (notifyElements) {
            this.components.forEach((v) => v.onCanvasResized?.());
        }

        this.elements.forEach((v) => v.onCanvasResized());
        this.onCanvasResizedForVideoBuffers();
    }

    // toRect() {
    // 	//var result = new c2.Rect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
    // 	//return result;
    // 	return this.geometry.shape;
    // }
    /**
     *
     * @param {MouseEvent} e
     * @returns {c2.Point}
     */
    convertEventWithPointToRelativePoint(e) {
        var asMouse = { x: e.offsetX, y: e.offsetY };

        var rect = this.geometry.shape;

        var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

        return result;
    }

    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context) {
        var now = Date.now();
        var delta = now - update_context.time;

        update_context.time = now;
        update_context.deltaTime = delta;

        //console.info(`interactive_canvas:update ${update_context.interval}`);
        update_context.isDrawFrame = false;
        this.components.forEach((v) => v?.update?.(update_context));

        if (update_context.isDrawFrame) {
            this.drawFrame();
            update_context.isDrawFrame = false;
        }

        this.addTimer(update_context.interval);
    }
    /**
     *
     */
    ClearScreen() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.clientWidth, this.ctx.canvas.clientHeight);
    }
    /**
     *
     * @param {InteractiveCanvasComponentInterface} c
     */
    addComponent(c) {
        this.components.push(c);
    }

    /**
     *
     */
    drawFrame() {
        var start_now = performance.now();
        this.draw_log = [];
        this.draw_log.push({ message: `draw:frame# ${this.frameCount}` + "\n" });
        this.ClearScreen();

        this.elements.sort((a, b) => (a.draw_order > b.draw_order ? 1 : -1));
        this.elements.sort((a, b) => (a.draw_order > b.draw_order ? 1 : -1));

        for (const each of this.elements) {
            if (!each.isHidden) {
                each.draw();
            }
        }

        for (const each of this.components) {
            each.drawFrame(this);
        }

        var end_now = performance.now();
        var ms = end_now - start_now;
        this.draw_log.push({ message: `draw:duration ${ms.toFixed(3)}`, details: "MS" });

        var draw_log_message = this.draw_log
            .map((each) => each.message + (each.details ? "(" + each.details + ")\n" : ""))
            .join("  ");
        //console.log(draw_log_message);
        this.frameCount += 1;

        if (this.isDrawDebugText) {
            if (this.debugMessage) {
                //const displayElement = this.elements[1]?.resource.toRect(this);
                //if (displayElement?.w, displayElement?.h) {
                this.draw_text(this.debugMessage, new c2.Point(20, 80), 23);
                //}
            }
        }
        this.platformCanvas.drawFrame();
    }

    /**
     *
     * @param {string} msg
     */
    set_new_debug_message(msg) {
        this.debugMessage = msg;
        this.invalidate();
    }
    /**
     *
     */
    try_invalidated_draw() {
        let isDraw = false;

        for (let eachElement in this.elements) {
            let element = this.elements[eachElement];

            if (element.is_invalidating_draw) {
                if (element.isLoading()) {
                    var loadingPromise = element.getFirstLoadingPromise();
                    if (loadingPromise) {
                        if (this.isBatchUpdating) {
                            this.batchUpdatePromises.push(loadingPromise);
                        } else {
                            loadingPromise.then(() => {
                                this.try_invalidated_draw();
                            });
                        }
                    }
                } else {
                    if (this.isBatchUpdating) {
                        this.batchUpdatePromises.push(Promise.resolve());
                    } else {
                        isDraw = true;
                        element.validate();
                    }
                }
            }
        }

        if (isDraw) {
            // console.log("canvas draw");
            this.drawFrame();
        } else {
            // console.log("try canvas draw");
        }
    }
    /**
     *
     * @param {Event} e
     * @returns
     */
    isCanvasEvent(e) {
        if (!(e.target instanceof HTMLElement)) {
            return false;
        }
        return e.target?.nodeName == "CANVAS" || e.target?.nodeName == "VIDEO";
    }
    /**
     *
     * @param {MouseEvent} e
     */
    mousedown(e) {
        if (!this.isCanvasEvent(e) || this.isInputDisabled) {
            return;
        }

        this.onActivity();

        let relative_e = new InteractiveMouseEvent(e);
        for (let each in this.components) {
            this.components[each].mousedown(this, relative_e);
        }

        // this.try_invalidated_draw();
    }
    /**
     *
     * @param {object} e
     */
    keydown(e) {
        if (this.isInputDisabled) {
            return;
        }

        this.onActivity();

        let ievent = new InteractiveEvent(this, e);

        this.state.author.diagnostics_overlay.set_key_code(e.keyCode, true);
        // this.set_new_debug_message("keyCode=" + e.keyCode);

        // console.info("logging keycode " + ievent.e.keyCode + " ")

        for (let each in this.components) {
            this.components[each].keydown(this, ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }

        this.try_invalidated_draw();
    }
    /**
     *
     * @param {KeyboardEvent} e
     */
    keyup(e) {
        if (this.isInputDisabled) {
            return;
        }

        this.onActivity();

        let ievent = new InteractiveEvent(this, e);

        this.state.author.diagnostics_overlay.set_key_code(e.keyCode, false);

        for (let each in this.components) {
            this.components[each].keyup?.(this, ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }

        this.try_invalidated_draw();
    }
    /**
     *
     * @param {MouseEvent} e
     */
    mouseup(e) {
        // hack: compare when the input was enabled within a millisecond to now to prevent jquery ui dialog resize from registering a mouseup interaction.
        // remove hack: || this.isInputEnabledMilliseconds + 3 >= Date.now()

        if (!this.isCanvasEvent(e) || this.isInputDisabled) {
            return;
        }

        this.onActivity();
        // console.log("mup " + this.isInputEnabledMilliseconds + " " + Date.now());
        let relative_e = new InteractiveMouseEvent(e);
        for (let each in this.components) {
            this.components[each].mouseup(this, relative_e);
        }
        this.try_invalidated_draw();
    }

    mousemove(e) {
        if (!this.isCanvasEvent(e) || this.isInputDisabled) {
            return;
        }
        this.onActivity();
        let relative_e = new InteractiveMouseEvent(e);
        for (let i = 0; i < this.components.length; i++) {
            this.components[i].mousemove(this, relative_e);
        }
        this.try_invalidated_draw();
    }
    /**
     *
     * @returns {number}
     */
    get_width() {
        return this.canvas.clientWidth;
    }
    /**
     *
     * @returns {number}
     */
    get_height() {
        return this.canvas.clientHeight;
    }
    /**
     *
     */
    resize() {
        console.log(`resize`); //${ this.canvas.width} ${this.canvas.height}
        this.updateCanvasSize();
        this.drawFrame();
        this.onActivity();
        dialogOptions.width = this.canvas.width;
        dialogOptions.height = this.canvas.height;
    }
    /**
     *
     */
    start() {
        for (let each in this.components) {
            this.components[each].start();
        }
        this.updateCanvasSize();

        //this.try_invalidated_draw();
    }
    /**
     *
     * @param {c2.Point} shape
     * @param {number} radius
     * @param {number} drawScope
     */
    draw_point(shape, radius = 3, drawScope = DrawScope.Normal) {
        if (this.drawScope < drawScope) {
            return;
        }
        this.ctx.beginPath();
        this.ctx.fillStyle = "white";
        this.ctx.arc(shape.x, shape.y, radius, 0, 2 * Math.PI, true);
        this.ctx.fill();
    }
    /**
     *
     * @param {c2.Rect} shape
     * @param {number} drawScope
     * @param {number} lineWidth
     */
    draw_rect(shape, drawScope = DrawScope.Normal, lineWidth = 2) {
        if (this.drawScope < drawScope) {
            return;
        }
        this.ctx.beginPath();
        this.ctx.lineWidth = lineWidth;
        this.ctx.strokeStyle = "white";
        this.ctx.rect(shape.p.x, shape.p.y, shape.w, shape.h);
        this.ctx.stroke();
    }
    /**
     * Draws a rectangle on a canvas with a semi-transparent background and a border.
     *
     * @param {object} shape An object defining the rectangle's properties.
     * @param {object} shape.p An object containing the x and y postion of the rectangle's top-left corner.
     * @param {number} shape.p.x The x-coordinate of the rectangle's top-left corner.
     * @param {number} shape.p.y The y-coordinate of the rectangle's top-left corner.
     * @param {number} shape.w The width of the rectangle.
     * @param {number} shape.h The height of the rectangle.
     * @param {array} color The RGB color of the rectangle as an array [red, green, blue] (each 0-255).
     * @param {number} backgroundOpacity The opacity of the rectangle's background (0-1).
     * @param {number} borderWidth The width of the rectangle's border.
     * @param {number} borderOpacity The opacity of the rectangle's border (0-1).
     */

    draw_rect_with_border(
        shape,
        color = [0, 0, 0],
        backgroundOpacity = 0.75,
        borderWidth = 2,
        borderOpacity = 0.1,
    ) {
        const x = shape.p.x;
        const y = shape.p.y;
        const width = shape.w;
        const height = shape.h;

        this.ctx.fillStyle = `rgba(${color.join(", ")}, ${backgroundOpacity})`;
        this.ctx.fillRect(x, y, width, height);

        this.ctx.lineWidth = borderWidth;
        this.ctx.strokeStyle = `rgba(${color.join(", ")}, ${borderOpacity})`;
        this.ctx.strokeRect(x, y, width, height);
    }
    /**
     *
     * @param {string} string
     * @param {c2.Point} position
     * @param {number} size
     * @param {number} drawScope
     */
    draw_text(string, position, size = 15, drawScope = DrawScope.Normal) {
        if (this.drawScope < drawScope) {
            return;
        }
        this.ctx.font = size + "px Georgia";
        this.ctx.fillStyle = "white";
        this.ctx.fillText(string, position.x, position.y);
        var textWidth = Math.floor(this.ctx.measureText(string).width);
        return textWidth;
    }
    /**
     *
     * @param {string} string
     * @param {c2.Point} position
     * @param {number} size
     * @param {string} color
     * @param {number} drawScope
     * @returns
     */
    draw_text_with_newlines(
        string,
        position,
        size = 15,
        color = "white",
        drawScope = DrawScope.Normal,
    ) {
        if (this.drawScope < drawScope) {
            return;
        }
        this.ctx.font = size + "px Georgia";
        this.ctx.fillStyle = color;

        var lines = string.split("\n");
        var lineHeight = size * 1.2;

        for (let i = 0; i < lines.length; i++) {
            this.ctx.fillText(lines[i], position.x, position.y + i * lineHeight);
        }

        //var textWidth = Math.floor(this.ctx.measureText(string).width);
        //return textWidth;
    }
    /**
     *
     * @param {c2.Point} point
     * @param {number} amount
     * @returns {c2.Point}
     */
    move_point_up(point, amount) {
        let result = point.copy();
        result.y -= amount;
        return result;
    }
    /**
     *
     */
    invalidate() {
        //this.screenElement.invalidate();

        for (let eachElement in this.elements) {
            let element = this.elements[eachElement];
            element.invalidate();
        }
    }
    /**
     *
     */
    invaidate() {
        this.invalidate();
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
    /**
     *
     * @param {DragEvent} ev
     * @returns {Array.<object>|undefined}
     */
    collectFilesFromDragdropEvent(ev) {
        var files = [];
        if (!ev.dataTransfer) {
            return;
        }

        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item, i) => {
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    files.push({ file: file, dataTransfer: item });
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach((file, i) => {
                files.push({ file: file });
            });
        }
        return files;
    }
    /**
     *
     * @param {DragEvent} ev
     */
    drop_file(ev) {
        ev.preventDefault();

        var files = this.collectFilesFromDragdropEvent(ev);

        this.file_dropped(ev, files);
        this.try_invalidated_draw();
    }
    /**
     *
     * @param {DragEvent} ev
     */
    drag_file(ev) {
        ev.preventDefault();

        var files = this.collectFilesFromDragdropEvent(ev);

        this.file_dragged(ev, files);
        this.try_invalidated_draw();
    }
    /**
     *
     * @param {DragEvent} e
     * @param {Array.<object>|undefined} files
     */
    file_dropped(e, files) {
        for (let i in this.components) {
            var each = this.components[i];
            if (each.file_dropped) {
                each.file_dropped(e, files);
            }
        }
    }
    /**
     *
     * @param {DragEvent} e
     * @param {Array.<object>|undefined} files
     */
    file_dragged(e, files) {
        for (let i in this.components) {
            var each = this.components[i];
            if (each.drag_file) {
                each.drag_file(e, files);
            }
        }
    }
    /**
     *
     * @param {any} value
     * @param {any} value_context
     */
    activate(value, value_context) {
        if (value == "interactive.input" && value_context?.keydown) {
            let e = {};
            e.key = value_context.keydown;
            this.keydown(e);
            return;
        }

        let event = new InteractiveEvent();
        event.activate_value = value;
        event.activate_value_context = value_context;

        for (var each = this.components.length - 1; each >= 0; each--) {
            let c = this.components[each];
            if (!c.activate) {
                continue;
            }
            c.activate(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    /**
     *
     */
    startBatchUpdate() {
        this.isBatchUpdating = true;
        this.batchUpdatePromises = [];
    }
    /**
     *
     */
    endBatchUpdate() {
        this.try_invalidated_draw();

        this.isBatchUpdating = false;

        if (this.batchUpdatePromises.length == 0) {
            return;
        }

        return Promise.all(this.batchUpdatePromises).then(() => {
            this.try_invalidated_draw();
        });
    }

    /**
     *
     * @param {number} milliseconds
     */
    addTimer(milliseconds) {
        let update_context = new UpdateContext();
        update_context.interval = milliseconds;
        update_context.start_time = Date.now();
        update_context.time = update_context.start_time;
        update_context.deltaTime = update_context.time - update_context.start_time;
        update_context.isDrawFrame = false;

        let timer = setTimeout(() => {
            this.update(update_context);
        }, milliseconds);

        this.timers[milliseconds] = timer;
    }

    /**
     *
     */
    initializeVideoBuffers() {
        if (this.video_buffers) {
            this.video_buffers.icanvas = this;
            this.video_buffers.initializeVideoBuffers();
        }
    }

    unregister_video_buffer_index(subject, index) {
        this.video_buffers?.unregister_video_buffer_index(subject, index);
    }
    is_registered_video_buffer_index_as(subject, index) {
        return this.video_buffers?.is_registered_video_buffer_index_as(subject, index);
    }
    register_next_video_buffer(subject) {
        return this.video_buffers?.register_next_video_buffer(subject);
    }
    clear_buffer_by_index(index) {
        this.video_buffers?.clear_buffer_by_index(index);
    }
    swap_buffer_to_index(index) {
        this.video_buffers?.swap_buffer_to_index(index);
    }
    get_next_video_buffer_index(index) {
        return this.video_buffers?.get_next_video_buffer_index(index);
    }
    get_video_buffer(index) {
        return this.video_buffers?.get_video_buffer(index);
    }
    onCanvasResizedForVideoBuffers() {
        this.video_buffers?.onCanvasResizedForVideoBuffers();
    }
}
