//@ts-check
import { CanvasElement } from "./CanvasElement.js";

/**
 * 
 */
export class HTML5CanvasElement extends CanvasElement {
  /**
  *  @type {HTMLMediaElement|HTMLImageElement}
  */
  element;

  /**
   * 
  * @param {import('../resources/resourceInterface').ResourceInterface|undefined} resource 
   * @param {HTMLMediaElement|HTMLImageElement} element 
   * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} resource_canvas_element 
   * @param {boolean} is_invalidating_draw 
   */
  constructor(resource, element, resource_canvas_element, is_invalidating_draw = true) {
    super(resource, resource_canvas_element, is_invalidating_draw);
    this.element = element;
  }
  /**
   * 
   * @returns {string|undefined}
   */
  toSourceURL() {
    return this.element?.currentSrc;
  }
}
