import { v4 as uuidv4 } from "uuid";
import { UserInterfaceElement } from "./UserInterfaceElement.js";

export class TextboxUserInterfaceElement extends UserInterfaceElement {
    content;
    element;
    constructor(elements, json) {
        super(elements, json);
    }

    addToDOM() {
        let id = uuidv4();

        this.element = Object.assign(document.createElement("input"), {
            id: id,
        });
        //this.element.style.width="100%";
        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }
        this.elements.div.appendChild(this.element);
    }
    value_string() {
        return this.element.value;
    }
}
