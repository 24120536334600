//@ts-check

// /* context

// https://rotato.app/blog/transparent-videos-for-the-web
// https://www.tutorialspoint.com/How-do-I-make-a-transparent-canvas-in-HTML5
// https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Manipulating_video_using_canvas

// */

import { WebApplication } from "../../../webApplication.js";
import { VideoDisplayToElement } from "./VideoDisplayToElement.js";
/**
 *
 */
export class VideoFileToCanvasElement extends VideoDisplayToElement {
    /**
     *  @type {import('../../../ValueChangedSubscriptions').onValueChanged|undefined}
     */
    onVideoFramesPerSecondSettingChangedSubscription;
    /**
     *
     * @param {import('./VideoFileToElement.js').VideoFileToElement} video_file_to_element
     */
    constructor(video_file_to_element) {
        super(video_file_to_element);
    }
    /**
     * @returns {CanvasImageSource}
     */
    get element_imageSource() {
        var r = /** @type {CanvasImageSource} */ (this.element);
        return r;
    }

    /**
     *
     * @param {any} value
     */
    onVideoFramesPerSecondSettingChanged(value) {
        var fps = this.GetFPSSetting();
        this.video_file_to_element.setAutoInvalidateFrequencyPerSecond(fps);
    }
    /**
     *
     */
    addedToInteractiveCanvas() {
        //console.log("video:canvas")
        this.onVideoFramesPerSecondSettingChangedSubscription = (v) => {
            this.onVideoFramesPerSecondSettingChanged(v);
        };
        this.icanvas?.State.application.addSettingSubscription(
            WebApplication.VideoFramesPerSecondSettingName,
            this.onVideoFramesPerSecondSettingChangedSubscription,
        );

        var fps = this.GetFPSSetting();
        this.video_file_to_element.setAutoInvalidateFrequencyPerSecond(fps);
    }
    /**
     *
     */
    removedFromInteractiveCanvas() {
        this.video_file_to_element.setAutoInvalidateFrequencyPerSecond(0);
        this.icanvas?.State.application.removeSettingSubscription(
            WebApplication.VideoFramesPerSecondSettingName,
            this.onVideoFramesPerSecondSettingChangedSubscription,
        );
        this.onVideoFramesPerSecondSettingChangedSubscription = undefined;
    }
    /**
     *
     * @returns {number}
     */
    GetFPSSetting() {
        var fps =
            this.icanvas?.State.application.getSetting(
                WebApplication.VideoFramesPerSecondSettingName,
            ) || 30;
        fps = Math.min(120, fps);
        fps = Math.max(0, fps);
        return fps;
    }
    /**
     *
     */
    draw() {
        if (!this.icanvas || !this.video_file_to_element.resource_canvas_element) {
            return;
        }

        this.icanvas.draw_log.push({
            message: "video",
            details: this.element.currentSrc?.split("/").pop(),
        });

        let image_rect =
            this.video_file_to_element.resource_canvas_element.geometry.get_absolute_rect_shape();

        this.icanvas.ctx.drawImage(this.element_imageSource, 0, 0, image_rect.w, image_rect.h);
    }
}
