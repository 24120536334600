import { KeyCodes } from "../input/KeyCodes";

export class InteractiveInput {
    static typename = "interactive.input";

    interactive;
    verb;
    verb_value;

    constructor(interactive, json) {
        this.interactive = interactive;
        const [firstProperty, firstValue] = Object.entries(json)[0];
        this.verb = firstProperty;
        this.verb_value = firstValue;
    }

    drawFrame(icanvas) {}

    keydown = (ievent) => {
        this.interactive.scene.simulation.selectionMode.onInteractiveInputKeydown(ievent, this);
    };

    keyup = (ievent) => {
        this.interactive.scene.simulation.selectionMode.onInteractiveInputKeyup(ievent, this);
    };
    activate(ievent) {
        for (const property in ievent.activate_value_context) {
            if (
                this.verb === property &&
                this.verb_value === ievent.activate_value_context[property]
            ) {
                this.interactive.onInteractiveInput(ievent, this);
                break;
            }
        }
    }

    onTouchTap(ievent) {}
    onTouchPan(ievent) {}
    onTouchSwipe(ievent) {
        let event = ievent.e.events[0];
        let data = ievent.e.data[0];
        let dir = data.currentDirection;

        // Correctly assigning a single key string based on swipe direction
        if (dir > 180 - 20 && dir < 180 + 20) {
            ievent.e.keyCode = KeyCodes.ArrowRight;
        } else if (dir > 360 - 20 || dir < 20) {
            ievent.e.keyCode = KeyCodes.ArrowLeft;
        } else if (dir > 270 - 20 && dir < 270 + 20) {
            ievent.e.keyCode = KeyCodes.ArrowUp;
        } else if (dir > 90 - 20 && dir < 90 + 20) {
            ievent.e.keyCode = KeyCodes.ArrowDown;
        }

        if (ievent.e.keyCode) {
            this.keydown(ievent); // Process the synthesized key press
        }
    }
    onTouchDistance(ievent) {}
    onTouchRotate(ievent) {}
    onTouchGesture(ievent) {}
}
