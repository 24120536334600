//@ts-check
import { Action } from "../actions/action";
/**
 *
 */
export class OpenFullscreenModuleAction extends Action {
    /**
     * @type {any}
     */
    value;
    /**
     * @type {any}
     */
    value_context;
    /**
     *
     * @param {string} verb
     * @param {string|undefined} name
     * @param {*} json
     */
    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }
    /**
     *
     * @param {import('../scene').SceneObjectInterface} interactive
     */
    run(interactive) {
        console.log("open fullscreen module");
        const loginModule = interactive.scene.icanvas.externalModules.findOpenModule("login");
        if (loginModule) {
            interactive.scene.icanvas.externalModules.closeModule(loginModule);
        }
        const module = interactive.scene.icanvas.externalModules.openModule(this.value, true);
        if (module) {
            module.data_context = this.value_context;
            var account_rewards = interactive.simulation.account.addTokenRewardSource(module.name);
            module.addActivationRewards(account_rewards);
        }
    }
}
