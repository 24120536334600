//@ts-check

import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope";

/**
 * @typedef ScenePathInterface
 * @property {string|undefined} sceneGraphName
 * @property {string|undefined} sceneName
 */

/**
 *
 */
export class ScenePath {
    /**
     *
     * @type {string|undefined}
     */
    sceneGraphName;
    /**
     *
     * @type {string|undefined}
     */
    sceneName;
    /**
     *
     * @param {string|undefined} sceneGraphName
     * @param {string|undefined} sceneName
     */
    constructor(sceneGraphName = undefined, sceneName = undefined) {
        this.sceneGraphName = sceneGraphName;
        this.sceneName = sceneName;
    }
    /**
     *
     * @param {object} json
     * @returns
     */
    static onlySceneGraphNameFromJsonPath(json) {
        return new ScenePath(json.sceneGraphName);
    }
    /**
     *
     * @returns {AudioListenerScope}
     */
    toAudioScope() {
        return new AudioListenerScope(this.sceneGraphName, this.sceneName);
    }
    /**
     *
     * @param {object} a
     * @param {object} b
     * @returns
     */
    static areLocationsTheSame(a, b) {
        return a.sceneGraphName === b.sceneGraphName && a.sceneName === b.sceneName;
    }
}
