// @ts-check

import { StackLayout } from "../../view/stackLayout";
import { NullConnectedStems } from './NullConnectedStems';

import { PlaylistManager } from "./playlistAPI/playlistManager.js";
import { getRadioSettings } from "./playlistAPI/Radio.js";
/**
 * 
 */
export class NullWebAudio {
  /**
* 
* @type {NullConnectedStems}
*/
  connectedStems;
  /** 
   * stores a reference to the playlist manager object
   * @type {PlaylistManager} 
   */
  playlistManager
  /**
 * 
 * @param {import('../../resources.js').Resources} resources 
 * @param {import('../../customerAccount.js').CustomerAccount} account 
 */
  constructor(resources, account) {
    this.connectedStems = new NullConnectedStems();
    //this.playlistManager = new PlaylistManager("playlists", getRadioSettings)
  }

  connectNodeToLayer(layer, node) {
  }

  initialize() {
    console.log("No Audio");
  }

  tryStartAudio() {
  }

  uninitialize() { }

  start() { }

  deactivate() {
  }

  reactivate() {
  }

  stroageItemName() {
  }

  saveState() {
  }

  shutdown() {
  }

  playSoundEffects(stems) {
  }

  startAudioOnGesture() {
  }


  drawFrame(icanvas) { }
  mousedown(icanvas, e) {
    this.startAudioOnGesture();
  }
  mouseup(icanvas, e) { }
  mousemove(icanvas, e) { }
  keydown(icanvas, e) {
    this.startAudioOnGesture();
  }
  onTouchTap(e) {
    this.startAudioOnGesture();
  }
  onTouchPan(e) { }
  onTouchSwipe(e) {
    this.startAudioOnGesture();
  }
  onTouchDistance(e) { }
  onTouchRotate(e) { }
  onTouchGesture(e) { }

  collectEditableProperties(layout) {
  }

  getAuthorInterfaceName() {
    return "audio";
  }
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }
}
