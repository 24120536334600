//@ts-check

import { AudioListenerScope } from './AudioListenerScope';

/**
 * 
 */
export class AmbianceContext {
    /**
   * 
   * @type {AudioListenerScope|undefined}
   */
  scope;

  /**
   * 
   * @param {AudioListenerScope|undefined} scope 
   */
  constructor(scope = undefined) {
    this.scope = scope;
  }
  /**
   * 
   * @returns {any}
   */
  get area() {
    return this.scope?.area;
  }
  /**
   * 
   * @returns {any}
   */
  get region() {
    return this.scope?.region;
  }
    /**
   * 
   * @returns {string|undefined}
   */
  get regionName() {
    return this.region?.name;
  }
    /**
   * 
   * @returns {string|undefined}
   */
  get areaName() {
    return this.area?.name;
  }
}
