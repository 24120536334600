// @ts-check
import { SceneObjects } from "./scene_objects.js";
import { SceneObject } from "./SceneObject.js";
import { Interactives } from "./interactives.js";
import { AudioAmbiance } from "../audio_visual/audio/audioAmbiance.js";
import { Interactive } from "./Interactive.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope.js";
import { AmbianceContext } from "../audio_visual/audio/AmbianceContext.js";
import { Actions } from "./actions/actions.js";
import { RectangleGeometry } from "../geometry/RectangleGeometry.js";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector.js";
import { CustomerAccount } from "../customerAccount.js";
import { Resources } from "../resources.js";
import { WebApplication } from "../webApplication.js";
import { VisualElements } from "../audio_visual/visual/VisualElements.js";
import { LocalServerFileCache } from "../LocalServerFileCache.js";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.js";
import { InteractiveEvent } from "./InteractiveEvent.js";
import { DragDropEvent } from "./DragDropEvent.js";
let c2 = require("c2.js");
let geometry_js = require("../geometry.js");

/**
 * @callback getResourcePath_SceneObjectInterface
 * @returns {string}
 */
/**
 * @callback toScenePath_SceneObjectInterface
 * @returns {ScenePath}
 */
/**
 * @callback toAudioContext_SceneObjectInterface
 * @returns {AmbianceContext}
 */
/**
 * @callback isFadedOut_SceneObjectInterface
 * @returns {boolean}
 */
/**
 * @callback runActionJson_SceneObjectInterface
 * @param {object} action
 */
/**
 * @typedef SceneObjectInterface
 * @property {import('../audio_visual/visual/visualElement.js').VisualElement|undefined} visual_element
 * @property {SceneObjectInterface} scene
 * @property {InteractiveCanvas} icanvas
 * @property {Resources} resources
 * @property {isFadedOut_SceneObjectInterface} isFadedOut
 * @property {toScenePath_SceneObjectInterface} toScenePath
 * @property {import('./scene_graph.js').SceneGraph} sceneGraph
 * @property {import('./scene_graph_node.js').SceneGraphNode|undefined} [scene_graph_node]
 * @property {toAudioContext_SceneObjectInterface} [toAudioContext]
 * @property {import('../audio_visual/audio/audioAmbiance.js').AudioAmbiance} [audioAmbiance]
 * @property {RectangleGeometry} geometry
 * @property {RectangleGeometry} [visual_geometry]
 * @property {WebApplication} application
 * @property {VisualElements|undefined} visualElements
 * @property {LocalServerFileCache} server_file_cache
 * @property {import('../simulation.js').Simulation} simulation
 * @property {number} draw_order
 * @property {getResourcePath_SceneObjectInterface} getResourcePath
 * @property {runActionJson_SceneObjectInterface} runActionJson
 */

/**
 *
 */
export class Scene {
    /**
     *
     * @type {import('./scene_graph.js').SceneGraph}
     */
    scene_graph;
    /**
     *
     * @type {import('./scene_graph_node.js').SceneGraphNode|undefined}
     */
    scene_graph_node;
    /**
     *
     * @type {string}
     */
    name;
    /**
     *
     * @type {object}
     */
    json;
    /** @type {VisualElement} */
    visual_element;
    /**
     *
     * @type {import('../audio_visual/audio/audioAmbiance.js').AudioAmbiance}
     */
    audioAmbiance;
    /**
     *
     * @type {import('./scene_objects.js').SceneObjects}
     */
    objects;
    /**
     *
     * @type {import('./InteractiveLayers.js').InteractiveLayers}
     */
    interactiveLayers;
    /**
     *
     * @type {boolean}
     */
    isVisualError = false;
    /**
     *
     * @type {import('../geometry/RectangleGeometry.js').RectangleGeometry}
     */
    geometry;
    /**
     * @type {import('../geometry/RectangleGeometry.js').RectangleGeometry}
     */
    visual_geometry;
    /**
     *
     * @param {import('./scene_graph.js').SceneGraph} scene_graph
     * @param {string} name
     * @param {object} json
     */
    constructor(scene_graph, name, json) {
        this.scene_graph = scene_graph;
        this.name = name;
        this.json = json;

        this.initializeFromJson();

        const playlistConfig = this.scene_graph.resources.fetched_json.playlist;
        this.audioAmbiance = new AudioAmbiance(
            this.toAudioContext(),
            this.json.audioAmbiance,
            this.getResourcePath.bind(this),
            playlistConfig,
        );
    }
    /**
     *
     * @returns {boolean}
     */
    isFadedOut() {
        if (this.sceneInteractiveLayer.isFadedOut) {
            return true;
        }
        return false;
    }
    /**
     *
     * @param {Array} stems
     */
    onPlaySoundEffects(stems) {
        this.audioAmbiance.onPlaySoundEffects(stems);
    }
    /**
     *
     * @returns {boolean}
     */
    isVisualContentReady() {
        if (!this.visual_element?.isReadyOrEmpty()) {
            return false;
        }
        if (this.objects && !this.objects.isVisualContentReady()) {
            return false;
        }

        return this.interactiveLayers.isVisualContentReady();
    }
    /**
     * @returns {import('./InteractiveLayer.js').InteractiveLayer}
     */
    get sceneInteractiveLayer() {
        return this.interactiveLayers.default_layer;
    }
    /**
     * @returns {import('./interactives.js').Interactives}
     */
    get sceneInteractiveLayerInteractives() {
        return this.sceneInteractiveLayer.interactives;
    }
    /**
     * @returns {import('../audio_visual/visual/VisualElements.js').VisualElements|undefined}
     */
    get visualElements() {
        return this.scene_graph_node?.visualElements;
    }
    /**
     * @returns {import('../resources.js').Resources}
     */
    get resources() {
        return this.scene_graph.resources;
    }
    /**
     * @returns {import('../audio_visual/interactive_canvas.js').InteractiveCanvas}
     */
    get icanvas() {
        return this.sceneGraph.icanvas;
    }
    /**
     * @returns {import('../simulation.js').Simulation}
     */
    get simulation() {
        return this.scene_graph.simulation;
    }
    /**
     * @returns {import('./scene_graph.js').SceneGraph}
     */
    get sceneGraph() {
        return this.scene_graph;
    }
    /**
     *
     * @type {import('./scene_graph_node.js').SceneGraphNode|undefined}
     */
    get sceneGraphNode() {
        return this.scene_graph_node;
    }
    /**
     *
     * @type {number}
     */
    get sceneLayerOrder() {
        return this.json.sceneLayerOrder;
    }
    /**
     *
     * @type {SceneObjectInterface}
     */
    get scene() {
        return this;
    }
    /**
     *
     * @type {SceneObjectInterface}
     */
    get localScene() {
        return this;
    }
    /**
     *
     * @type {SceneObjectInterface}
     */
    get globalScene() {
        return this;
    }
    /**
     * @returns {import('../webApplication.js').WebApplication}
     */
    get application() {
        return this.scene_graph.application;
    }
    /**
     * @returns {import('../webApplicationServer.js').WebApplicationServer}
     */
    get server() {
        return this.scene_graph.simulation.server;
    }
    /**
     * @returns {import('../LocalServerFileCache.js').LocalServerFileCache}
     */
    get server_file_cache() {
        return this.server.server_file_cache;
    }
    /**
     * @returns {number}
     */
    get draw_order() {
        return this.sceneLayerOrder;
    }

    // get geometry_isRelativeTo() {
    //   return undefined;
    // }
    /**
     *
     */
    initializeFromJson() {
        if (this.json.sceneLayerOrder == undefined) {
            this.json.sceneLayerOrder = 1;
        }

        this.interactiveLayers = new InteractiveLayers(this);

        this.objects?.initializeFromJson();

        this.interactiveLayers.setDefaultLayer(this.json);
        this.interactiveLayers.initializeFromJson();

        if (this.json.inactivityFadeoutSeconds != undefined) {
            this.interactiveLayers.default_layer.json.inactivityFadeoutSeconds =
                this.json.inactivityFadeoutSeconds;
        }
    }
    /**
     *
     * @param {import('./InteractiveEvent.js').InteractiveEvent} ievent
     */
    activate_event(ievent) {
        this.interactiveLayers.activate_event(ievent);
    }
    /**
     *
     * @param {any} value
     * @param {any} value_context
     */
    activate(value, value_context) {
        this.interactiveLayers.activate(value, value_context);
    }
    /**
     *
     * @returns {getResourcePath_SceneObjectInterface}
     */
    getResourcePathFunction() {
        return this.getResourcePath.bind(this);
    }
    /**
     *
     * @returns {AmbianceContext}
     */
    toAudioContext() {
        var scope = AudioListenerScope.fromScenePath(this.toScenePath(), this, this.sceneGraph);
        var audioContext = new AmbianceContext(scope);
        return audioContext;
    }
    /**
     *
     * @returns {ScenePath}
     */
    toScenePath() {
        return new ScenePath(this.scene_graph.name, this.name);
    }
    /**
     *
     * @param {InteractiveLayerJson} item
     */
    push(item) {
        if (item instanceof InteractiveLayerJson) {
            this.interactiveLayers.pushLayer(item.json);
        }
    }
    /**
     *
     */
    removeTemporary() {
        this.sceneInteractiveLayer.removeTemporary();
    }
    /**
     *
     * @returns {boolean}
     */
    canNavigateBack() {
        return this.simulation.player.canNavigateBack();
    }
    /**
     *
     */
    navigateBack() {
        this.simulation.player.navigateBack();
    }
    /**
     *
     */
    navigateHome() {
        this.simulation.player.navigateHome();
    }
    /**
     *
     * @param {string} scene_name
     * @param {string|undefined} scene_graph_name
     */
    navigate(scene_name, scene_graph_name = undefined) {
        if (scene_graph_name == undefined) {
            scene_graph_name = this.sceneGraph.name;
        }
        this.simulation.player.startChangeLocation(new ScenePath(scene_graph_name, scene_name));
    }
    /**
     *
     * @param {import('./ScenePath.js').ScenePathInterface|undefined} previousPath
     */
    graphStarted(previousPath = undefined) {}

    /**
     *
     * @param {import('./GettingReadyCallbackCollector.js').GettingReadyCallbackCollector} gettingReadyCallbackCollector
     */
    startVisualContentGetReady(gettingReadyCallbackCollector) {
        if (!this.visual_element) {
            this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
        }

        this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
            this.json,
            this,
            undefined,
            undefined,
            geometry_js.Geometry.CoordinatesType.ABSOLUTE,
            geometry_js.Geometry.CoordinatesType.ABSOLUTE,
        );
        this.geometry.letterboxInto = this.icanvas;

        //var test_rect = this.geometry.get_absolute_shape();

        this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);

        this.objects?.startVisualContentGetReady(gettingReadyCallbackCollector);
        this.interactiveLayers.startVisualContentGetReady(gettingReadyCallbackCollector);
    }
    /**
     *
     * @param {SceneObjectInterface|undefined} previousScene
     * @returns
     */
    start(previousScene = undefined) {
        if (!this.visual_element) {
            console.info("loading scene: " + this.name);
            this.callback_collector = new GettingReadyCallbackCollector();
            this.startVisualContentGetReady(this.callback_collector);
            this.callback_collector_promise = this.callback_collector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promise = undefined;
                    this.callback_collector = undefined;
                    this.start(previousScene);
                    this.icanvas.try_invalidated_draw();
                });
            return;
        }

        console.info("start scene: " + this.name);

        this.visual_element.start();

        this.objects?.start();
        this.interactiveLayers.start();

        this.scene_graph_node?.listener.addAudioAmbiance(this.audioAmbiance);

        if (this.json["scene.event.start"]) {
            var actions = new Actions();
            actions.parseJson(this.json["scene.event.start"], this.application);
            actions.runActions(this);
        }
    }

    /**
     *
     * @param {import('../audio_visual/visual/resources/resourceInterface.js').ResourceInterface} resource
     */
    onVisualError(resource) {
        this.isVisualError = true;
    }
    /**
     *
     * @param {import('../audio_visual/visual/resources/resourceInterface.js').ResourceInterface} resource
     * @param {boolean} success
     */
    onVisualDisplayed(resource, success) {
        if (success && !this.isVisualError) {
            console.info("scene visual is displayed");
        }
    }
    /**
     *
     * @param {Scene|undefined} nextScene
     */
    stop(nextScene) {
        console.info("stop scene: " + this.name);
        this.objects?.stop();
        this.interactiveLayers.stop();
        var next_vis = nextScene?.visual_element;
        this.visual_element.stop(next_vis?.firstResourceCanvasElement());
        this.scene_graph_node?.listener.removeAudioAmbiance(
            this.audioAmbiance,
            nextScene?.audioAmbiance,
        );
        this.removeTemporary();
    }
    /**
     *
     * @param {import('./ScenePath.js').ScenePathInterface|undefined} nextPath
     */
    graphStopped(nextPath = undefined) {}
    /**
     *
     * @returns {string}
     */
    getResourcePath() {
        return this.json.resourcePath || this.scene_graph.json.resourcePath;
    }

    // deriveRectForScene(scaleToCanvas = true) {

    //   var defaultW = 3840;
    //   var defaultH = 2160;

    //   if (scaleToCanvas) {
    //     var hRatio = this.simulation.icanvas.get_width() / defaultW;
    //     var vRatio = this.simulation.icanvas.get_height() / defaultH;
    //     var ratio = Math.min(hRatio, vRatio);
    //     return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
    //   }

    //   return new c2.Rect(0, 0, defaultW, defaultH);
    // }

    // toRect(scaleToCanvas = true) {
    //   if (scaleToCanvas && this.scene_graph_node == undefined) {
    //     return new c2.Rect(0, 0, 0, 0)
    //   }

    //   if (this.visual_element.isReady()) {
    //     var visual_rect = this.visual_element.toRect(scaleToCanvas);
    //     if (visual_rect) {
    //       return visual_rect;
    //     }
    //   }

    //   return this.deriveRectForScene(scaleToCanvas);
    // }

    // getScreenSpaceAreaRect() {
    //   return this.toRect();
    // }
    /**
     *
     * @returns {c2.Rect}
     */
    getScreenSpaceAreaRect() {
        return this.geometry.get_absolute_rect_shape();
    }
    /**
     *
     * @param {import('../audio_visual/interactive_canvas.js').InteractiveCanvas} icanvas
     */
    drawFrame(icanvas) {
        if (this.scene_graph_node == undefined) {
            return;
        }

        this.interactiveLayers.drawFrame(icanvas);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mousedown(ievent) {
        this.interactiveLayers.mousedown(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mouseup(ievent) {
        this.interactiveLayers.mouseup(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    mousemove(ievent) {
        if (!this.isIEventOnGeometry(ievent)) {
            return;
        }

        this.interactiveLayers.mousemove(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    keydown(ievent) {
        this.interactiveLayers.keydown(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    keyup(ievent) {
        this.interactiveLayers.keyup(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchTap(ievent) {
        this.interactiveLayers.onTouchTap(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchPan(ievent) {
        this.interactiveLayers.onTouchPan(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchSwipe(ievent) {
        this.interactiveLayers.onTouchSwipe(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchDistance(ievent) {
        this.interactiveLayers.onTouchDistance(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchRotate(ievent) {
        this.interactiveLayers.onTouchRotate(ievent);
    }
    /**
     * @param {InteractiveEvent} ievent
     */
    onTouchGesture(ievent) {
        this.interactiveLayers.onTouchGesture(ievent);
    }
    /**
     * @param {DragDropEvent} ievent
     */
    file_dropped(ievent) {
        this.interactiveLayers.file_dropped(ievent);
    }
    /**
     * @param {DragDropEvent} ievent
     */
    drag_file(ievent) {
        this.interactiveLayers.drag_file(ievent);
    }
    /**
     *
     * @param {object} file
     * @returns {string}
     */
    static getNameFromFilename(file) {
        return file.name;
    }
    /**
     *
     * @param {object} file
     * @returns {object}
     */
    static newJsonFromFile(file) {
        return {};
    }
    /**
     *
     * @param {string} name
     * @param {boolean} isTemporary
     * @returns {import('./Interactive.js').Interactive}
     */
    findInteractiveByName(name, isTemporary) {
        return this.interactiveLayers.default_layer.findInteractiveByName(name, isTemporary);
    }
    /**
     *
     * @param {MouseEvent} e
     * @returns {c2.Point}
     */
    convertEventWithPointToRelativePoint(e) {
        var asMouse = { x: e.offsetX, y: e.offsetY };

        var rect = this.geometry.shape;

        var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

        return result;
    }
    /**
     *
     * @param {InteractiveEvent} ievent
     * @returns
     */
    isIEventOnGeometry(ievent) {
        var geometry = this.getScreenSpaceAreaRect();
        let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
        return geometry.intersects(mouse_point);
    }

    /**
     *
     * @param {object} action
     */
    runActionJson(action) {
        var actions = new Actions();
        actions.parseJson(action, this.application);
        actions.runActions(this);
    }
}
