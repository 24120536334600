//@ts-check
import { CustomerAccountTokenRewards } from "./customerAccount/CustomerAccountTokenRewards";
import { Treeview } from "./view/treeview";
import { DataLocation } from "./webApplicationServer";
/**
 *
 */
export class CustomerAccount {
    /**
     *
     * @type {import('./resources').Resources}
     */
    resources;
    /**
     *
     * @type {string}
     */
    name;
    /**
     *
     * @type {object}
     */
    json;
    /**
     *
     * @type {import('./webApplicationServer').WebApplicationServer}
     */
    server;
    /**
     *
     * @type {import('./webApplication').WebApplication}
     */
    application;
    /**
     *
     * @type {number}
     */
    location;
    /**
     *
     * @type {string}
     */
    static guest_account_id = "guest";
    /**
     *
     * @type {Array.<CustomerAccountTokenRewards>}
     */
    token_rewards = [];
    /**
     *
     * @param {import('./resources').Resources} resources
     * @param {import('./webApplication').WebApplication} application
     * @param {import('./webApplicationServer').WebApplicationServer} server
     * @param {string} name
     */
    constructor(resources, application, server, name) {
        this.resources = resources;
        this.name = name;
        this.application = application;
        this.server = server;
        this.location = DataLocation.None;
    }
    /**
     * @returns {object}
     */
    get json_player() {
        let result = this.json.player;
        if (result == undefined) {
            result = this.json.player = {};
        }
        return result;
    }
    /**
     * @returns {object}
     */
    get json_simulation() {
        let result = this.json.simulation;
        if (result == undefined) {
            result = this.json.simulation = {};
        }
        return result;
    }
    /**
     *
     * @param {string} name
     * @returns {CustomerAccountTokenRewards}
     */
    addTokenRewardSource(name) {
        var result = new CustomerAccountTokenRewards(this, name);
        this.token_rewards.push(result);
        return result;
    }
    /**
     *
     * @param {import('./resources').Resources} resources
     * @param {import('./webApplication').WebApplication} application
     * @param {import('./webApplicationServer').WebApplicationServer} server
     * @param {object} data
     * @returns {CustomerAccount}
     */
    static fromServerData(resources, application, server, data) {
        let result = new CustomerAccount(resources, application, server, data.name);
        result.json = data;
        result.location = DataLocation.Cloud;

        if (
            /*application.getSetting("isLoadLocalStorage") &&*/ application.getSetting(
                "isLoadAccountLocalStorage",
            )
        ) {
            if (result.json.name == CustomerAccount.guest_account_id) {
                let from_storage = result.resources.getLocalStorageJsonItem(
                    result.stroageItemName(),
                );
                if (from_storage) {
                    result.json = from_storage;
                }
            }
        }

        return result;
    }
    /**
     *
     * @param {import('./resources').Resources} resources
     * @param {import('./webApplication').WebApplication} application
     * @param {import('./webApplicationServer').WebApplicationServer} server
     * @returns {CustomerAccount}
     */
    static fromGuest(resources, application, server) {
        let result = new CustomerAccount(
            resources,
            application,
            server,
            CustomerAccount.guest_account_id,
        );
        result.json = {
            name: CustomerAccount.guest_account_id,
        };
        result.location = DataLocation.Client;

        if (
            /*application.getSetting("isLoadLocalStorage") &&*/ application.getSetting(
                "isLoadAccountLocalStorage",
            )
        ) {
            if (result.json.name == CustomerAccount.guest_account_id) {
                let storage_name = result.stroageItemName();
                let from_storage = result.resources.getLocalStorageJsonItem(storage_name);
                if (from_storage) {
                    result.json = from_storage;
                }
            }
        }
        return result;
    }
    /**
     *
     * @returns {string}
     */
    stroageItemName() {
        return this.resources.combineJsonResourceName(
            this.application.name,
            this.name,
            "account.storage",
        );
    }
    /**
     *
     */
    initialize() {
        if (this.application.getSetting("isLoadAccountLocalStorage")) {
            if (this.json == undefined) {
                this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
                this.location = DataLocation.Client;
            }
        }

        this.saveState();
    }
    /**
     *
     */
    saveState() {
        if (this.location == DataLocation.Cloud) {
            this.server.updateCustomerAccount(this.name, this.json);
        }
        if (this.application.getSetting("isSaveAccountLocalStorage")) {
            if (this.location == DataLocation.Client || this.location == DataLocation.Cloud) {
                this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
            }
        }
    }
    /**
     *
     */
    shutdown() {
        this.saveState();
    }
    /**
     *
     * @returns {string}
     */
    getAuthorInterfaceName() {
        return "Account";
    }
    /**
     *
     * @returns {HTMLElement}
     */
    createAuthorInterfaceElement() {
        let layout = new Treeview();
        layout.buildElementFromJson(this.json, 1);
        return layout.element;
    }
    /**
     *
     * @param {Treeview} treeview
     */
    addAuthorInterfaceElementToTreeview(treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, true);
    }
}
