//@ts-check

import { CustomerAccount } from "./customerAccount.js";
import Bowser from "bowser";
import { StackLayout } from "./view/stackLayout.js";
import { ReadonlyProperty } from "./view/propertyEditor.js";
import { Treeview } from "./view/treeview.js";
/**
 *
 */
export class WebBrowserClient {
    /**
     * @type {import('./resources').Resources}
     */
    resources;
    /**
     * @type {import('./webApplication.js').WebApplication}
     */
    application;
    /**
     * @type {object}
     */
    json;
    /**
     * @type {import('./webApplicationServer.js').WebApplicationServer}
     */
    server;
    /**
     * @type {Bowser.Parser.Parser}
     */
    browser;
    /**
     *
     * @param {import('./resources').Resources} resources
     * @param {import('./webApplication.js').WebApplication} application
     * @param {import('./webApplicationServer.js').WebApplicationServer} server
     */
    constructor(resources, application, server) {
        this.resources = resources;
        this.application = application;
        this.server = server;
        this.browser = Bowser.getParser(window.navigator.userAgent);
    }
    /**
     *
     * @returns {string}
     */
    stroageItemName() {
        return this.resources.combineJsonResourceName(this.application.name, "browser.storage");
    }
    /**
     *
     */
    initialize() {
        this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
    }
    /**
     *
     */
    saveState() {
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }
    /**
     *
     */
    shutdown() {
        this.saveState();
    }

    /**
     *
     * @param {StackLayout} layout
     */
    collectEditableProperties(layout) {
        let name_property = Object.assign(new ReadonlyProperty(), {
            name: "name",
            getValue: () => {
                return this.browser.getBrowserName();
            },
        });
        layout.addAsTableRow(name_property.getEditorElements());

        let version_property = Object.assign(new ReadonlyProperty(), {
            name: "version",
            getValue: () => {
                return this.browser.getBrowserVersion();
            },
        });
        layout.addAsTableRow(version_property.getEditorElements());
    }
    /**
     *
     * @returns {string}
     */
    getAuthorInterfaceName() {
        return "Browser";
    }
    /**
     *
     * @returns {HTMLElement}
     */
    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        return layout.element;
    }
    /**
     *
     * @param {Treeview} treeview
     */
    addAuthorInterfaceElementToTreeview(treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, true);
    }
}
