import { AmbianceContext } from "../audio/AmbianceContext";
import { AudioAmbiance } from "../audio/audioAmbiance";
import { AudioListenerScope } from "../audio/AudioListenerScope";
import { VisualElement } from "./visualElement";
import { VisualElements } from "./VisualElements";

/**
 *
 */
export class AudioVisualPlaybackQueueItem {
    /**
     * @type {GettingReadyCallbackCollector|undefined}
     */
    callback_collector;
    /**
     *
     * @type {Array.<object>}
     */
    stems;
    /**
     *
     * @type {VisualElement|undefined}
     */
    visual_element;

    /**
     *
     * @type {import('../audio/audioAmbiance').AudioAmbiance|undefined}
     */
    audio_element;

    /**
     *
     * @type {boolean}
     */
    is_looping = true;

    /**
     *
     * @type {boolean}
     */
    is_play_immediate = true;

    /**
     *
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj
     */
    start(obj) {
        if (this.visual_element) {
            this.visual_element.start();
        }
        if (this.audio_element) {
            obj.scene_graph_node?.listener.addAudioAmbiance(this.audio_element);
        }
    }
    /**
     *
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj
     * @param {AudioVisualPlaybackQueueItem|undefined} [next_queue_item]
     */
    stop(obj, next_queue_item) {
        if (this.visual_element) {
            this.visual_element.stop(undefined);
        }
        if (this.audio_element) {
            obj.scene_graph_node?.listener.removeAudioAmbiance(
                this.audio_element,
                next_queue_item?.audio_element,
            );
        }
    }
    /**
     *
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj
     * @param {import('../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector
     */
    start_loading(obj, gettingReadyCallbackCollector) {
        if (this.visual_element || this.audio_element) {
            this.visual_element?.startVisualContentGetReady(gettingReadyCallbackCollector);
            return;
        }

        for (const each of this.stems || []) {
            if (VisualElements.VideoVisualResourceType?.canCreateResourceFromJsonObject(each)) {
                if (!this.visual_element) {
                    each["video.play_immediate"] = this.is_play_immediate;
                    if (this.is_looping == false) {
                        each["video.loop"] = false;

                        each["video.event.playedOnce"] = {
                            actions: [
                                {
                                    verb: "audio_visual_script.next_queue_item",
                                },
                            ],
                        };
                    }
                    this.visual_element = new VisualElement(obj, each, obj.getResourcePath());
                    this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
                }
            } else if (each.audioSrc) {
                if (!this.audio_element) {
                    this.audio_element = this.newAudioAmbiance(obj, {});
                }
                this.audio_element.addStemFromJson(each);
            } else if (each.audioAmbiance) {
                this.audio_element = this.newAudioAmbiance(obj, each.audioAmbiance);
            }
        }
    }
    /**
     *
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj
     * @param {object} json
     */
    newAudioAmbiance(obj, json) {
        if (!obj.toAudioContext) {
            var scope = AudioListenerScope.fromScenePath(obj.toScenePath(), obj, obj.sceneGraph);
            var audioContext = new AmbianceContext(scope);
        } else {
            var audioContext = obj.toAudioContext();
        }

        return new AudioAmbiance(audioContext, json, () => obj.getResourcePath());
    }
}
