//@ts-check
import { Action } from "./action";
import { ActivateAction } from "../sceneActions/ActivateAction";
import { BackAction } from "../sceneActions/BackAction";
import { NavigateAction } from "../sceneActions/NavigateAction";
import { NavigateHomeAction } from "../sceneActions/NavigateHomeAction";
//import { OpenAreaSizedModuleAction } from "../sceneActions/OpenAreaSizedModuleAction";
import { OpenFullscreenModuleAction } from "../sceneActions/OpenFullscreenModuleAction";
//import { OpenSceneSizedModuleAction } from '../sceneActions/OpenSceneSizedModuleAction';
import { ToggleAction } from "../sceneActions/ToggleAction";
import { PlayAudioAction } from '../sceneActions/playAudio';
import { ExitModule } from "../sceneActions/ExitModule";
import { CallWebFunction } from "../sceneActions/CallWebFunction";
import { ToggleApplicationSettingAction } from '../sceneActions/ToggleApplicationSettingAction';
import { SetApplicationSettingAction } from '../sceneActions/SetApplicationSettingAction';
import { ChangeSelectionMode } from '../sceneActions/ChangeSelectionMode';
import { ToggleInteractiveVisibility } from '../sceneActions/ToggleInteractiveVisibility';
import { AudioVisualScriptNextStateAction } from '../sceneActions/AudioVisualScriptNextStateAction';
import { WebApplication } from '../../webApplication';
import { RefreshPageAction } from '../sceneActions/RefreshPageAction';
import { ToggleRadio } from "../sceneActions/ToggleRadioAction";
import { ChangeRadioStationAction } from "../sceneActions/ChangeRadioStationAction";
import { NavigateBrowserAction } from '../sceneActions/NavigateBrowserAction';
/**
 * 
 */
export class Actions {
  /**
 * @type {string}
 */
  static typename = "actions";

  /**
 * @type {Array.<Action>}
 */
  actions = [];
  /**
   * 
   * @param {object} j 
   * @param {WebApplication} application 
   */
  addActionFromJson(j, application) {
    var a = newActionFromJson(j, application);
    if (a) {
      this.actions.push(a);
    }
  }
  /**
   * 
   * @param {object} json 
   * @param {WebApplication} application 
   */
  parseJson(json, application) {
    for (let each in json) {
      if (each == Action.typename) {
        this.addActionFromJson(json[each], application);
      } else if (each == Actions.typename) {
        for (let each_action in json[each]) {
          this.addActionFromJson(json[each][each_action], application);
        }
      }
    }
  }
  /**
   * 
   * @param {import('../scene').SceneObjectInterface} param 
   */
  runActions(param) {
    for (let each in this.actions) {
      this.actions[each].run(param);
    }
  }

  /**
   * 
   * @param {string} verb 
   * @param {string|undefined} name 
   * @returns {object}
   */
  static newJsonFromVerb(verb, name = undefined) {
    return { verb: verb, name: name };
  }
}

/**
 * 
 * @param {object|undefined} json 
 * @param {WebApplication|undefined} application 
 * @returns {Action|undefined}
 */
export function newActionFromJson(json, application) {

  if (json == undefined) {
    return undefined;
  }
  /** @type {string} */ 
  var verb =json.verb;

  /** @type {string} */ 
  var name = json.name;
  var obj;

  if (verb == undefined) {
    return undefined;
  }

  if (application) {
    if (json['if settings']) {
      for (const settingName of json['if settings']) {
        if (application.getSetting(settingName) == false) {
          return newActionFromJson(json["else"], application);
        }
      }
    }
  }


  if (application == undefined) {
    console.log("action with no application");
  }

  if (verb == "toggle") {
    obj = new ToggleAction(verb, name, json);
  } else if (verb == "toggle_application_setting") {
    obj = new ToggleApplicationSettingAction(verb, name, json, application);
  } else if (verb == "set_application_setting") {
    obj = new SetApplicationSettingAction(verb, name, json, application);
  } else if (verb == "refresh_page") {
    obj = new RefreshPageAction(verb, name, json);
  } else if (verb == "navigate_browser_page") {
    obj = new NavigateBrowserAction(verb, name, json);
  } else if (verb == "navigate") {
    obj = new NavigateAction(verb, name, json);
  } else if (verb == "back") {
    obj = new BackAction(verb, name, json);
  } else if (verb == "home") {
    obj = new NavigateHomeAction(verb, name, json);
  } else if (verb == "change-selection-mode") {
    obj = new ChangeSelectionMode(verb, name, json);
  } else if (verb == "toggle-interactive-visibility") {
    obj = new ToggleInteractiveVisibility(verb, name, json);
  } else if (verb == "activate") {
    obj = new ActivateAction(verb, name, json);
  } else if (verb == "openFullscreenModule") {
    obj = new OpenFullscreenModuleAction(verb, name, json);
  // } else if (verb == "openAreaSizedModule") {
  //   obj = new OpenAreaSizedModuleAction(verb, name, json);
  // } else if (verb == "openSceneSizedModule") {
  //   obj = new OpenSceneSizedModuleAction(verb, name, json);
  } else if (verb == "toggle radio") {
    obj = new ToggleRadio(verb, name, json);
  } else if (verb == "change radio station") {
    obj = new ChangeRadioStationAction(verb, name, json);
  } else if (verb == "play audio") {
    obj = new PlayAudioAction(verb, name, json);
  } else if (verb == "ExitModule") {
    obj = new ExitModule(verb, name, json);
  } else if (CallWebFunction.verb_names.some(each => each == verb)) {
    obj = new CallWebFunction(verb, name, json);
  } else if (verb == "audio_visual_script.next_state") {
    obj = new AudioVisualScriptNextStateAction(verb, name, json);
  } else {
    if(verb.startsWith("_")){
      return undefined;
    }
    console.warn("unknown action " + verb);
    obj = new Action(verb, name);
  }
  return obj;
}
