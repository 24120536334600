// @ts-check
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.js";
import * as htmlHelper from "../htmlHelper.js";
import { Treeview } from "../view/treeview.js";
import { ReadonlyProperty, BooleanEditableProperty, NumberEditableProperty } from "./../view/propertyEditor";
import { WebApplication } from '../webApplication.js';
import { ValueChangedSubscriptions } from '../ValueChangedSubscriptions.js';
import { DebugOverlay } from './DebugOverlay.js';
import { ConsoleOverlay } from './ConsoleOverlay.js';
import { HTMLScreenOverlay } from './HTMLScreenOverlay.js';
import { MemoryDebugOverlay } from './MemoryDebugOverlay.js';
import { Resources } from '../resources.js';
import { CustomerAccount } from '../customerAccount.js';
import { InteractiveEvent } from '../sceneGraph/InteractiveEvent.js';
import { WebApplicationSettings } from '../WebApplicationSettings.js';
import { StackLayout } from '../view/stackLayout.js';
import { InteractiveMouseEvent } from '../MouseEvent.js';

/**
 *
 * @callback AuthorInterfaceComponentInterface_addAuthorInterfaceElementToTreeview
 * @param {Treeview} treeview
 */

/**
 *
 * @callback AuthorInterfaceComponentInterface_getAuthorInterfaceName
 * @returns {string} 
 */

/**
 *
 * @callback AuthorInterfaceComponentInterface_createAuthorInterfaceElement
 * @returns {HTMLElement|undefined} 
 */

/**
 * @typedef AuthorInterfaceComponentInterface
 * @property {AuthorInterfaceComponentInterface_addAuthorInterfaceElementToTreeview} [addAuthorInterfaceElementToTreeview] 
 * @property {AuthorInterfaceComponentInterface_createAuthorInterfaceElement} [createAuthorInterfaceElement] 
 * @property {AuthorInterfaceComponentInterface_getAuthorInterfaceName} getAuthorInterfaceName 
*/

/**
 * 
 */
export class AuthorInterface {
  /**
   * 
   * @type {import('../audio_visual/interactive_canvas.js').InteractiveCanvas}
   */
  icanvas;
  /**
   * 
   * @type {import('../resources.js').Resources}
   */
  resources;
  /**
   * 
   * @type {object}
   */
  json;
  /**
   * 
   * @type {boolean}
   */
  isActive;
  /**
   * 
   * @type {import('../customerAccount.js').CustomerAccount}
   */
  account;
  /**
   * 
   * @type {HTMLElement|undefined}
   */
  nav;
  /**
   * 
   * @type {HTMLElement|undefined}
   */
  sideNav;
  /**
   * 
   * @type {Treeview}
   */
  sideNav_treeView;
  /**
   * 
   * @type {Array.<AuthorInterfaceComponentInterface>}
   */
  components = [];
  /**
  * 
  * @type {Array.<HTMLScreenOverlay>}
  */
  debug_overlays;

  /**
   * @returns {DebugOverlay}
   */
  get diagnostics_overlay() {
    var r = /** @type {DebugOverlay} */ (this.debug_overlays[0]);
    return r;
  }
  /**
   * @returns {ConsoleOverlay}
   */
  get console_overlay() {
    var r = /** @type {ConsoleOverlay} */ (this.debug_overlays[1]);
    return r;
  }
  /**
   * 
   * @param {Resources} resources 
   * @param {InteractiveCanvas} icanvas 
   * @param {CustomerAccount} account 
   */
  constructor(resources, icanvas, account) {
    this.resources = resources;
    this.icanvas = icanvas;
    this.account = account;
    this.debug_overlays = [new DebugOverlay(), new ConsoleOverlay(), new MemoryDebugOverlay()];
    for (const debug_overlay of this.debug_overlays) {
      debug_overlay.initialize(this.application)
    }
  }
  /**
   * 
   */
  onActivity() {
    for (const debug_overlay of this.debug_overlays) {
      debug_overlay.onActivity();
    }
  }
  /**
   * 
   * @returns {WebApplication}
   */
  get application() {
    return this.account.application;
  }
  /**
   * 
   * @returns {boolean}
   */
  get isVisible() {
    let result = this.json.isVisible;

    if (result == undefined) {
      result = false;
    }
    return result;
  }
  /**
   * 
   * @param {boolean} v
   */
  set isVisible(v) {
    this.json.isVisible = v;
    this.updateActive();
  }
  /**
   * 
   */
  initialize() {
    var storage_name = this.stroageItemName();
    var from_storage = this.resources.getLocalStorageJsonItem(storage_name);
    this.json = from_storage;

  }
  /**
   * 
   */
  start() {
    this.updateActive();
  }
  /**
   * 
   */
  updateActive() {
    if (this.isVisible && !this.isActive) {
      this.addToDOM();
    } else if (!this.isVisible && this.isActive) {
      this.removeFromDOM();
    }
  }
  /**
   * 
   */
  deactivate() {
    this.json.isDeactivate = true;
    this.json.isDeactivateVisible = this.isVisible;
    if (this.isVisible) {
      this.isVisible = false;
    }
  }
  /**
   * 
   */
  reactivate() {
    if (this.json.isDeactivate) {
      this.json.isDeactivate = false;
      if (this.json.isDeactivateVisible) {
        this.isVisible = true;
      }
    }
  }
  /**
   * 
   * @param {HTMLElement} div 
   */
  addCloseButton(div) {
    const closeButton = document.createElement("button");
    closeButton.textContent = "Close";

    closeButton.addEventListener("click", () => {
      this.close();
    });

    div.appendChild(closeButton);
  }
  /**
   * 
   * @returns {HTMLElement}
   */
  createSidebar() {
    let result = document.createElement("div");
    result.style.padding = "4px";

    this.addCloseButton(result);


    result.classList.add("sidenav");
    this.sideNav_treeView = new Treeview(true);

    this.components.forEach((element) => {

      if (element.addAuthorInterfaceElementToTreeview) {
        element.addAuthorInterfaceElementToTreeview(this.sideNav_treeView);
      } else {
        if (element.createAuthorInterfaceElement) {
          let elm = element.createAuthorInterfaceElement();
          if(elm){
             this.sideNav_treeView.addItem(element.getAuthorInterfaceName(), elm, false);
         
          }
        }
      }
    });

    result.appendChild(this.sideNav_treeView.element);
    return result;
  }
  /**
   * 
   * @returns {HTMLElement}
   */
  createNavbar() {
    let result = document.createElement("nav");
    result.classList.add("navbar");
    result.classList.add("bg-body-tertiary");

    let form = document.createElement("form");
    form.classList.add("container-fluid");
    form.classList.add("justify-content-start");

    result.appendChild(form);

    return result;
  }
  /**
   * 
   */
  addToDOM() {
    let viewport = document.getElementById(InteractiveCanvas.viewportId);

    this.nav = this.createNavbar();
    this.sideNav = this.createSidebar();

    if (!viewport || !viewport.parentElement) {
      return;
    }

    viewport.parentElement.insertBefore(this.nav, viewport);

    viewport.style.marginLeft = "448px";
    viewport.parentElement.insertBefore(this.sideNav, viewport);

    //this.sideNav_accordianGroup.addCallbacks();
    //for (const debug_overlay of this.debug_overlays) {
    //  debug_overlay.set_x_offset(viewport.style.marginLeft);
    //}
    HTMLScreenOverlay.set_debug_table_x_offset(viewport.style.marginLeft)
    this.isActive = true;
  }
  /**
   * 
   */
  removeFromDOM() {

    this.nav?.remove();
    this.nav = undefined;

    this.sideNav?.remove();
    this.sideNav = undefined;


    let viewport = document.getElementById(InteractiveCanvas.viewportId);
    if (viewport) {
      viewport.style.marginLeft = "0px";
    }

    this.isActive = false;
    //for (const debug_overlay of this.debug_overlays) {
    //  debug_overlay.set_x_offset(0);
    //}
    HTMLScreenOverlay.set_debug_table_x_offset(0)
  }
  /**
   * 
   * @param {InteractiveCanvas} icanvas 
   */
  drawFrame(icanvas) {
    for (const debug_overlay of this.debug_overlays) {
      debug_overlay.update();
    }
  }
  /**
   * 
   * @param {InteractiveCanvas} icanvas 
   * @param {InteractiveMouseEvent} e 
   */
  mousedown(icanvas, e) { }
  /**
   * 
   * @param {InteractiveCanvas} icanvas 
   * @param {InteractiveMouseEvent} e 
   */
  mouseup(icanvas, e) { }
  /**
   * 
   * @param {InteractiveCanvas} icanvas 
   * @param {InteractiveMouseEvent} e 
   */
  mousemove(icanvas, e) { }
  /**
   * 
   * @param {InteractiveCanvas} icanvas 
   * @param {InteractiveEvent} ievent 
   */
  keydown(icanvas, ievent) {

    if (ievent.e_keyboardevent.key == "2") {
      this.isVisible = !this.isVisible;
      this.updateActive();
      icanvas.updateCanvasSize();
      icanvas.invaidate();
    }
  }
  /**
   * 
   */
  close() {
    this.isVisible = false;
    this.updateActive();
    this.icanvas.updateCanvasSize();
    this.icanvas.invaidate();
  }
  /**
   * 
   * @returns {string}
   */
  stroageItemName() {
    return this.resources.combineJsonResourceName(this.application.name, this.account.name, "author.storage");
  }
  /**
   * 
   */
  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  /**
   * 
   */
  shutdown() {
    this.saveState();
  }
  /**
   * 
   * @param {*} e 
   */
  onTouchTap(e) { }
  /**
   * 
   * @param {*} e 
   */
  onTouchPan(e) { }
  /**
   * 
   * @param {*} e 
   */
  onTouchSwipe(e) { }
  /**
   * 
   * @param {*} e 
   */
  onTouchDistance(e) { }
  /**
   * 
   * @param {*} e 
   */
  onTouchRotate(e) { }
  /**
   * 
   * @param {*} e 
   */
  onTouchGesture(e) { }
/**
 * 
 * @param {object} client_values 
 * @param {object} ui 
 * @param {object} default_values 
 * @param {StackLayout} layout 
 * @param {object} change_subscriptions 
 */
  static collectEditablePropertiesFromProperties(client_values, ui, default_values, layout, change_subscriptions = undefined) {
    for (const property in ui) {
      var editor = undefined;
      var value = undefined;

      var get_value = () => {
        if (client_values.hasOwnProperty(property)) {
          return client_values[property];
        } else if (default_values.hasOwnProperty(property)) {
          return default_values[property];
        }
      };
      value = get_value();

      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
      var value_type = typeof value;

      if (value_type == "boolean") {
        editor = new BooleanEditableProperty();
      }

      if (editor == undefined) {
        var asNumber = Number(value);

        if (!Number.isNaN(asNumber)) {
          editor = new NumberEditableProperty();

          if (default_values.hasOwnProperty(property + WebApplicationSettings.MinSuffix)) {

          }
          if (default_values.hasOwnProperty(property + WebApplicationSettings.MaxSuffix)) {

          }
        }
      }

      if (editor == undefined) {
        editor = new ReadonlyProperty();
      }

      if (ui.hasOwnProperty(property)) {
        var ui_properties = ui[property];
        if (ui_properties.hasOwnProperty("isEnabled")) {
          var isEnabled = ui_properties["isEnabled"];
          editor.setEnabled(isEnabled);
        }
      }

      let property_interface = Object.assign(editor, {
        name: property,
        getValue: () => {
          return get_value();
        },
        setValue: (v) => {
          client_values[property] = v;
          if (change_subscriptions) {
            ValueChangedSubscriptions.updateValueByName(change_subscriptions, property, v);
          }
        },
      });
      layout.addAsTableRow(property_interface.getEditorElements());
    }
  }
}
