//@ts-check
import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope";
import { AudioAmbianceSet } from "../audio_visual/audio/audioAmbianceSet";
import { AmbianceAudioListener } from "../audio_visual/audio/audioListener";
import { InteractiveEvent } from "./InteractiveEvent";
import { DragDropEvent } from "./DragDropEvent";
import { VisualElements } from "../audio_visual/visual/VisualElements";
import { WebApplication } from "../webApplication";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { ScenePath } from "./ScenePath";
import { WebApplicationSettings } from "../WebApplicationSettings";
import { Scene } from "./scene";
import { SceneChangeRequest } from "./SceneChangeRequest";
import { Resources } from "../resources.js";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.js";
import { WebApplicationServer } from "../webApplicationServer.js";
import { LocalServerFileCache } from "../LocalServerFileCache.js";
import { SceneGraphSet } from "./sceneGraphSet.js";
import { UpdateContext } from "../update.js";

let c2 = require("c2.js");

/**
 *
 * @callback event_scene_change_request_completeFunction
 * @param {SceneChangeRequest} request
 */

/**
 *
 */
export class CommonScenes {
    /**
     * @type {string}
     */
    static UXSceneName = "ux-scene";
    /**
     * @type {string}
     */
    static UXSceneGraphName = "ux";
}

export class SceneGraphNode {
    /** @type {Scene|undefined} */
    active_scene;
    /** @type {Scene|undefined} */
    ux_scene;
    /** @type {Array.<Scene>} */
    scenes;
    /** @type {import('../simulation').Simulation} */
    simulation;
    /** @type {AmbianceAudioListener} */
    listener;
    /** @type {VisualElements} */
    visual_elements;
    /** @type {event_scene_change_request_completeFunction|undefined} */
    event_scene_change_request_complete;
    /** @type {Array.<SceneChangeRequest>} */
    scene_change_requests_in_progress = [];
    /** @type {Promise|undefined} */
    ux_scene_loading_promise;

    /**
     * @returns {import('../webApplication.js').WebApplication}
     */
    get application() {
        return this.simulation.application;
    }
    /**
     * @returns {Scene|undefined}
     */
    get firstScene() {
        return this.scenes[0];
    }
    /**
     * @returns {import('../audio_visual/audio/webAudio.js').WebAudioInterface}
     */
    get audio() {
        return this.simulation.audio;
    }
    /**
     * @returns {Resources}
     */
    get resources() {
        return this.simulation.resources;
    }
    /**
     * @returns {InteractiveCanvas}
     */
    get icanvas() {
        return this.simulation.icanvas;
    }
    /**
     * @returns {VisualElements}
     */
    get visualElements() {
        return this.visual_elements;
    }
    /**
     * @returns {WebApplicationServer}
     */
    get server() {
        return this.simulation.server;
    }
    /**
     * @returns {LocalServerFileCache}
     */
    get server_file_cache() {
        return this.server.server_file_cache;
    }
    /**
     * @returns {SceneGraphSet}
     */
    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }
    /**
     *
     * @param {import('../simulation.js').Simulation} simulation
     */
    constructor(simulation) {
        this.scenes = [];
        this.simulation = simulation;
        this.visual_elements = new VisualElements(this);
    }
    /**
     *
     */
    async initiaize() {
        var a = this.audio;
        this.listener = new AmbianceAudioListener(a);
        this.audio.onStartAudioOnGesture = () => {
            this.onStartAudioOnGesture();
        };

        if (this.application.getSetting(WebApplicationSettings.isUXSceneEnabled_SettingName)) {
            this.ux_scene_loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(
                CommonScenes.UXSceneGraphName,
            );
            this.ux_scene_loading_promise = this.ux_scene_loading_promise?.then((result) => {
                this.ux_scene_loading_promise = undefined;
                this.startUXScene();
                return result;
            });
        }
    }
    /**
     *
     */
    startUXScene() {
        var ux = this.sceneGraphSet.getSceneGraph(CommonScenes.UXSceneGraphName);

        if (ux) {
            this.ux_scene = ux.defaultOrFirstScene;

            if (this.ux_scene) {
                this.ux_scene.scene_graph_node = this;
                this.ux_scene.start(undefined);
                return;
            }
        }

        console.warn(`ux scene not found`);
    }
    /**
     *
     */
    onStartAudioOnGesture() {
        this.listener?.onStartAudioOnGesture();
    }
    /**
     *
     * @returns {Scene|undefined}
     */
    getUXScene() {
        return this.ux_scene;
    }
    /**
     *
     * @returns {Scene|undefined}
     */
    getActiveScene() {
        for (let index = 0; index < this.scenes.length; index++) {
            const element = this.scenes[index];
            if (element.name == CommonScenes.UXSceneName) {
                continue;
            }
            return element;
        }
        return undefined;
    }
    /**
     *
     * @param {SceneChangeRequest} request
     * @param {SceneChangeRequest|undefined} fallback_request_on_error
     */
    startSceneChange(request, fallback_request_on_error = undefined) {
        if (this.scene_change_requests_in_progress.length > 0) {
            console.warn("startSceneChange ignored: scene change in progress");
            return;
        }

        for (const each_request_in_progress of this.scene_change_requests_in_progress) {
            if (
                ScenePath.areLocationsTheSame(request.location, each_request_in_progress.location)
            ) {
                console.warn("startSceneChange ignored: same location in progress");
                return;
            }
        }

        this.simulation.increment_item_set();

        request.isPrimary = true;
        this.scene_change_requests_in_progress.push(request);

        var name = request.location.sceneGraphName;
        if (this.sceneGraphSet.isSceneGraphFetched(name)) {
            this.performSceneChange(request);

            if (fallback_request_on_error && request.error) {
                this.performSceneChange(fallback_request_on_error);
            }
        } else {
            request.loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(name);
            request.loading_promise = request.loading_promise.then((result) => {
                request.loading_promise = undefined;
                if (
                    !request.isCancled &&
                    this.scene_change_requests_in_progress.some((obj) => obj === request)
                ) {
                    this.performSceneChange(request);

                    if (fallback_request_on_error && request.error) {
                        this.performSceneChange(fallback_request_on_error);
                    }
                }
                return result;
            });
        }
    }
    /**
     *
     * @param {SceneChangeRequest} request
     */
    performSceneChange(request) {
        var name = request.location.sceneGraphName;

        var found = this.sceneGraphSet.getSceneGraph(name);

        if (found == undefined) {
            request.error = `scene graph not found ${name}`;
            console.error(request.error);
            this.event_scene_change_request_complete?.(request);

            request.isCancled = true;
            this.scene_change_requests_in_progress = this.scene_change_requests_in_progress.filter(
                (obj) => obj !== request,
            );
            return;
        }

        if (request.location.sceneName == undefined) {
            request.location.sceneName = found.defaultOrFirstScene.name;
        }

        var found_scene = found.findSceneByName(request.location.sceneName);

        if (found_scene == undefined) {
            request.error = `scene not found ${request.location.sceneName}`;
            console.error(request.error);
            this.event_scene_change_request_complete?.(request);

            request.isCancled = true;
            this.scene_change_requests_in_progress = this.scene_change_requests_in_progress.filter(
                (obj) => obj !== request,
            );

            return;
        }

        if (
            this.simulation.application.getSetting(
                WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingName,
            ) ||
            this.simulation.application.getSetting(WebApplication.IsMSVideoEnabledSettingName)
        ) {
            if (!found_scene.isVisualContentReady() && !request.callback_collector) {
                var callback_collector = new GettingReadyCallbackCollector();
                found_scene.startVisualContentGetReady(callback_collector);

                request.callback_collector = callback_collector;
                request.callback_collector_promise = request.callback_collector
                    .newWaitPromiseForAllCallbacks()
                    .then((result) => {
                        request.callback_collector_promise = undefined;
                        this.performSceneChange(request);
                    });

                return;
            }
        }
        let previous_active_scene = undefined;

        if (this.active_scene) {
            this.active_scene.stop(found_scene);
            this.active_scene.scene_graph_node = undefined;
            previous_active_scene = this.active_scene;
            this.active_scene = undefined;
        }

        this.active_scene = found_scene;

        if (this.active_scene) {
            this.active_scene.scene_graph_node = this;

            this.scene_change_requests_in_progress = this.scene_change_requests_in_progress.filter(
                (obj) => obj !== request,
            );

            this.scenes = [this.active_scene];

            if (this.ux_scene) {
                this.scenes.push(this.ux_scene);
            }

            this.active_scene.start(previous_active_scene);
        }

        request.isCompleted = true;
        this.event_scene_change_request_complete?.(request);
        this.invalidate();
    }

    /**
     *
     */
    invalidate() {
        this.icanvas.invalidate();

        this.icanvas.try_invalidated_draw();
    }
    /**
     *
     * @param {ScenePath} path
     * @returns {Scene|undefined}
     */
    findSceneByPath(path) {
        return this.findSceneByName(path.sceneName);
    }
    /**
     *
     * @param {ScenePath} path
     * @returns {Number|undefined}
     */
    findSceneIndexByPath(path) {
        if (!path) {
            return undefined;
        }
        for (let index = 0; index < this.scenes.length; index++) {
            const element = this.scenes[index];
            if (element.name == path.sceneName) {
                return index;
            }
        }
    }
    /**
     *
     * @param {string|undefined} name
     * @returns {Scene|undefined}
     */
    findSceneByName(name) {
        for (const scene of this.scenes) {
            if (scene.name == name) {
                return scene;
            }
        }
    }

    drawFrame(icanvas) {
        for (let each in this.scenes) {
            let i = this.scenes[each];
            i.drawFrame(icanvas);
        }
    }

    mousedown(icanvas, e) {
        let event = new InteractiveEvent(icanvas, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mousedown(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    mouseup(icanvas, e) {
        let event = new InteractiveEvent(icanvas, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mouseup(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    mousemove(icanvas, e) {
        let event = new InteractiveEvent(icanvas, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mousemove(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    keydown(icanvas, ievent) {
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    keyup(icanvas, ievent) {
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchTap(e) {
        let event = new InteractiveEvent(undefined, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchTap(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchPan(e) {
        let event = new InteractiveEvent(undefined, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchPan(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchSwipe(e) {
        let event = new InteractiveEvent(undefined, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchSwipe(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchDistance(e) {
        let event = new InteractiveEvent(undefined, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchDistance(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchRotate(e) {
        let event = new InteractiveEvent(undefined, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchRotate(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchGesture(e) {
        let event = new InteractiveEvent(undefined, e);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchGesture(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    file_dropped(e, files) {
        let event = new DragDropEvent(this.active_scene?.icanvas, e, files);
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].file_dropped(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    drag_file(e, files) {
        let event = new DragDropEvent(this.active_scene?.icanvas, e, files);
        event.isDrag = true;
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].drag_file(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    activate_event(event) {
        for (var each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].activate_event(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    activate(value, value_context) {
        let event = new InteractiveEvent();
        event.activate_value = value;
        event.activate_value_context = value_context;
        this.activate_event(event);
    }
    /**
     *
     * @param {Number} increment
     */
    selectNextSceneInteractive(increment = 1) {
        var selectable = [];

        for (const each_scene of this.scenes) {
            var scene_selectable = each_scene.sceneInteractiveLayerInteractives.interactives.filter(
                (item) => item.isSelectable(),
            );
            selectable.push(...scene_selectable);
        }

        var index = selectable.findIndex((item) => item.isSelected);

        if (index == -1) {
            index = selectable.findIndex((item) => item.json.isDefaultSelection);
            if (index != -1) {
                index -= increment;
            }
        } else {
            selectable[index].setSelected(false);
        }

        index += increment;
        index = index % selectable.length;

        if (index < 0) {
            index += selectable.length;
        }

        selectable[index].setSelected(true);
    }
    /**
     *
     * @returns {Scene|undefined}
     */
    getSelectedSceneInteractive() {
        for (const each_scene of this.scenes) {
            for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
                if (each.isSelected) {
                    return each;
                }
            }
        }

        return undefined;
    }
    /**
     *
     */
    selectNone() {
        for (const each_scene of this.scenes) {
            for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
                if (each.isSelected) {
                    each.setSelected(false);
                }
            }
        }
    }
    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context) {
        this.visual_elements.update(update_context);
    }
}
