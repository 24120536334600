//@ts-check

import { AudioListenerScope } from "./AudioListenerScope";
import { AudioPlaylist } from "./playlistAPI/AudioPlaylist";
import { PlaylistData } from "./playlistAPI/PlaylistData";
import { Stem } from "./stem";

/**
 * @callback stringFunction
 * @returns {string}
*/

/**
 * 
 */
export class AudioAmbiance {
  /**
   *  @type {import('./audioAmbianceSet').AudioAmbianceSet|undefined}
   */
  ambianceSet;
  /**
   *  @type {import('./AmbianceContext').AmbianceContext}
   */
  audioContext;
  /**
   *  @type {object}
   */
  json;
  /** @type {object} */
  playlistConfig
  /**
* 
* @type {Array.<import('./stem').Stem>}
*/
  stems = [];
  /** @type {Array<Object<string, AudioPlaylist>>} */
  playlists = [];
  /**
* 
* @type {Array.<import('./stem').Stem>}
*/
  sound_effects_played = []
  /**
* 
* @type {stringFunction}
*/
  parentGetResourcePath;
  /**
   * @returns {string|undefined}
   */
  get regionName() {
    return this.audioContext.regionName;
  }
  /**
   * @returns {string|undefined}
   */
  get areaName() {
    return this.audioContext.areaName;
  }
/**
 * 
 * @param {import('./AudioAmbianceGroup').AudioAmbianceGroup} audio_ambiance_group 
 */
  onListenLocationExit(audio_ambiance_group) {
    for (const each of this.sound_effects_played) {
      if (each.json.isListenerAreaLocal == true) {
        audio_ambiance_group.audio.connectedStems.stopStem(each);
      }
    }
    this.sound_effects_played = [];
  }
/**
 * 
 * @param {Array.<import('./stem').Stem>} stems 
 */
  onPlaySoundEffects(stems) {
    this.sound_effects_played.push(...stems);
  }
/**
 * 
 * @param {import('./AudioAmbianceGroup').AudioAmbianceGroup} group 
 * @param {import('./AmbianceContext').AmbianceContext} context 
 */
  collectAmbianceGroup(group, context) { }
/**
 * 
 * @param {import('./AudioAmbianceGroup').AudioAmbianceGroup} group 
 * @param {import('./AmbianceContext').AmbianceContext} context 
 */
  collectConnectedAmbianceGroup(group, context) { }

  /**
   * 
   * @param {import('./AmbianceContext').AmbianceContext} audioContext 
   * @param {object} json 
   * @param {stringFunction} parentGetResourcePath 
   */
  constructor(audioContext, json = undefined, parentGetResourcePath, playlistConfig) {
    this.audioContext = audioContext;
    this.playlistConfig = playlistConfig;
    this.parentGetResourcePath = parentGetResourcePath;

    this.json = json || {};

    this.json.stems?.forEach((element) => {
      let stem = this.newStem(element);
      this.stems.push(stem);
    });
    if (!this.json?.playlists) {
      return
    }
    const playlists = this.json.playlists.map((playlist) => {
      return { 
        name: playlist.name,
        playlist: this.newAudioPlaylist(playlist)
      }
    })
    playlists.forEach((playlist) => {
      this.playlists.push(playlist)
    })
  }


/**
 * 
 * @param {object} playlist 
 * @returns {PlaylistData}
 */
newAudioPlaylist(playlist) {
  // TODO add proper error handling to ensure data is valid 
  console.info("CONTSTRUCTING NEW AUDIO PLAYLIST", playlist, this.playlistConfig)
  const audioPlaylist = new PlaylistData(this.playlistConfig, playlist.name, playlist.type, playlist.playlistId, playlist.gain, true)

  return audioPlaylist
}

/**
 * 
 * @param {object} json 
 * @returns {Stem}
 */
  addStemFromJson(json){
    let stem = this.newStem(json);
    this.stems.push(stem);
    return stem;
  }

/**
 * 
 * @param {object} element 
 * @returns {Stem}
 */
  newStem(element) {
    return new Stem(element, this.getResourcePath.bind(this));
  }
/**
 * 
 * @returns {string}
 */
  getResourcePath() {
    const resourcePath = this.json.resourcePath || this.parentGetResourcePath();
    return resourcePath
  }

  /**
   * 
   * @returns {Array.<Stem>}
   */
  get_soundscape() {
    var result = [];

    this.stems.forEach((each) => {
      if (!each.isEnabled) {
        return;
      }
      result.push(each);
    });

    this.ambianceSet?.collection.forEach((each_ambiance) => {
      if (each_ambiance === this) {
        return;
      }

      each_ambiance.stems.forEach((each) => {
        if (!each.isEnabled) {
          return;
        }
        if (each.hasRegionGain()) {
          if (result.some((each_result) => each.isEquivilentToStem(each_result))) {
            return;
          }
          result.push(each.toRegionStem());
        }
      });
    });

    return result;
  }
}
