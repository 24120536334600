//@ts-check
import { VideoDisplayToElement } from './VideoDisplayToElement.js';
/**
 * 
 */
export class VideoFileToVideoElement extends VideoDisplayToElement {
  /**
  *  @type {boolean}
  */
  is_added_to_viewport;
 /**
  * 
  * @param {import('./VideoFileToElement.js').VideoFileToElement} video_file_to_element 
  */
  constructor(video_file_to_element) {
    super(video_file_to_element);
  }

/**
 * 
 */
  addedToInteractiveCanvas() { 
    //console.log("video:video")
    if (!this.is_added_to_viewport) {
      this.updateElementSize();
      this.icanvas?.viewport.appendChild(this.element);
      this.is_added_to_viewport = true;
    }
  }
/**
 * 
 */
  removedFromInteractiveCanvas() {
    if (this.is_added_to_viewport) {
    
      this.icanvas?.viewport.removeChild(this.element);
      this.is_added_to_viewport = false;
    }
  }
/**
 * 
 */
  draw() {

  }
  /**
 * 
 */
  validate() {
   
  }
/**
 * 
 */
  onCanvasResized() {
    if (this.is_added_to_viewport) {
      this.updateElementSize();
    }
  }

/**
 * 
 */
  updateElementSize() {   
    if(!this.video_file_to_element.resource_canvas_element){
      return;
    }

    let image_rect = this.video_file_to_element.resource_canvas_element.geometry.get_absolute_rect_shape(); 
    
    this.video_file_to_element.element_video.width = image_rect.w;
    this.video_file_to_element.element_video.height = image_rect.h;
  }
}