// https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
// https://github.com/zingchart/zingtouch#include-the-library


import { InteractivePlatformCanvas } from "./InteractivePlatformCanvas.js";
import { WebApplication } from '../webApplication.js';

export class InteractiveMobileCanvas extends InteractivePlatformCanvas {

  static IPhoneBrandName = "iPhone";
  static AndroidBrandName = "Android";
  region;
  user_agent_string;
  is_platform;

  constructor() {
    super();
  }

  isPlatform() {
    return this.is_platform;
  }

  isTouchCapable() {
    return InteractiveMobileCanvas.isTouchCapable();
  }

  uninitialize() {
    this.region.unbind(this.canvas);
  }

  initialize_input() {
    let self = this;

    this.region = new ZingTouch.Region(document.body);

    let tap = new ZingTouch.Tap({
      maxDelay: 300,
      numInputs: 1,
      tolerance: 10,
    });

    this.region.bind(
      this.canvas,
      tap,
      function (e) {
        var event = e.detail;
        if (event.events && event.events.length > 0) {
          event = event.events[0];
        }

        self.onTouchTap(event);
      },
      false
    );

    let swipe = new ZingTouch.Swipe({
      numInputs: 1,
      maxRestTime: 100,
      escapeVelocity: 0.05,
    });

    this.region.bind(this.canvas, swipe, function (e) {
      if (e) {
        self.onTouchSwipe(e.detail);
      }
    });

    // let distance = new ZingTouch.Distance();
    // this.region.bind(this.canvas, distance, function (e) {
    //   self.onDistance(e);
    // });
    let pan = new ZingTouch.Pan({
      numInputs: 2,
    });
    this.region.bind(this.canvas, pan, function (e) {
      self.onTouchPan(e.detail);
    });
    // let rotate = new ZingTouch.Rotate();
    // this.region.bind(this.canvas, rotate, function (e) {
    //   self.onTouchRotate(e.detail);
    // });
    // let gesture = new ZingTouch.gesture();
    // this.region.bind(this.canvas, gesture, function (e) {
    //   self.onGesture(e);
    // });
  }
  isRunningOnIphone() {
    return /iPhone/.test(navigator.userAgent);
  }
  isRunningOnAndroid() {
    return /Android/.test(navigator.userAgent);
  }
  initialize(interactiveCanvas) {
    super.initialize(interactiveCanvas);
    this.name = "Mobile";

    this.user_agent_string = navigator.userAgent || navigator.vendor || window.opera;

    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        this.user_agent_string
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        this.user_agent_string.substr(0, 4)
      )
    ) {
      this.is_platform = true;
    }

    if (this.isRunningOnIphone()) {
      this.brand = InteractiveMobileCanvas.IPhoneBrandName;
    } else if (this.isRunningOnAndroid()) {
      this.brand = InteractiveMobileCanvas.AndroidBrandName;
    }
  }

  configure_visual_elements() {
  }
  apply_to_default_settings() {
    console.log(`configure settings for ${this.brand} ${this.name}`);

    if (this.brand == InteractiveMobileCanvas.IPhoneBrandName) {
      this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingName, false);
    } else {
    }

    //this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsSBVideoEnabledSettingName, false);
   //this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsPSVideoEnabledSettingName, true);

    //this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowConsoleLogOverlaySettingName, false);
    //this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isShowMemoryDiagnosticsOverlaySettingName, false);

  }

  static isTouchCapable() {
    //https://stackoverflow.com/questions/14439903/how-can-i-detect-device-touch-support-in-javascript
    return (
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0
    );
  }

  onTouchTap(e) {
    console.log("tap");
    this.interactiveCanvas.onActivity();
    for (let each in this.interactiveCanvas.components) {
      this.interactiveCanvas.components[each].onTouchTap(e);
    }
    this.interactiveCanvas.try_invalidated_draw();
  }
  onTouchPan(e) {
    this.interactiveCanvas.onActivity();
    for (let each in this.interactiveCanvas.components) {
      this.interactiveCanvas.components[each].onTouchPan(e);
    }
    this.interactiveCanvas.try_invalidated_draw();
  }
  onTouchSwipe(e) {
    console.log("swipe");
    this.interactiveCanvas.onActivity();
    for (let each in this.interactiveCanvas.components) {
      this.interactiveCanvas.components[each].onTouchSwipe(e);
    }
    this.interactiveCanvas.try_invalidated_draw();
  }
  onTouchDistance(e) {
    this.interactiveCanvas.onActivity();
    for (let each in this.interactiveCanvas.components) {
      this.interactiveCanvas.components[each].onTouchDistance(e);
    }
    this.interactiveCanvas.try_invalidated_draw();
  }
  onTouchRotate(e) {
    this.interactiveCanvas.onActivity();
    for (let each in this.interactiveCanvas.components) {
      this.interactiveCanvas.components[each].onTouchRotate(e);
    }
    this.interactiveCanvas.try_invalidated_draw();
  }
  onTouchGesture(e) {
    this.interactiveCanvas.onActivity();
    for (let each in this.interactiveCanvas.components) {
      this.interactiveCanvas.components[each].onTouchGesture(e);
    }
    this.interactiveCanvas.try_invalidated_draw();
  }

}
