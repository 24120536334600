import { VideoFileToElement } from '../canvasElements/VideoFileToElement.js';
import { ResourceCanvasElement } from '../resourceCanvasElements/ResourceCanvasElement.js';
import { WebApplication } from '../../../webApplication.js';
import { FileResourceRequest } from '../../../resources/FileResourceRequest.js';

import { FileResource } from '../../../resources/FileResource.js';
let c2 = require("c2.js");

export class VideoFileResource extends FileResource {

  static requestMp4VideoResource(request) {
    request.setExtension(".mp4");
    request.setCategoryPath("visuals/video/");
    return new VideoFileResource(request);
  }
  static disposeMp4VideoResource(resource) { }

  static canCreateResourceFromJson(json, property) {
    return property == "video" && json[property] !== undefined;
  }
  static canCreateResourceFromJsonObject(json) {
    return json["video"] !== undefined;
  }

  static requestResource(name, path, path2, webapp) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, webapp);
    var resource = this.requestMp4VideoResource(request);
    if (resource) {
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "video";
    }
    return resource;
  }
  /**
 *
 * @param {object} json
 * @param {string} property
 * @param {string} path
 * @param {string} path2
 * @param {import('../../../webApplication').WebApplication} webapp
 * @returns {import('./resourceInterface').ResourceInterface} 
 */
  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property, webapp)) {
      let result = this.requestResource(json.video, path, path2, webapp);
      result.event_playedOnce = json["video.event.playedOnce"];
      result.isLoop = json["video.loop"];
      result.isMuted = json["video.muted"];
      result.isAutoPlay = json["video.autoplay"];

      return result;
    }
  }

  resource_element;
  resource_request;
  loading_promise;
  isError;
  isLoaded;

  event_playedOnce;
  isLoop;
  isMuted;
  isAutoPlay;
  onVisualLoaded;
  server_file_cache;
  started_element_error_callback;
  resource_canvas_element;

  constructor(resource_request) {
    super();
    this.resource_request = resource_request;
  }

  toSourceURLName() {
    return this.resource_request.name;
  }
 
  start_loading() {

    if (this.resource_request.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
      console.info(`video:element:loading ${this.url}`);
    }

    // https://gist.github.com/aferriss/99dc65bbc079e28f95030ff34e5a161b

    this.resource_element = document.createElement("video");
    this.resource_request.application.memory_diagnostics.start_download("video", this.resource_request, this.resource_element);
    this.resource_element.autoplay = false;
    //this.resource_element.playsinline = 'playsinline';
    this.resource_element.setAttribute('playsinline', 'playsinline');
    this.resource_element.controls = false;
    this.resource_element.name = this.resource_request.name;

    if (this.isAutoPlay !== undefined) {
    } else {
      this.isAutoPlay = true;
    }

    if (this.isMuted !== undefined) {
      this.resource_element.muted = this.isMuted == true;
    } else {
      this.resource_element.muted = true;
    }

    if (this.isLoop !== undefined) {
      this.resource_element.loop = this.isLoop == true;
    }
    else {
      this.resource_element.loop = true;
    }

    if (this.event_playedOnce !== undefined) {
      this.resource_element.onended = () => {

        this.onRunAction?.(this.event_playedOnce);
      }
    }
    else {
      this.resource_element.onended = undefined;
    }

    let self = this;

    this.loading_promise = new Promise((resolve, reject) => {
      var onCanPlay = () => {

        console.info("video:element:canPlay")
        self.loading_promise = undefined;
        self.isLoaded = true;
        self.isError = false;
        self.onVisualLoaded?.(self);
        self.resource_element.removeEventListener("canplay", onCanPlay);
        self.resource_element.removeEventListener("loadeddata", onCanPlay);
        self.resource_element.removeEventListener("error", onError);
        resolve(self);
      }

      self.resource_element.addEventListener("canplay", onCanPlay);

      var onError = () => {

        console.warn("video:element:load:error")
        self.loading_promise = undefined;
        self.isLoaded = true;
        self.isError = true;

        self.onVisualLoaded?.(self);
        self.resource_element.removeEventListener("error", onError);
        self.resource_element.removeEventListener("canplay", onCanPlay);
        resolve(self);
      };

      self.resource_element.addEventListener("error", onError);

    });

    this.server_file_cache.loadFromCacheOrUrl(this.resource_element, this.url);//
    this.resource_element.load();
  }

  notifyError() {
    if (!this.isError) {
      this.isError = true;
    }
  }
  initialize() {

  }


  start(resource_canvas_element) {
    this.resource_canvas_element = resource_canvas_element;
    this.resource_element.currentTime = 0;
  }

  stop() {
    this.resource_canvas_element = undefined;
    this.resource_element.currentTime = 0;
  }
  isLoading() {
    return this.loading_promise != undefined;
  }

  getLoadingPromise() {
    return this.loading_promise;
  }

  checkForErrors() {

    return false
  }

  // toRect(icanvas = undefined) {

  //   var video_width = this.url_file_info.width;
  //   var video_height = this.url_file_info.height

  //   if (icanvas) {
  //     var canvas_w = icanvas.get_width();
  //     var canvas_h = icanvas.get_height();

  //     if (video_width == 0 || video_height == 0) {
  //       video_width = canvas_w;
  //       video_height = canvas_h;
  //     }

  //     var hRatio = canvas_w / video_width;
  //     var vRatio = canvas_h / video_height;
  //     var ratio = Math.min(hRatio, vRatio);
  //     var result = new c2.Rect(0, 0, video_width * ratio, video_height * ratio);
  //     if (result.w == NaN) {

  //     }
  //     return result;
  //   }

  //   var result = new c2.Rect(0, 0, video_width, video_height);
  //   return result;

  // }
  createResourceCanvasElement(vis) {
    return new ResourceCanvasElement(vis, this);
  }
  createCanvasElement(resource_canvas_element) {

    return new VideoFileToElement(this, this.resource_element,resource_canvas_element);
  }

  pixel_size() {
    var video_width = this.url_file_info.width;
    var video_height = this.url_file_info.height
    return [video_width, video_height];
  }
}
