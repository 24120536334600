//@ts-check
import { ExternalModule } from "./../externalModules/ExternalModule";
import { GameCenterModuleIntegration } from "./../externalModules/GameCenterModuleIntegration";
/**
 *
 */
export class GolfExternalModule extends ExternalModule {
    /**
     *
     */
    constructor() {
        super();
        this.name = "golf-driving";
        this.html = "https://d3j65gcajhy5fh.cloudfront.net/";
        this.addIntegrations = (openModule) =>
            (openModule.integrations[0] = new GameCenterModuleIntegration(openModule));
    }
}
