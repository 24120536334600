//@ts-check

/**
 * @callback onDrawingFunction
 * @param {OnDrawingParams} params 
*/

/**
 * 
 */
export class FileResourceRequest {
  /**
   * @type {string}
   */
  static LargeDataFolder = "dataLarge/";
  /**
   * @type {string}
   */
  system_path;
  /**
   * @type {string}
   */
  path;
  /**
   * @type {string}
   */
  category_path;
  /**
   * @type {string}
   */
  categorized_path;
  /**
   * @type {string}
   */
  name;
  /**
   * @type {string}
   */
  extension;
  /**
   * @type {string}
   */
  fullpath;
  /**
   * @type {boolean}
   */
  isWorkInProgress;
  /**
   * @type {boolean}
   */
  isArchive;
  /**
   * @type {import('../webApplication').WebApplication|undefined}
   */
  application;
  /**
   * @type {import('../LocalServerFileCache').LocalServerFileCache}
   */
  server_file_cache;
  /**
   * 
   * @param {string} path 
   * @param {string} name 
   * @param {string} extension 
   * @param {string} category_path 
   * @param {string} categorized_path 
   * @param {boolean} isDataLarge 
   * @param {boolean} isWorkInProgress 
   * @param {boolean} isArchive 
   * @param {import('../webApplication').WebApplication|undefined} application 
   */
  constructor(
    path = "",
    name = "",
    extension = "",
    category_path = "",
    categorized_path = "",
    isDataLarge = true,
    isWorkInProgress = false,
    isArchive = false,
    application = undefined) {

    this.setPath(path, false);
    this.setCategoryPath(category_path, false);
    this.setCategorizedPath(categorized_path, false);
    this.system_path = "./assets/";

    if (isDataLarge) {
      this.system_path += FileResourceRequest.LargeDataFolder;
    }

    this.setExtension(extension, false);
    this.name = name;
    this.isWorkInProgress = isWorkInProgress;
    this.isArchive = isArchive;
    this.updateFullpath();
    this.application = application;
  }
  /**
   * 
   * @param {string} name 
   */
  change_to_filename(name) {
    this.name = name;
    this.updateFullpath();
  }
  /**
   * 
   * @param {string} category_path 
   * @param {boolean} isUpdateFullpath 
   */
  setCategoryPath(category_path, isUpdateFullpath = true) {
    this.category_path = category_path;
    if (this.category_path && this.category_path.endsWith("/") == false) {
      this.category_path += "/";
    }
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }
  /**
   * 
   * @param {string} categorized_path 
   * @param {boolean} isUpdateFullpath 
   */
  setCategorizedPath(categorized_path, isUpdateFullpath = true) {
    this.categorized_path = categorized_path;
    if (this.categorized_path && this.categorized_path.endsWith("/") == false) {
      this.categorized_path += "/";
    }
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }
  /**
   * 
   * @param {string} path 
   * @param {boolean} isUpdateFullpath 
   */
  setPath(path, isUpdateFullpath = true) {
    this.path = path;
    if (this.path && this.path.endsWith("/") == false) {
      this.path += "/";
    }
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }
  /**
   * 
   * @param {string} extension 
   * @param {boolean} isUpdateFullpath 
   */
  setExtension(extension, isUpdateFullpath = true) {
    this.extension = extension;
    if (isUpdateFullpath) {
      this.updateFullpath();
    }
  }
  /**
   * @returns {string}
   */
  get filename() {
    return this.fullpath;
  }

  // prependPath(path, isUpdateFullpath = true) {
  //   this.path = `${path}${this.path}`;
  //   if (isUpdateFullpath) {
  //     this.updateFullpath();
  //   }
  // }

  // appendPath(path, isUpdateFullpath = true) {
  //   this.path = `${this.path}${path}`;
  //   if (isUpdateFullpath) {
  //     this.updateFullpath();
  //   }
  // }
  /**
   * 
   * @param {string} extension 
   * @param {string} category_path 
   * @returns {string}
   */
  fullpathWithExtension(extension, category_path = this.category_path) {
    return `${this.getCategorizedPath(category_path)}${this.name}${extension}`;
  }
  /**
   * 
   */
  updateFullpath() {
    var updatedPath = this.getCategorizedPath();

    if (this.isWorkInProgress) {
      updatedPath = `${updatedPath}${"wip-demo/"}`;
    }

    this.fullpath = `${updatedPath}${this.name}${this.extension}`;
  }
  /**
   * 
   * @param {string} category_path 
   * @returns {string}
   */
  getCategorizedPath(category_path = this.category_path) {
    return this.system_path + this.path + category_path + this.categorized_path;
  }
  /**
   * 
   * @returns {string}
   */
  toUrlPath() {
    return this.fullpath;
  }
  /**
   * 
   * @returns {string}
   */
  toURLName() {
    return this.name;
  }
  /**
   * 
   * @returns {string}
   */
  toURLNameAndExtension() {
    return this.name + this.extension;
  }
}
