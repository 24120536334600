// @ts-check

// TEMPORARY STATE will resolve next sprint 10/28/2024
const radios = {
    "cabin-radio": {
        station: "bossa-nova",
        on: false,
    },
    "tower-radio": {
        station: "lofi-piano",
        on: false,
    },
};

export const getRadioSettings = () => {
    return Object.create(radios);
};

export const getRadio = (radioName) => {
    return {
        ...radios[radioName],
    };
};
/**
 * @param {string} radio
 * @param {string} playlistName
 */
export const changeStation = (radio, playlistName) => {
    if (radios[radio] == undefined) {
        throw new Error("passed invalid radio name");
    }
    radios[radio].station = playlistName;
};

/**
 * @param {string} radio
 */
export const toggleRadio = (radio) => {
    if (radios[radio] == undefined) {
        throw new Error("passed invalid radio name");
    }
    radios[radio].on = !radios[radio].on;
};

/**
 *
 * @param {object} radio
 * @param {object} playlistconfig
 */
export function resolveRadioStems(radio, playlistconfig) {
    const validStations = playlistconfig.playlists.radio;
    if (validStations[radio.station]?.stems === undefined) throw new Error("");
    return validStations[radio.station].stems;
}

/**
 *
 * @param {object} radio
 * @param {object} playlistconfig
 */
export function resolveRadioStationName(radio, playlistconfig) {
    const validStations = playlistconfig.playlists.radio;
    if (validStations[radio.station] == undefined) throw new Error("");
    return radio.station;
}

/**
 *
 * @param {object} radio
 */
export function resolveRadioMuted(radio) {
    return radios[radio].on;
}
