// @ts-check

//https://www.golinuxcloud.com/mathtoradians-javascript/
/**
 *
 * @param {number} degrees
 * @returns {number}
 */
export function degreesToRadians(degrees) {
    return (degrees % 360) * (Math.PI / 180);
}
