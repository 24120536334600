// @ts-check

import { AudioAmbianceGroup } from "./AudioAmbianceGroup";

/**
 * 
 */
export class AmbianceAudioListener {
    /**
* 
* @type {import('./webAudio').WebAudioInterface}
*/
  audio;
    /**
* 
* @type {import('./AudioAmbianceGroup').AudioAmbianceGroup}
*/
  ambianceGroup;
/**
 * 
 * @param {import('./webAudio').WebAudioInterface} audio 
 */
  constructor(audio) {
    this.audio = audio;
    this.ambianceGroup = new AudioAmbianceGroup(this.audio);
  }
/**
 * 
 */
  onStartAudioOnGesture() {
    this.ambianceGroup.onStartAudioOnGesture();
  }
/**
 * 
 * @param {import('./audioAmbiance').AudioAmbiance} ambiance 
 */
  addAudioAmbiance(ambiance) {
    this.ambianceGroup.add(ambiance);
 
  }
/**
 * 
 * @param {import('./audioAmbiance').AudioAmbiance} ambiance 
 * @param {import('./audioAmbiance').AudioAmbiance|undefined} [next_ambiance]
 */
  removeAudioAmbiance(ambiance, next_ambiance) {
    if (next_ambiance !== undefined) {
      this.ambianceGroup.transition(ambiance, next_ambiance);
    } else {
      this.ambianceGroup.remove(ambiance);
    }
  }

  // start() {
  //   this.ambianceGroup.start();
  // }
}
