import { Action } from "../actions/action";

export class ExitModule extends Action {
    value;
    value_context;
    constructor(verb, name, json) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive) {
        console.log("action:Exit");
        const parent = window.location?.ancestorOrigins[0];
        window.parent.postMessage(
            {
                action: "exit", // Action key.
                receiptToken: "{receipt-token}", // Receipt validation token.
            },
            parent,
        );
    }
}
