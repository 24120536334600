import { WebApplicationState } from "../WebApplicationState";
import page from "page";
import { showPendingPage } from "./routes/login/pending/pendingPage";
import { hideLoadingIndicator, showPhoneLoginPage } from "./routes/login/phoneAuth/login";
import { showTVLoginPage } from "./routes/login/TVLogin";
import { signUpPage } from "./routes/login/phoneAuth/newAccount";
import { successPage } from "./routes/login/phoneAuth/success";
import { showLoadingPage } from "./routes/loading/loading";

/**
 * @typedef {Object} RouterInterface
 * @property {() => void} redirectToLogin
 * @property {() => void} initialize
 * @property {() => void} saveState
 * @property {() => void} shutdown
 * @property {() => void} loadPageContent
 */

/**
 * @implements {AuthInterface}
 */
export class StaticRouter {
    /**
     * @param {WebApplicationState} state
     */
    constructor(state) {
        this.state = state;
    }

    /**
     * Initialize the router by defining all routes.
     */
    initialize() {
        page("/login", async () => {
            if (window.location.hash.includes("pending")) {
                // login/#pending/connectionID - Pending page
                const connectionID = window.location.hash.split("/")[1];
                if (connectionID) {
                    showPendingPage(connectionID, this.state);
                    return;
                }
            } else if (window.location.hash.includes("success")) {
                // login/#success - Success page
                successPage();
                return;
            } else if (window.location.hash.includes("new")) {
                // login/#new - Create account page
                const connectionID = window.location.hash.split("/")[1];
                signUpPage(this.state, connectionID);
                return;
            } else if (window.location.hash.includes("game_redirect")) {
                showPhoneLoginPage("", this.state);
            } else if (!window.location.hash.includes("redirect")) {
                // login/#connectionID - Phone login on QR code scan.
                const connectionID = window.location.hash.split("#")[1];
                if (connectionID) {
                    showPhoneLoginPage(connectionID, this.state);
                    return;
                }
            }
            showTVLoginPage(this.state);
        });

        page("/loading", () => {
            showLoadingPage(this.state);
        });

        page("/", () => {});
        page(); // Initialize all routes
    }

    /**
     * Redirects to the login page.
     */
    redirectToLogin() {
        page.redirect("/login");
    }

    /**
     * Resets the canvas to the original state.
     */
    resetPageToCanvas() {
        document.getElementById("login-modal")?.remove();
        this.state.canvas.canvas.style.display = "block";
        const video = document.getElementById("video-");
        video.style.display = "block";
        this.state.canvas.enableInput();
    }

    /**
     * Used to load the content of a page into the viewport.
     * @param {String} page the HTML content of the page to load.
     */
    addPageToViewport(page) {
        this.state.canvas.disableInput();
        this.state.canvas.canvas.style.display = "none";
        const video = document.getElementById("video-");
        video.style.display = "none";

        if (!document.getElementById("login-modal")) {
            const container = document.createElement("div");
            container.id = "login-modal";
            container.innerHTML = page;
            this.state.canvas.viewport.appendChild(container);
        } else {
            document.getElementById("login-modal").innerHTML = page;
        }
    }

    /**
     * TODO - what is this used for?
     */
    saveState() {
        console.log("Static router save state");
    }

    /**
     *
     */
    shutdown() {
        this.saveState();
    }
}
