// @ts-check
import { PlaylistStem } from "./playlistStem";
import { IAudioPlaylist } from "./Interfaces/IAudioPlaylist";

/**
 * @class
 * @implements {IAudioPlaylist}
 */
export class AudioPlaylist {
    /** @type {string} */
    name;
    /** @type {Array} */
    stems;
    /** @type {Array<PlaylistStem>} */
    #playlist = [];
    /** @type {number} */
    #currentTrack = 0;
    #paused = false;
    container;
    /** @type {boolean} */
    #isLooping = true;
    /** @type {boolean} */
    #isMuted = false;
    /** @type {number} */
    #gain = 0.5;
    get paused() {
        return this.#paused;
    }
    get currentTrack() {
        return this.#currentTrack;
    }
    get muted() {
        return this.#isMuted;
    }
    get gain() {
        return this.#gain;
    }
    get currentStem() {
        return this.#playlist[this.#currentTrack];
    }
    /**
     *
     * @param {String} channelContainerId
     * @param {string} channelId
     * @param {string} playlistName
     * @param {Array<Object>} stems
     * @param {number} gain
     * @param {Boolean} looping
     */
    constructor(channelContainerId, channelId, playlistName, stems, gain, looping = false) {
        this.#gain = gain;
        this.stems = stems;
        if (stems === undefined) {
            throw new Error(`Playlist Did Not recieve Stems`);
        }
        this.name = playlistName;
        this.#isLooping = looping;
        this.#currentTrack = 0;

        const channelContainer = document.getElementById(channelContainerId);
        const playlistContainer = document.getElementById(channelId);
        if (channelContainer === null) {
            throw new Error(`NO CHANNEL CONTAINER TO APPEND PLAYLIST`);
        }
        if (!playlistContainer) {
            const newContainer = document.createElement("div");
            newContainer.id = channelId;
            channelContainer.appendChild(newContainer);
        }

        this.container = document.getElementById(channelId);

        this.#playlist = this.stems.map((element) => {
            return new PlaylistStem(
                element.audioSrc,
                element.gain,
                () => "music", // TODO: Make filepath Configurable
                this,
                this.playNextTrack.bind(this),
            );
        });
    }

    /**
     *
     */
    setGain(newGain) {
        const gain = newGain;
        if (gain <= 0.0) {
            this.#gain = 0.0;
        } else if (gain >= 1.0) {
            this.#gain = 1.0;
        } else {
            this.#gain = gain;
        }

        // error handling
        this.currentStem.syncStemWithPlaylistSettings();
    }

    /**
     *
     */
    start() {
        if (!this.container?.hasChildNodes()) {
            this.currentStem.load();
            this.currentStem.play();
        }
    }

    /**
     *
     */
    mute() {
        this.#isMuted = true;
        this.currentStem.syncStemWithPlaylistSettings();
    }

    /**
     *
     */
    unmute() {
        this.#isMuted = false;
        this.currentStem.syncStemWithPlaylistSettings();
    }

    /**
     *
     */
    playNextTrack() {
        this.#currentTrack++;
        if (this.#currentTrack >= this.#playlist.length) {
            this.#currentTrack = 0;
            if (!this.#isLooping) {
                return;
            }
        }
        this.start();
    }

    /**
     *
     */
    stop() {
        this.currentStem.remove();
    }

    /**
     *
     */
    pause() {
        // if not loaded or playing do nothing
        this.#paused = true;
        this.currentStem.pause();
    }

    /**
     *
     */
    resume() {
        // if not loaded or paused do nothing
        this.#paused = false;
        this.currentStem.play();
    }
}
