//@ts-check

import { GettingReadyCallback, GettingReadyCallbackCollector } from '../../sceneGraph/GettingReadyCallbackCollector';
import { AmbianceContext } from '../audio/AmbianceContext';
import { AudioAmbiance } from '../audio/audioAmbiance';
import { AudioListenerScope } from '../audio/AudioListenerScope';
import { VisualElement } from './visualElement';
import { VisualElements } from './VisualElements';
/**
 * 
 */
export class AudioVisualPlaybackQueueItem {

    /**
     * @type {GettingReadyCallbackCollector|undefined}
     */
    callback_collector;
    /**
     * 
     * @type {Array.<object>}
     */
    stems;
    /**
     * 
     * @type {VisualElement|undefined}
     */
    visual_element;

    /**
     * 
     * @type {import('../audio/audioAmbiance').AudioAmbiance|undefined}
     */
    audio_element;

    /**
    * 
    * @type {boolean}
    */
    is_looping = true;

    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     */
    start(obj) {
        if (this.visual_element) {
            this.visual_element.start();
        }
        if (this.audio_element) {
            obj.scene_graph_node?.listener.addAudioAmbiance(this.audio_element);
        }
    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {AudioVisualPlaybackQueueItem|undefined} [next_queue_item] 
     */
    stop(obj, next_queue_item) {
        if (this.visual_element) {
            this.visual_element.stop(undefined);
        }
        if (this.audio_element) {
            obj.scene_graph_node?.listener.removeAudioAmbiance(this.audio_element, next_queue_item?.audio_element);
        }
    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {import('../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     */
    start_loading(obj, gettingReadyCallbackCollector) {

        if (this.visual_element || this.audio_element) {

            this.visual_element?.startVisualContentGetReady(gettingReadyCallbackCollector);
            return;
        }

        for (const each of this.stems || []) {

            if (VisualElements.VideoVisualResourceType?.canCreateResourceFromJsonObject(each)) {

                if (!this.visual_element) {
                    this.visual_element = new VisualElement(obj, each, obj.getResourcePath());
                    this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
                }

            } else if (each.audioSrc) {

                if (!this.audio_element) {
                    this.audio_element = this.newAudioAmbiance(obj, {});
                }
                this.audio_element.addStemFromJson(each);

            } else if (each.audioAmbiance) {
                this.audio_element = this.newAudioAmbiance(obj, each.audioAmbiance);
            }
        }
    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {object} json 
     */
    newAudioAmbiance(obj, json) {
        if (!obj.toAudioContext) {
            var scope = AudioListenerScope.fromScenePath(obj.toScenePath(), obj, obj.sceneGraph);
            var audioContext = new AmbianceContext(scope);
        } else {
            var audioContext = obj.toAudioContext();
        }

        return new AudioAmbiance(
            audioContext,
            json,
            () => obj.getResourcePath()
        );
    }
}

/**
 * 
 */
export class AudioVisualPlaybackQueue {
    /**
  * 
  * @type {Promise|undefined}
  */
    callback_collector_promise;

    /**
     * 
     * @type {boolean}
     */
    isStarted = false;
    /**
     * 
     * @type {AudioVisualPlaybackQueueItem}
     */
    active_item;
    /**
     * 
     * @type {Array.<AudioVisualPlaybackQueueItem>}
     */
    item_queue = [];


    /**
     * 
     * @type {import('../../sceneGraph/scene').SceneObjectInterface}
     */
    scene_object;

    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} scene_object 
     */
    constructor(scene_object) {
        this.scene_object = scene_object;
    }
    /**
     * 
     * @param {Array.<object>} stems 
     */
    set_looping_stems(stems) {
        var item = new AudioVisualPlaybackQueueItem();
      
        item.stems = stems;

        if (!this.isStarted) {
            this.active_item = item;
        } else {
            this.item_queue = [item];
            this.beginPlayingNextInQueue();
        }
    }
    /**
    * 
    * @param {AudioVisualPlaybackQueueItem} item 
    */
    pushItem(item) {
        this.item_queue.push(item);
    }
    /**
     * 
     */
    beginPlayingNextInQueue() {
        var next = this.item_queue.shift();

        if (!next) {
            return;
        }

        var toStop;

        if (this.isStarted) {
            toStop = this.active_item;
        }

        this.active_item = next;
        this.active_item.callback_collector = new GettingReadyCallbackCollector();

        this.start_loading(this.active_item.callback_collector);

        this.callback_collector_promise = this.active_item.callback_collector.newWaitPromiseForAllCallbacks().then((result) => {
            this.callback_collector_promise = undefined;

            if (toStop) {
                toStop.stop(this.scene_object, this.active_item);
            }
            this.start();
        });
    }

    /**
     * 
     * @param {import('../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     */
    start_loading(gettingReadyCallbackCollector) {
        this.active_item?.start_loading(this.scene_object, gettingReadyCallbackCollector);
    }
    /**
      * 
      */
    start() {
        this.active_item?.start(this.scene_object);
        this.isStarted = true;
    }
    /**
     * 
     * @param {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} next_resource_canvas_element 
     */
    stop(next_resource_canvas_element) {
        this.active_item?.stop(this.scene_object, undefined);
        this.isStarted = false;
    }
}
