//@ts-check

import {
    GettingReadyCallback,
    GettingReadyCallbackCollector,
} from "../../sceneGraph/GettingReadyCallbackCollector";
import { AudioVisualPlaybackQueueItem } from "./AudioVisualPlaybackQueueItem";
/**
 *
 */
export class AudioVisualPlaybackQueue {
    /**
     *
     * @type {Promise|undefined}
     */
    callback_collector_promise;

    /**
     *
     * @type {boolean}
     */
    isStarted = false;
    /**
     *
     * @type {AudioVisualPlaybackQueueItem}
     */
    active_item;
    /**
     *
     * @type {Array.<AudioVisualPlaybackQueueItem>}
     */
    item_queue = [];

    /**
     *
     * @type {import('../../sceneGraph/scene').SceneObjectInterface}
     */
    scene_object;

    /**
     *
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} scene_object
     */
    constructor(scene_object) {
        this.scene_object = scene_object;
    }
    /**
     *
     * @param {Array.<object>} stems
     */
    set_looping_stems(stems) {
        var item = new AudioVisualPlaybackQueueItem();

        item.stems = stems;

        if (!this.isStarted) {
            this.active_item = item;
        } else {
            this.item_queue = [item];
            this.beginPlayingNextInQueue();
        }
    }
    /**
     *
     * @param {AudioVisualPlaybackQueueItem} item
     */
    pushItem(item) {
        this.item_queue.push(item);
    }

    /**
     *
     */
    clearQueue() {
        this.item_queue = [];
    }

    /**
     *
     */
    beginPlayingNextInQueue() {
        var next = this.item_queue.shift();

        if (!next) {
            return;
        }

        var toStop;

        if (this.isStarted) {
            toStop = this.active_item;
        }

        this.active_item = next;
        this.active_item.callback_collector = new GettingReadyCallbackCollector();

        this.start_loading(this.active_item.callback_collector);

        this.callback_collector_promise = this.active_item.callback_collector
            .newWaitPromiseForAllCallbacks()
            .then((result) => {
                this.callback_collector_promise = undefined;

                if (toStop) {
                    toStop.stop(this.scene_object, this.active_item);
                }
                this.start();
            });
    }

    /**
     *
     * @param {import('../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector
     */
    start_loading(gettingReadyCallbackCollector) {
        this.active_item?.start_loading(this.scene_object, gettingReadyCallbackCollector);
    }
    /**
     *
     */
    start() {
        this.active_item?.start(this.scene_object);
        this.isStarted = true;
    }
    /**
     *
     * @param {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} next_resource_canvas_element
     */
    stop(next_resource_canvas_element) {
        this.active_item?.stop(this.scene_object, undefined);
        this.isStarted = false;
    }
}
