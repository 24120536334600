// @ts-check

/**
 * 
 */
export class AutoCanvasElementInvalidation {
	/**
	 *  @type {NodeJS.Timeout|undefined}
	 */
	timer;
	/**
	 *  @type {Array.<import('./visual/canvasElements/CanvasElement').CanvasElement>}
	 */
	elements;
	/**
	 *  @type {number}
	 */
	fps;
	/**
	 *  @type {import('./interactive_canvas').InteractiveCanvas}
	 */
	icanvas;
	/**
	 *  @type {boolean}
	 */
	isStop;
/**
 * 
 * @param {number} fps 
 * @param {import('./interactive_canvas').InteractiveCanvas} icanvas 
 */
	constructor(fps, icanvas) {
		this.fps = fps;
		this.elements = [];
		this.icanvas = icanvas;
	}
/**
 * 
 */
	start() {
		this.timerCallback();
	}
	/**
	 * 
	 * @param {import('./visual/canvasElements/CanvasElement').CanvasElement} element 
	 */
	removeElement(element) {
		let index = this.elements.indexOf(element);
		if (index >= 0) {
			this.elements.splice(index, 1);
		}
	}
	/**
	 * 
	 * @returns {boolean}
	*/
	get isEmpty() {
		return this.elements.length == 0;
	}
/**
 * 
 */
	timerCallback() {
		if (this.isStop) {
			return;
		}

		this.invalidateElements();
		this.icanvas.try_invalidated_draw();

		this.timer = setTimeout(() => {
			this.timerCallback();
		}, this.milliseconds)
	}
/**
 * 
 */
	stop() {
		this.isStop = true;
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = undefined;
		}
	}
	/**
	 * 
	 * @returns {number}
	*/
	get milliseconds() {
		return (1 / this.fps) * 1000;
	}
/**
 * 
 */
	invalidateElements() {
		for (const each of this.elements) {
			each.invalidate();
		}
	}
}
