// @ts-check
/**
 *
 */
export class OnDrawingParams {
    /**
     * @type {boolean}
     */
    isCancel;
}

/**
 * @callback onDrawingFunction
 * @param {OnDrawingParams} params
 */
